import * as Yup from "yup";
import { number } from "yup";

const STRING_REQUIRED = Yup.string().required(
  "Это поле обязательно к заполнению"
);
const REQUIRED = Yup.mixed().required("Это поле обязательно к заполнению");
const STRING_REQUIRED_NO_LATIN = STRING_REQUIRED.matches(
  /^[A-Я]([А-Я]|[а-я]|\s|-)+$/,
  "Строка содержит недопустимый символ или начинается со строчной буквы"
).max(50, "Mаксимум 50 символов");
const VALIDATE_DATE_REQUIRED = Yup.date()
  .min(new Date("1900/01/01"), "Недопустимая дата")
  .max(new Date(), "Недопустимая дата")
  .required("Это поле обязательно к заполнению");

export const clientSchema = Yup.object().shape({
  traffic_id: Yup.string().required("Это поле обязательно к заполнению"),
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
  phone: Yup.string()
    .min(10, "Номер не полный")
    .required("Это поле обязательно к заполнению"),
  car_id: Yup.string().required("Это поле обязательно к заполнению"),
  result_id: Yup.string().required("Это поле обязательно к заполнению"),
  comment: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
});

export const statusSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
  stageId: Yup.string().required("Это поле обязательно к заполнению"),
});

export const stageSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
});

export const regionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
});

export const categorySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
});

export const tourSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Минимум 2 символа")
    .required("Это поле обязательно к заполнению"),
  distance: Yup.number("Должно быть числом")
    .min(1, "Минимальное значение 1")
    .required("Это поле обязательно к заполнению"),
  category: Yup.string().required("Это поле обязательно к заполнению"),
  region: Yup.string().required("Это поле обязательно к заполнению"),
  points: Yup.array().of(
    Yup.object().shape({
      name: STRING_REQUIRED,
      description: STRING_REQUIRED,
      latitude: STRING_REQUIRED.min(9, "Минимум 8 чисел"),
      longitude: STRING_REQUIRED.min(9, "Минимум 8 чисел"),
    })
  ),
});

export const updateUserSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: STRING_REQUIRED.matches(
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      "Недопустимый формат"
    ).max(50, "Mаксимум 50 символов"),
    password: STRING_REQUIRED.min(6, "Минимум 6 символов"),
    name: STRING_REQUIRED,
    surname: STRING_REQUIRED,
    patronymic: STRING_REQUIRED,
    birthdate: VALIDATE_DATE_REQUIRED,
    phone: STRING_REQUIRED.min(10, "Номер не полный"),
  }),
  roles: Yup.array().of(
    Yup.object().shape({
      id: STRING_REQUIRED,
    })
  ),
  passport: Yup.object().shape({
    series_number: STRING_REQUIRED.matches(
      /^[0-9]+$/,
      "Можно вводить только цифры"
    )
      .min(10, "Минимум 10 символов")
      .max(10, "Mаксимум 10 символов"),
    isset: VALIDATE_DATE_REQUIRED,
    reg_address: STRING_REQUIRED,
  }),
});

export const createUserSchema = Yup.object().shape({
  src: Yup.object().shape({
    passportPhotoInformation: STRING_REQUIRED,
    passportPhotoWithUser: STRING_REQUIRED,
    passportPhototRegistration: STRING_REQUIRED,
    licencePhoto: STRING_REQUIRED,
    licencePhotoOtherside: STRING_REQUIRED,
  }),
  user: Yup.object().shape({
    email: STRING_REQUIRED.matches(
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      "Недопустимый формат"
    ).max(50, "Mаксимум 50 символов"),
    password: STRING_REQUIRED.min(6, "Минимум 6 символов"),
    name: STRING_REQUIRED_NO_LATIN,
    surname: STRING_REQUIRED_NO_LATIN,
    patronymic: STRING_REQUIRED_NO_LATIN,
    birthdate: VALIDATE_DATE_REQUIRED,
    phone: STRING_REQUIRED.min(10, "Номер не полный"),
  }),
  roles: Yup.array().of(
    Yup.object().shape({
      id: STRING_REQUIRED,
    })
  ),
  passport: Yup.object().shape({
    photo_information: REQUIRED,
    series_number: STRING_REQUIRED.matches(
      /^[0-9]+$/,
      "Можно вводить только цифры"
    )
      .min(10, "Минимум 10 символов")
      .max(10, "Mаксимум 10 символов"),
    isset: VALIDATE_DATE_REQUIRED,
    reg_address: STRING_REQUIRED.max(50, "Mаксимум 50 символов"),
    photo_with_user: REQUIRED,
    photo_registration: REQUIRED,
  }),
  driver_licence: Yup.object().shape({
    photo: REQUIRED,
    photo_otherside: REQUIRED,
  }),
});

export const rentSchema = Yup.object().shape({
  carForRent: REQUIRED,
  user: REQUIRED,
  startDate: STRING_REQUIRED.nullable(),
  endDate: STRING_REQUIRED.nullable(),
  startTime: STRING_REQUIRED.nullable(),
});

export const carFinanceSchema = Yup.object().shape({
  cost: STRING_REQUIRED,
  discountBefore: STRING_REQUIRED,
  discountAfter: STRING_REQUIRED,
  // costThreeYears: STRING_REQUIRED,
  // oneThreeDaysCost: STRING_REQUIRED,
  // fourTenDaysCost: STRING_REQUIRED,
  // elevenThirtyDaysCost: STRING_REQUIRED,
  insuranceFirstYear: STRING_REQUIRED,
  insuranceSecondYear: STRING_REQUIRED,
  insuranceThirdYear: STRING_REQUIRED,
  tireWorks: STRING_REQUIRED,
  technicalInspectionThreeYears: STRING_REQUIRED,
});
