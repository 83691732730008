import { useFormik } from "formik";
import {
  isBefore,
  isValid,
  setHours,
  setMinutes,
  isSameDay,
  isWithinInterval,
  differenceInDays,
} from "date-fns";
import ReactDatePicker from "react-datepicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { formatDate, getDateTimeBetween } from "utils/date";
import DatePickerInput from "components/Common/DatePickerInput";
import { rentSchema } from "utils/validationShemas";
import { rentAPI } from "services/rentService";

const CreateRentModal = ({ setIsShow, isShow, cars }) => {
  const [maxDate, setMaxDate] = useState(null);
  const [rentDocLink, setRentDocLink] = useState("");
  const [inputUser, setInputUser] = useState("");

  const [getRentForCar, { data: rentsForCar, isLoading: rentsForCarLoading }] =
    rentAPI.useLazyGetRentsForCarQuery({});
  const [getRentSum, { data: rentSum, isLoading: rentSumLoading }] =
    rentAPI.useLazyGetRentSumQuery({});
  const [createRent, { data: createdRent, isLoading: createRentLoading }] =
    rentAPI.useCreateRentMutation();
  const [getUsers, { data: users }] = rentAPI.useLazyGetUsersForRentQuery();

  const formik = useFormik({
    initialValues: {
      carForRent: null,
      user: null,
      startDate: null,
      endDate: null,
      startTime: null,
    },
    validationSchema: rentSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (
      { carForRent, user, startDate, endDate, startTime },
      { resetForm }
    ) => {
      createRent({
        user_id: user.value.id,
        car_id: carForRent.value.id,
        date_start: formatDate(
          setHours(
            setMinutes(startDate, startTime.getMinutes()),
            startTime.getHours()
          ),
          "yyyy-MM-dd HH:mm:ss"
        ), //перевожу в формат, в котором хранится на бэке, чтобы не путаться с ISO
        date_end: formatDate(
          setHours(
            setMinutes(endDate, startTime.getMinutes()),
            startTime.getHours()
          ),
          "yyyy-MM-dd HH:mm:ss"
        ),
        amount: rentSum.sum,
      });
      setRentDocLink(createdRent?.data.data.link);
      setFieldValue("carForRent", null);
      setFieldValue("userId", "");
      resetForm();
      handleModalToggle();
    },
  });

  const setFieldValue = formik.setFieldValue; //необходимо для того, чтобы использовать в useEffect, и не включать в зависимости formik
  const user = useMemo(() => formik.values.user, [formik.values.user]);
  const carForRent = useMemo(
    () => formik.values.carForRent,
    [formik.values.carForRent]
  ); //чтобы не писать везде formik.values.
  const startDate = useMemo(
    () => formik.values.startDate,
    [formik.values.startDate]
  );
  const endDate = useMemo(() => formik.values.endDate, [formik.values.endDate]);
  const startTime = useMemo(
    () => formik.values.startTime,
    [formik.values.startTime]
  );

  //   const {
  //     loading: getRentsForCarLoading,
  //     execute: executeGetRentsForCar,
  //     result: rentsForCar,
  //   } = useAsync({ asyncFunction: getRentsForCar });

  //   const { loading: createRentLoading, execute: executeCreateRent } = useAsync({
  //     asyncFunction: createRent,
  //   });
  //   const { result: rentSum, execute: executeGetRentSum } = useAsync({
  //     asyncFunction: getRentSum,
  //   });

  const handleModalToggle = useCallback(() => {
    setIsShow(!isShow);
    setFieldValue("startDate", null);
    setFieldValue("endDate", null);
    setFieldValue("carForRent", null);
  }, [isShow, setFieldValue, setIsShow]);

  const period = useCallback(() => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays || 0;
  }, [endDate, startDate]);

  // const calcRent = useCallback(() => {
  //   let cost = 0;
  //   let periodDays = period();
  //   if (periodDays <= 3 && carForRent) {
  //     cost = carForRent?.one_three_days_cost;
  //   } else if (periodDays >= 4 && periodDays <= 10) {
  //     cost = carForRent?.four_ten_days_cost;
  //   } else if (periodDays >= 10) {
  //     cost = carForRent?.eleven_thirty_days_cost;
  //   }

  //   setSumRent(cost * period());
  // }, [carForRent, period]);

  //   useEffect(() => {
  //     if (rentSum !== null) setSumRent(rentSum?.data?.sum);
  //   }, [rentSum]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const dateFrom = formatDate(startDate, "yyyy-MM-dd");
      const dateTo = formatDate(endDate, "yyyy-MM-dd");
      getRentSum({
        carId: carForRent.value.id,
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
    }
  }, [endDate, startDate]);

  const getMaxEndDateForSelectedStartDate = useCallback(() => {
    const sortedRentsByDate = [...rentsForCar?.data].sort(
      (rentA, rentB) =>
        new Date(rentA.start).getTime() - new Date(rentB.start).getTime()
    );

    const firstRentAfterSelectedStartDate = sortedRentsByDate.find((rent) =>
      isBefore(
        new Date(
          setHours(
            setMinutes(startDate, startTime.getMinutes()),
            startTime.getHours()
          )
        ),
        new Date(rent.end)
      )
    );
    return firstRentAfterSelectedStartDate?.start;
  }, [rentsForCar, startDate, startTime]);

  const getOccupiedHoursForDate = useCallback(
    (date) => {
      const rentsInDate = rentsForCar?.data?.filter((rent) => {
        return (
          isSameDay(new Date(rent.start), date) ||
          isSameDay(new Date(rent.end), date) ||
          (isBefore(new Date(rent.start), new Date(rent.end)) &&
            isWithinInterval(date, {
              start: new Date(rent.start),
              end: new Date(rent.end),
            }))
        );
      });
      if (!rentsInDate?.length) return rentsInDate;
      const excludedTimes = rentsInDate?.map((rent) =>
        getDateTimeBetween(rent.start, rent.end, date)
      );
      return excludedTimes?.flat();
    },
    [rentsForCar]
  );

  useEffect(() => {
    if (!startTime) return;

    const maxDate = getMaxEndDateForSelectedStartDate();
    if (!isValid(new Date(maxDate))) return;
    setMaxDate(new Date(maxDate));
  }, [startTime, getMaxEndDateForSelectedStartDate, getOccupiedHoursForDate]);

  useEffect(() => {
    getUsers({ fio: inputUser });
  }, [inputUser]);

  useEffect(() => {
    if (startDate) {
      setMaxDate(null);
      setFieldValue("startTime", null);
      setFieldValue("endDate", null);
    }
  }, [startDate, setFieldValue]);

  useEffect(() => {
    setFieldValue("endDate", null);
  }, [startTime, setFieldValue]);

  useEffect(() => {}, [endDate, setFieldValue]);

  useEffect(() => {
    carForRent && getRentForCar(carForRent.id);
    setFieldValue("startDate", null);
    setFieldValue("endDate", null);
    setFieldValue("startTime", null);
  }, [carForRent, getRentForCar, setFieldValue]);

  const selectOptionsCars = useMemo(() => {
    return cars?.data
      .map((car) => {
        return {
          value: car,
          label: car.brand + " " + car.model + " " + car.registration_number,
        };
      })
      .filter((item) => item.label.length != 0);
  }, [cars]);

  const selectOptionsUsers = useMemo(() => {
    return users?.data
      .map((user) => {
        return {
          value: user,
          label:
            user.name != null
              ? user?.surname + " " + user?.name + " " + user?.phone
              : "id: " + user.id,
        };
      })
      .filter((item) => item.label.length != 0);
  }, [users]);

  const limitationDates = () => {
    const newDate = new Date(startDate);
    return newDate?.setDate(newDate?.getDate() + 1);
  };

  return (
    <Modal toggle={handleModalToggle} isOpen={isShow}>
      <ModalHeader toggle={handleModalToggle}>Создайте аренду </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup row style={{ marginBottom: 10 }}>
            <Label for="carForRent" sm={4}>
              Машина
            </Label>

            <Col sm={8}>
              <div style={{ width: "65%" }}>
                <Select
                  value={carForRent}
                  onChange={(e) => {
                    formik.setFieldValue("carForRent", e);
                  }}
                  placeholder="Выберите машину"
                  isClearable
                  isSearchable
                  options={selectOptionsCars}
                />
              </div>

              <FormText color="danger">{formik.errors.carForRent}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: 10 }}>
            <Label for="user" sm={4}>
              Клиент
            </Label>
            <Col sm={8}>
              <div style={{ width: "65%" }}>
                <Select
                  onInputChange={(e) => {
                    setInputUser(e);
                  }}
                  value={user}
                  onChange={(e) => formik.setFieldValue("user", e)}
                  placeholder="Выберите пользователя"
                  isClearable
                  isSearchable
                  options={selectOptionsUsers}
                />
              </div>

              <FormText color="danger">{formik.errors.user}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: 10 }}>
            <Label sm={4} for="startDate">
              Дата начала аренды
            </Label>
            <Col sm={8}>
              <ReactDatePicker
                id="startDate"
                minDate={new Date()}
                selected={startDate}
                onChange={(date) => formik.setFieldValue("startDate", date)}
                disabled={rentsForCarLoading || !carForRent}
                customInput={
                  <DatePickerInput
                    invalid={!!formik.errors.startDate}
                    disabled={rentsForCarLoading || !carForRent}
                  />
                }
                dateFormat="dd.MM.yyyy"
              />
              <FormText color="danger">{formik.errors.startDate}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: 10 }}>
            <Label sm={4} for="startTime">
              Время начала и окончания аренды
            </Label>
            <Col sm={8}>
              <ReactDatePicker
                showTimeSelectOnly
                id="startTime"
                selected={startTime}
                onChange={(date) => {
                  formik.setFieldValue("startTime", date);
                }}
                excludeTimes={getOccupiedHoursForDate(startDate)}
                disabled={rentsForCarLoading || !startDate}
                customInput={
                  <DatePickerInput
                    invalid={!!formik.errors.startTime}
                    disabled={!startDate}
                  />
                }
                dateFormat="HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormText color="danger">{formik.errors.startTime}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: 10 }}>
            <Label sm={4} for="endDate">
              Дата конца аренды
            </Label>
            <Col sm={8}>
              <ReactDatePicker
                id="endDate"
                selected={endDate}
                onChange={(date) => formik.setFieldValue("endDate", date)}
                minDate={limitationDates()}
                {...(startDate &&
                  startTime &&
                  isBefore(
                    setHours(
                      setMinutes(startDate, startTime.getMinutes()),
                      startTime.getHours()
                    ),
                    maxDate
                  ) &&
                  maxDate && { maxDate })}
                dateFormat="dd.MM.yyyy"
                customInput={
                  <DatePickerInput
                    invalid={!!formik.errors.endDate}
                    disabled={!startTime}
                  />
                }
                disabled={rentsForCarLoading || !startTime}
              />
              <FormText color="danger">{formik.errors.endDate}</FormText>
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label sm={4} for="endTime">
              Время конца аренды
            </Label>
            <Col sm={8}>
              <ReactDatePicker
                showTimeSelectOnly
                id="endTime"
                selected={endTime}
                onChange={(date) => {
                  formik.setFieldValue("endTime", date);
                }}
                {...(isSameDay(startDate, endDate)
                  ? {
                      minTime: startTime,
                      maxTime: setHours(setMinutes(endDate, 45), 23),
                    }
                  : {})}
                {...(!isSameDay(startDate, endDate) &&
                  maxDate && {
                    maxTime: isBefore(endDate, maxDate)
                      ? setHours(setMinutes(new Date(), 45), 23)
                      : setHours(
                          setMinutes(new Date(), maxDate.getMinutes()),
                          maxDate.getHours()
                        ),
                    minTime: setHours(setMinutes(new Date(), 0), 0),
                  })}
                disabled={getRentsForCarLoading || !endDate}
                customInput={
                  <DatePickerInput
                    invalid={!!formik.errors.endTime}
                    disabled={!endDate}
                  />
                }
                dateFormat="HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormText color="danger">{formik.errors.startTime}</FormText>
            </Col>
          </FormGroup> */}
          <span> Сумма аренды: </span>{" "}
          <span> {rentSum && rentSum.sum > 0 ? `${rentSum.sum} ₽` : ""} </span>
        </ModalBody>
        <ModalFooter>
          <FormText color="danger">
            {/* {formik.errors.lengthRentError &&
              "Длина аренды должа быть минимум 1 сутки"} */}
          </FormText>
          {rentDocLink && (
            <Button className="w-auto ml-2" color="primary">
              <a className="text-white" href={rentDocLink} target="_blank">
                Скачать новый договор
              </a>
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            disabled={createRentLoading}
            onClick={() => {
              startTime &&
                formik.setFieldValue(
                  "lengthRentError",
                  Math.abs(
                    differenceInDays(
                      setHours(
                        setMinutes(startDate, startTime.getMinutes()),
                        startTime.getHours()
                      ),
                      setHours(
                        setMinutes(endDate, startTime.getMinutes()),
                        startTime.getHours()
                      )
                    )
                  ) < 1
                    ? null
                    : "ok"
                );
            }}
          >
            {createRentLoading ? "Загрузка..." : "Создать"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateRentModal;
