import { Button, Card, Input, Label } from 'reactstrap';
import KnowledgeReasonSelector from './Common/KnowledgeReasonSelector';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { careAPI } from 'services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { KnowledgeReason, SelectObj } from 'types/CareServiceTypes';
import { useHistory } from 'react-router-dom';

interface CreateKnowledgeProps {
  preHandler?: () => Promise<number | undefined>;
  carId?: number | null;
  disableBtnCondition?: boolean;
  navigateToBack?: boolean;
  title?: string;
  enabledScrollToBottom?: boolean;
  onSuccess?: () => void;
}

interface AdditionalInfo {
  advise: string;
  isHelped: null | SelectObj;
}

const CreateKnowledge = ({
  preHandler,
  carId,
  disableBtnCondition = false,
  navigateToBack = true,
  title,
  enabledScrollToBottom = true,
  onSuccess,
}: CreateKnowledgeProps) => {
  const history = useHistory();

  const [createKnowledge, { isSuccess, isLoading: isCreating }] = careAPI.useCreateKnowledgeMutation();
  const [selectedReasonItem, setSelectedReasonItem] = useState<null | KnowledgeReason>(null);

  const { data: errorCodes } = careAPI.useGetKnowledgeErrorsQuery();

  const [errorCode, setErrorCode] = useState<{ value: number; label: string } | null>(null);
  const [reasonCode, setReasonCode] = useState<number>();

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    advise: '',
    isHelped: null,
  });

  const createHandler = useCallback(async () => {
    const value = await preHandler?.();

    if (preHandler && value === undefined) {
      return;
    }

    createKnowledge({
      car_id: carId ?? (await preHandler?.()),
      reason: selectedReasonItem?.name,
      result_solution: [additionalInfo.isHelped?.value],
      works: [additionalInfo.advise],
      reasonCodes: reasonCode ? [reasonCode] : null,
      errorCodes: errorCode ? [errorCode.value] : null
    });
  }, [preHandler, selectedReasonItem, additionalInfo, errorCode, reasonCode]);

  const helpedValues = useMemo(
    () => [
      { label: 'Помогло', value: true },
      { label: 'Не помогло', value: false },
    ],
    []
  );

  useEffect(() => {
    setAdditionalInfo({
      advise: '',
      isHelped: null,
    });

    setSelectedReasonItem(null);

    if (isSuccess && navigateToBack) {
      history.push('/care-service');
    }

    onSuccess?.();
  }, [isSuccess]);

  return (
    <div className='card'>
      <div className='card-header'>{title ?? 'Подробности заявки'}</div>
      <div className='card-body'>
        <div style={{ display: 'flex', gap: 15 }}>
          <KnowledgeReasonSelector
            setReasonCode={setReasonCode}
            enableScrollToBottom={enabledScrollToBottom}
            reasonItem={selectedReasonItem}
            setReasonItem={setSelectedReasonItem}
            onReset={() => setReasonCode(undefined)}
          />
          <Flex>
            <FlexContainer>
              <Flex>
                <Label for='serie'>Что посоветовали</Label>
                <AdviseContainer>
                  <Input
                    height={400}
                    style={{ height: 120, marginLeft: 0 }}
                    onChange={({ target: { value } }) => setAdditionalInfo((prev) => ({ ...prev, advise: value }))}
                    type='textarea'
                    placeholder='Напишите, что посоветовали'
                  />
                </AdviseContainer>
              </Flex>
            </FlexContainer>
            <FlexContainer>
              <Flex>
                <Label for='serie'>Помогло или нет</Label>
                <ReactSelect
                  value={additionalInfo.isHelped}
                  onChange={(isHelped: any) => {
                    setAdditionalInfo((prev) => ({ ...prev, isHelped }));
                  }}
                  placeholder='Выберите значение'
                  isSearchable={false}
                  isClearable
                  options={helpedValues}
                />
              </Flex>
              <Flex max50 >
                <Label for='serie'>Код ошибки</Label>
                <ReactSelect
                  value={errorCode}
                  onChange={(value: any) => {
                    setErrorCode(value)
                  }}
                  placeholder='Выберите значение'
                  isSearchable={true}
                  isClearable
                  options={errorCodes?.data.map(({ code, description, id }) => ({
                    value: id,
                    label: `${description} (${code})`,
                  }))}
                />
              </Flex>
            </FlexContainer>
            <Flex>
              <Input
                value={String(reasonCode ?? '')}
                disabled={true}
                height={400}
                style={{ opacity: 0.5, marginLeft: 0 }}
                placeholder='Код причины (заполнится автоматически)'
              />
            </Flex>
          </Flex>
        </div>
        <Button
          disabled={
            isCreating ||
            !additionalInfo.advise ||
            !additionalInfo.isHelped ||
            selectedReasonItem === null ||
            disableBtnCondition ||
            carId === null
          }
          onClick={createHandler}
          color='success'
        >
          {isCreating ? 'Создание...' : 'Создать запись в базу знаний '}
        </Button>
      </div>
    </div>
  );
};

export const AdviseContainer = styled.div`
  .form-control {
    height: 120px !important;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Flex = styled.div<{max50?: boolean}>`
  flex: 1;
  flex-grow: 1;
  max-width: ${({max50}) => max50 ? '50%' : 'none'};
`;

export const Separator = styled.div`
  margin-bottom: 10px;
`;

export default CreateKnowledge;
