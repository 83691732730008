import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Col, Row, TabPane } from "reactstrap";
import CarMonitoring from "components/Rents/Modal/Cars/Car/CarMonitoring/CarMonitoring";
import CarInformation from "components/Rents/Modal/Cars/Car/CarInformation/CarInformation";
import ListItem from "components/Rents/Modal/Cars/Car/RentsList/ListItem";
import CarFinance from "components/Rents/Modal/Cars/Car/CarFinance/CarFinance";
import Loader from "components/Common/Loader";
import Breadcrumb from "components/Common/RentBreadcrumb";
import RentTabs from "components/Common/Tabs/RentTabs";
import { rentCarAPI } from "services";
import RegisterSignaling from "components/Common/RegisterSignaling";

interface IParams {
  id: string;
}

const CarPage: React.FC = () => {
  const { id } = useParams<IParams>();
  const [currentPage, setCurrentPage] = useState(1);
  const [amountPage, setAmountPage] = useState(1);
  const { data: car, isLoading: carIsLoading } = rentCarAPI.useGetCarQuery(+id);
  const { data: carRents, isLoading: carRentsIsLoading } = rentCarAPI.useGetCarRentsQuery({
    id: +id,
    page: currentPage,
    per_page: 10,
  });
  const { data: getRentInfo } = rentCarAPI.useGetRentInfoQuery(+id);
  const { data: financeInfo } = rentCarAPI.useGetFinanceInfoQuery(+id);

  useEffect(() => {
    setAmountPage(carRents?.meta?.last_page);
  }, [carRents]);

  const carFinances = useMemo(() => {
    console.log("CHANGED");
    return {
      price: car?.data.price_list.cost,
      price_list: car?.data.price_list,
      discount_before: financeInfo?.data.rental?.discount_before,
      discount_after: financeInfo?.data?.rental?.discount_after,
      rentalId: financeInfo?.data?.rental?.rentalId,
    };
  }, [car, financeInfo]);

  const navlinks = ["Общее", "Мониторинг", "Аренды", "Финансы", "Старлайн"];
  const listTitleItems = [
    { id: 2, title: "Машина" },
    { id: 2, title: "Номер" },
    { id: 2, title: "ФИО клиента" },
    { id: 3, title: "Начало" },
    { id: 4, title: "Конец" },
  ];

  return (
    <div>
      {car && (
        <React.Fragment>
          <Breadcrumb
            breadcrumbs={[
              { title: "Машины", link: "/dashboard/cars" },
              { title: `Машина - ${car?.data.brand} ${car?.data.model}` },
            ]}
          />
          <RentTabs navlinks={navlinks}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CarInformation car={car?.data} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <CarMonitoring carData={car?.data} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  {!carRentsIsLoading && (
                    <ListItem
                      rents={carRents?.data}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      amountPage={amountPage}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <CarFinance
                    finances={carFinances}
                    refetchCar={async () => console.log(123)}
                    statistic={car?.data?.statistic}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <RegisterSignaling isRent={true} />
                </Col>
              </Row>
            </TabPane>
          </RentTabs>
        </React.Fragment>
      )}
    </div>
  );
};

export default CarPage;
