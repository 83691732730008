import React, { memo, ReactNode, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import Loader from './Loader';
import { numberToArray } from 'common';
import Pagination from './Pagination';
import PaginationComp from 'components/Common/Pagination';

type TitlesType = string | ReactNode;

interface PaginationTableProps {
  title: string;
  thTitles: TitlesType[];
  tableData?: any[];
  canDelete?: boolean;
  canUpdate?: boolean;
  onDelete?: Function;
  deletePending?: boolean;
  loading: boolean;
  OptionalBtn?: React.FC | null;
  onRowClick?: (id: number, param?: string) => void;
  setPerPage?: (count: number) => void;
  currentPage?: number;
  setCurrentPage?: (count: number) => void;
  amountPage?: number;
  perPage?: number;
  setAmountPage?: (count: number) => void;
  isBackPagination?: boolean;
  canCall?: boolean;
  onCall?: (id: number) => void;
  setIsUpdateAdminModalOpen?: (value: boolean) => void;
  isShowLabel?: boolean;
  showId?: boolean;
}

const PaginationTable = ({
  title,
  thTitles,
  loading,
  tableData = [],
  canDelete = false,
  canUpdate = false,
  onDelete,
  deletePending,
  OptionalBtn = null,
  onRowClick,
  setPerPage,
  currentPage,
  setCurrentPage,
  perPage,
  amountPage,
  setAmountPage,
  setIsUpdateAdminModalOpen,
  isBackPagination = false,
  canCall,
  onCall,
  isShowLabel = true,
  showId = true
}: PaginationTableProps) => {
  const [currentPageFront, setCurrentPageFront] = useState(1);
  const [amountToShow, setAmountToShow] = useState(10);
  const pagesAmount = Math.ceil(tableData.length / amountToShow);

  // if (loading) return <Loader />;
  return (
    <>
      <h3 className='mb-3'>{title}</h3>

      <div className='d-flex align-items-center mb-3'>
        <Label className='mb-0 mx-2' for='amount'>
          Количество записей
        </Label>
        <Input
          className='w-auto'
          id='amount'
          type='select'
          value={perPage}
          onChange={(e) => {
            if (isBackPagination) {
              setPerPage && setPerPage(+e.target.value);
              setCurrentPage && setCurrentPage(1);
            } else {
              setAmountToShow(+e.target.value);
            }
          }}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Input>
        {!!OptionalBtn && <OptionalBtn />}
      </div>

      <div style={{ overflow: 'scroll' }}>
        <table className='table dataTable no-footer' style={{ paddingRight: 20 }}>
          <tbody style={{ paddingRight: 20 }}>
            <tr>
              {thTitles.map((th, i) => (
                <th key={i}>{th}</th>
              ))}
              {canDelete && <th></th>}
            </tr>

            {!loading && tableData.length
              ? tableData.map((tableDataItem, i) => (
                  <tr
                    onClick={() => {
                      onRowClick?.(tableDataItem.id, tableDataItem?.typeName);
                    }}
                    style={{
                      backgroundColor: i % 2 ? 'none' : '#fff',
                      cursor: 'pointer',
                    }}
                    key={i}
                  >
                    {Object.keys(tableDataItem).map((dataItemKey, i) =>
                      (dataItemKey === 'id' && !showId) ? null : (
                        <td className='align-middle' style={{ whiteSpace: 'pre-line' }} key={i}>
                          {tableDataItem[dataItemKey]}
                        </td>
                      )
                    )}
                    {canUpdate && setIsUpdateAdminModalOpen && (
                      <td>
                        <button
                          onClick={(e) => {
                            setIsUpdateAdminModalOpen(true);
                          }}
                          className='btn btn-primary'
                          disabled={deletePending}
                        >
                          Изменить
                        </button>
                      </td>
                    )}
                    {canDelete && (
                      <td>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete?.(tableDataItem.id);
                          }}
                          className='btn btn-danger'
                          disabled={deletePending}
                        >
                          Удалить
                        </button>
                      </td>
                    )}

                    {canCall && (
                      <td>
                        <button onClick={() => onCall?.(tableDataItem.id as number)} className='btn btn-success'>
                          Позвонить
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              : !loading && (
                  <tr>
                    <td colSpan={9} className='text-center'>
                      По указанным параметрам нет данных
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
        {loading && <Loader />}
      </div>

      {!!tableData.length && (
        <>
          {isBackPagination ? (
            <div className='d-flex justify-content-center'>
              {tableData?.length > 0 && (
                <Pagination pagesAmount={amountPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />
              )}
            </div>
          ) : (
            <div className='my-3 d-flex align-items-center justify-content-center'>
              {isShowLabel && (
                <p>
                  Показаны с {currentPageFront * amountToShow - amountToShow + 1} по{' '}
                  {tableData.length > amountToShow
                    ? currentPageFront * amountToShow > tableData.length
                      ? tableData.length
                      : currentPageFront * amountToShow
                    : tableData.length}{' '}
                  из {tableData.length} элементов
                </p>
              )}
              <br />
              <button onClick={() => currentPageFront > 1 && setCurrentPageFront(currentPageFront - 1)} className='btn'>
                {'<'}
              </button>
              <div className='' style={{ width: '100%' }}>
                <PaginationComp
                  pagesAmount={pagesAmount}
                  currentPage={currentPageFront}
                  setCurrentPage={setCurrentPageFront}
                />
                {/* {numberToArray(pagesAmount).map((i) => (
                  <button
                    className={`btn mx-1 ${i == currentPageFront ? "btn-primary" : "btn-success "}`}
                    key={i}
                    onClick={() => setCurrentPageFront(i)}>
                    {i}
                  </button>
                ))} */}
              </div>
              <button
                onClick={() => currentPageFront < amountToShow && setCurrentPageFront(currentPageFront + 1)}
                className='btn'
              >
                {'>'}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default memo(PaginationTable);
