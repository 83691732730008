import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { rentUserAPI } from "services";
import ZoomPhotoModal from "components/Common/Modal/ZoomPhotoModal";
import whyNoVerify from "../../../utils/whyNoVerify";

const UserInformation = ({ user }) => {
  const {
    passport,
    driver_licence,
    birthdate,
    email,
    phone,
    name,
    surname,
    id,
    verify,
    permissions,
    verify_desc,
  } = user;

  const [verifyUser, {}] = rentUserAPI.useVerifyUserMutation();
  const [canselVerifyUser, {}] =
    rentUserAPI.useCancellationVerificationMutation();
  const [refuseVerify] = rentUserAPI.useRefuseVerificationMutation();
  const [isShow, setIsShow] = React.useState(false);
  const [action, setAction] = useState(undefined);
  const [desc, setDesc] = React.useState(1);

  const [isShowZoomPhotoModal, setIsShowZoomPhotoModal] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);
  const [activePhoto, setActivePhoto] = useState(1);

  const handleVerifyUser = () => {
    verifyUser(id);
  };

  const showZoomPhotoModal = (id) => {
    setActivePhoto(id);
    setIsShowZoomPhotoModal(true);
  };

  useEffect(() => {
    document.body.style.setProperty(
      "--overflow-body",
      isShowZoomPhotoModal ? "hidden" : "auto"
    );
  }, [isShowZoomPhotoModal]);

  useEffect(() => {
    setPhotoArray([
      {
        id: 1,
        url: passport?.photo_information,
        name: "Фотография паспорта - главный разворот",
      },
      {
        id: 2,
        url: passport?.photo_registration,
        name: "Фотография паспорта - регистрация",
      },
      {
        id: 3,
        url: passport?.photo_with_user,
        name: "Селфи с паспортом",
      },
      {
        id: 4,
        url: driver_licence?.photo,
        name: "Фотография водительских прав",
      },
      {
        id: 5,
        url: driver_licence?.photo_otherside,
        name: "Фотография водительских прав с обратной стороны",
      },
    ]);
  }, [passport, driver_licence]);

  const checkPermission = () => {
    const verifyRejection = permissions.find(
      (per) => per.name === "verify-rejection"
    );
    return verifyRejection ? true : false;
  };
  return (
    <>
      <div
        className="card box-user-information"
        style={{ overflowY: isShowZoomPhotoModal ? "hidden" : "auto" }}
      >
        <Accordion atomic={false} style={{ marginTop: 20 }}>
          <AccordionItem className="card-header bg-primary" title="Паспорт">
            <div
              className="collapse show"
              id="collapseicon"
              aria-labelledby="collapseicon"
              data-parent="#accordionoc"
            >
              <div className="row col-sm-12">
                <div className="col-sm-6 user__photo">
                  {passport ? (
                    <img
                      src={passport.photo_information}
                      alt="Ссылка недействительна"
                      onClick={() => showZoomPhotoModal(1)}
                    />
                  ) : (
                    "Фотография отсутствует"
                  )}
                  <h3>Фотография паспорта</h3>
                </div>
                <div className="col-sm-6 user__photo ">
                  {passport ? (
                    <img
                      src={passport?.photo_registration}
                      alt="Ссылка недействительна"
                      onClick={() => showZoomPhotoModal(2)}
                    />
                  ) : (
                    "Фотография отсутствует"
                  )}
                  <h3>Фотография прописки</h3>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            className="card-header bg-primary"
            title="Селфи с паспортом"
          >
            <div
              className="collapse show"
              id="collapseicon"
              aria-labelledby="collapseicon"
              data-parent="#accordionoc"
            >
              <div className="card">
                <div className="user__photo">
                  {passport ? (
                    <div>
                      <img
                        src={passport?.photo_with_user}
                        alt="Ссылка недействительна"
                        onClick={() => showZoomPhotoModal(3)}
                      />
                    </div>
                  ) : (
                    "Фотография отсутствует"
                  )}
                  <h3>Селфи с паспортом</h3>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            className="card-header bg-primary"
            title="Водительские права"
          >
            <div
              className="collapse show"
              id="collapseicon"
              aria-labelledby="collapseicon"
              data-parent="#accordionoc"
            >
              <div className="row col-sm-12">
                <div className="col-sm-6 user__photo">
                  {driver_licence ? (
                    <img
                      src={driver_licence?.photo}
                      alt="Ссылка недействительна"
                      onClick={() => showZoomPhotoModal(4)}
                    />
                  ) : (
                    "Фотография отсутствует"
                  )}
                  <h3>Фотография водительских прав</h3>
                </div>
                <div className="col-sm-6 user__photo">
                  {driver_licence ? (
                    <img
                      src={driver_licence?.photo_otherside}
                      alt="Ссылка недействительна"
                      onClick={() => showZoomPhotoModal(5)}
                    />
                  ) : (
                    "Фотография отсутствует"
                  )}
                  <h3>Фотография водительских прав с обратной стороны</h3>
                </div>
              </div>
            </div>
          </AccordionItem>
        </Accordion>

        <button
          className="btn btn-success"
          style={{ width: "100%" }}
          onClick={() => {
            showZoomPhotoModal(1);
          }}
        >
          Посмотреть все фото
        </button>
      </div>

      <div className="user__item">
        ФИО: {name && surname ? `${name} ${surname}` : "отсутствует"}
      </div>
      <div className="user__item">
        Tелефон: {phone ? `${phone}` : "отсутствует"}
      </div>
      <div className="user__item">
        Email: {email ? `${email}` : "отсутствует"}
      </div>
      <div className="user__item">
        Серия паспорта:{" "}
        {passport ? `${passport.series_number.slice(0, 4)}` : "отсутствует"}
      </div>
      <div className="user__item">
        Номер паспорта:{" "}
        {passport ? `${passport.series_number.slice(4)}` : "отсутствует"}
      </div>

      <div className="user__item">
        Дата рождения: {birthdate ? birthdate : "отсутствует"}
      </div>
      {/* <div className="user__item">
        {checkPermission() && verify_desc ? verify_desc : ""}
      </div> */}

      {verify == 1 && (
        <Button
          onClick={() => {
            setIsShow(true);
            setAction(2);
          }}
          color="danger"
        >
          Отменить верификацию
        </Button>
      )}

      {verify == 0 && !checkPermission() && (
        <React.Fragment>
          <Button onClick={handleVerifyUser} color="success">
            Верифицировать
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              setIsShow(true);
              setAction(1);
            }}
            color="danger"
          >
            Отказать в верификации
          </Button>
        </React.Fragment>
      )}
      {verify == 0 && checkPermission() && (
        <Button onClick={() => setIsShow(true)} color="danger">
          {checkPermission() && verify_desc ? verify_desc : ""}
        </Button>
      )}

      {isShowZoomPhotoModal && (
        <ZoomPhotoModal
          photos={photoArray}
          activePhoto={activePhoto}
          setIsShowZoomPhotoModal={setIsShowZoomPhotoModal}
          setActivePhoto={setActivePhoto}
        />
      )}

      <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
        <ModalHeader>Введите причину отказа</ModalHeader>
        <ModalBody>
          <Input
            type="select"
            style={{ marginBottom: 10, marginLeft: 0 }}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          >
            {whyNoVerify.map((reason) => {
              return (
                <option key={reason.id} value={reason.id}>
                  {reason.label}
                </option>
              );
            })}
          </Input>
          <button
            onClick={() => {
              if (action == 1) {
                refuseVerify({ desc: String(desc), userId: id });
              }
              if (action == 2) {
                canselVerifyUser({ desc: String(desc), userId: id });
              }
              setAction(undefined);
              setIsShow(false);
              setDesc(1);
            }}
            className="btn btn-success"
            style={{ width: "100%" }}
          >
            Готово
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UserInformation;
