import { useWindowSize } from "hooks/useWindowSize";
import WebChat from "./WebChat";
import MobileChat from "./MobileChat";

const Chat = () => {
  const size = useWindowSize();

  return <div>{size.width > 700 ? <WebChat /> : <MobileChat />}</div>;
};

export default Chat;
