import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

const DeleteModal = ({ text, setIsShow, isShow, onSubmit }) => {
  return (
    <Modal centered toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader>Вы уверены, что хотите удалить {text}? </ModalHeader>
      <ModalFooter>
        <Button color="danger" onClick={() => setIsShow(false)}>
          Нет
        </Button>
        <Button onClick={onSubmit}>Да</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
