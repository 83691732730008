import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { rentAPI } from "../../../../services/rentService";
import { rentCarAPI } from "services";
import { rentUserAPI } from "services";
// import CalendarTable from "components/Rents/CalendarTable";
import ListRents from "components/Rents/ListRents";
import CreateRentModal from "components/Rents/Modal/CreateRentModal";
import { number } from "prop-types";
import CalendarTable from "components/Rents/CalendarTable";
import { formatRentServiceDataForBackend } from "../../../../common/dataFormatters";

const Rents: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  const [section, setSection] = useState(1);
  const [pagesAmount, setPagesAmount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(undefined);
  const [dataEnd, setDataEnd] = useState(undefined);
  const [onlyPaid, setOnlyPaid] = useState(false);

  const { data: rents, isLoading: rentsLoading } = rentAPI.useGetRentsQuery(
    formatRentServiceDataForBackend({
      page: currentPage,
      from: dataStart,
      to: dataEnd,
      type: onlyPaid,
    })
  );

  const { data: cars, isLoading: carsLoading } = rentCarAPI.useGetRentCarsQuery(
    {
      "filters[inPool]": "1",
    }
  );
  const { data: users, isLoading: usersLoading } =
    rentUserAPI.useGetUsersQuery();

  useEffect(() => {
    rents?.meta && setPagesAmount(rents?.meta?.last_page);
  }, [rents]);

  useMemo(() => {
    if ((dataStart && dataEnd) || (!dataStart && !dataEnd)) {
      setCurrentPage(1);
    }
  }, [dataStart, dataEnd, onlyPaid]);

  return (
    <div
      className="container-fluid"
      style={{
        overflowX: section === 1 ? "hidden" : "auto",
      }}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5> Аренды </h5>
            </div>
            <div
              className="card-body card-padding"
              style={{ overflow: section === 1 ? "hidden" : "auto" }}
            >
              <div className="header-rent-page">
                <div className="card-buttons">
                  <Button
                    color={"primary"}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    onClick={() => setSection(1)}
                    outline={section === 2}
                  >
                    Календарь
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setSection(2)}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    outline={section === 1}
                  >
                    Список
                  </Button>
                </div>

                <div className="card-buttons">
                  <Button
                    className="w-auto"
                    color="primary"
                    onClick={() => setIsShow(true)}
                  >
                    Создать аренду
                  </Button>
                </div>
              </div>
              {section === 1 && <CalendarTable cars={cars} />}
              {section === 2 &&
                (rentsLoading ? (
                  <p style={{ textAlign: "center" }}>Загрузка...</p>
                ) : (
                  <ListRents
                    rents={rents?.data}
                    pagesAmount={pagesAmount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    //@ts-ignore
                    onDataFiltersChange={rentAPI.useGetRentsQuery}
                    setDataStart={setDataStart}
                    setDataEnd={setDataEnd}
                    onlyPaid={onlyPaid}
                    setOnlyPaid={setOnlyPaid}
                  />
                ))}
            </div>
          </div>
          <CreateRentModal
            users={users}
            cars={cars}
            setIsShow={setIsShow}
            isShow={isShow}
          />
        </div>
      </div>
    </div>
  );
};

export default Rents;
