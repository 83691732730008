import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { boolean } from "yup";
import { baseQuery } from "./rentSettings";
import {
  IFiltersRents,
  IRentSum,
  ICreateRent,
  IDataRents,
  IRentCompleteAndExtend,
} from "types/RentTypes";
import { Obj } from "reselect/es/types";
import { method } from "lodash";

export const rentAPI = createApi({
  reducerPath: "rentAPI",
  tagTypes: ["rents", "rent"],
  baseQuery,
  endpoints: (build) => ({
    getRents: build.query<IDataRents, void | IFiltersRents>({
      query: (params) => ({
        url: `/admin/rents`,
        method: "GET",
        params: {
          ...params,
        },
      }),
      providesTags: () => ["rents"],
    }),
    getMonthRents: build.query<{ data: any }, any>({
      query: (date) => ({
        url: `admin/rents/getMonthRents?month=${date}`,
        method: "GET",
      }),
    }),
    createRent: build.mutation<void, ICreateRent>({
      query: (rent) => {
        return {
          url: `admin/rents/${rent.car_id}`,
          method: "POST",
          body: rent,
        };
      },
      invalidatesTags: ["rents"],
    }),
    getRentsForCar: build.query<{ data: any }, number>({
      query: (carId) => ({
        url: `admin/rents/${carId}`,
        method: "GET",
      }),
    }),
    getRentSum: build.query<{ data: any }, IRentSum>({
      query: ({ carId, dateFrom, dateTo }) => ({
        url: `finance/getRentalSum?car_id=${carId}&date_from=${dateFrom}&date_to=${dateTo}`,
        method: "GET",
      }),
    }),
    //получение конкретной аренды и фоток
    getRent: build.query<{ data: any }, string>({
      query: (rentId) => ({
        url: `admin/rent/${rentId}`,
        method: "GET",
      }),
      providesTags: () => ["rent"],
    }),
    getRentPhotosBeforeIn: build.query<{ data: any }, string>({
      query: (rentId) => ({
        url: `admin/rent/photos/${rentId}?type_event=3`,
        method: "GET",
      }),
    }),
    getRentPhotosBeforeOut: build.query<{ data: any }, string>({
      query: (rentId) => ({
        url: `admin/rent/photos/${rentId}?type_event=1`,
        method: "GET",
      }),
    }),
    getRentPhotosAfterIn: build.query<{ data: any }, string>({
      query: (rentId) => ({
        url: `admin/rent/photos/${rentId}?type_event=4`,
        method: "GET",
      }),
    }),
    getRentPhotosAfterOut: build.query<{ data: any }, string>({
      query: (rentId) => ({
        url: `admin/rent/photos/${rentId}?type_event=2`,
        method: "GET",
      }),
    }),
    //
    // completeRent: build.mutation<{ data: any }, IRentCompleteAndExtend>({
    //   query: ({ rentId, completeDate }) => {
    //     return {
    //       url: `admin/rent/${rentId}?end_date=${completeDate}`,
    //       method: "PATCH",
    //     };
    //   },
    //   invalidatesTags: ["rent"],
    // }),
    // extendRent: build.mutation<{ data: any }, IRentCompleteAndExtend>({
    //   query: ({ rentId, extendDate }) => ({
    //     url: `admin/rent/${rentId}?end_date=${extendDate}`,
    //     method: "PATCH",
    //   }),
    //   invalidatesTags: ["rent"],
    // }),
    updateEndDate: build.mutation<{ data: any }, IRentCompleteAndExtend>({
      query: ({ rentId, endDate }) => ({
        url: `rent/${rentId}`,
        method: "PUT",
        body: {
          end_date: endDate,
        },
      }),
      invalidatesTags: ["rent"],
    }),
    removeCarPhoto: build.mutation({
      query: ({ photoId }: { photoId: number }) => ({
        url: `admin/cars/photo/${photoId}`,
        method: "DELETE",
      }),
    }),
    getUsersForRent: build.query<{ data: any }, any>({
      query: (params) => ({
        url: `/helpers/getUsers`,
        method: "GET",
        params: { ...params },
      }),
    }),
  }),
});
