import Breadcrumb from "components/Common/Breadcrumb2";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { carAPI, userAPI } from "services";
import SelectUser from "./SelectUser";
import { UserNotFound } from "./styles";
import Modal from "components/Common/Modal/Modal";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Loader from "components/Common/Loader";

const Car = () => {
  const { id } = useParams<{ id: string }>();
  const [refresh, setRefresh] = React.useState(true);

  const { data: car, isLoading: carLoading } = carAPI.useGetCarQuery(+id);
  const [updateData, { isLoading: deleteCarLoading }] =
    carAPI.useUpdateDataMutation();

  const { data: insurances } = carAPI.useGetInsurancesQuery(+id);
  const { data: history } = carAPI.useGetHistoryQuery(+id);

  const [getUser, { data: user, isLoading: userLoading }] =
    userAPI.useLazyGetUserQuery();

  function checkNum(num: number) {
    return num < 10 ? `0${num}` : num;
  }

  const getDate = (date: Date) =>
    `${checkNum(date.getDate())}.${checkNum(
      date.getMonth()
    )}.${date.getFullYear()}`;

  const getTime = (date: Date) =>
    `${checkNum(date.getHours())}:${checkNum(date.getMinutes())}`;

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);

    return `${getDate(date)} ${getTime(date)}`;
  };

  const [signaling, setSignaling] = React.useState<{
    key: string;
    name: string;
    type: "gps-alarm";
    tel: string;
    service: null | number;
  }>({
    key: "",
    name: "",
    type: "gps-alarm",
    tel: "",
    service: null,
  });

  React.useEffect(() => {
    if (id) {
      setRefresh((prev) => !prev);
    }
  }, [id]);

  React.useEffect(() => {
    if (car?.data.user_id) {
      getUser(+car.data.user_id);
    }
    console.log(car?.data);
  }, [car]);

  if (carLoading) return <Loader />;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb
          breadcrumbItems={[
            { title: "Автомобили", link: "/cars" },
            { title: "Страница автомобиля" },
          ]}
        />

        <div
          className="page-title-box d-flex justify-content-between align-items-center"
          style={{ gap: 10 }}
        >
          <h2>Страница автомобиля</h2>
          <div className="box-btn-car-user">
            <Link to={`/edit-car/${id}`}>
              <button className="btn btn-warning">Редактировать</button>
            </Link>
            <button
              onClick={() => updateData(+id)}
              className="btn btn-primary"
              // style={{ marginLeft: 10 }}
            >
              Обновить данные
            </button>
          </div>
        </div>

        <div className="card">
          <div className="card-header">Информация об автомобиле</div>
          <div className="card-body card-body-scroll">
            <table className="table dataTable no-footer">
              <thead>
                <tr>
                  {[
                    "Марка",
                    "Модель",
                    "Модификация",
                    "Пробег",
                    "Номер",
                    "Год",
                    "STS",
                    "VIN",
                    "Соединение",
                  ].map((th, i) => (
                    <th key={i}>{th}</th>
                  ))}
                  {car?.data.connectType === "starline" && <th>IMEI</th>}
                  {car?.data.connectType === "alyansauto" && (
                    <React.Fragment>
                      <th>ID</th>
                      <th>Код</th>
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{car?.data.brand}</td>
                  <td>{car?.data.model}</td>
                  <td>{car?.data.modification}</td>
                  <td>{car?.data.mileage}</td>
                  <td>{car?.data.registration_number}</td>
                  <td>{car?.data.year}</td>
                  <td>{car?.data.sts}</td>
                  <td>{car?.data.vin}</td>
                  <td>{car?.data.connectType}</td>
                  {car?.data.connectType === "starline" && (
                    <td>{car?.data.imei}</td>
                  )}
                  {car?.data.connectType === "alyansconnect" && (
                    <React.Fragment>
                      <td>{car?.data.imei}</td>
                      <td>{car?.data.code}</td>
                    </React.Fragment>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {!!car?.data.user_id ? (
          <div className="card">
            <div className="card-header">Информация об пользователе</div>
            <div className="card-body card-body-scroll">
              <table className="table dataTable no-footer">
                <thead>
                  <tr>
                    {[
                      "E-mail",
                      "Имя",
                      "Фамилия",
                      "Отчество",
                      "Адрес",
                      "Номер телефона",
                    ].map((th, i) => (
                      <th key={i}>{th}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{user?.data.email}</td>
                    <td>{user?.data.name}</td>
                    <td>{user?.data.surname}</td>
                    <td>{user?.data.patronymic}</td>
                    <td>{user?.data.address}</td>
                    <td>{user?.data.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <UserNotFound>
            <h3>Машина не привязана к пользователю</h3>
            <div className="custom-row">
              <SelectUser
                onSelected={() => {
                  console.log(123);
                }}
              />
              <button className="btn btn-primary">
                Привязать пользователя
              </button>
            </div>
          </UserNotFound>
        )}

        {!!insurances && insurances.data.length > 0 && (
          <div className="card">
            <div className="card-header">Страховые полисы</div>
            <div className="card-body card-body-scroll">
              <table className="table dataTable no-footer">
                <thead>
                  <tr>
                    {[
                      "Компания",
                      "Тип страховки",
                      "Номер документа",
                      "Дата оформления",
                      "Дата последнего продления",
                      "Дата окончания",
                    ].map((th, i) => (
                      <th key={i}>{th}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {insurances.data.map((item, i) => (
                    <tr key={i}>
                      <td>{item.insurer}</td>
                      <td>{item.insurancetype}</td>
                      <td>{item.documentnumber}</td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{formatDate(item.updated_at)}</td>
                      <td>{formatDate(item.сontractexpirationdate)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {!!history && history?.workhistory.length > 0 && (
          <div className="card">
            <div className="card-header">История ТО</div>
            <div className="card-body card-body-scroll">
              <table className="table dataTable no-footer">
                <thead>
                  <tr>
                    {["Номер документа", "Дата", "Список работ"].map(
                      (th, i) => (
                        <th key={i}>{th}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {history.workhistory.map((item, i) => (
                    <tr key={i}>
                      <td>{item.documentnumber}</td>
                      <td>{getDate(new Date(item.date))}</td>
                      <td>{item.works?.join(", ")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Car;
