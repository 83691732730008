import React from "react";
import { Col, Row, TabPane } from "reactstrap";
import CarFinanceCurrentInfo from "./CarFinanceCurrentInfo";
import CarFinanceGeneralInfo from "./CarFinanceGeneralInfo";
import RentTabs from "components/Common/Tabs/RentTabs";

const CarFinance = ({ finances, refetchCar, statistic }) => {
  return (
    <RentTabs navlinks={["Общая информация", "Текущие показатели"]}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <CarFinanceGeneralInfo
              finances={finances}
              refetchCar={refetchCar}
            />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            <CarFinanceCurrentInfo statistic={statistic} />
          </Col>
        </Row>
      </TabPane>
    </RentTabs>
  );
};

export default CarFinance;
