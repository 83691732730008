import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import { carAPI, careAPI, userAPI } from 'services';
import { ICarCharacteristic, ICarInfo } from 'types/CarTypes';
import { CustomCar, KnowledgeReason, SelectObj } from 'types/CareServiceTypes';

import 'react-dropdown-tree-select/dist/styles.css';
import { useDispatch } from 'react-redux';
import KnowledgeReasonSelector from 'components/Common/KnowledgeReasonSelector';
import CreateKnowledge from 'components/CreateKnowledge';
import KnowledgeTable, { ImperativeKnowledgeTableProps } from 'components/KnowledgeTable';

interface CustomUser {
  phone: string;
  name: string;
  surname: string;
  patronymic: string;
}

interface TreeSelect {
  label: string;
  value: number;
  children: TreeSelect[];
  parentId: number | null;
}

const CareServiceCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: marks } = carAPI.useGetMarksQuery();

  const [createCar] = carAPI.useCreateCarMutation();
  const [createUser] = userAPI.useCreateUserMutation();

  const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();
  const [getGenerations, { data: generations }] = carAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = carAPI.useLazyGetSeriesQuery();
  const [getModifications, { data: modifications }] = carAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] = carAPI.useLazyGetEquipmentsQuery();

  const [findUser, { data: user, isLoading }] = userAPI.useLazyFindUserQuery();

  const [userInput, setUserInput] = useState('7');
  const [selectedCar, setSelectedCar] = useState<null | { label: string; value: ICarInfo }>(null);

  const [isOtherUser, setIsOtherUser] = useState(false);
  const [isOtherCar, setIsOtherCar] = useState(false);

  const [knowledgeCarId, setKnowledgeCarId] = useState<number | ''>('');
  const [knowledgeModificationId, setKnowledgeModificationId] = useState<number | ''>('');

  const knowledgeTable = useRef<ImperativeKnowledgeTableProps>();

  const [customUser, setCustomUser] = useState<CustomUser>({
    phone: '',
    name: '',
    surname: '',
    patronymic: '',
  });

  const [customCar, setCustomCar] = useState<CustomCar>({
    sts: '',
    year: '',
    vin: '',
    registration_number: '',
    mileage: '',

    mark_id: null,
    model_id: null,
    generation_id: null,
    serie_id: null,
    modification_id: null,
    equipment_id: null,
  });

  const createKnowledgeHandler = useCallback(async () => {
    let carId = selectedCar?.value.id;
    const {
      mark_id,
      model_id,
      generation_id,
      serie_id,
      modification_id,
      equipment_id,
      year,
      vin,
      registration_number,
      mileage,
      sts,
    } = customCar;

    if (isOtherUser || isOtherCar) {
      let user_id = null;

      if (
        isOtherUser &&
        Object.keys(customUser)
          .map((key) => customUser[key as keyof CustomUser])
          .some((item) => item === '')
      ) {
        toast.error('Заполните все данные пользователя');
        return;
      }

      if (
        Object.keys(customCar)
          .map((key) => (key === 'equipment_id' ? 'skip' : customCar[key as keyof CustomCar]))
          .some((item) => item === null || item === '' || (typeof item === 'object' && item.value === null))
      ) {
        toast.error('Заполните все данные автомобиля');
        return;
      }

      if (isOtherUser) {
        const { data: createdUser } = await createUser(customUser).unwrap();
        user_id = createdUser.id;
      }

      const { car: createdCar } = await createCar({
        mark_id: mark_id?.value as string,
        model_id: model_id?.value as string,
        generation_id: generation_id?.value as string,
        serie_id: serie_id?.value as string,
        modification_id: modification_id?.value as string,
        equipment_id: equipment_id?.value as string,
        registration_number: registration_number as string,
        year: +year as any,
        vin: vin as string,
        sts: sts as string,
        mileage: +mileage as any,
        user_id: isOtherUser ? user_id : user.data.id,
      }).unwrap();

      carId = createdCar.id;
    }

    return carId as number;
  }, [customCar, selectedCar, customUser, isOtherCar, isOtherUser]);

  const formatDataArray = useCallback(
    (array: ICarCharacteristic[]) => array.map(({ id, name }) => ({ label: name, value: id })),
    []
  );

  const userFullname = useMemo(() => {
    if (!user) {
      return '';
    }

    const { surname, name, patronymic } = user.data;
    return [surname, name, patronymic].join(' ');
  }, [user]);

  const userCars = useMemo(() => {
    if (!user) {
      return [];
    }

    return user.data.cars.map((car: ICarInfo) => ({ value: car, label: [car.brand, car.model].join(' ') }));
  }, [user]);

  const filter = (arr: TreeSelect[], id: number): TreeSelect[] =>
    (arr || [])
      .map((n) => ({ ...n, children: filter(n.children, id) }))
      .filter((n) => n.value === id || n.children.length);

  useEffect(() => {
    if (user) {
      setUserInput(userFullname);
    }
  }, [user]);

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='page-title-box'>
          <h2 className='mb-3'>Создание заявки</h2>
        </div>
        <div className='card'>
          <div className='card-header'>Пользователь</div>
          <div className='card-body'>
            <Row style={{ display: 'flex', flexDirection: 'row' }}>
              <Col md={3}>
                <Label for='mark'>Пользователь</Label>
                <Input
                  disabled={isOtherUser}
                  style={{
                    marginLeft: 0,
                    opacity: isOtherUser ? 0.5 : 1,
                    background: isOtherUser ? '#f2f2f2' : '#fff',
                  }}
                  type='text'
                  maxLength={11}
                  placeholder='Введите номер пользователя'
                  value={userInput}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (!Number.isInteger(+value)) {
                      return;
                    }

                    if (value === '') {
                      setUserInput('7');
                      return;
                    }

                    if (value.length > 11) {
                      setUserInput('7');
                      setSelectedCar(null);
                      dispatch(userAPI.util.resetApiState());
                      return;
                    }
                    setUserInput(value);
                  }}
                />
                <Button
                  disabled={isLoading || userInput === '7' || userInput.length !== 11}
                  onClick={() => findUser({ phone: userInput })}
                  style={{ width: '100%', marginTop: 10 }}
                  color='primary'
                >
                  {isLoading ? 'Идёт поиск...' : 'Найти'}
                </Button>
                <Label style={{ paddingTop: 8 }} check>
                  <Input
                    type='checkbox'
                    checked={isOtherUser}
                    onChange={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        setUserInput('7');
                        setSelectedCar(null);
                        setIsOtherCar(true);
                        dispatch(careAPI.util.invalidateTags(['knowledge']));
                        dispatch(userAPI.util.resetApiState());
                      }
                      setIsOtherUser(checked);
                    }}
                    style={{ marginRight: 8 }}
                  />
                  Пользователь не из системы
                </Label>
              </Col>
              <Col md={3}>
                <Label for='mark'>Автомобиль</Label>
                <ReactSelect
                  isDisabled={isOtherCar || !user}
                  value={selectedCar}
                  onChange={(data: any) => {
                    setSelectedCar(data);
                    knowledgeTable.current?.updateKnowledge({ car_id: data.value.id, modification_id: '' });
                    setKnowledgeCarId(data.value.id);
                  }}
                  placeholder='Выберите автомобиль'
                  isSearchable={false}
                  isClearable
                  options={userCars}
                />
              </Col>
              <Col>
                <Label for='mark'>Другой автомобиль</Label>
                <Label style={{ paddingTop: 8, width: '100%' }} check>
                  <Input
                    type='checkbox'
                    checked={isOtherCar}
                    onChange={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        setSelectedCar(null);
                        dispatch(careAPI.util.invalidateTags(['knowledge']));
                      }
                      setIsOtherCar(checked);
                    }}
                    style={{ marginRight: 8 }}
                  />
                  Очистить и заполнить данные вручную
                </Label>
              </Col>
            </Row>
          </div>
        </div>

        <KnowledgeTable
          modification_id={isOtherCar ? knowledgeModificationId : ''}
          car_id={!isOtherCar ? knowledgeCarId : ''}
          ref={knowledgeTable}
        />

        {isOtherUser && (
          <div className='card'>
            <div className='card-header'>Данные пользователя автомобиля</div>
            <div className='card-body'>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 15 }}>
                <Col md={2}>
                  <Label for='serie'>Фамилия</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    placeholder='Введите фамилию'
                    value={customUser.surname}
                    onChange={(e) => {
                      const { value } = e.target;
                      setCustomUser((prev) => ({ ...prev, surname: value }));
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Имя</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    placeholder='Введите имя'
                    value={customUser.name}
                    onChange={(e) => {
                      const { value } = e.target;
                      setCustomUser((prev) => ({ ...prev, name: value }));
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Отчество</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    placeholder='Введите отчество'
                    value={customUser.patronymic}
                    onChange={(e) => {
                      const { value } = e.target;
                      setCustomUser((prev) => ({ ...prev, patronymic: value }));
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Номер телефона</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    maxLength={11}
                    placeholder='Введите номер телефона'
                    value={customUser.phone}
                    onChange={(e) => {
                      const { value } = e.target;
                      setCustomUser((prev) => ({ ...prev, phone: value }));
                    }}
                  />
                </Col>
              </div>
            </div>
          </div>
        )}
        {isOtherCar && (
          <div className='card'>
            <div className='card-header'>Характеристики автомобиля</div>
            <div className='card-body'>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 15 }}>
                <Col md={2}>
                  <Label for='mark'>Марка</Label>
                  <ReactSelect
                    isDisabled={!marks}
                    value={customCar.mark_id}
                    onChange={(mark_id: any) => {
                      setCustomCar((state) => ({
                        ...state,
                        mark_id,
                        model_id: null,
                        generation_id: null,
                        serie_id: null,
                        modification_id: null,
                        equipment_id: null,
                      }));
                      if (mark_id) {
                        getModels(mark_id?.value);
                      }
                    }}
                    placeholder='Выберите марку'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(marks?.data ?? [])}
                  />
                </Col>
                <Col md={2}>
                  <Label for='model'>Модель</Label>
                  <ReactSelect
                    isDisabled={!models || !customCar?.mark_id}
                    value={customCar.model_id}
                    onChange={(model_id: any) => {
                      setCustomCar((state) => ({
                        ...state,
                        model_id,
                        generation_id: null,
                        serie_id: null,
                        modification_id: null,
                        equipment_id: null,
                      }));
                      if (model_id) {
                        getGenerations(model_id.value);
                      }
                    }}
                    placeholder='Выберите модель'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(models?.data ?? [])}
                  />
                </Col>
                <Col md={2.5}>
                  <Label for='generation'>Поколение</Label>
                  <ReactSelect
                    isDisabled={!generations || !customCar?.model_id}
                    value={customCar.generation_id}
                    onChange={(generation_id: any) => {
                      setCustomCar((state) => ({
                        ...state,
                        generation_id,
                        serie_id: null,
                        modification_id: null,
                        equipment_id: null,
                      }));
                      if (generation_id) {
                        getSeries(generation_id.value);
                      }
                    }}
                    placeholder='Выберите поколение'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(generations?.data ?? [])}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Серия</Label>
                  <ReactSelect
                    isDisabled={!series || !customCar?.generation_id}
                    value={customCar.serie_id}
                    onChange={(serie_id: any) => {
                      setCustomCar((state) => ({ ...state, serie_id, modification_id: null, equipment_id: null }));
                      if (serie_id) {
                        getModifications(serie_id.value);
                      }
                    }}
                    placeholder='Выберите серию'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(series?.data ?? [])}
                  />
                </Col>
                <Col md={2.5}>
                  <Label for='serie'>Модификация</Label>
                  <ReactSelect
                    isDisabled={!series || !customCar?.serie_id}
                    value={customCar.modification_id}
                    onChange={(modification_id: any) => {
                      setCustomCar((state) => ({ ...state, modification_id, equipment_id: null }));
                      if (modification_id) {
                        knowledgeTable.current?.updateKnowledge({ car_id: '', modification_id: modification_id.value });
                        setKnowledgeModificationId(modification_id.value);
                        getEquipments(modification_id.value);
                      }
                    }}
                    placeholder='Выберите модификацию'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(modifications?.data ?? [])}
                  />
                </Col>
                <Col md={2.5}>
                  <Label for='serie'>Комплектация</Label>
                  <ReactSelect
                    isDisabled={!series || !customCar?.modification_id}
                    value={customCar.equipment_id}
                    onChange={(equipment_id: any) => {
                      setCustomCar((state) => ({ ...state, equipment_id }));
                      if (equipment_id) {
                        getEquipments(equipment_id.value);
                      }
                    }}
                    placeholder='Выберите комплектацию'
                    isSearchable={false}
                    isClearable
                    options={formatDataArray(equipments?.data ?? [])}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>VIN</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    placeholder='Введите VIN'
                    value={customCar.vin}
                    maxLength={17}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (/[a-zA-Z\d]$/.test(value) || value === '') {
                        setCustomCar((prev) => ({ ...prev, vin: value }));
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>СТС</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    placeholder='Введите номер стс'
                    value={customCar.sts}
                    maxLength={10}
                    onChange={(e) => {
                      const { value } = e.target;
                      
                      if (/[а-яА-я\d]$/.test(value) || value === '') {
                        setCustomCar((prev) => ({ ...prev, sts: value }));
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Регистрационный номер</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    maxLength={7}
                    placeholder='Введите регистрационный номер'
                    value={customCar.registration_number}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (/[а-яА-я\d]$/.test(value) || value === '') {
                        setCustomCar((prev) => ({ ...prev, registration_number: value }));
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Год</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    maxLength={4}
                    placeholder='Введите год'
                    value={customCar.year}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (Number.isInteger(+value)) {
                        setCustomCar((prev) => ({ ...prev, year: value }));
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label for='serie'>Пробег</Label>
                  <Input
                    style={{ marginLeft: 0 }}
                    type='text'
                    maxLength={6}
                    
                    placeholder='Введите пробег'
                    value={customCar.mileage}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (Number.isInteger(+value)) {
                        setCustomCar((prev) => ({ ...prev, mileage: value }));
                      }
                    }}
                  />
                </Col>
              </div>
            </div>
          </div>
        )}
        <CreateKnowledge
          preHandler={createKnowledgeHandler}
          disableBtnCondition={!isOtherCar && !selectedCar?.value.id}
        />
      </Container>
    </div>
  );
};

export default CareServiceCreate;
