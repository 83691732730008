import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Button } from "reactstrap";
import { isExistsPhoto } from "helpers/photo";
import EditCarModal from "../../EditCarModal";

const CarInformation = ({ car }) => {
  const [isShowEdit, setIsShowEdit] = useState(false);

  const nowYear = new Date().getFullYear();
  const yearsArr = [];
  for (let i = 0; i < 60; i++) {
    yearsArr.push(nowYear - i);
  }

  const information = [
    { name: "Год выпуска", ref: "year" },
    { name: "Vin номер", ref: "vin" },
    { name: "Номер СТС", ref: "sts" },
    { name: "Гос. номер", ref: "registration_number" },
    // { name: "Flex", ref: "flex" },
  ];

  return (
    <div>
      {!car.inPool && (
        <Alert variant="danger">Внимание! Автомобиль не в пуле</Alert>
      )}

      <div className="card" style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "60%" }}>
          {isExistsPhoto(car.photo) ? (
            <img src={car.photo} className="card-img-top" alt="..."></img>
          ) : (
            <h1>Фотографии нет</h1>
          )}
          <h1 style={{ fontSize: 12 }}>Дополнительные фотографии</h1>
          {car?.photos.map((item) => {
            return (
              isExistsPhoto(item.name) && (
                <img src={item.name} className="card-img-top" alt="..."></img>
              )
            );
          })}
          {/* {car?.photos?.map((photo) => (
            <img src={photo.name} className="card-img-top"></img>
          ))} */}
        </div>

        <div className="card-body">
          <Button
            color="primary"
            onClick={() => setIsShowEdit(true)}
            style={{ marginBottom: "20px" }}
          >
            Редактировать машину
          </Button>
          <h5 className="card-title">
            {car.brand} {car.model}
          </h5>
          <div className="card-text">
            <ol className="list-group list-group-numbered">
              {information.map((item, i) => (
                <h3 key={i}>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{item.name}</div>
                      <div className="fw">{car[item.ref]}</div>
                    </div>
                  </li>
                </h3>
              ))}

              <h3>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Город</div>
                    <div className="fw">{car.city.name}</div>
                  </div>
                </li>
              </h3>

              <h3>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Flex</div>
                    <div className="fw">{car.flex === 0 ? "-" : "+"}</div>
                  </div>
                </li>
              </h3>
            </ol>
          </div>
        </div>
      </div>
      <EditCarModal
        isShow={isShowEdit}
        setIsShow={setIsShowEdit}
        yearsArr={yearsArr}
        carData={car}
      />
    </div>
  );
};

export default CarInformation;
