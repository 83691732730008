import React, { useState } from 'react';
import Modal from 'components/Common/Modal/Modal';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import { adminAPI } from 'services';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  adminId: number;
}

const ChangePasswordModal = ({ isOpen, onClose, adminId }: ChangePasswordModalProps) => {
  const [password, setPassword] = useState('');

  const [editAdmin, { isLoading: editAdminLoading }] = adminAPI.useEditAdminMutation();

  const handleChangePassword = async () => {
    if (!password.trim()) return;
    await editAdmin({ id: adminId, password });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Изменить пароль">
      <FormGroup className="mb-3">
        <Label for="password">Пароль</Label>
        <Input id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </FormGroup>

      <button className="btn btn-success" onClick={handleChangePassword} disabled={editAdminLoading}>
        Изменить
      </button>
    </Modal>
  );
};

export default ChangePasswordModal;
