import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { baseQuery } from "services/settings";
import {
  ICarRecommendation,
  ICarFilters,
  ICarInfo,
  ICreateCarInfo,
  IEquipment,
  IGeneration,
  IMark,
  IModel,
  IModification,
  IModificationCharacteristics,
  ISerie,
  ICreateServiceCar,
  IServiceCar,
  IServiceDevices,
  ICteateEmployeeCarService,
  IEmployeeCarService,
  ICarInsurance,
  ICarHistory,
} from "types/CarTypes";

export const carAPI = createApi({
  reducerPath: "carAPI",
  tagTypes: ["car", "carRecommendation", "cars", "serviseCar", "employee", 'models', 'generations', 'series', 'modifications', 'equipments'],
  baseQuery,
  endpoints: (build) => ({
    updateData: build.mutation<void, number>({
      query: (carId) => ({
        url: `admins/users/${carId}/getCars`,
        method: "GET",
      }),
      invalidatesTags: ["cars"],
    }),
    getInsurances: build.query<{ data: ICarInsurance[] }, number>({
      query: (id) => ({
        url: `/insurance/${id}`,
        method: "GET",
      }),
    }),
    getHistory: build.query<{ workhistory: ICarHistory[] }, number>({
      query: (id) => ({
        url: `cars/${id}/get-to-history`,
        method: "GET",
      }),
    }),
    // Для рекомендаций к машине
    getMarks: build.query<{ data: IMark[] }, void>({
      query: () => ({
        url: "helpers/marks",
        method: "GET",
      }),
    }),
    getModels: build.query<{ data: IModel[] }, number>({
      query: (markId) => ({
        url: `helpers/models/${markId}`,
        method: "GET",
      }),
    }),
    getGenerations: build.query<{ data: IGeneration[] }, number>({
      query: (modelId) => ({
        url: `helpers/generations/${modelId}`,
        method: "GET",
      }),
    }),
    getSeries: build.query<{ data: ISerie[] }, number>({
      query: (generationId) => ({
        url: `helpers/series/${generationId}`,
        method: "GET",
      }),
    }),
    getModifications: build.query<{ data: IModification[] }, number>({
      query: (serieId) => ({
        url: `helpers/modifications/${serieId}`,
        method: "GET",
      }),
    }),
    getEquipments: build.query<{ data: IEquipment[] }, number>({
      query: (modificationId) => ({
        url: `helpers/equipment/${modificationId}`,
        method: "GET",
      }),
    }),
    getСharacteristics: build.query<{ data: IModificationCharacteristics[] }, number>({
      query: (modificationId) => ({
        url: `helpers/characteristics/${modificationId}`,
        method: "GET",
      }),
    }),
    createTOCarRecommendation: build.mutation({
      query: (comment) => ({
        url: `/admins/to-comments`,
        method: "POST",
        body: comment,
      }),
      invalidatesTags: ["carRecommendation"],
    }),
    getTOCarRecommendation: build.query<{ data: ICarRecommendation[]; meta: any }, any>({
      query: (params) => ({
        url: `/admins/to-comments`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["carRecommendation"],
    }),
    deleteTOCarRecommendation: build.mutation<void, number>({
      query: (commentId) => ({
        url: `/admins/to-comments/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["carRecommendation"],
    }),
    createCarRecommendation: build.mutation({
      query: (comment) => ({
        url: `/comments`,
        method: "POST",
        body: comment,
      }),
      invalidatesTags: ["carRecommendation"],
    }),
    getCarRecommendation: build.query<{ data: ICarRecommendation[]; meta: any }, any>({
      query: (params) => ({
        url: `/comments`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["carRecommendation"],
    }),
    deleteCarRecommendation: build.mutation<void, number>({
      query: (commentId) => ({
        url: `/comments/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["carRecommendation"],
    }),
    // CRUD для машины
    createCar: build.mutation<{car: {id: number}}, ICreateCarInfo>({
      query: (car) => ({
        url: `/admins/cars`,
        method: "POST",
        body: car,
      }),
      invalidatesTags: ["cars"],
    }),
    editCar: build.mutation<{ data: any }, ICreateCarInfo>({
      query: (car) => ({
        url: `admins/cars/${car.car_id}/update`,
        method: "POST",
        body: car,
      }),
      invalidatesTags: ["car"],
    }),
    getCars: build.query<{ data: ICarInfo[]; meta: any }, ICarFilters | void>({
      query: (params) => ({
        url: `/admins/cars`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["cars"],
    }),
    getCar: build.query<{ data: ICarInfo }, number>({
      query: (carId) => ({
        url: `/admins/cars/${carId}`,
        method: "GET",
      }),
      providesTags: (result) => ["car"],
    }),
    deleteCar: build.mutation<void, number>({
      query: (carId) => ({
        url: `/admins/cars/${carId}`,
        method: "DELETE",
        body: carId,
      }),
      invalidatesTags: ["cars"],
    }),

    // cервисного авто
    createServiceCar: build.mutation<void, ICreateServiceCar>({
      query: (serviceCar) => ({
        url: `/admins/service-car`,
        method: "POST",
        body: serviceCar,
      }),
      invalidatesTags: ["serviseCar"],
    }),
    getServiceCar: build.query<{ data: IServiceCar[] }, any>({
      query: (params) => ({
        url: `/admins/service-car`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["serviseCar"],
    }),
    getServiceDevices: build.query<{ data: IServiceDevices[] }, any>({
      query: (params) => ({
        url: `/service-devices`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["serviseCar"],
    }),
    deleteServiceCar: build.mutation<void, number>({
      query: (serviceCarId) => ({
        url: `/admins/service-car/${serviceCarId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["serviseCar"],
    }),

    // сотрудник службы заботы
    createEmployeeCarService: build.mutation<void, ICteateEmployeeCarService>({
      query: (employeeCarService) => ({
        url: `/admins/care-application-employee`,
        method: "POST",
        body: employeeCarService,
      }),
      invalidatesTags: ["employee"],
    }),
    getEmployeeCarService: build.query<{ data: IEmployeeCarService[] }, any>({
      query: (params) => ({
        url: `/admins/care-application-employee`,
        method: "GET",
        params,
      }),
      providesTags: (result) => ["serviseCar"],
    }),
    deleteEmployeeCarService: build.mutation<void, number>({
      query: (employeeId) => ({
        url: `/admins/care-application-employee/${employeeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["serviseCar"],
    }),
    registerSignaling: build.mutation<
      void,
      {
        key: string;
        name: string;
        type: "gps-alarm";
        tel: string;
        service?: null | number;
        car_id: number;
      }
    >({
      query: (body) => ({
        url: `cars/register-car`,
        method: "POST",
        body,
      }),
    }),
  }),
});
