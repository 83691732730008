import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "components/Common/Pagination";
import { useHistory } from "react-router";

const ListItem = ({ rents, currentPage, setCurrentPage, amountPage }) => {
  const listTitleItems = [
    { id: 2, title: "Машина" },
    { id: 2, title: "Номер" },
    { id: 2, title: "ФИО клиента" },
    { id: 3, title: "Начало" },
    { id: 4, title: "Конец" },
    { id: 5, title: "Оплачена" },
  ];

  const history = useHistory();
  const showInfoUser = (rent) => {
    return rent.user.name && rent?.user.surname
      ? rent.user.surname + " " + rent.user.name
      : rent.user?.FIO != null && rent.user?.FIO.trim().length > 0
      ? rent.user.FIO
      : rent.user.id != null
      ? "id: " + rent?.user.id
      : "Информации нет";
  };

  return (
    <div>
      <div className="card">
        <div className="card-header list-title" style={{ marginBottom: 20 }}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                {listTitleItems.map((item, index) => (
                  <th scope="col" key={index}>
                    {item.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rents.map((rent, index) => {
                return (
                  <tr key={index}>
                    <th
                      onClick={() =>
                        history.push(`/alyans-rent/rent/${rent?.rent?.id}`)
                      }
                      style={{ cursor: rent?.rent?.id ? "pointer" : "" }}
                    >
                      {rent.rent.id}
                    </th>
                    <td
                      onClick={() =>
                        history.push(`/alyans-rent/cars/${rent?.car?.id}`)
                      }
                      style={{ cursor: rent?.car?.id ? "pointer" : "" }}
                    >
                      {rent.car.mark} {rent.car.model}
                    </td>
                    <td>{rent.car.registration_number}</td>
                    <td
                      onClick={() =>
                        history.push(`/alyans-rent/users/${rent?.user?.id}`)
                      }
                      style={{ cursor: rent?.user?.id ? "pointer" : "" }}
                    >
                      {showInfoUser(rent)}
                    </td>
                    <td>
                      {moment(rent.rent?.start_date).format("DD.MM.YYYY HH:mm")}
                    </td>
                    <td>
                      {moment(rent.rent?.end_date).format("DD.MM.YYYY HH:mm")}
                    </td>
                    <td>{rent.rent?.is_paid === 0 ? "-" : "+"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesAmount={amountPage}
        />
      </div>
    </div>
  );
};

export default ListItem;
