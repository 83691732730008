import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Button, Label } from "reactstrap";
import { careAPI } from "services";
import styled from "styled-components";
import { KnowledgeReason } from "types/CareServiceTypes";

interface KnowledgeReasonSelectorProps {
  reasonItem: null | KnowledgeReason;
  setReasonItem: (Dispatch<SetStateAction<KnowledgeReason | null>>) | ((reason: KnowledgeReason | null) => void);
  setReasonCode?: (Dispatch<SetStateAction<number>>) | ((value: number) => void);
  enableScrollToBottom?: boolean
  onReset?: () => void;
}

const KnowledgeReasonSelector = ({
  reasonItem, 
  setReasonItem, 
  enableScrollToBottom = true, 
  setReasonCode,
  onReset 
}: KnowledgeReasonSelectorProps) => {
  const [reasonId, setReasonId] = useState<number>();

  const {
    data: reasonsData,
    isLoading: reasonsIsLoading,
    isFetching: reasonsIsFetching,
  } = careAPI.useGetKnowledgeReasonsQuery(reasonId);

  const [reasons, setReasons] = useState<KnowledgeReason[]>([]);
  const [reasonsSelectHistory, setReasonsSelectHistory] = useState<KnowledgeReason[][]>([]);
  const [reasonCodes, setReasonCodes] = useState<number[]>([]);

  const firstRender = useRef(true)

  const onChangeReason = useCallback((item: KnowledgeReason) => {
    if (item.isGroup) {
      setReasonId(item.id);
      setReasonCodes(prev => [...prev, item.code])
    } else {
      setReasonItem(item);
      setReasonCode?.(item.code)
    }
  }, [reasonCodes]);

  const onBackReason = useCallback(() => {
    setReasons(reasonsSelectHistory[reasonsSelectHistory.length - 2]);
    setReasonsSelectHistory((prev) => prev.slice(0, prev.length - 1));
    setReasonItem(null);
    setReasonCodes(prev => prev.slice(0, prev.length - 1))
  }, [reasonsSelectHistory]);

  const onResetReason = useCallback(() => {
    setReasons(reasonsSelectHistory[0]);
    setReasonsSelectHistory((prev) => [prev[0]]);
    setReasonItem(null);
    setReasonCodes([])
    onReset?.()
  }, [reasonsSelectHistory]);

  useEffect(() => {
    if (reasonsData) {
      setReasons(reasonsData.data);
      setReasonsSelectHistory((prev) => [...prev, reasonsData.data]);
    }
  }, [reasonsData]);

  useEffect(() => {
    if (enableScrollToBottom) {
      if (reasons.length > 0) {
        if (!firstRender.current) {
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 50);
        }
        firstRender.current = false;
      }
    }
  }, [reasons]);

  return ( <div style={{ flex: 1 }}>
    <Label for='serie'>Причина</Label>
    <ReasonSelect>
      {reasonsIsLoading || reasonsIsFetching ? (
        <div className='loading'>Загрузка...</div>
      ) : (
        <>
          <div className='items'>
            {reasons?.map((item) => (
              <article
                className={!item.isGroup && reasonItem?.id === item.id ? 'active' : ''}
                key={item.id}
                onClick={() => onChangeReason(item)}
              >
                <p>{item.name}</p>
              </article>
            ))}
          </div>
          {reasonsSelectHistory.length > 1 ? (
            <div className='btns'>
              <Button color='primary' onClick={onBackReason}>
                Назад
              </Button>
              <Button color='danger' onClick={onResetReason}>
                Сбросить
              </Button>
            </div>
          ) : null}
        </>
      )}
    </ReasonSelect>
  </div> );
}

const ReasonSelect = styled.div`
  margin-bottom: 50px;
  div.items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 10px;
  }

  article {
    background-color: #f8f7f9;
    padding: 10px 10px;
    transition: opacity 0.5s;
    cursor: pointer;
    border-radius: 4px;

    &.active {
      background-color: #0e6dd1;
      color: #fff;
      pointer-events: none;
    }

    p {
      margin-bottom: 0;
      line-height: 14px;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  div.btns {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  div.loading {
    padding: 10px 0;
  }
`;
 
export default KnowledgeReasonSelector;