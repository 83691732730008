import { carAPI, careAPI } from 'services';
import KnowledgeReasonSelector from './Common/KnowledgeReasonSelector';
import PaginationTable from './Common/PaginationTable';
import { MutableRefObject, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { formatDataForKnowledgeTable } from 'common/dataFormatters';
import { KnowledgeFilters, KnowledgeReason } from 'types/CareServiceTypes';
import { Button, Input, Label } from 'reactstrap';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { debounce } from 'lodash';

interface KnowledgeTableProps {
  car_id: number | '';
  modification_id?: number | '';
}

export interface ImperativeKnowledgeTableProps {
  updateKnowledge: ({ car_id, modification_id }: KnowledgeTableProps) => void;
}

export interface KnowledgeTableFilter {
  reasonCode: string | null;
  errorCode: { value: number; label: string } | null;
  transmissionType: { value: string; label: string } | null;
  engineType: { value: string; label: string } | null;
  source: { value: string; label: string } | null;
  year: { value: number; label: number } | null;
  model: { value: number; label: string } | null;
  mark: { value: number; label: string } | null;
}

export const KNOWLEDGE_HEADER = [
  'Источник',
  'Марка',
  'Модель',
  'Тип двигателя',
  'Год',
  'Причина',
  'Выполненные работы',
  'Задействованные материалы',
  'Результат работ / Рекомендации',
  'Код ошибки',
];

const KnowledgeTable = forwardRef<ImperativeKnowledgeTableProps | undefined, KnowledgeTableProps>(
  ({ car_id, modification_id }, ref) => {
    const { data: marks } = carAPI.useGetMarksQuery();
    const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();

    const [getKnowledge, { data: knowledge, isLoading, isFetching }] = careAPI.useLazyGetKnowledgeQuery();

    const [filter, setFilter] = useState<KnowledgeTableFilter>({
      reasonCode: '',
      errorCode: null,
      transmissionType: null,
      engineType: null,
      source: null,
      year: null,
      mark: null,
      model: null,
    });

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const changePageHandler = useCallback(
      (targetPage: number) => {
        setPage(targetPage);
        getKnowledge({ modification_id, car_id, page: targetPage, per_page: perPage, filter });
      },
      [modification_id, car_id, filter]
    );

    const confirmFilter = useCallback(() => {
        if (page === 1) {
          getKnowledge({ modification_id, car_id, page, per_page: perPage, filter });
          return;
        }

        setPage(1);
    }, [page, modification_id, car_id, filter, perPage])

    const updateReasonCode = useCallback(debounce((value: string) => {
      setFilter(prev => ({...prev, reasonCode: value}))
    }, 300), [])

    useImperativeHandle(
      ref,
      () => ({
        updateKnowledge(props) {
          getKnowledge({ ...props, page, per_page: perPage, filter });
        },
      }),
      [page, perPage, filter, car_id, modification_id]
    );

    return (
      <div className='card'>
        <div className='card-header'>Информация из базы знаний</div>
        <div className='card-body' style={{ maxHeight: 600, overflow: 'scroll' }}>
          {!knowledge ? <div>Данных не найдено</div> : null}
          {knowledge ? (
            <div>
              <h4>Фильтры</h4>
              <div>
                <FlexContainer>
                  <Flex>
                    <Label>Бренд</Label>
                    <ReactSelect
                      value={filter.mark}
                      onChange={(mark) => {
                        setFilter((prev) => ({ ...prev, mark: mark }));
                        if (mark?.value) getModels(mark?.value);
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={marks?.data.map(({ id, name }) => ({ label: name, value: id }))}
                    />
                  </Flex>
                  <Flex>
                    <Label>Модель</Label>
                    <ReactSelect
                    isDisabled={!filter.mark}
                      value={filter.model}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, model: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={models?.data.map(({ id, name }) => ({ label: name, value: id }))}
                    />
                  </Flex>
                  <Flex>
                    <Label>Тип двигателя</Label>
                    <ReactSelect
                      value={filter.engineType}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, engineType: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={knowledge.filters.engineType.map((value) => ({ label: value, value }))}
                    />
                  </Flex>
                  <Flex>
                    <Label>Коробка передач</Label>
                    <ReactSelect
                      value={filter.transmissionType}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, transmissionType: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={knowledge.filters.transmissionType.map((value) => ({ label: value, value }))}
                    />
                  </Flex>
                  <Flex>
                    <Label>Год</Label>
                    <ReactSelect
                      value={filter.year}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, year: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={knowledge.filters.year.map((value) => ({ label: value, value }))}
                    />
                  </Flex>
                </FlexContainer>
                <FlexContainer>
                  <Flex>
                    <Label>Источник</Label>
                    <ReactSelect
                      value={filter.source}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, source: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={knowledge.filters.source.map((value) => ({ label: value, value }))}
                    />
                  </Flex>
                  <Flex>
                    <Label>Причина</Label>
                    <Input style={{marginLeft: 0}} onChange={({target}) => updateReasonCode(target.value)}/>
                  </Flex>
                  <Flex>
                    <Label>Код ошибки</Label>
                    <ReactSelect
                      value={filter.errorCode}
                      onChange={(value) => {
                        setFilter((prev) => ({ ...prev, errorCode: value }));
                      }}
                      placeholder='Выберите значение'
                      isSearchable={true}
                      isClearable
                      options={knowledge.filters.errorCodes.map(({ code, id }) => ({
                        label: code,
                        value: id,
                      }))}
                    />
                  </Flex>
                </FlexContainer>
                <Button
                  onClick={confirmFilter}
                  style={{ width: 150 }}
                  color='primary'
                >
                  Найти
                </Button>
              </div>
            </div>
          ) : null}
          <PaginationTable
            title=''
            thTitles={KNOWLEDGE_HEADER}
            tableData={formatDataForKnowledgeTable(knowledge?.data || [])}
            canDelete={false}
            loading={isLoading || isFetching}
            currentPage={page}
            setCurrentPage={changePageHandler}
            amountPage={knowledge?.meta.last_page}
            perPage={perPage}
            setPerPage={setPerPage}
            isBackPagination={true}
          />
        </div>
      </div>
    );
  }
);

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Flex = styled.div`
  flex: 1;
`;

export default KnowledgeTable;
