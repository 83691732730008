import PropTypes from "prop-types";
// import MetaTags from 'react-meta-tags';
import React from "react";
import { useFormik } from "formik";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";

import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import { authAPI } from "services";
import { loginSchema } from "common/validationSchemas";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const [login, { isError, isLoading: loginLoading }] =
    authAPI.useLoginMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await login(values);
      resetForm();
      history.push((location.state as any)?.from?.pathname || "/dashboard");
    },
  });

  return (
    <React.Fragment>
      {/* <MetaTags>
        <title>Логин</title>
      </MetaTags> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Добро пожаловать!
                    </h5>
                    <p className="text-white-50">
                      Введите свои данные, чтобы зайти в панель администратора
                    </p>

                    <div
                      className="logo logo-admin d-flex align-items-center justify-content-center"
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        borderBottom: "2px solid #626ed4",
                      }}
                    >
                      <img
                        className="h-auto"
                        style={{ width: 90 }}
                        src={logo}
                        height="24"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <form
                      onSubmit={formik.handleSubmit}
                      className="form-horizontal mt-5"
                    >
                      <FormGroup className="mb-3">
                        <Label for="email">E-mail</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          invalid={!!formik.errors.email}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          placeholder="Введите e-mail"
                        />
                        <FormText color="danger">
                          {formik.errors.email}
                        </FormText>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="password">Пароль</Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          invalid={!!formik.errors.password}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          placeholder="Введите пароль"
                        />
                        <FormText color="danger">
                          {formik.errors.password}
                        </FormText>
                      </FormGroup>

                      <Row className="mb-3">
                        <Col sm={12} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loginLoading}
                          >
                            Войти
                          </button>
                        </Col>
                      </Row>

                      {/* <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </Row> */}
                    </form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Нет аккаунта?{" "}
                  <Link to="register" className="fw-medium text-primary">
                    Зарегистрироваться
                  </Link>
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
