import React, { Fragment, useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  const [show, setShow] = useState(true);

  return (
    <Fragment>
      <div className="box-tail-spin">
        <TailSpin color="#02a499" />
      </div>
    </Fragment>
  );
};

export default Loader;
