import { createCarSchema, editCarSchema } from "common/validationSchemas";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { carAPI } from "services";
import { ICarConnectType } from "types/CarTypes";
import SelectUser from "./SelectUser";
import { CONNECT_TYPES } from "./static";

const EditCar = () => {
  const { id } = useParams<{ id: string }>();

  const { data: car } = carAPI.useGetCarQuery(+id);

  const { data: marks } = carAPI.useGetMarksQuery();
  const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();
  const [getGenerations, { data: generations }] =
    carAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = carAPI.useLazyGetSeriesQuery();
  const [getModifications, { data: modifications }] =
    carAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] =
    carAPI.useLazyGetEquipmentsQuery();

  const [markId, setMarkId] = React.useState<string | undefined>();

  const [editCar] = carAPI.useEditCarMutation();

  const connectTypes: {
    [key: string]: ICarConnectType;
  } = { alyansconnect: "alyansconnect", starline: "starline" };

  useEffect(() => {
    if (marks && car) {
      const mark = marks.data.find((item) => item.name === car.data.brand);
      if (mark) {
        setMarkId(String(mark.id));
        getModels(mark.id);
      }
    }
  }, [car, marks]);

  useEffect(() => {
    const { model, generation, serie, modification } = formik.values;

    !!model && getGenerations(+model);
    !!generation && getSeries(+generation);
    !!serie && getModifications(+serie);
    !!modification && getEquipments(+modification);
  }, [models, generations, series, modifications]);

  function checkNull(item: string | number | undefined | null) {
    return item === null || !item ? "" : item;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mark: markId,
      model: checkNull(car?.data.model_id),
      generation: checkNull(car?.data.generation_id),
      serie: checkNull(car?.data.serie_id),
      modification: checkNull(car?.data.modification_id),
      equipment: checkNull(car?.data.equipment_id),
      connect_type: checkNull(car?.data.connectType),
      year: checkNull(car?.data.year),
      vin: checkNull(car?.data.vin),
      sts: checkNull(car?.data.sts),
      registrationNumber: checkNull(car?.data.registration_number),
      imei: checkNull(car?.data.imei),
      code: checkNull(car?.data.code),
      mileage: checkNull(car?.data.mileage),
      user: checkNull(car?.data.user_id),
    },
    validationSchema: editCarSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      let params = {};
      if (values.connect_type !== "") {
        params =
          values.connect_type === connectTypes.alyansconnect
            ? {
                id: values.imei,
                code: values.code,
              }
            : {
                id: values.imei,
              };

        params = { ...params, connectType: values.connect_type };
      }

      const data = {
        car_id: id,
        mark_id: values.mark,
        model_id: values.model,
        generation_id: values.generation,
        serie_id: values.serie,
        modification_id: values.modification,
        equipment_id: values.equipment,
        registration_number: values.registrationNumber,
        year: values.year,
        vin: values.vin,
        sts: values.sts,
        mileage: values.mileage,
        user_id: values.user,
        ...params,
      };

      //@ts-ignore
      editCar(data);
      resetForm();
    },
  });

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Редактирование автомобиля</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="mb-5 ">
          <div className="card">
            <div className="card-header">Информация об автомобиле</div>
            <div className="card-body card-body-mobile">
              <div className="row mb-3 card-body-mobile">
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="mark">Марка</Label>
                    <Input
                      id="mark"
                      name="mark"
                      type="select"
                      value={formik.values.mark}
                      onChange={(e) => {
                        const markId = e.target.value;
                        formik.setFieldValue("mark", markId);
                        markId && getModels(+markId);
                      }}
                      invalid={!!formik.errors.mark}
                    >
                      <option value="">Выберите марку</option>
                      {marks?.data.map((mark) => (
                        <option key={mark.id} value={mark.id}>
                          {mark.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.mark}</FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="model">Модель</Label>
                    <Input
                      id="model"
                      name="model"
                      type="select"
                      value={formik.values.model}
                      onChange={(e) => {
                        const modelId = e.target.value;
                        formik.setFieldValue("model", modelId);
                        modelId && getGenerations(+modelId);
                      }}
                      invalid={!!formik.errors.model}
                    >
                      <option value="">Выберите модель</option>
                      {models?.data.map((model) => (
                        <option key={model.id} value={model.id}>
                          {model.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.model}</FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="generation">Поколение</Label>
                    <Input
                      id="generation"
                      name="generation"
                      type="select"
                      value={formik.values.generation}
                      onChange={(e) => {
                        const generationId = e.target.value;
                        formik.setFieldValue("generation", generationId);
                        generationId && getSeries(+generationId);
                      }}
                      invalid={!!formik.errors.generation}
                    >
                      <option value="">Выберите поколение</option>
                      {generations?.data.map((generation) => (
                        <option key={generation.id} value={generation.id}>
                          {generation.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">
                      {formik.errors.generation}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="serie">Серия</Label>
                    <Input
                      id="serie"
                      name="serie"
                      type="select"
                      value={formik.values.serie}
                      onChange={(e) => {
                        const serieId = e.target.value;
                        formik.setFieldValue("serie", serieId);
                        serieId && getModifications(+serieId);
                      }}
                      invalid={!!formik.errors.serie}
                    >
                      <option value="">Выберите серию</option>
                      {series?.data.map((serie) => (
                        <option key={serie.id} value={serie.id}>
                          {serie.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.serie}</FormText>
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-3 card-body-mobile">
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="modification">Модификация</Label>
                    <Input
                      id="modification"
                      name="modification"
                      type="select"
                      value={formik.values.modification}
                      onChange={(e) => {
                        const modificationId = e.target.value;
                        formik.setFieldValue("modification", modificationId);
                        modificationId && getEquipments(+modificationId);
                      }}
                      invalid={!!formik.errors.modification}
                    >
                      <option value="">Выберите модификацию</option>
                      {modifications?.data.map((modification) => (
                        <option key={modification.id} value={modification.id}>
                          {modification.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">
                      {formik.errors.modification}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="equipment">Комплектация</Label>
                    <Input
                      id="equipment"
                      name="equipment"
                      type="select"
                      value={formik.values.equipment}
                      onChange={(e) => {
                        const equipmentId = e.target.value;
                        formik.setFieldValue("equipment", equipmentId);
                      }}
                    >
                      <option value="">Выберите комплектацию</option>
                      {equipments?.data.map((equipment) => (
                        <option key={equipment.id} value={equipment.id}>
                          {equipment.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="connectType">Connect Type</Label>
                    <Input
                      id="connectType"
                      name="connectType"
                      type="select"
                      value={formik.values.connect_type}
                      onChange={(e) => {
                        const connectId = e.target.value;
                        formik.setFieldValue("connect_type", connectId);
                      }}
                    >
                      <option value="">Выберите комплектацию</option>
                      {CONNECT_TYPES.map((connectType) => (
                        <option
                          key={connectType.value}
                          value={connectType.value}
                        >
                          {connectType.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="year">Год выпуска</Label>
                    <Input
                      id="year"
                      name="year"
                      type="number"
                      value={formik.values.year}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.year}
                    />
                    <FormText color="danger">{formik.errors.year}</FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="vin">VIN</Label>
                    <Input
                      id="vin"
                      name="vin"
                      type="text"
                      value={formik.values.vin}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.vin}
                    />
                    <FormText color="danger">{formik.errors.vin}</FormText>
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-3 card-body-mobile">
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="sts">STS</Label>
                    <Input
                      id="sts"
                      name="sts"
                      type="text"
                      value={formik.values.sts}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.sts}
                    />
                    <FormText color="danger">{formik.errors.sts}</FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="registrationNumber">Номер регистрации</Label>
                    <Input
                      id="registrationNumber"
                      name="registrationNumber"
                      type="text"
                      value={formik.values.registrationNumber}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.registrationNumber}
                    />
                    <FormText color="danger">
                      {formik.errors.registrationNumber}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="mileage">Пробег</Label>
                    <Input
                      id="mileage"
                      name="mileage"
                      type="number"
                      value={formik.values.mileage}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.mileage}
                    />
                    <FormText color="danger">{formik.errors.mileage}</FormText>
                  </FormGroup>
                </div>
                {formik.values.connect_type === "starline" && (
                  <div className="col">
                    <FormGroup className="mb-3">
                      <Label for="imei">IMEI</Label>
                      <Input
                        id="imei"
                        name="imei"
                        type="text"
                        value={formik.values.imei}
                        onChange={formik.handleChange}
                        invalid={!!formik.errors.imei}
                      />
                      <FormText color="danger">{formik.errors.imei}</FormText>
                    </FormGroup>
                  </div>
                )}
                {formik.values.connect_type === "alyansconnect" && (
                  <React.Fragment>
                    <div className="col">
                      <FormGroup className="mb-3">
                        <Label for="imei">ID</Label>
                        <Input
                          id="imei"
                          name="imei"
                          type="text"
                          value={formik.values.imei}
                          onChange={formik.handleChange}
                          invalid={!!formik.errors.imei}
                        />
                        <FormText color="danger">{formik.errors.imei}</FormText>
                      </FormGroup>
                    </div>
                    <div className="col">
                      <FormGroup className="mb-3">
                        <Label for="code">Код</Label>
                        <Input
                          id="code"
                          name="code"
                          type="text"
                          value={formik.values.code}
                          onChange={formik.handleChange}
                          invalid={!!formik.errors.code}
                        />
                        <FormText color="danger">{formik.errors.code}</FormText>
                      </FormGroup>
                    </div>
                  </React.Fragment>
                )}
                <SelectUser
                  width={"100%"}
                  defaultUser={car?.data.user_id}
                  onSelected={(item) => formik.setFieldValue("user", item.id)}
                />
              </div>

              <button type="submit" className="btn btn-success btn-card-body">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCar;
