import React from "react";
import s from "./Modal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}
const Modal = ({ isOpen, title, children, onClose }: ModalProps) => {
  return (
    <div onClick={onClose} className={`${s.Modal} ${isOpen && s.Modal_open}`}>
      <div onClick={(e) => e.stopPropagation()} className={s.Modal__body}>
        <div className={s.Modal__header}>
          <div className={s.Modal__header__title}>
            <h3>{title}</h3>
          </div>
          <div onClick={onClose} className={s.Modal__close}>
            <i className="ti-close"></i>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
