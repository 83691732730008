import { ICarInfo } from './CarTypes';
import { IUserInfo } from './UserTypes';

export interface IStatus {
  id: number;
  name: string;
  color: string;
}

export const serviceTypes = [
  { id: 1, name: 'Служба заботы' },
  { id: 2, name: 'Консьерж сервис' },
  { id: 3, name: 'Выездной шиномонтаж' },
  { id: 4, name: 'Вызов эвакуатора' },
  { id: 5, name: 'Аварийный комиссар'}
];

export interface IRequestCreate {
  id: number;
  user_id: number;
  care_status_id: number;
  type: number;
  latitude: string;
  longitude: string;
}

export interface IRequest {
  id: number;
  type: string;
  status: IStatus;
  user: Omit<IUserInfo, 'email' | 'address'>;
  latitude: string;
  longitude: string;
  address: string;
  created_at: string;
  info: {
    documentRealizationId: string;
    amount: string;
    wheel: string;
    invoice: string;
    sliding_platform: 'false' | 'true';
    latitude_to: string;
    longitude_to: string;
    address: string;
    documentid: string;
    card_payment: string;
  };
  car: ICarInfo;
  comments: { id: number; text: string }[];
}

export interface IRequestFilters {
  'filters[date_from]'?: string;
  'filters[date_to]'?: string;
  'filters[documentid]'?: string;
  page: number;
  per_page: number;
}

export interface Knowledge {
  id: number;
  source: string;
  brandName: string;
  modelName: string;
  mpBrandName: string;
  mpModelName: string;
  equipment: string;
  modification: string;
  mpEquipment: string;
  engineType: string;
  engineVolume: number;
  transmissionType: string;
  year: number;
  mileage: number;
  reason: string;
  reasonCodes: string[];
  errorCodes: {code: string}[];
  mainDetailReason: null;
  result_solution: string[];
  result_goods: { name: string }[];
  result_works: { name: string }[];
}

export interface KnowledgeReason {
  id: number;
  name: string;
  code: number;
  isGroup: boolean;
}

export interface SelectObj {
  label: string;
  value: string | number;
}

export interface CustomCar {
  year: string;
  vin: string;
  registration_number: string;
  mileage: string;
  sts: string;

  mark_id: null | SelectObj;
  model_id: null | SelectObj;
  generation_id: null | SelectObj;
  serie_id: null | SelectObj;
  modification_id: null | SelectObj;
  equipment_id: null | SelectObj;
}

export interface KnowledgeFilters {
  source: string[];
  reasonCodes: {id: number, reason: string}[];
  errorCodes: {id: number, code: string}[];
  transmissionType: string[];
  engineType: string[];
  year: number[];
}