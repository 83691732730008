export const infoUsersShow = (rent) => {
  if (!rent?.user) return "Информации нет";
  return rent?.user.name && rent?.user.surname
    ? rent.user.surname + " " + rent.user.name
    : rent.user?.FIO != null && rent.user?.FIO.trim().length > 0
    ? rent.user.FIO
    : rent.user.id != null
    ? "id: " + rent?.user.id
    : "Информации нет";
};
