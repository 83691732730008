import FilesLoad from "./FilesLoad";

interface InputChatProps {
  value: string;
  setValue: (v: string) => void;
  files: any;
  setFiles: (v: any) => void;
  handleSubmit: (v: any) => void;
  handleFileChange: (v: any) => void;
  handleChange: (v: any) => void;
}

const InputChat = ({
  value,
  setValue,
  files,
  setFiles,
  handleSubmit,
  handleFileChange,
  handleChange,
}: InputChatProps) => {
  return (
    <form
      onSubmit={(e) => {
        if (value !== "" || files.length > 0) {
          handleSubmit(e)
          setValue("");
          setFiles([]);
        }
      }}
    >
      <div className="p-0 m-0 d-flex justify-content-between align-items-center">
        <label className="p-0 m-0">
          <input
            type="file"
            className="d-none"
            accept="image/jpeg, image/png"
            onChange={(e) => handleFileChange(e)}
          />
          <i
            className="ti-pin-alt"
            style={{ fontSize: "20px", cursor: "pointer" }}
          />
        </label>
        <div style={{ width: "85%" }} className="py-1 px-2">
          <input
            type="text"
            style={{
              width: "100%",
              border: "1px solid #e9ecef",
              borderRadius: "4px",
            }}
            value={value}
            onChange={(e) => handleChange(e.target)}
          />
        </div>
        <button className="border-0 bg-transparent" type="submit">
          <svg
            aria-hidden="true"
            style={{ width: "20px" }}
            className="svg-inline--fa fa-paper-plane fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
            ></path>
          </svg>
        </button>
      </div>
      <div>{files?.length > 0 && <FilesLoad files={files} />}</div>
    </form>
  );
};
export default InputChat;
