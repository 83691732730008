import React, { useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { carAPI } from "services";

import { createEmployeeCarServiceSchema } from "common/validationSchemas";
// // import CarModificationCharacteristics from './CarModificationCharacteristics';
import PaginationTable from "components/Common/PaginationTable";
import { formatEmployeeCarServiceDataForTable } from "common/dataFormatters";

const EmployeeCarService = () => {
  const [createEmployeeCarService] =
    carAPI.useCreateEmployeeCarServiceMutation();
  const [getServiceCar, { data: serviceCarData }] =
    carAPI.useLazyGetServiceCarQuery();
  const [
    getEmployeeCarService,
    { data: employeeCarService, isLoading: employeeCarServiceLoading },
  ] = carAPI.useLazyGetEmployeeCarServiceQuery();
  const [
    deleteEmployeeCarService,
    { isLoading: deleteEmployeeCarServiceLoading },
  ] = carAPI.useDeleteEmployeeCarServiceMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      telegram: "",
      type: 1,
      serviceCar: 1,
    },
    validationSchema: createEmployeeCarServiceSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createEmployeeCarService({
        name: values.name,
        telegram_id: values.telegram,
        type: values.type,
        service_car_id: values.serviceCar,
      });
      getEmployeeCarService(true);
      resetForm();
    },
  });

  useEffect(() => {
    getEmployeeCarService(+formik.values.type);
    getServiceCar(+formik.values.type);
  }, [formik.values.type]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Создание сотрудника службы заботы</h2>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="mb-5">
            <div className="card">
              <div className="card-header">Данные сотрудника</div>
              <div className="card-body">
                <div className="filter-cars">
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="name">Имя</Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Введите имя"
                        value={formik.values.name}
                        onChange={(e) => {
                          const name = e.target.value;
                          formik.setFieldValue("name", name);
                        }}
                        invalid={!!formik.errors.name}
                      ></Input>
                      <FormText color="danger">{formik.errors.name}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="telegram">ID в телеграм</Label>
                      <Input
                        id="telegram"
                        name="telegram"
                        type="text"
                        placeholder="Введите ID"
                        value={formik.values.telegram}
                        onChange={(e) => {
                          const telegram = e.target.value;
                          formik.setFieldValue("telegram", telegram);
                        }}
                        invalid={!!formik.errors.telegram}
                      ></Input>
                      <FormText color="danger">
                        {formik.errors.telegram}
                      </FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup name="type">
                      <Label for="type">Тип</Label>
                      <Input
                        id="type"
                        type="select"
                        name="type"
                        value={formik.values.type}
                        onChange={(e) => {
                          const type = e.target.value;
                          formik.setFieldValue("type", +type);
                        }}
                        invalid={!!formik.errors.type}
                      >
                        <option value="1">Служба заботы</option>
                        <option value="4">Эвакуатор</option>
                        <option value="3">Выездной шиномонтаж</option>
                        <option value="5">Комиссар</option>
                      </Input>
                      <FormText color="danger">{formik.errors.type}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="serviceCar">Название сервисного авто</Label>
                      <Input
                        id="serviceCar"
                        name="serviceCar"
                        type="select"
                        value={formik.values.serviceCar}
                        onChange={(e) => {
                          const serviceCar = e.target.value;
                          formik.setFieldValue("serviceCar", serviceCar);
                        }}
                        invalid={!!formik.errors.serviceCar}
                      >
                        {serviceCarData?.data.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">
                        {formik.errors.serviceCar}
                      </FormText>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="btn btn-success">
                Сохранить данные
              </button>
            </div>
          </form>
        </FormikProvider>

        <PaginationTable
          title="Список сервисных авто"
          thTitles={["ID", "Имя", "ID телеграм", "Тип", "Название авто"]}
          tableData={formatEmployeeCarServiceDataForTable(
            employeeCarService?.data
          )}
          canDelete
          onDelete={deleteEmployeeCarService}
          deletePending={deleteEmployeeCarServiceLoading}
          loading={employeeCarServiceLoading}
        />
      </div>
    </div>
  );
};

export default EmployeeCarService;
