import React from "react";
import { IMessage } from "types/ChatTypes";

interface IProps {
  messages: any[];
  userId: number;
}

const MONTHS = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

function handleMessages(messages: any) {
  return messages.reduce(
    (data: any, currentMessage: any) => {
      const date = new Date(currentMessage.created_at);

      const day = date.getDate();
      const month = date.getMonth();

      if (data.prev?.day !== day || data.prev?.month !== month) {
        data.prev = {
          day,
          month,
        };
        data.msgs.push({
          date: [day, month],
        });
      }

      data.msgs.push(currentMessage);

      return data;
    },
    {
      msgs: [],
      prev: null,
    }
  ).msgs;
}

function getTime(dateMessage: string) {
  const date = new Date(dateMessage);

  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
}

const Messages = ({ messages, userId }: IProps) => {
  React.useEffect(() => {
    console.log("MESSAGES FROM MESSAGES", messages);
  }, []);
  return (
    <div style={{ minHeight: "80vh" }}>
      {handleMessages(messages).map((item: any, i: number) =>
        item.date ? (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px 0",
            }}>
            <div
              style={{
                textAlign: "center",
                width: "100px",
                padding: "3px 7px",
                backgroundColor: "#2E87E720",
                color: "#00000070",
                borderRadius: "20px",
                fontSize: "12px",
              }}>
              {item.date[0]} {MONTHS[item.date[1]]}
            </div>
          </div>
        ) : (
          <div key={i}>
            <div
              style={
                item.to_user
                  ? {
                      justifyContent: "flex-end",
                      display: "flex",
                      marginBottom: "4px",
                    }
                  : {
                      justifyContent: "flex-start",
                      display: "flex",
                      marginBottom: "4px",
                    }
              }>
              <div
                style={
                  item.to_user
                    ? {
                        position: "relative",
                        padding: "5px 10px",
                        background: "#2E87E7",
                        maxWidth: "60%",
                        color: "#fff",
                        borderRadius: "15px 15px 0px 15px",
                        // borderBottomRightRadius: "0px",
                      }
                    : {
                        position: "relative",
                        padding: "5px 8px",
                        background: "#F6F6F6",
                        maxWidth: "60%",
                        borderRadius: "15px 15px 15px 0px ",
                      }
                }>
                <div
                  style={{
                    position: "absolute",
                    fontSize: "10px",
                    color: "#00000050",
                    bottom: "0",
                    left: item.to_user ? "0" : "auto",
                    right: item.to_user ? "auto" : 0,
                    transform: `translateX(${item.to_user ? "-110%" : "110%"})`,
                  }}>
                  {getTime(item.created_at)}
                </div>
                <p style={{ textAlign: "left", marginBottom: 0 }}>{item.body}</p>
                {item.file.length !== 0 &&
                  item.file.map((item: any, i: number) => (
                    <div key={i} style={{ maxWidth: "100%" }}>
                      {item.type === "video" && (
                        <video style={{ width: "100%" }} controls>
                          <source src={item.name} />
                        </video>
                      )}
                      {item.type === "image" && (
                        <img src={item.name} alt="image" style={{ width: "100%" }}></img>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Messages;
