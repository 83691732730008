import React from "react"
import { IMaskInput } from "react-imask"
import { Input } from "reactstrap"

const MaskedInput = props => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="+ 7 000 000-00-00"
      onAccept={value => onChange({ target: { value } })}
      overwrite
    />
  )
}

export const PhoneInput = ({ value, onChange, ...props }) => {
  return (
    <Input
      {...props}
      label="Номер телефона"
      value={value}
      onChange={e => onChange(e.target.value)}
      // placeholder="+ 7 123 456-78-90"
      tag={MaskedInput}
      autoComplete="off"
      type="tel"
    />
  )
}
