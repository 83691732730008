import React, { Fragment, useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import ListRentUsers from 'components/Rents/Users/ListRentUsers';
import { useHistory } from 'react-router';
import Param from 'components/Rents/Users/Param';
import { Button, Input, Label } from 'reactstrap';
import { rentUserAPI } from 'services';
import UserModal from 'components/Rents/Users/UserModal';

import Pagination from 'components/Common/Pagination';
import { current } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

const Users = () => {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const params = new URLSearchParams(history.location.search);
  const [selectedUser, setSelectedUser] = useState(null);
  const [onlyVerified, setOnlyVerified] = useState(false);
  const [onlyApplications, setOnlyApplications] = useState(false);
  const [filterPhone, setFilterPhone] = useState(null);
  const [filterFIO, setFilterFIO] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesAmount, setPagesAmount] = useState(1);

  const [getFilteredUsers] = rentUserAPI.useGetAllUsersWithFiltersMutation();
  const [filteredUsers, setFilteredUsers] = React.useState([]);

  const { data: users, isLoading: usersLoading } = rentUserAPI.useGetUsersQuery({
    page: currentPage,
    verify: onlyVerified,
    request: onlyApplications,
  });

  useEffect(() => {
    users?.meta && setPagesAmount(users?.meta?.last_page);
  }, [users]);

  const listTitleItems = [
    { id: 1, title: 'ФИО' },
    { id: 2, title: 'Дата регистрации' },
    { id: 3, title: 'Последняя аренда' },
    { id: 4, title: 'Тип пользователя' },
    { id: 5, title: 'Статус' },
    { id: 6, title: 'Номер' },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [onlyVerified, onlyApplications]);

  const openInfoUser = (user) => {
    history.push(`/alyans-rent/users/${user.id}`);
  };

  const selectOptions = useMemo(() => {
    return filteredUsers
      .map((user) => {
        return {
          value: user,
          label:
            (user.surname ? user.surname + ' ' : '') +
            (user.name ? user.name + ' ' : '') +
            (user.patronymic ? user.patronymic : ''),
        };
      })
      .filter((item) => item.label.length != 0);
  }, [filteredUsers]);

  const selectByPhoneOptions = useMemo(
    () =>
      filteredUsers
        .map((user) => ({
          value: user,
          label: (user.phone ? user.phone + ' ' : '') + (user.surname ? user.surname : ''),
        }))
        .filter((item) => item.label.length != 0),
    [filteredUsers]
  );

  const onCnangeSelectPhone = (data) => {
    setFilterFIO(null);
    setFilterPhone(data);
    setSelectedUser(data?.value);
  };
  const onCnangeSelectFIO = (data) => {
    setFilterPhone(null);
    setFilterFIO(data);
    setSelectedUser(data?.value);
  };
  const deleteSelectedUser = () => {
    setFilterPhone(null);
    setFilterFIO(null);
    setSelectedUser(null);
  };

  const onInput = React.useCallback(
    debounce(({ value, type }) => {
      if (value) {
        getFilteredUsers({ [type]: value })
          .unwrap()
          .then((res) => setFilteredUsers(res.data));
      }
    }, 800),
    []
  );

  return (
    <div>
      <Fragment>
        <div className='container-fluid' style={{ backgroundColor: 'white' }}>
          <div className='row' style={{ backgroundColor: 'white' }}>
            <div className='col-sm-12' style={{ backgroundColor: 'white' }}>
              <div className='card-user-page' style={{ backgroundColor: 'white' }}>
                {usersLoading ? (
                  <p> Загрузка...</p>
                ) : (
                  <>
                    <div className='card-header '>
                      <div className='list-btn header-rent-page'>
                        <div className='d-flex align-items-center mr-2'>
                          <Label check>
                            <Input
                              type='checkbox'
                              checked={onlyVerified}
                              onChange={() => {
                                setOnlyApplications(false);
                                setOnlyVerified(!onlyVerified);
                              }}
                              style={{ marginRight: 8 }}
                            />
                            Только верифицированные
                          </Label>
                        </div>
                        <div className='d-flex align-items-center mr-2'>
                          <Label check>
                            <Input
                              type='checkbox'
                              checked={onlyApplications}
                              onChange={() => {
                                setOnlyVerified(false);
                                setOnlyApplications(!onlyApplications);
                              }}
                              style={{ marginRight: 8 }}
                            />
                            Заявки на верификацию
                          </Label>
                        </div>

                        <div className='mr-2 custom-select'>
                          <Select
                            onFocus={() => setFilteredUsers([])}
                            onInputChange={(value) => onInput({ value, type: 'phone' })}
                            value={filterPhone}
                            onChange={(data) => onCnangeSelectPhone(data)}
                            placeholder='Поиск по телефону'
                            isClearable
                            isSearchable
                            options={selectByPhoneOptions}
                          />
                        </div>

                        <div className='mr-2 custom-select'>
                          <Select
                            onFocus={() => setFilteredUsers([])}
                            value={filterFIO}
                            onInputChange={(value) => onInput({ value, type: 'name' })}
                            onChange={(data) => onCnangeSelectFIO(data)}
                            placeholder='Поиск по ФИО'
                            isClearable
                            isSearchable
                            options={selectOptions}
                          />
                        </div>

                        <Button color='primary' onClick={() => setIsShow(true)}>
                          Создать пользователя
                        </Button>
                      </div>
                      {onlyVerified && users == undefined ? (
                        <div
                          style={{
                            alignSelf: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#4466f2',
                            width: '100%',
                            justifyItems: 'center',
                          }}
                        >
                          <p>Загрузка...</p>
                        </div>
                      ) : (
                        <div id='table' className='card-header list-title' style={{ backgroundColor: 'white' }}>
                          <table className='table' style={{ backgroundColor: 'white' }}>
                            <thead>
                              <tr>
                                <th scope='col'>#</th>
                                {listTitleItems.map((i) => (
                                  <Param id={i.id} title={i.title} key={i.id} />
                                ))}
                                <th scrope='col'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedUser ? (
                                <ListRentUsers
                                  user={selectedUser}
                                  onOpen={openInfoUser}
                                  deleteSelectedUser={deleteSelectedUser}
                                />
                              ) : (
                                <>
                                  {users?.data?.map((user) => (
                                    <ListRentUsers user={user} key={user.id} onOpen={openInfoUser} />
                                  ))}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                    {!selectedUser && (
                      <Pagination
                        pagesAmount={pagesAmount}
                        currentPage={currentPage}
                        setCurrentPage={(page) => setCurrentPage(page)}
                      />
                    )}
                  </>
                )}
              </div>
              <UserModal isShow={isShow} setIsShow={setIsShow} />
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Users;
