import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { method } from "lodash";
import { toast } from "react-toastify";
import { baseQuery } from "services/settings";
import { CreateUserCareService, IEditUserInfo, IUserFilter, IUserInfo } from "types/UserTypes";

export const userAPI = createApi({
  reducerPath: "userAPI",
  tagTypes: ["users", "user", "findUser"],
  baseQuery,
  endpoints: (build) => ({
    createUser: build.mutation<
    {data: {id: number}},
      Omit<IUserInfo, "id" | "care_applications"> | CreateUserCareService
    >({
      query: (user) => ({
        url: `/admins/users`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["users"],
    }),
    sendSms: build.mutation<void, string>({
      query: (phone) => ({
        url: `/register?phone=${phone}`,
        method: "POST",
      }),
    }),
    confirmCode: build.mutation<void, [string, string]>({
      query: ([phone, code]) => ({
        url: `/verify/${code}?phone=${phone}`,
        method: "POST",
      }),
    }),
    deleteUser: build.mutation({
      query: (userId) => ({
        url: `/admins/users/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
    getUsers: build.query<{ data: IUserInfo[]; meta: any }, IUserFilter | void>(
      {
        query: (params) => ({
          url: `/admins/users/getUsers`,
          method: "GET",
          params,
        }),
        providesTags: ["users"],
      }
    ),
    getUser: build.query<{ data: IUserInfo }, number>({
      query: (userId) => ({
        url: `/admins/users/${userId}`,
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    editUser: build.mutation<void, IEditUserInfo & { id: number }>({
      query: (user) => ({
        url: `/admins/users/${user.id}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["user"],
    }),
    //Прявязка и отвязка машины к пользователю
    linkCarToUser: build.mutation({
      query: ({ carId, userId }) => ({
        url: `/admins/cars/${carId}/link`,
        method: "POST",
        body: { user_id: userId },
      }),
      invalidatesTags: ["user"],
    }),
    unlinkCarFromUser: build.mutation({
      query: ({ carId, userId }) => ({
        url: `/admins/cars/${carId}/unlink`,
        method: "POST",
        body: { user_id: userId },
      }),
      invalidatesTags: ["user"],
    }),
    findUser: build.query({
      query: ({phone}) => ({
        url: 'admins/users/getUserByPhone',
        method: 'GET',
        params: {
          phone
        }
      }),
      providesTags: ['findUser']
    })
  }),
});
