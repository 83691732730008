import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { formatPhone } from "../../../../utils/formatPhone";
import { clientSchema } from "utils/validationShemas";
import { PhoneInput } from "components/Common/PhoneInput";
import { rentCarAPI } from "services";

const trafficTypes = [
  { id: 1, name: "Звонок" },
  { id: 2, name: "Визит" },
  { id: 3, name: "Интернет обращение" },
];
const resultTypes = [
  { id: 1, name: "Не прошел проверку" },
  { id: 2, name: "Не соответствует требованиям" },
  { id: 3, name: "Такси" },
  { id: 4, name: "Нет свободных" },
  { id: 5, name: "Консультация" },
];

const CreateClientModal = ({
  isShow,
  setIsShow,
  onSumbit,
  isLoading,
  firstStageLength,
}) => {
  const { data: cars, isLoading: carsLoading } = rentCarAPI.useGetRentCarsQuery(
    {}
  );
  const formik = useFormik({
    initialValues: {
      traffic_id: "",
      surname: "",
      name: "",
      patronymic: "",
      phone: "",
      car_id: "",
      result_id: "",
      comment: "",
    },
    validationSchema: clientSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await onSumbit({
        ...values,
        statusId: 1,
        stageId: 1,
        count: firstStageLength,
      });
      toast.success("Клиент успешно создан");
      setIsShow(false);
      resetForm();
    },
  });

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>Создать лид</ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="traffic_id">Вид трафика</Label>
            <Input
              className="w-auto"
              type="select"
              id="traffic_id"
              name="traffic_id"
              invalid={!!formik.errors.traffic_id}
              onChange={(e) =>
                formik.setFieldValue("traffic_id", e.target.value)
              }
            >
              <option value="">Выбрать вид трафика</option>
              {trafficTypes.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Input>
            <FormText color="danger">{formik.errors.traffic_id}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="surname">Фамилия</Label>
            <Input
              id="surname"
              name="surname"
              type="text"
              invalid={!!formik.errors.surname}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.surname}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="name">Имя</Label>
            <Input
              id="name"
              name="name"
              type="text"
              invalid={!!formik.errors.name}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.name}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="patronymic">Отчество</Label>
            <Input
              id="patronymic"
              name="patronymic"
              type="text"
              invalid={!!formik.errors.patronymic}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.patronymic}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="phone">Номер телефона</Label>
            <PhoneInput
              id="phone"
              name="phone"
              value={formik.values.phone}
              invalid={!!formik.errors.phone}
              onChange={(value) =>
                formik.setFieldValue("phone", formatPhone(value))
              }
            />
            <FormText color="danger">{formik.errors.phone}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="car_id">Автомобиль</Label>
            <Input
              className="w-auto"
              type="select"
              id="car_id"
              name="car_id"
              invalid={!!formik.errors.car_id}
              onChange={(e) => formik.setFieldValue("car_id", e.target.value)}
              disabled={carsLoading}
            >
              <option value="">Выбрать автомобиль</option>
              {cars?.data.map(({ id, brand, model, registration_number }) => (
                <option key={id} value={id}>
                  {brand} {model} {registration_number}
                </option>
              ))}
            </Input>
            <FormText color="danger">{formik.errors.car_id}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="result_id">Результат</Label>
            <Input
              className="w-auto"
              type="select"
              id="result_id"
              name="result_id"
              invalid={!!formik.errors.result_id}
              onChange={(e) =>
                formik.setFieldValue("result_id", e.target.value)
              }
            >
              <option value="">Выбрать результат</option>
              {resultTypes.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Input>
            <FormText color="danger">{formik.errors.result_id}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="comment">Комментарий</Label>
            <Input
              id="comment"
              name="comment"
              type="text"
              invalid={!!formik.errors.comment}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.comment}</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading ? "Загрузка..." : "Создать"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateClientModal;
