import React, { Fragment, useEffect, useState, useMemo } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import ru from "date-fns/locale/ru";
import { Input, Label } from "reactstrap";
import { useHistory } from "react-router";
import Select from "react-select";
import { getDaysInMonth, isSameDay, isWithinInterval } from "date-fns";
import { getArrayFromDigit } from "../../utils/getArrayFromDigit";
import { copyDate, formatDate } from "../../utils/date";
import { rentAPI } from "../../services/rentService";
import DatePickerInput from "components/Common/DatePickerInput";
import { IRentCars } from "types/RentTypes";
import Loader from "components/Common/Loader";
registerLocale("ru", ru);

const CalendarTable = ({ cars }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState(() =>
    getArrayFromDigit(getDaysInMonth(new Date()))
  );
  const history = useHistory();
  const [selectedCar, setSelectedCar] = useState();
  const [carsAndRentsShow, setCarsAndRentsShow] = useState(null);
  const [onlyActual, setOnlyActual] = useState(false);
  const {
    data: allRents,
    isLoading: isLoadingRent,
    isFetching,
  } = rentAPI.useGetMonthRentsQuery(formatDate(selectedDate, "yyyy-MM-dd"));

  const makeUniq = (arr) => {
    // eslint-disable-next-line no-undef
    return Array.from(new Set(arr));
  };

  useEffect(() => {
    let carsAndRent = null;
    if (selectedCar) {
      carsAndRent = [
        {
          car: selectedCar,
          rents: allRents?.data?.filter(
            (rent) => rent.car.id === selectedCar.id
          ),
        },
      ];
    } else {
      let cars_id_unique = null;
      if (onlyActual) {
        const actual_rents = allRents?.data?.filter((rent) =>
          getIsActualRent(rent)
        );

        const date_actual_rents = actual_rents?.map(
          (rent) => rent.rent.start_date
        );
        let min_date_actual_rent = date_actual_rents[0];
        for (let i = 0; i < date_actual_rents.length; i++) {
          if (new Date(min_date_actual_rent) > new Date(date_actual_rents[i])) {
            min_date_actual_rent = date_actual_rents[i];
          }
        }

        if (
          new Date(min_date_actual_rent) < new Date() &&
          new Date(selectedDate).getMonth() == new Date().getMonth()
        ) {
          setDaysInSelectedMonth(
            daysInSelectedMonth.filter(
              (item) =>
                item > new Date(min_date_actual_rent).getDate() ||
                item == new Date(min_date_actual_rent).getDate()
            )
          );
        }

        cars_id_unique = makeUniq(actual_rents?.map((rent) => rent.car.id));
        const cars_unique = cars?.data?.filter((car, i) => {
          return cars_id_unique?.includes(car?.id);
        });
        carsAndRent = cars_unique?.map((car_unique) => {
          return {
            car: car_unique,
            rents: actual_rents?.filter(
              (rent) => rent?.car?.id === car_unique.id
            ),
          };
        });
      } else {
        cars_id_unique = makeUniq(allRents?.data?.map((rent) => rent.car.id));
        const cars_unique = cars?.data?.filter((car, i) => {
          return cars_id_unique?.includes(car?.id);
        });
        carsAndRent = cars_unique?.map((car_unique) => {
          return {
            car: car_unique,
            rents: allRents?.data?.filter(
              (rent) => rent?.car?.id === car_unique.id
            ),
          };
        });
        setDaysInSelectedMonth(getArrayFromDigit(getDaysInMonth(new Date())));
      }
    }
    setCarsAndRentsShow(carsAndRent);
  }, [allRents, cars, selectedCar, onlyActual]);

  useEffect(() => {
    setDaysInSelectedMonth(getArrayFromDigit(getDaysInMonth(selectedDate)));
    // ReactTooltip.rebuild();
  }, [selectedDate]);

  const handleCarSelect = async (car) => {
    setSelectedCar(car);
  };

  const getIsActiveCell = (rent, day) => {
    const isActiveCell = isSameDay(
      new Date(copyDate(selectedDate).setDate(day)),
      new Date(rent.rent.start_date)
    );
    return isActiveCell;
  };

  const getIsBusyCell = (rentObj, day) => {
    const { rent } = rentObj;

    if (
      new Date(new Date(rent.start_date)) > new Date(new Date(rent.end_date))
    ) {
      console.log("дата начала позже даты конца");
    } else {
      return (
        !isSameDay(
          new Date(copyDate(selectedDate).setDate(day)),
          new Date(rent.end_date)
        ) &&
        isWithinInterval(new Date(copyDate(selectedDate).setDate(day)), {
          start: new Date(new Date(rent.start_date).setHours(0, 0, 0)),
          end: new Date(new Date(rent.end_date).setHours(23, 59, 59)),
        })
      );
    }
  };

  const getIsActualRent = (rent) => {
    return (
      new Date(new Date()) < new Date(new Date(rent.rent.start_date)) ||
      isWithinInterval(new Date(), {
        start: new Date(new Date(rent.rent.start_date).setHours(0, 0, 0)),
        end: new Date(new Date(rent.rent.end_date).setHours(23, 59, 59)),
      })
    );
  };

  const selectOptions = useMemo(() => {
    return cars?.data
      ?.map((car) => {
        return {
          value: car,
          label: car.brand + " " + car.model + " " + car.registration_number,
        };
      })
      .filter((item) => item.label.length != 0);
  }, [cars]);

  const getInscriptionInCell = (rent) => {
    return rent.user.name && rent?.user.surname
      ? rent.user.surname + " " + rent.user.name
      : rent.user?.FIO != null && rent.user?.FIO.trim().length > 0
      ? rent.user.FIO
      : rent.user.id != null
      ? "id: " + rent?.user.id
      : "Информации нет";
  };

  if (isFetching || isLoadingRent) return <Loader />;
  return (
    <>
      <div className="header-calendar">
        <div className="box-change-date">
          <p className="label-change-date">Выберите месяц:</p>
          <ReactDatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            locale="ru"
            customInput={<DatePickerInput />}
          />
        </div>

        <div className="box-select-avto">
          <Select
            onChange={(e) => {
              console.log(e);
              handleCarSelect(e?.value);
            }}
            classNamePrefix="filter"
            placeholder="Выберите машину"
            isClearable
            isSearchable
            options={selectOptions}
          />
        </div>
        <div className="box-checkbox-only-actual">
          <Label check>
            <Input
              type="checkbox"
              checked={onlyActual}
              onChange={() => setOnlyActual(!onlyActual)}
              style={{ marginRight: 8 }}
            />
            Только актуальные
          </Label>
        </div>
      </div>
      <table
        className="table d-block o-auto table-fixed-head"
        style={{
          overflow: "auto",
        }}
      >
        <thead>
          <tr>
            <th scope="col"></th>

            {daysInSelectedMonth.map((day, i) => (
              <th className="text-nowrap" scope="col" key={i}>
                {formatDate(copyDate(selectedDate).setDate(day), "d MMMM")}
              </th>
            ))}
          </tr>
        </thead>
        {/* {JSON.stringify(carsAndRentsShow)} */}
        <tbody>
          {(
            selectedCar
              ? carsAndRentsShow[0]?.rents?.length > 0
              : carsAndRentsShow?.length > 0
          ) ? (
            carsAndRentsShow?.map((item, i) => {
              let colorBusyCells = "rgba(69, 110, 243, 0.13)";
              return (
                <Fragment key={item.car.id}>
                  <tr>
                    <th>
                      {item.car?.brand} {item.car?.model}{" "}
                      {item.car?.registration_number}
                    </th>
                    {daysInSelectedMonth.map((day, i) => {
                      const activeRents = item.rents.filter((rent1) =>
                        getIsActiveCell(rent1, day)
                      );
                      const busyRents = item.rents.filter((rent2) =>
                        getIsBusyCell(rent2, day)
                      );

                      const isActive = activeRents.length > 0;
                      const isBusy = busyRents.length > 0;
                      const active_rent = activeRents[0];
                      const busy_rent = busyRents[0];
                      let inscriptionInCell = null;
                      if (isBusy && day == 1) {
                        inscriptionInCell = getInscriptionInCell(busy_rent);
                      } else if (isActive) {
                        inscriptionInCell = getInscriptionInCell(active_rent);
                      }

                      if (isActive) {
                        if (colorBusyCells == "#D7DBDF") {
                          colorBusyCells = "rgba(69, 110, 243, 0.13)";
                        } else {
                          colorBusyCells = "#D7DBDF";
                        }
                      }

                      return (
                        <td
                          onClick={() => {
                            isActive &&
                              history.push(
                                `/alyans-rent/rent/${active_rent?.rent.id}`
                              );
                            isBusy &&
                              day == 1 &&
                              history.push(
                                `/alyans-rent/rent/${busy_rent?.rent.id}`
                              );
                          }}
                          key={i}
                          style={{
                            cursor:
                              (isActive || (isBusy && day == 1)) && "pointer",
                            backgroundColor: isBusy && colorBusyCells,
                            border: "1px solid lightgray",
                          }}
                          data-tip={
                            active_rent?.rent.start_date &&
                            active_rent?.rent.start_date &&
                            `С ${formatDate(
                              active_rent?.rent.start_date,
                              "HH:mm d MMMM yyyy"
                            )} по ${formatDate(
                              active_rent?.rent.end_date,
                              "HH:mm d MMMM yyyy"
                            )}`
                          }
                        >
                          {<strong>{inscriptionInCell}</strong>}
                          {
                            <p
                              style={{
                                fontSize: "9px",
                                textDecoration: "underline",
                              }}
                            >
                              {isBusy && day == 1 && "Продолжение"}
                            </p>
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })
          ) : isLoadingRent ? (
            <tr>
              <td colSpan={daysInSelectedMonth.length}>Загрузка...</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={daysInSelectedMonth.length}>
                В этом месяце нет аренд
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default CalendarTable;
