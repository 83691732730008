import React from "react";
import { useFormik } from "formik";
import { editUserSchema } from "common/validationSchemas";
import { PhoneInput } from "components/Common/PhoneInput";
import { FormGroup, FormText, Label, Input } from "reactstrap";
import { IEditUserInfo, IUserInfo } from "types/UserTypes";

interface EditUserFormProps {
  dataToEdit?: IUserInfo;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  onSubmit: (user: IEditUserInfo) => void;
}
const EditUserForm = ({ dataToEdit, setIsEdit, onSubmit, isLoading }: EditUserFormProps) => {
  const formik = useFormik({
    initialValues: {
      email: dataToEdit?.email || "",
      phone: dataToEdit?.phone.replace(/[-+ ]/g, "") || "",
      address: dataToEdit?.address || "",
      name: dataToEdit?.name || "",
      surname: dataToEdit?.surname || "",
      patronymic: dataToEdit?.patronymic || "",
    },
    validationSchema: editUserSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      setIsEdit(false);
      resetForm();
    },
  });

  const [phone, setPhone] = React.useState("");

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row mb-3">
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="email">E-mail</Label>
            <Input
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              invalid={!!formik.errors.email}
            />
            <FormText color="danger">{formik.errors.email}</FormText>
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="address">Адрес</Label>
            <Input
              id="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              invalid={!!formik.errors.address}
            />
            <FormText color="danger">{formik.errors.address}</FormText>
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="phone">Номер телефона</Label>
            <PhoneInput
              id="phone"
              name="phone"
              value={phone}
              onChange={(value: string) => {
                formik.setFieldValue("phone", value.replace(/[-+ ]/g, ""));
                setPhone(value);
              }}
              invalid={!!formik.errors.phone}
            />
            <FormText color="danger">{formik.errors.phone}</FormText>
          </FormGroup>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="name">Имя</Label>
            <Input
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              invalid={!!formik.errors.name}
            />
            <FormText color="danger">{formik.errors.name}</FormText>
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="surname">Фамилия</Label>
            <Input
              id="surname"
              name="surname"
              value={formik.values.surname}
              onChange={formik.handleChange}
              invalid={!!formik.errors.surname}
            />
            <FormText color="danger">{formik.errors.surname}</FormText>
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup className="mb-3">
            <Label for="patronymic">Отчество</Label>
            <Input
              id="patronymic"
              name="patronymic"
              value={formik.values.patronymic}
              onChange={formik.handleChange}
              invalid={!!formik.errors.patronymic}
            />
            <FormText color="danger">{formik.errors.patronymic}</FormText>
          </FormGroup>
        </div>
      </div>

      <button type="submit" disabled={isLoading} className="btn btn-success">
        Редактировать
      </button>

      <button onClick={() => setIsEdit(false)} type="button" className="btn btn-danger mx-3">
        Отменить редактирование
      </button>
    </form>
  );
};

export default EditUserForm;
