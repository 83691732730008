import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/UserProfile";

import Dashboard from "pages/Dashboard/index";
import Users from "pages/Dashboard/Users/Users";
import User from "pages/Dashboard/Users/User";
import Cars from "pages/Dashboard/Cars/Cars";
import Car from "pages/Dashboard/Cars/Car";
import CarRecommendation from "pages/Dashboard/CarRecommendation/CarRecommendation";
import CareService from "pages/Dashboard/CareService/CareService";
import Request from "pages/Dashboard/CareService/Requests/Request";
import Monitoring from "pages/Dashboard/CareService/Monitoring/Monitoring";
import CreateServiceCar from "pages/Dashboard/CareService/CreateServiceCar/CreateServiceCar";
import Admins from "pages/Dashboard/Settings/Admins/Admins";
import Statuses from "pages/Dashboard/CareService/Statuses/Statuses";
import EmployeeCarService from "pages/Dashboard/CareService/EmployeeCarService/EmployeeCarService";
import VideoHelp from "pages/Dashboard/CareService/VideoHelp/VideoHelp";
import OldChat from "pages/Dashboard/CareService/OldChat/Chat";
import Chat from "pages/Dashboard/CareService/Chat/Chat";
import CreateCar from "pages/Dashboard/Cars/CreateCar";
import EditCar from "pages/Dashboard/Cars/EditCar";
//прокат
import Rents from "pages/Dashboard/Rents/Rents/Rents";
import Rent from "pages/Dashboard/Rents/Rents/Rent";
import RentUsers from "pages/Dashboard/Rents/Users/RentUsers";
import RentCars from "pages/Dashboard/Rents/Cars/RentCars";
import RentUser from "pages/Dashboard/Rents/Users/RentUser";
import CarPage from "pages/Dashboard/Rents/Cars/RentCar";
import RentFines from "pages/Dashboard/Rents/Fines/RentFines";
import RentFine from "pages/Dashboard/Rents/Fines/RentFine";
import RentPayments from "pages/Dashboard/Rents/Payments/RentPayments";
import RentClients from "pages/Dashboard/Rents/Clients/RentClients";
import RentMonitoring from "pages/Dashboard/Rents/Monitoring/RentMonitoring";
import RentTours from "pages/Dashboard/Rents/Tours/RentTours";
import RentTour from "pages/Dashboard/Rents/Tours/RentTour";
import Messages from "pages/Dashboard/Rents/Messages/Messages";
import TOCarRecommendation from "pages/Dashboard/CarRecommendation/TOCarRecommendation";
import CareServiceCreate from "pages/Dashboard/CareService/Create/CareServiceCreate";

export interface RouteType {
  path: string;
  component: React.FC<RouteComponentProps>;
  exact?: boolean;
}

const rentRoutes: RouteType[] = [
  //прокат
  { path: "/alyans-rent/rents", exact: true, component: Rents },
  { path: "/alyans-rent/rent/:id", exact: true, component: Rent },
  { path: "/alyans-rent/users", exact: true, component: RentUsers },
  { path: "/alyans-rent/users/:id", exact: true, component: RentUser },
  { path: "/alyans-rent/cars", exact: true, component: RentCars },
  { path: "/alyans-rent/cars/:id", exact: true, component: CarPage },
  { path: "/alyans-rent/fines", exact: true, component: RentFines },
  { path: "/alyans-rent/fines/:id", exact: true, component: RentFine },
  { path: "/alyans-rent/payments", exact: true, component: RentPayments },
  { path: "/alyans-rent/clients", exact: true, component: RentClients },
  { path: "/alyans-rent/monitoring", exact: true, component: RentMonitoring },
  { path: "/alyans-rent/tours", exact: true, component: RentTours },
  { path: "/alyans-rent/tours/:id", exact: true, component: RentTour },
  { path: "/alyans-rent/messages", exact: true, component: Messages },
  //
];

const supportRoutes: RouteType[] = [
  //техподдержка
  { path: "/chat", exact: true, component: Chat },
  { path: "/oldChat", exact: true, component: OldChat },
  //
];

const careServiceRoutes: RouteType[] = [
  //cлужба заботы
  { path: "/care-service", exact: true, component: CareService },
  { path: "/care-service/create", exact: true, component: CareServiceCreate },
  { path: "/care-service/:id", exact: true, component: Request },
  { path: "/request-statuses", exact: true, component: Statuses },
  { path: "/monitoring", exact: true, component: Monitoring },
  { path: "/create-service-car", exact: true, component: CreateServiceCar },
  { path: "/create-service-car/:id", exact: true, component: CreateServiceCar },
  { path: "/employee-car-service", exact: true, component: EmployeeCarService },
  { path: "/video-help", exact: true, component: VideoHelp },
  //
];

const superAdminRoutes: RouteType[] = [
  //супер-админ
  { path: "/settings/admins", exact: true, component: Admins },
  //
];

const adminRoutes: RouteType[] = [
  //админ
  { path: "/users", exact: true, component: Users },
  { path: "/users/:id", exact: true, component: User },
  //
];

const STORoutes: RouteType[] = [
  //СТО
  { path: "/cars", exact: true, component: Cars },
  { path: "/cars/:id", exact: true, component: Car },
  { path: "/create-car", exact: true, component: CreateCar },
  { path: "/edit-car/:id", exact: true, component: EditCar },
  {
    path: "/create-car-recommendation",
    exact: true,
    component: CarRecommendation,
  },
  {
    path: "/create-to-car-recommendation",
    exact: true,
    component: TOCarRecommendation,
  },
];

const userRoutes: RouteType[] = [
  { path: "/profile", exact: true, component: UserProfile },
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/users", exact: true, component: Users },
  { path: "/users/:id", exact: true, component: User },
  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const authRoutes: RouteType[] = [
  { path: "/login", exact: true, component: Login },
  { path: "/logout", exact: true, component: Logout },
  // { path: "/register", exact: true, component: Register },
  // { path: "/forgot-password", exact: true, component: ForgetPwd },
];

export {
  userRoutes,
  authRoutes,
  rentRoutes,
  supportRoutes,
  careServiceRoutes,
  superAdminRoutes,
  adminRoutes,
  STORoutes,
};
