import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import carmark from "assets/images/cars/carmark.svg";
import { useDispatch, useSelector } from "react-redux";
import { rentCarAPI } from "services";
// import { fetchDevicesAction } from "../../../actions/cars.action";
// import Loader from "../../common/loader";
import SpeedMonitoring from "components/Rents/Monitoring/SpeedMonitoring";
import DatePickerInput from "components/Common/DatePickerInput";

const Monitoring = () => {
  const dispatch = useDispatch();
  //   const { devices, isLoading } = useSelector((state) => state.cars);
  const { data: devices, isLoading } = rentCarAPI.useGetRentCarsQuery({
    pollingInterval: 10000,
  });
  console.log("devices", devices);

  const [currentDevice, setCurrentDevice] = useState(null);

  // const [speed, setSpeed] = useState([
  //   { speed: 152, time: "19:00" },
  //   { speed: 110, time: "19:10" },
  //   { speed: 120, time: "19:20" },
  //   { speed: 120, time: "19:30" },
  //   { speed: 92, time: "19:40" },
  //   { speed: 64, time: "19:50" },
  //   { speed: 60, time: "20:00" },
  //   { speed: 68, time: "20:10" },
  //   { speed: 75, time: "20:20" },
  //   { speed: 80, time: "20:30" },
  //   { speed: 85, time: "20:40" },
  // ]);

  const scrollTo = () => {
    const scrollComponent = document.getElementById("scroll-component");
    if (scrollComponent) {
      scrollComponent.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const mapData = {
    center: [56.137469, 47.238481],
    zoom: 5,
  };
  //   useEffect(() => {
  //     // dispatch(fetchDevicesAction());
  //     const interval = setInterval(() => {
  //       //   dispatch(fetchDevicesAction());
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }, [dispatch]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <Fragment>
      {isLoading ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body" width>
                  {/* <Loader /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <YMaps query={{ lang: "ru_RU", load: "package.full" }}>
            <Map
              defaultState={mapData}
              modules={["control.ZoomControl", "control.FullscreenControl"]}
              width={"100%"}
              height={790}
            >
              <Clusterer
                options={{
                  preset: "islands#invertedVioletClusterIcons",
                  groupByCoordinates: false,
                  balloonPanelMaxMapArea: Infinity,
                }}
              >
                {devices?.data?.map((device) => {
                  return (
                    <Placemark
                      key={device.id}
                      geometry={[device.lat, device.long]}
                      onClick={(event) => {
                        console.log(event);
                        setCurrentDevice(device);

                        scrollTo();
                      }}
                      properties={{
                        item: device.id,
                        balloonContentHeader: `
      
										Название автомобиля: ${
                      device.brand +
                      " " +
                      device.model +
                      " " +
                      device.registration_number
                    }
										`,
                        balloonContentBody: `
									    
									    `,
                      }}
                      //   Телефон: ${device.telephone},
                      //   статус: ${device.position.is_move ? "В движении" : "Стоит"}
                      options={{
                        iconLayout: "default#imageWithContent",
                        // Своё изображение иконки метки.
                        iconImageHref: "/assets/carmark.svg",
                        // Размеры метки.
                        iconImageSize: [50, 30],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-30, 0],
                        balloonPanelMaxMapArea: Infinity,
                      }}
                    />
                  );
                })}
              </Clusterer>
            </Map>
          </YMaps>
          {currentDevice && (
            <div className="container">
              <div className="col-sm-12" style={{ marginTop: "20px" }}>
                <div className="col-sm-12">
                  {/* <div className="card" id="scroll-component">
                    <div className="card-header">
                      <h5>Диаграмма скорости в км/ч</h5>
                    </div>
                    <div className="card-body speed-body">
                      <h6>Выберите промежуток для просмотра</h6>
                      <div className="d-flex mb-5">
                        <div className="mr-3">
                          <strong className="mr-2">Начало:</strong>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            customInput={<DatePickerInput />}
                            dateFormat="dd.MM.yyyy HH:mm"
                            showTimeInput
                          />
                        </div>
                        <div>
                          <strong className="mr-2">Конец:</strong>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            customInput={<DatePickerInput />}
                            dateFormat="dd.MM.yyyy HH:mm"
                            showTimeInput
                          />
                        </div>
                      </div>

                      <SpeedMonitoring speed={speed} />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Данные о автомобиле</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      <strong>Напряжение сети:</strong>{" "}
                      {currentDevice?.device?.battery}{" "}
                      {currentDevice?.device?.battery_type}
                    </p>
                    <p>
                      <strong>Температура двигателя:</strong>{" "}
                      {currentDevice?.device?.etemp}°C
                    </p>
                    <p>
                      <strong>Наружняя температура:</strong>{" "}
                      {currentDevice?.device?.ctemp}°C
                    </p>
                    <p>
                      <strong>Пробег:</strong>{" "}
                      {currentDevice?.device?.mileage
                        ? currentDevice?.device?.mileage + "км"
                        : "Не указано"}
                    </p>
                    <p>
                      <strong>Уровень топлива:</strong>{" "}
                      {currentDevice?.obd?.fuel_percent
                        ? currentDevice?.obd?.fuel_percent + "%"
                        : "Не указано"}
                    </p>
                    {/* <p>
                      <strong>Нажатие педали газа:</strong> 55 %
                    </p>
                    <p>
                      <strong>Стиль вождения:</strong> Умеренный
                    </p>
                    <p style={{ color: "red" }}>
                      <strong>Ошибки OBD2:</strong> P1023
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Monitoring;
