import React, { useEffect, useState } from "react";
import { useFormik, FormikProvider, FieldArray, Field, FieldProps } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";

import { carAPI } from "services/carService";
import { createCarCommentSchema } from "common/validationSchemas";
import CarModificationCharacteristics from "./CarModificationCharacteristics";
import PaginationTable from "components/Common/PaginationTable";
import {
  formatCarCommentDataForBackend,
  formatCarCommentDataForTable,
} from "common/dataFormatters";

const CarRecommendation = () => {
  const userId = JSON.parse(localStorage.getItem("user") || "{}")?.id;

  const { data: marks } = carAPI.useGetMarksQuery();

  const formik = useFormik({
    initialValues: {
      mark: "",
      model: "",
      generation: "",
      serie: "",
      modification: "",
      equipment: "",
      page: 1,
      per_page: 10,
      comments: [{ mileage: "", author: "", comment: "", user_id: userId }],
    },
    validationSchema: createCarCommentSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createCarRecommendation({
        ...formatCarCommentDataForBackend(values),
        comments: values.comments,
      });

      formik.values.comments = [{ mileage: "", author: "", comment: "", user_id: userId }];
    },
  });

  const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();

  const [getGenerations, { data: generations }] = carAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = carAPI.useLazyGetSeriesQuery();
  const [getModifications, { data: modifications }] = carAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] = carAPI.useLazyGetEquipmentsQuery();
  const [amountPage, setAmountPage] = useState(1);

  const { data: carRecommendation, isLoading: carRecommendationLoading } =
    carAPI.useGetCarRecommendationQuery(formatCarCommentDataForBackend(formik.values));

  const [getLazyRecomendations] = carAPI.useLazyGetCarRecommendationQuery();

  const [getСharacteristics, { data: characteristics }] = carAPI.useLazyGetСharacteristicsQuery();

  const [createCarRecommendation] = carAPI.useCreateCarRecommendationMutation();
  const [deleteCarRecommendation, { isLoading: deleteCarRecommendationLoading }] =
    carAPI.useDeleteCarRecommendationMutation();

  useEffect(() => {
    setAmountPage(carRecommendation?.meta?.last_page);
  }, [carRecommendation]);

  useEffect(() => {
    if (!formik.values.mark) return;
    getLazyRecomendations(formatCarCommentDataForBackend(formik.values));
  }, [
    formik.values.mark,
    formik.values.model,
    formik.values.generation,
    formik.values.serie,
    formik.values.modification,
    formik.values.equipment,
  ]);

  useEffect(() => {
    formik.values.modification && getСharacteristics(+formik.values.modification);
  }, [formik.values.modification]);

  const checkFields = () => {
    const { mark, modification, model, serie, generation } = formik.values;

    return !(mark && model && serie && modification && generation);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Создание рекомендации</h2>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="mb-5">
            <div className="card">
              <div className="card-header">Фильтр автомобилей</div>
              <div className="card-body">
                <div className="filter-cars">
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="mark">Марка</Label>
                      <Input
                        id="mark"
                        name="mark"
                        type="select"
                        value={formik.values.mark}
                        onChange={(e) => {
                          const markId = e.target.value;
                          formik.setFieldValue("mark", markId);
                          markId && getModels(+markId);
                        }}
                        invalid={!!formik.errors.mark}>
                        <option value="">Выберите марку</option>
                        {marks?.data.map((mark) => (
                          <option key={mark.id} value={mark.id}>
                            {mark.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.mark}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="model">Модель</Label>
                      <Input
                        id="model"
                        name="model"
                        type="select"
                        value={formik.values.model}
                        onChange={(e) => {
                          const modelId = e.target.value;
                          formik.setFieldValue("model", modelId);
                          modelId && getGenerations(+modelId);
                        }}
                        invalid={!!formik.errors.model}>
                        <option value="">Выберите модель</option>
                        {models?.data.map((model) => (
                          <option key={model.id} value={model.id}>
                            {model.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.model}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="generation">Поколение</Label>
                      <Input
                        id="generation"
                        name="generation"
                        type="select"
                        value={formik.values.generation}
                        onChange={(e) => {
                          const generationId = e.target.value;
                          formik.setFieldValue("generation", generationId);
                          generationId && getSeries(+generationId);
                        }}
                        invalid={!!formik.errors.generation}>
                        <option value="">Выберите поколение</option>
                        {generations?.data.map((generation) => (
                          <option key={generation.id} value={generation.id}>
                            {generation.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.generation}</FormText>
                    </FormGroup>
                  </div>
                </div>
                <div className="filter-cars">
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="serie">Серия</Label>
                      <Input
                        id="serie"
                        name="serie"
                        type="select"
                        value={formik.values.serie}
                        onChange={(e) => {
                          const serieId = e.target.value;
                          formik.setFieldValue("serie", serieId);
                          serieId && getModifications(+serieId);
                        }}
                        invalid={!!formik.errors.serie}>
                        <option value="">Выберите серию</option>
                        {series?.data.map((serie) => (
                          <option key={serie.id} value={serie.id}>
                            {serie.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.serie}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="modification">Модификация</Label>
                      <Input
                        id="modification"
                        name="modification"
                        type="select"
                        value={formik.values.modification}
                        onChange={(e) => {
                          const modificationId = e.target.value;
                          formik.setFieldValue("modification", modificationId);
                          modificationId && getEquipments(+modificationId);
                        }}
                        invalid={!!formik.errors.modification}>
                        <option value="">Выберите модификацию</option>
                        {modifications?.data.map((modification) => (
                          <option key={modification.id} value={modification.id}>
                            {modification.name}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.modification}</FormText>
                    </FormGroup>
                  </div>
                  {/* {equipments?.data && equipments.data.length > 0 && (
                    <div className="custom-select">
                      <FormGroup className="mb-3">
                        <Label for="equipment">Комплектация</Label>
                        <Input
                          id="equipment"
                          name="equipment"
                          type="select"
                          value={formik.values.equipment}
                          onChange={(e) => {
                            const equipmentId = e.target.value;
                            formik.setFieldValue("equipment", equipmentId);
                          }}>
                          <option value="">Выберите комплектацию</option>
                          {equipments?.data.map((equipment) => (
                            <option key={equipment.id} value={equipment.id}>
                              {equipment.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">Создание рекомендации</div>
              <div className="card-body">
                <FieldArray
                  name="comments"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.comments.map((_, index) => (
                        <div className="filter-cars" key={index}>
                          <Field name={`comments.${index}.mileage`}>
                            {({ field, meta }: FieldProps) => (
                              <div className="custom-select">
                                <FormGroup>
                                  <Label for={`comments.${index}.mileage`}>Пробег</Label>
                                  <Input
                                    placeholder="Укажите пробег"
                                    id={`comments.${index}.mileage`}
                                    invalid={!!meta.error}
                                    {...field}
                                  />
                                  <FormText color="danger">{meta.error}</FormText>
                                </FormGroup>
                              </div>
                            )}
                          </Field>
                          <Field name={`comments.${index}.comment`}>
                            {({ field, meta }: FieldProps) => (
                              <div className="custom-select">
                                <FormGroup>
                                  <Label for={`comments.${index}.comment`}>Комментарий</Label>
                                  <Input
                                    placeholder="Напишите комментарий"
                                    id={`comments.${index}.comment`}
                                    invalid={!!meta.error}
                                    {...field}
                                  />
                                  <FormText color="danger">{meta.error}</FormText>
                                </FormGroup>
                              </div>
                            )}
                          </Field>
                          <Field name={`comments.${index}.author`}>
                            {({ field, meta }: FieldProps) => (
                              <div className="custom-select">
                                <FormGroup>
                                  <Label for={`comments.${index}.author`}>Автор рекомендации</Label>
                                  <Input
                                    id={`comments.${index}.author`}
                                    invalid={!!meta.error}
                                    {...field}
                                    type="select">
                                    <option selected value={undefined}>
                                      Выберите автора
                                    </option>
                                    <option value="1">Производитель рекомендует</option>
                                    <option value="2">Мы рекомендуем</option>
                                    <option value="3">Другие рекомендуют</option>
                                  </Input>
                                  <FormText color="danger">{meta.error}</FormText>
                                </FormGroup>
                              </div>
                            )}
                          </Field>
                        </div>
                      ))}

                      <div className="box-btn-create-cars-page">
                        <div>
                          <button
                            onClick={() =>
                              arrayHelpers.push({
                                mileage: "",
                                author: "",
                                comment: "",
                                user_id: userId,
                              })
                            }
                            className="btn btn-primary">
                            Добавить поля для рекомендации
                          </button>
                        </div>
                        <div>
                          <button
                            disabled={checkFields()}
                            type="submit"
                            className="btn btn-success">
                            Сохранить рекомендацию
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </form>
        </FormikProvider>

        <PaginationTable
          title="Список рекомендаций"
          thTitles={[
            "ID",
            "Модель",
            "Поколение",
            "Серия",
            "Модификация",
            "Пробег",
            "Рекомендовано",
            "Комментарий",
          ]}
          tableData={formatCarCommentDataForTable(carRecommendation?.data)}
          canDelete
          onDelete={deleteCarRecommendation}
          deletePending={deleteCarRecommendationLoading}
          loading={carRecommendationLoading}
          perPage={formik.values.per_page}
          setPerPage={(count) => formik.setFieldValue("per_page", count)}
          currentPage={formik.values.page}
          setCurrentPage={(count) => formik.setFieldValue("page", count)}
          amountPage={amountPage}
          setAmountPage={setAmountPage}
          isBackPagination={true}
        />

        {characteristics?.data?.length ? (
          <CarModificationCharacteristics characteristics={characteristics.data} />
        ) : (
          <h4 className="mt-5">Выберите модификацию, чтобы просмотреть ее характеристики</h4>
        )}
      </div>
    </div>
  );
};

export default CarRecommendation;
