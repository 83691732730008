import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Input, Label } from "reactstrap";
import ReactDatePicker from "react-datepicker";

// import downloadphoto from "../../../../assets/images/cars/downloadphoto.png";
import { getArrayFromDigit } from "utils/getArrayFromDigit";
import { useHistory } from "react-router";
import DatePickerInput from "components/Common/DatePickerInput";
import { formatDate } from "../../utils/date";
import Pagination from "../Common/Pagination";
import { validateDate } from "utils/validateDate";

const ListRents = ({
  rents,
  pagesAmount,
  currentPage,
  setCurrentPage,
  setDataStart,
  setDataEnd,
  onlyPaid,
  setOnlyPaid,
}) => {
  const history = useHistory();
  const [startDateFilter, setStartDateFilter] = useState(undefined);
  const [endDateFilter, setEndDateFilter] = useState(undefined);

  useEffect(() => {
    setDataEnd(formatDate(endDateFilter, "yyyy-MM-dd"));
    setDataStart(formatDate(startDateFilter, "yyyy-MM-dd"));
    return () => {};
  }, [startDateFilter, endDateFilter]); //currentPage не добавлять в зависимости

  const listTitleItems = [
    { id: 1, title: "Машина" },
    { id: 2, title: "Номер" },
    { id: 3, title: "ФИО клиента" },
    { id: 4, title: "Начало" },
    { id: 5, title: "Конец" },
    { id: 6, title: "Оплачена" },
    { id: 7, title: "" },
  ];

  const clearDateFilter = () => {
    setStartDateFilter(undefined);
    setEndDateFilter(undefined);
  };

  

  return (
    <div>
      <div
        className="card"
        style={{
          backgroundColor: "white",
          height: rents.length == 0 ? "80vh" : "100%",
        }}
      >
        <div className="container-filter-data">
          <div className="filter-date">
            <span className="label-filter-data">Фильтр по дате аренды с</span>
            <ReactDatePicker
              locale="ru"
              selected={startDateFilter}
              onChange={(value) => {
                setStartDateFilter(validateDate(value));
              }}
              selectsStart
              startDate={startDateFilter}
              customInput={<DatePickerInput className="date-picket-input" />}
              dateFormat="dd.MM.yyyy"
            />
            <span>по</span>
            <ReactDatePicker
              locale="ru"
              selected={endDateFilter}
              onChange={(value) => {
                setEndDateFilter(validateDate(value));
              }}
              selectsEnd
              startDate={startDateFilter}
              endDate={endDateFilter}
              minDate={startDateFilter}
              customInput={<DatePickerInput className="date-picket-input" />}
              dateFormat="dd.MM.yyyy"
              className="mr-2"
            />
            <div className="card-buttons">
              <Button
                className="btn-clear-filter"
                color="primary"
                onClick={() => {
                  clearDateFilter();
                }}
              >
                Убрать фильтр
              </Button>
            </div>
          </div>
          <div className="box-checkbox-only-paid">
            <Label check>
              <Input
                type="checkbox"
                checked={onlyPaid}
                onChange={() => setOnlyPaid(!onlyPaid)}
                style={{ marginRight: 8 }}
              />
              Только оплаченные
            </Label>
          </div>
        </div>
        <div
          id="table"
          className="card-header list-title"
          style={{ backgroundColor: "white" }}
        >
          <table className="table" style={{ backgroundColor: "white" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                {listTitleItems.map((i) => (
                  <th scope="col" key={i.id}>
                    {i.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rents.length === 0 && (
                <p style={{ textAlign: "center" }}>Записей нет</p>
              )}
              {rents?.map((rent) => {
                return (
                  <tr
                    onClick={() =>
                      history.push(`/alyans-rent/rent/${rent.rent.id}`)
                    }
                    key={rent.rent.id}
                  >
                    <th>{rent.rent.id}</th>
                    <th>
                      {rent.car.brand} {rent.car.model}
                    </th>
                    <th>{rent.car.registration_number}</th>
                    <th>
                      {rent?.user.name && rent?.user.surname
                        ? rent.user.surname + " " + rent.user.name
                        : rent.user?.FIO != null &&
                          rent.user?.FIO.trim().length > 0
                        ? rent.user.FIO
                        : rent.user.id != null
                        ? "id: " + rent?.user.id
                        : "Информации нет"}
                    </th>
                    <th>
                      {moment(rent.rent.start_date).format("DD.MM.YYYY HH:mm")}
                    </th>
                    <th>
                      {moment(rent.rent.end_date).format("DD.MM.YYYY HH:mm")}
                    </th>
                    <th>{rent.rent.is_paid ? "+" : "-"}</th>
                    <th>
                      {rent.contract && (
                        <a
                          className="text-white"
                          href={rent.contract}
                          target="_blank"
                        >
                          <img alt="Скачать логовор аренды" width={20} />
                        </a>
                      )}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center">
          {rents?.length > 0 && (
            <Pagination
              pagesAmount={pagesAmount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListRents;
