import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import Bookmark from "./Bookmark";

const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/monitoring">
                      <Home />
                    </Link>
                  </li>
                  {breadcrumbs?.map((breadcrumb, i) => (
                    <li className="breadcrumb-item active" key={i}>
                      {breadcrumb.link ? (
                        <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
                      ) : (
                        breadcrumb.title
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
