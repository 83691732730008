import React, { useMemo } from "react";
import { useFormik } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import Modal from "components/Common/Modal/Modal";
import { updateAdminSchema } from "common/validationSchemas";
import { adminAPI } from "services";
import Select from "react-select";
import { dataFormattingForUpdateAdmin } from "../../../../utils/formatData";

interface CreateAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  adminId: number;
}
const UpdateAdminModal = ({
  isOpen,
  onClose,
  adminId,
}: CreateAdminModalProps) => {
  const [updateAdmin, { isLoading: updateAdminLoading }] =
    adminAPI.useEditAdminMutation();
  const { data: rolesWithId, isLoading: userTypesLoading } =
    adminAPI.useGetUsersTypesQuery();

  const initialValues = {
    roles: null,
    password: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: updateAdminSchema,
    // validateOnChange: false,
    // validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      console.log(
        "dataFormattingForUpdateAdmin(values)",
        dataFormattingForUpdateAdmin({ ...values, id: adminId })
      );
      await updateAdmin(
        dataFormattingForUpdateAdmin({ ...values, id: adminId })
      );
      resetForm(initialValues);
      onClose();
    },
  });

  const selectOptionsRoles = useMemo(() => {
    return rolesWithId?.data
      .map((role) => {
        return {
          value: role.id,
          label: role.name,
        };
      })
      .filter((item) => item.label.length != 0);
  }, [rolesWithId]);
  console.log("adminId", adminId);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Создать администратора">
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
        <FormGroup className="mb-3">
          <Label for="role">Роли</Label>
          <Select
            defaultValue={formik.values.roles}
            isMulti
            value={formik.values.roles}
            name="colors"
            options={selectOptionsRoles}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              formik.setFieldValue("roles", e);
            }}
            placeholder="Выберите роли"
            isClearable
            isSearchable
          />

          <FormText color="danger">{formik.errors.roles}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="password">Пароль</Label>
          <Input
            id="password"
            name="password"
            value={formik.values.password}
            onChange={(e) => formik.setFieldValue("password", e.target.value)}
          />
        </FormGroup>

        <button
          type="submit"
          className="btn btn-success"
          disabled={updateAdminLoading}
        >
          Изменить
        </button>
      </form>
    </Modal>
  );
};

export default UpdateAdminModal;
