import styled, { css } from "styled-components";

const Block = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px #00000020;
`;

export const Container = styled.div`
  display: flex;
  // min-height: 500px;
  max-height: 700px;
  gap: 10px;
  padding: 20px 0;
`;

export const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  object-fit: cover;
`;

export const HeaderContainer = styled.div(
  (props) => css`
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 15px;
    gap: 10px;
    padding: 0 20px;
    margin-bottom: 15px;

    p {
      color: #2b2d42;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
    }

    ${
      //@ts-ignore
      props.isMobile &&
      css`
        height: 80px;
      `
    }
  `
);

export const LoaderContainer = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PreviewChatsContainer = styled.div`
  flex: 2;
  height: 100%;
`;

export const PreviewChatsListContainer = styled(Block)`
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  height: 70vh;
  overflow: hidden;
  padding-top: 10px;

  .chats {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
  }
`;

export const ChatViewContainer = styled.div`
  flex: 5;
  height: 100%;
`;

export const MessagesContainer = styled(Block)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  padding-bottom: 0;
  max-height: 600px;
  height: 70vh;
  gap: 10px;

  .messages {
    overflow: scroll;
  }

  form {
    background: #fff;
    padding: 5px 0 15px 0;
  }
`;

export const PreviewChatItem = styled.li<{ bg: string }>`
  position: relative;
  height: 70px;
  display: flex;
  align-items: "center";
  cursor: pointer;
  transition: background 0.3s;
  border-radius: 10px;
  padding: 10px 12px;
  background: ${({ bg }) => bg};
  gap: 10px;

  &:hover {
    background: #f8f7f9;
  }

  p {
    margin: 0;
    &.name {
      font-weight: 500;
      color: #000;
    }
  }
`;
