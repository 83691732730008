import React, { useState } from "react";
import { formatAdminDataForTable } from "common/dataFormatters";
import PaginationTable from "components/Common/PaginationTable";
import { adminAPI } from "services";
import CreateAdminModal from "./CreateAdminModal";
import ChangePasswordModal from "./ChangePasswordModal";
import UpdateAdminModal from "./UpdateAdminModal";

const Admins = () => {
  const [clickedAdminId, setClickedAdminId] = useState<number>();
  const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isUpdateAdminModalOpen, setIsUpdateAdminModalOpen] = useState(false);

  const { data: admins, isLoading: getAdminsLoading } =
    adminAPI.useGetAdminsQuery();
  const [deleteAdmin, { isLoading: deleteAdminLoading }] =
    adminAPI.useDeleteAdminMutation();

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Администраторы</h2>
        </div>

        <button
          onClick={() => setIsCreateAdminModalOpen(true)}
          className="btn btn-success mb-4"
        >
          Создать администратора
        </button>

        <PaginationTable
          title="Список администраторов"
          thTitles={["ID", "Фамилия", "Имя", "Отчество", "Почта", "Роль"]}
          tableData={formatAdminDataForTable(admins?.data)}
          loading={getAdminsLoading}
          canDelete
          canUpdate
          onDelete={deleteAdmin}
          setIsUpdateAdminModalOpen={setIsUpdateAdminModalOpen}
          deletePending={deleteAdminLoading}
          onRowClick={(id) => {
            // setIsChangePasswordModalOpen(true);
            setClickedAdminId(id);
          }}
          isShowLabel={false}
        />

        <CreateAdminModal
          isOpen={isCreateAdminModalOpen}
          onClose={() => setIsCreateAdminModalOpen(false)}
        />
        <ChangePasswordModal
          adminId={clickedAdminId!}
          isOpen={isChangePasswordModalOpen}
          onClose={() => setIsChangePasswordModalOpen(false)}
        />
        <UpdateAdminModal
          adminId={clickedAdminId!}
          isOpen={isUpdateAdminModalOpen}
          onClose={() => setIsUpdateAdminModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default Admins;
