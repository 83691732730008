import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useFormik } from 'formik';
import { createStatusSchema } from 'common/validationSchemas';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import { careAPI } from 'services';

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const StatusModal = ({ isOpen, onClose }: StatusModalProps) => {
  const [createStatus, { isLoading: createStatusLoading }] = careAPI.useCreateStatusMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#000000',
    },
    validationSchema: createStatusSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createStatus(values);
      resetForm();
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Создать статус для заявки">
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
        <FormGroup className="mb-3">
          <Label for="name">Название</Label>
          <Input
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            invalid={!!formik.errors.name}
          />
          <FormText color="danger">{formik.errors.name}</FormText>
        </FormGroup>

        <FormGroup className="mb-3 align-self-start">
          <Label for="color">Цвет</Label>
          <Input
            bsSize="sm"
            id="color"
            name="color"
            type="color"
            value={formik.values.color}
            onChange={formik.handleChange}
            invalid={!!formik.errors.color}
            style={{ width: '50px' }}
          />
          <FormText color="danger">{formik.errors.color}</FormText>
        </FormGroup>

        <button type="submit" disabled={createStatusLoading} className="btn btn-success">
          Создать
        </button>
      </form>
    </Modal>
  );
};

export default StatusModal;
