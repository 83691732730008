import * as C from "./styles";
import { formatComfortablePhone, formatPhone } from "utils/formatPhone";
import { useWindowSize } from "hooks/useWindowSize";

interface HeaderUserChatProps {
  setIsShowListChats?: (value: boolean) => void;
  setIsShowCarInfo?: (value: boolean) => void;
  isShowCarInfo?: boolean;
  isShowListChats?: boolean;
  activeChat: any;
  userInfo: any;
  chatsFetching: boolean;
}
const HeaderUserChat = ({
  setIsShowListChats,
  setIsShowCarInfo,
  isShowCarInfo,
  isShowListChats,
  activeChat,
  userInfo,
  chatsFetching,
}: HeaderUserChatProps) => {
  const dim = useWindowSize();
  return (
    //@ts-ignore
    <C.HeaderContainer isMobile={dim.width < 700}>
      {setIsShowListChats && (
        <p
          style={{ fontSize: 25 }}
          onClick={() => {
            setIsShowListChats(!isShowListChats);
          }}
        >
          {" "}
          ≣
        </p>
      )}

      {!chatsFetching && (
        <>
          <C.Avatar
            src={
              activeChat?.creator.profilePhotoUrl === "null"
                ? "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                : activeChat?.creator.profilePhotoUrl
            }
            alt="userImage"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="m-0 ms-2 fs-5 text-name">
              {userInfo
                ? `${userInfo?.surname} ${userInfo?.name} ${userInfo?.patronymic}`
                : ""}
            </p>
            <p className="m-0 text-name" style={{ fontSize: 12 }}>
              {userInfo?.contragent_code}
            </p>

            <b className="m-0 me-2 text-name ms-2">
              {formatComfortablePhone(userInfo?.phone)}
            </b>
          </div>

          {setIsShowCarInfo && (
            <i
              className={`${
                isShowCarInfo ? "ti-back-left" : "ti-car"
              } car-button`}
              onClick={() => setIsShowCarInfo(!isShowCarInfo)}
            ></i>
          )}
        </>
      )}
    </C.HeaderContainer>
  );
};
export default HeaderUserChat;
