import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { KnowledgeTableFilter } from 'components/KnowledgeTable';
import { method } from 'lodash';
import { baseQuery } from 'services/settings';
import { IRequest, IRequestCreate, IRequestFilters, IStatus, Knowledge, KnowledgeFilters, KnowledgeReason } from 'types/CareServiceTypes';
import { IChats, IOldChats, IVideoCallHistory } from 'types/ChatTypes';

export const careAPI = createApi({
  reducerPath: 'careAPI',
  tagTypes: ['statuses', 'requests', 'request', 'chat', 'knowledge'],
  baseQuery,
  endpoints: (build) => ({
    createStatus: build.mutation<void, Omit<IStatus, 'id'>>({
      query: (status) => ({
        url: `/admins/care-statuses`,
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['statuses'],
    }),
    getStatuses: build.query<{ data: IStatus[] }, void>({
      query: () => ({
        url: `/admins/care-statuses`,
        method: 'GET',
      }),
      providesTags: () => ['statuses'],
    }),
    editStatus: build.mutation<void, Pick<IStatus, 'id' | 'color'>>({
      query: ({ id, color }) => ({
        url: `/admins/care-statuses/${id}`,
        method: 'PUT',
        body: { color },
      }),
      invalidatesTags: ['statuses'],
    }),
    deleteStatus: build.mutation<void, number>({
      query: (statusId) => ({
        url: `/admins/care-statuses/${statusId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['statuses'],
    }),

    // CRUD для заявок
    createRequest: build.mutation<void, Omit<IRequestCreate, 'id'>>({
      query: (request) => ({
        url: `/admins/care-application`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['requests'],
    }),
    getRequests: build.query<{ data: IRequest[]; meta: any }, IRequestFilters | void>({
      query: (params) => ({
        url: `/admins/care-application`,
        method: 'GET',
        params,
      }),
      providesTags: ['requests'],
    }),
    getRequest: build.query<{ data: IRequest }, number>({
      query: (requestId) => ({
        url: `/admins/care-application/${requestId}`,
        method: 'GET',
      }),
      providesTags: ['request'],
    }),
    deleteRequest: build.mutation<void, number>({
      query: (requestId) => ({
        url: `/admins/care-application/${requestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['requests'],
    }),
    editRequest: build.mutation<void, Pick<IRequestCreate, 'id' | 'care_status_id'>>({
      query: ({ id, care_status_id }) => ({
        url: `/admins/care-application/${id}`,
        method: 'PUT',
        body: { care_status_id },
      }),
      invalidatesTags: ['request'],
    }),
    addCommentToRequest: build.mutation<void, { id: number; text: string }>({
      query: ({ id, text }) => ({
        url: `/admins/care-application/${id}/comments`,
        method: 'POST',
        body: { text },
      }),
      invalidatesTags: ['request'],
    }),
    getChats: build.query<{ data: IOldChats[] }, void>({
      query: () => ({
        url: `/admins/chat/`,
        method: 'GET',
      }),
      providesTags: ['chat'],
    }),
    updateChats: build.mutation<{ data: IOldChats[] }, void>({
      query: () => ({
        url: `/admins/chat/`,
        method: 'GET',
      }),
      invalidatesTags: ['chat'],
    }),
    getVideoCallsHistory: build.query<{ data: IVideoCallHistory[] }, void>({
      query: () => ({
        url: `admins/phone-history`,
        method: 'GET',
      }),
    }),
    createKnowledge: build.mutation({
      query: (data) => ({
        url: `admins/knowledge/care-application`,
        method: 'POST',
        body: data,
      }),
    }),
    getKnowledge: build.query<
      {
        filters: KnowledgeFilters;
        data: Knowledge[];
        meta: {
          current_page: number;
          from: number;
          last_page: number;
          per_page: number;
          to: number;
          total: number;
        };
      },
      { car_id: number | ''; modification_id?: number | ''; page: number; per_page: number, filter: KnowledgeTableFilter }
    >({
      query: ({ car_id, modification_id, page, per_page, filter }) => {

        const {transmissionType, reasonCode, engineType, errorCode, model, mark, source, year} = filter

        console.log(filter)

        return {
          url: 'knowledge/getByModification',
          method: 'GET',
          params: {
            car_id,
            id_car_modification: modification_id,
            page,
            per_page,
            'filters[reasonCode]':  reasonCode ?? '',
            'filters[errorCode]': errorCode?.value ?? '',
            'filters[transmissionType]': transmissionType?.value ?? '',
            'filters[engineType]':  engineType?.value ?? '',
            'filters[year]': year?.value ?? '',
            'filters[mark_id]': mark?.value ?? '',
            '//filters[model_id]': model?.value ?? '',
            'filters[source]': source?.value ?? ''
          },
        }
      },
      providesTags: ['knowledge'],
    }),
    getKnowledgeReasons: build.query<{ data: KnowledgeReason[] }, number | undefined>({
      query: (id) => ({
        url: `/knowledge/dictionary/reasons${id !== undefined ? `?parent_id=${id}` : ''}`,
        method: 'GET',
      }),
    }),
    getKnowledgeErrors: build.query<{ data: { code: string; description: string, id: number }[] }, void>({
      query: () => ({
        url: '/knowledge/dictionary/errors',
        method: 'GET',
      }),
    }),
    updateCarInfo: build.query({
      query: ({ carId, id }) => ({
        url: `/admins/care-application/${id}/updateCar`,
        method: 'PUT',
        body: {
          car_id: carId,
        },
      }),
      providesTags: ['request'],
    }),
  }),
});
