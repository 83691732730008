import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";
import { IRentUsers, IDataRentUsers, IUpdateUser } from "types/RentUserTypes";

export const rentUserAPI = createApi({
  reducerPath: "rentUserAPI",
  tagTypes: ["users", "user"],
  baseQuery,
  endpoints: (build) => ({
    getAllUsersWithFilters: build.mutation<
      { data: Array<IDataRentUsers> },
      { phone?: string; name?: string }
    >({
      query: ({ phone, name }) => {
        return {
          url: `/admin/users?filters[phone]=${phone || ""}&filters[fio]=${name || ""}`,
          method: "GET",
        };
      },
    }),
    getUsers: build.query<{ data: Array<IDataRentUsers> }, void | IRentUsers>({
      query: (props) => {
        let url = `/admin/users`;
        if (props) {
          const { page, verify, request } = props;
          if (page) {
            url = `/admin/users?page=${page}&is_paginated=1&only_verify=${verify ? 1 : 0}&request=${
              request ? 1 : 0
            }`;
          }
        }
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: () => ["users"],
    }),
    getUserInfo: build.query<{ data: IDataRentUsers }, String>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: "GET",
      }),
      providesTags: () => ["user"],
    }),
    updateUser: build.mutation<{ data: IDataRentUsers }, IUpdateUser>({
      query: (props) => {
        const { id, formedUser } = props;
        console.log("formedUser", formedUser);
        return {
          url: `/admin/users/${id}`,
          method: "POST",
          body: formedUser,
        };
      },
      invalidatesTags: ["users"],
    }),
    createUser: build.mutation<{ data: IDataRentUsers }, FormData>({
      query: (formedUser) => {
        return {
          url: `/admin/users`,
          method: "POST",
          body: formedUser,
        };
      },
      invalidatesTags: ["users"],
    }),
    deleteUser: build.mutation<{ data: IDataRentUsers }, String>({
      query: (id) => {
        return {
          url: `/admin/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["users"],
    }),
    verifyUser: build.mutation<{ data: IDataRentUsers }, String>({
      query: (id) => {
        return {
          url: `/admin/users/verify?user_id=${id}&verify=1`,
          method: "POST",
        };
      },
      invalidatesTags: ["users", "user"],
    }),
    refuseVerification: build.mutation({
      query: ({ desc, userId }: { desc: string; userId: number }) => ({
        url: `admin/users/verify-rejection`,
        method: "POST",
        body: {
          desc,
          user_id: userId,
        },
      }),
      invalidatesTags: ["users", "user"],
    }),
    cancellationVerification: build.mutation({
      query: ({ desc, userId }: { desc: string; userId: number }) => ({
        url: `admin/users/verify-cancel`,
        method: "POST",
        body: {
          desc,
          user_id: userId,
        },
      }),
      invalidatesTags: ["users", "user"],
    }),
  }),
});
