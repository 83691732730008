import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import ReactSelect, { OptionProps } from 'react-select';
import { Col, Input, Label } from 'reactstrap';
import { carAPI } from 'services';
import { ICarCharacteristic, ICarInfo } from 'types/CarTypes';

interface DetailsCarInfoProps {
  car: ICarInfo;
  editable?: boolean;
  setCar: React.Dispatch<SetStateAction<ICarInfo>>;
  onChangeMark?: (value: number) => void;
  onChangeModel?: (value: number) => void;
  onChangeGeneration?: (value: number) => void;
  onChangeSerice?: (value: number) => void;
  onChangeModification?: (value: number) => void;
  onChangeEquipment?: (value: number) => void;
}

interface Selector {
  value: number;
  label: string;
}

interface Selectors {
  mark_id: undefined | Selector;
  model_id: undefined | Selector;
  generation_id: undefined | Selector;
  serie_id: undefined | Selector;
  modification_id: undefined | Selector;
  equipment_id: undefined | Selector;
}

const DetailsCarInfo = ({
  car,
  setCar,
  onChangeMark,
  onChangeModel,
  onChangeEquipment,
  onChangeGeneration,
  onChangeModification,
  onChangeSerice,
  editable = true,
}: DetailsCarInfoProps) => {
  const { data: marks } = carAPI.useGetMarksQuery();
  const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();
  const [getGenerations, { data: generations }] = carAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = carAPI.useLazyGetSeriesQuery();
  const [getModifications, { data: modifications }] = carAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] = carAPI.useLazyGetEquipmentsQuery();

  const [selectors, setSelectors] = useState<Selectors>({
    mark_id: undefined,
    model_id: undefined,
    generation_id: undefined,
    serie_id: undefined,
    modification_id: undefined,
    equipment_id: undefined,
  });

  const formatDataArray = useCallback(
    (array: ICarCharacteristic[]) => array.map(({ id, name }) => ({ label: name, value: id })),
    []
  );

  useEffect(() => {
    if (car.mark_id && !selectors.mark_id && marks) {
      getModels(+car.mark_id);

      const currentItem = marks?.data.find((item) => item.id === +car.mark_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, mark_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }

    if (car.model_id && !selectors.model_id && models) {
      getGenerations(+car.model_id);

      const currentItem = models?.data.find((item) => item.id === +car.model_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, model_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }

    if (car.generation_id && !selectors.generation_id && generations) {
      getSeries(+car.generation_id);

      const currentItem = generations?.data.find((item) => item.id === +car.generation_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, generation_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }

    if (car.serie_id && !selectors.serie_id && series) {
      getModifications(+car.serie_id);

      const currentItem = series?.data.find((item) => item.id === +car.serie_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, serie_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }

    if (car.modification_id && !selectors.modification_id && modifications) {
      getEquipments(+car.modification_id);

      const currentItem = modifications?.data.find((item) => item.id === +car.modification_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, modification_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }

    if (car.equipment_id && !selectors.equipment_id && equipments) {
      const currentItem = equipments?.data.find((item) => item.id === +car.equipment_id);
      if (currentItem) {
        setSelectors((prev) => ({ ...prev, equipment_id: { value: currentItem!.id, label: currentItem!.name } }));
      }
    }
  }, [car, marks, models, equipments, modifications, series, generations]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 15 }}>
      <Col md={2}>
        <Label for='mark'>Марка</Label>
        <ReactSelect
          isDisabled={!marks || !editable}
          value={selectors.mark_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, mark_id: String(selector?.value) }));
              setSelectors((prev) => ({ ...prev, mark_id: selector }));
              getModels(selector.value);

              onChangeMark?.(selector.value);
            }
          }}
          placeholder='Выберите марку'
          isSearchable={false}
          isClearable
          options={formatDataArray(marks?.data ?? [])}
        />
      </Col>
      <Col md={2}>
        <Label for='model'>Модель</Label>
        <ReactSelect
          isDisabled={!models || !editable}
          value={selectors.model_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, model_id: String(selector.value) }));
              setSelectors((prev) => ({ ...prev, model_id: selector }));
              getGenerations(selector.value);

              onChangeModel?.(selector.value);
            }
          }}
          placeholder='Выберите модель'
          isSearchable={false}
          isClearable
          options={formatDataArray(models?.data ?? [])}
        />
      </Col>
      <Col md={2.5}>
        <Label for='generation'>Поколение</Label>
        <ReactSelect
          isDisabled={!generations || !editable}
          value={selectors.generation_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, generation_id: String(selector.value) }));
              setSelectors((prev) => ({ ...prev, generation_id: selector }));
              getSeries(selector.value);

              onChangeGeneration?.(selector.value);
            }
          }}
          placeholder='Выберите поколение'
          isSearchable={false}
          isClearable
          options={formatDataArray(generations?.data ?? [])}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>Серия</Label>
        <ReactSelect
          isDisabled={!series || !editable}
          value={selectors.serie_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, serie_id: String(selector.value) }));
              setSelectors((prev) => ({ ...prev, serie_id: selector }));
              getModifications(selector.value);

              onChangeSerice?.(selector.value);
            }
          }}
          placeholder='Выберите серию'
          isSearchable={false}
          isClearable
          options={formatDataArray(series?.data ?? [])}
        />
      </Col>
      <Col md={2.5}>
        <Label for='serie'>Модификация</Label>
        <ReactSelect
          isDisabled={!series || !editable}
          value={selectors.modification_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, modification_id: String(selector.value) }));
              setSelectors((prev) => ({ ...prev, modification_id: selector }));
              getEquipments(selector.value);

              onChangeModification?.(selector.value);
            }
          }}
          placeholder='Выберите модификацию'
          isSearchable={false}
          isClearable
          options={formatDataArray(modifications?.data ?? [])}
        />
      </Col>
      <Col md={2.5}>
        <Label for='serie'>Комплектация</Label>
        <ReactSelect
          isDisabled={!series || !editable}
          value={selectors.equipment_id}
          onChange={(selector) => {
            if (selector) {
              setCar((state) => ({ ...state, equipment_id: String(selector.value) }));
              setSelectors((prev) => ({ ...prev, equipment_id: selector }));

              onChangeEquipment?.(selector.value);
            }
          }}
          placeholder='Выберите комплектацию'
          isSearchable={false}
          isClearable
          options={formatDataArray(equipments?.data ?? [])}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>VIN</Label>
        <Input
          disabled={!editable}
          style={{ marginLeft: 0, backgroundColor: !editable ? '#F2F2F2' : 'transparent', color: !editable ? '#00000050' : '#000000' }}
          type='text'
          placeholder='Введите VIN'
          value={car.vin}
          onChange={(e) => {
            const { value } = e.target;
            setCar((prev) => ({ ...prev, vin: value }));
          }}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>СТС</Label>
        <Input
          disabled={!editable}
          style={{ marginLeft: 0, backgroundColor: !editable ? '#F2F2F2' : 'transparent', color: !editable ? '#00000050' : '#000000' }}
          type='text'
          placeholder='Введите номер стс'
          value={car.sts}
          onChange={(e) => {
            const { value } = e.target;
            setCar((prev) => ({ ...prev, sts: value }));
          }}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>Регистрационный номер</Label>
        <Input
          disabled={!editable}
          style={{ marginLeft: 0, backgroundColor: !editable ? '#F2F2F2' : 'transparent', color: !editable ? '#00000050' : '#000000' }}
          type='text'
          placeholder='Введите регистрационный номер'
          value={car.registration_number}
          onChange={(e) => {
            const { value } = e.target;
            setCar((prev) => ({ ...prev, registration_number: value }));
          }}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>Год</Label>
        <Input
          disabled={!editable}
          style={{ marginLeft: 0, backgroundColor: !editable ? '#F2F2F2' : 'transparent', color: !editable ? '#00000050' : '#000000' }}
          type='text'
          maxLength={4}
          placeholder='Введите год'
          value={car.year}
          onChange={(e) => {
            const { value } = e.target;
            setCar((prev) => ({ ...prev, year: value }));
          }}
        />
      </Col>
      <Col md={2}>
        <Label for='serie'>Пробег</Label>
        <Input
          disabled={!editable}
          style={{ marginLeft: 0, backgroundColor: !editable ? '#F2F2F2' : 'transparent', color: !editable ? '#00000050' : '#000000' }}
          type='text'
          maxLength={7}
          placeholder='Введите пробег'
          value={car.mileage}
          onChange={(e) => {
            const { value } = e.target;
            setCar((prev) => ({ ...prev, mileage: value }));
          }}
        />
      </Col>
    </div>
  );
};

export default DetailsCarInfo;
