import React from 'react';
import { Placemark } from 'react-yandex-maps';
import carIcon from 'assets/images/carIcon.svg';

interface IPlacemarkItem {
  geometry: [number, number] | [],
  carName: string,
  speed: number
}

const PlacemarkItem = ({geometry=[0, 0], carName, speed = 0}: IPlacemarkItem) => {
  return (
    <Placemark
      geometry={geometry}
      properties={
        {
          balloonContentHeader: `Машина: ${carName}`,
          balloonContentBody: `Скорость: ${speed}км/ч`,
      }	}

      options={{
        iconLayout: 'default#imageWithContent',
        // Своё изображение иконки метки.
        iconImageHref: carIcon,
        // Размеры метки.
        iconImageSize: [90, 70],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-45, -35],
        // balloonPanelMaxMapArea: Infinity,
      }}
    />
  );
};

export default PlacemarkItem;
