import React from "react";

import { FormGroup, Input, Label } from "reactstrap";
import { userAPI } from "services";
import { IUserInfo } from "types/UserTypes";
import { UsersList } from "./styles";

interface ISelectUserProps {
  onSelected: (item: IUserInfo) => void;
  defaultUser?: string;
  width?: number | string;
}

const SelectUser: React.FC<ISelectUserProps> = ({
  onSelected,
  defaultUser,
  width,
}) => {
  const { data: users, isLoading: usersLoading } = userAPI.useGetUsersQuery();

  const [usersListIsVisible, setUsersListIsVisible] = React.useState(false);
  const [userStr, setUserStr] = React.useState<string>("");

  const checkUser = (item: IUserInfo) =>
    !!item.name && !!item.surname && !!item.phone;

  React.useEffect(() => {
    if (users && defaultUser) {
      const user = users?.data.find((user) => user.id === +defaultUser);

      setUserStr(`${user?.name} ${user?.surname} ${user?.phone}`);
    }
  }, [users]);

  return (
    <div className="col">
      <FormGroup
        className="custom-select"
        style={{ width: width ? width : "" }}
      >
        <Label for="user">Пользователь</Label>
        <Input
          id="user"
          name="user"
          type="text"
          value={userStr}
          onChange={(e) => setUserStr(e.target.value)}
          onFocus={() => setUsersListIsVisible(true)}
          onBlur={() =>
            setTimeout(() => {
              setUsersListIsVisible(false);
            }, 150)
          }
        />
        {usersListIsVisible && (
          <UsersList>
            {!!users &&
              users.data.map((item, i) => {
                const str: string = `${item.name} ${item.surname} ${item.phone}`;

                return (
                  checkUser(item) &&
                  str.toLowerCase().includes(userStr.toLowerCase()) && (
                    <li
                      onClick={() => {
                        setUserStr(str);
                        onSelected(item);
                      }}
                      key={i}
                    >
                      {str}
                    </li>
                  )
                );
              })}
          </UsersList>
        )}
      </FormGroup>
    </div>
  );
};

export default SelectUser;
