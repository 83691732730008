import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";

export const baseQuery = async (args: any, api: any, extraOptions: any) => {
  let query = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN + "messenger",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  })(args, api, extraOptions);

  const response = query.meta?.response;
  const message =
    (query.data as any)?.message || (query.error?.data as any)?.message;
  const result =
    (query.data as any)?.result || (query.error?.data as any)?.result;

  // console.log(query)

  // if (query.error && query.error.originalStatus === 403) {
  //   toast.error("У вас нет прав")
  // }

  if (response?.status.toString().startsWith("2")) {
    if (message && result === "success") toast.success(message);
    else if (message && result === "error") toast.error(message);
    else toast.success(message);
  }

  if (response?.status.toString().startsWith("4")) {
    if (response.status.toString() === "401") window.location.href = `/login`;
    toast.error(message || response?.statusText || "Произошла ошибка");
  }

  if (response?.status.toString().startsWith("5")) {
    toast.error(
      message || response?.statusText || "На сервере произошла ошибка"
    );
  }

  return query;
};
