import { useEffect, useState } from "react";
import { careAPI } from "services";
import { formatDate } from "common/dataFormatters";

export const useRequestsDateFilters = (
  perPage: number,
  setCurrentPage: (value: number) => void,
  setAmountPage: (value: number) => void,
  page: number
) => {
  const [getRequests, { data: requestsLazy, isLoading: requestsLoadingLazy }] =
    careAPI.useLazyGetRequestsQuery();
  const [start, setStarFilterDate] = useState<Date | null>(null);
  const [end, setEndFilterDate] = useState<Date | null>(null);

  const [documentId, setDocumentId] = useState('');

  const handleFilterDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStarFilterDate(start);
    setEndFilterDate(end);
  };

  const resetDateFilters = () => {
    setStarFilterDate(null);
    setEndFilterDate(null);
    getRequests();
  };

  const getData = (targetPage: number) => {
    getRequests({
      "filters[date_from]": start ? formatDate(start, "yyyy-MM-dd") : '',
      "filters[date_to]": end ? formatDate(end, "yyyy-MM-dd") : '',
      "filters[documentid]": documentId,
      page: targetPage,
      per_page: +perPage,
    });
  }

  useEffect(() => {
    setCurrentPage(1)
    getData(1)
  }, [start, end, documentId]);

  useEffect(() => {
    if (page !== 1) {
      getData(page)
    }
  }, [page])

  useEffect(() => {
    if (requestsLazy) {
      setAmountPage(requestsLazy?.meta.last_page)
    }
  }, [requestsLazy])

  return {
    startFilterDate: start,
    endFilterDate: end,
    handleFilterDateChange,
    resetDateFilters,
    requestsLazy,
    requestsLoadingLazy,
    setDocumentId
  };
};
