import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";

const RentTabs = ({ children, navlinks }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header" style={{ paddingBottom: 0 }}>
              <Nav tabs>
                {navlinks.map((navlink, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        className={`${activeTab === i + 1 && "active"}`}
                        onClick={() => setActiveTab(i + 1)}
                      >
                        {navlink}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </div>
            <div className="card-body">
              <TabContent activeTab={`${activeTab}`}>{children}</TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentTabs;
