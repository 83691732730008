import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { Button, Col, Input, Label } from 'reactstrap';
import { TailSpin } from 'react-loader-spinner';

import Breadcrumb from 'components/Common/Breadcrumb2';
import { carAPI, careAPI, userAPI } from 'services';
import { formatDate, getServiceTypeNameById } from 'common/dataFormatters';
import { CustomCar, KnowledgeReason } from 'types/CareServiceTypes';
import ReactSelect from 'react-select';
import { ICarCharacteristic, ICarInfo } from 'types/CarTypes';
import DetailsCarInfo from 'components/Common/DetailsCarInfo';
import KnowledgeReasonSelector from 'components/Common/KnowledgeReasonSelector';
import CreateKnowledge from 'components/CreateKnowledge';
import PaginationTable from 'components/Common/PaginationTable';
import KnowledgeTable, { ImperativeKnowledgeTableProps } from 'components/KnowledgeTable';

const PLACEHOLDER_CAR_INFO = {
  sts: '',
  year: '',
  vin: '',
  registration_number: '',
  mileage: '',
  mark_id: '',
  model_id: '',
  generation_id: '',
  serie_id: '',
  modification_id: '',
  equipment_id: '',

  id: -1,
  brand: '',
  model: '',
  generation: '',
  modification: '',
  serie: '',
  equipment: '',
  imei: '',
  code: '',
  connectType: '',
  user_id: '',
};

interface Filter {
  reason: null | KnowledgeReason;
}

const Request = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const [comment, setComment] = useState('');
  const [createCar, { isLoading: carIsCreating }] = carAPI.useCreateCarMutation();
  const [getCar] = carAPI.useLazyGetCarQuery();

  const [isUserCar, setIsUserCar] = useState(true);
  const [selectedCar, setSelectedCar] = useState<null | { label: string; value: ICarInfo }>(null);

  const [userCar, setUserCar] = useState<ICarInfo>(PLACEHOLDER_CAR_INFO);

  const [updateCarInfo, { isFetching: carIsUpdating }] = careAPI.useLazyUpdateCarInfoQuery();

  const { data: statuses } = careAPI.useGetStatusesQuery();
  const [getRequest, { data: request, isLoading: requestLoading }] = careAPI.useLazyGetRequestQuery();
  const { data: user } = userAPI.useGetUserQuery(request?.data.user.id as number, { skip: !request });
  const [editRequest] = careAPI.useEditRequestMutation();
  const [addCommentToRequest, { isLoading: isAddCommentToRequestLoading }] = careAPI.useAddCommentToRequestMutation();

  const [filter, setFilter] = useState<Filter>({ reason: null });

  const [showDetailsCarInfo, setShowDetailsCarInfo] = useState(false);

  const [knowledgeModificationId, setKnowledgeModificationId] = useState<number | ''>('');

  const knowledgeTable = useRef<ImperativeKnowledgeTableProps>();

  const handleAddComment = async () => {
    if (!comment.trim().length) return;

    await addCommentToRequest({ id: +id, text: comment });
    setComment('');
  };

  const userCars = useMemo(() => {
    if (!user) {
      return [];
    }

    return user.data.cars?.map((car: ICarInfo) => ({ value: car, label: [car.brand, car.model].join(' ') }));
  }, [user]);

  const [customCar, setCustomCar] = useState<ICarInfo>(PLACEHOLDER_CAR_INFO);

  const tableTitles = React.useMemo(() => {
    const titles = ['ID', 'Тип', 'Статус', 'Фамилия', 'Имя', 'Отчество'];

    if (search) {
      titles.push('Откуда');
      titles.push('Куда');
    } else {
      titles.push('Широта');
      titles.push('Долгота');
      titles.push('Адрес');
    }

    titles.push('Телефон');
    titles.push('Дата');

    return titles;
  }, [search]);

  const towTruckInfo = React.useMemo(() => {
    return ['ID документа', 'Стоимость', 'Заблокированные колёса', 'Квитанция', 'Сдвижная платформа'];
  }, []);

  const updateCar = useCallback(async () => {
    let carId = selectedCar?.value.id;

    const {
      mark_id,
      model_id,
      generation_id,
      serie_id,
      modification_id,
      equipment_id,
      year,
      vin,
      registration_number,
      mileage,
      sts,
    } = customCar;

    if (!isUserCar) {
      const { car: createdCar } = await createCar({
        mark_id: mark_id,
        model_id: model_id,
        generation_id: generation_id,
        serie_id: serie_id,
        modification_id: modification_id,
        equipment_id: equipment_id,
        registration_number: registration_number,
        year: +year as any,
        vin: vin as string,
        sts: sts as string,
        mileage: +mileage as any,
        user_id: user?.data.id,
      }).unwrap();

      carId = createdCar.id;
    }

    await updateCarInfo({ carId, id: request?.data.id });
    await getRequest(+id);
  }, [customCar, user, isUserCar, request, selectedCar]);

  const getUserCar = useCallback(async (id: number) => {
    const car = await getCar(id).unwrap();

    setUserCar(car.data);
  }, []);

  useEffect(() => {
    if (request && request.data.car) {
      knowledgeTable.current?.updateKnowledge({ car_id: request.data.car.id, modification_id: '' });

      const { model, brand } = request.data.car;

      setSelectedCar({ label: `${brand} ${model}`, value: request.data.car });
      getUserCar(request.data.car.id);
    }
  }, [request]);

  useEffect(() => {
    getRequest(+id);
  }, []);

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumb
          breadcrumbItems={[{ title: 'Служба заботы', link: '/care-service' }, { title: 'Страница заявки' }]}
        />

        <div className='page-title-box'>
          <h2 className='mb-3'>Страница заявки</h2>
        </div>

        <div className='card'>
          <div className='card-header'>Информация о заявке</div>
          {requestLoading ? (
            <TailSpin color='#02a499' />
          ) : (
            <div className='card-body'>
              <table className='table dataTable no-footer'>
                <thead>
                  <tr>
                    {tableTitles.map((th, i) => (
                      <th key={i}>{th}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{request?.data.id}</td>
                    <td>{getServiceTypeNameById(request?.data.type)}</td>
                    <td style={{ color: request?.data.status?.color }}>{request?.data.status?.name}</td>
                    <td>{request?.data.user.surname}</td>
                    <td>{request?.data.user.name}</td>
                    <td>{request?.data.user.patronymic}</td>
                    {search ? (
                      <>
                        <td>
                          {request?.data.latitude}
                          {'\n'}
                          {request?.data.longitude}
                          {'\n'}
                          {request?.data.address}
                        </td>
                        <td>
                          {request?.data.info.latitude_to}
                          {'\n'}
                          {request?.data.info.longitude_to}
                          {'\n'}
                          {request?.data.info.address}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{request?.data.latitude}</td>
                        <td>{request?.data.longitude}</td>
                      </>
                    )}
                    {!search && <td>{request?.data.address}</td>}
                    <td>{request?.data.user.phone}</td>
                    <td>{formatDate(request?.data.created_at, 'HH:mm dd.MM.yyyy')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        {request && (
          <div className='card'>
            <div className='card-header'>Характеристики автомобиля</div>
            <div className='card-body'>
              <Label style={{ paddingTop: 8 }} check>
                <Input
                  type='checkbox'
                  checked={isUserCar}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (!checked) {
                      if (request.data.car) {
                        const { brand, model } = request.data.car;

                        setSelectedCar({ label: `${brand} ${model}`, value: request.data.car });
                      } else {
                        setSelectedCar(null);
                      }
                    }
                    setIsUserCar(checked);
                  }}
                  style={{ marginRight: 8 }}
                />
                Привязать автомобиль пользователя
              </Label>
              {isUserCar && (
                <Label style={{ paddingTop: 8, width: '100%' }} check>
                  <Input
                    type='checkbox'
                    checked={showDetailsCarInfo}
                    onChange={(e) => {
                      const { checked } = e.target;

                      setShowDetailsCarInfo(checked);
                    }}
                    style={{ marginRight: 8 }}
                  />
                  Показать подробные данные по автомобилю
                </Label>
              )}

              {isUserCar && showDetailsCarInfo && userCar && (
                <div style={{ marginBottom: 20 }}>
                  <DetailsCarInfo editable={false} setCar={setUserCar} car={userCar} />
                </div>
              )}

              {isUserCar ? (
                <Col md={3}>
                  <Label for='mark'>Автомобиль</Label>
                  <ReactSelect
                    isDisabled={!user}
                    value={selectedCar}
                    onChange={(data: any) => {
                      setSelectedCar(data);
                    }}
                    placeholder='Выберите автомобиль'
                    isSearchable={false}
                    isClearable
                    options={userCars}
                  />
                </Col>
              ) : (
                <DetailsCarInfo
                  setCar={setCustomCar}
                  car={customCar}
                  onChangeModification={(value) => {
                    knowledgeTable.current?.updateKnowledge({ car_id: '', modification_id: value });
                    setKnowledgeModificationId(value);
                  }}
                />
              )}
              <Button
                style={{ marginTop: 20 }}
                onClick={updateCar}
                disabled={
                  (!isUserCar &&
                    Object.keys(customCar)
                      .map((key) => (key === 'equipment_id' ? 'skip' : customCar[key as keyof CustomCar]))
                      .some((value) => value === null || value === '')) ||
                  (isUserCar && !selectedCar?.value) ||
                  carIsUpdating ||
                  carIsCreating ||
                  userCar?.id === selectedCar?.value.id
                }
                color='primary'
              >
                Обновить данные автомобиля
              </Button>
            </div>
          </div>
        )}

        <KnowledgeTable
          ref={knowledgeTable}
          car_id={isUserCar ? request?.data?.car?.id || '' : ''}
          modification_id={!isUserCar ? knowledgeModificationId : ''}
        />

        {requestLoading ? (
          <TailSpin color='#02a499' />
        ) : request?.data.info?.documentRealizationId ? (
          <div className='card'>
            <div className='card-header'>Информация вызова эвакутора</div>
            <div className='card-body'>
              <table className='table dataTable no-footer'>
                <thead>
                  <tr>
                    {towTruckInfo.map((th, i) => (
                      <th key={i}>{th}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{request?.data.info.documentRealizationId}</td>
                    <td>{request?.data.info.amount} руб.</td>
                    <td>{request?.data.info.wheel}</td>
                    <td>{request?.data.info.invoice === 'false' ? 'Нет' : 'Да'}</td>
                    <td>{request?.data.info.sliding_platform === 'false' ? 'Нет' : 'Да'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <CreateKnowledge
          title='Создание записи в базу знаний'
          carId={request?.data.car?.id ?? null}
          navigateToBack={false}
          enabledScrollToBottom={false}
        />
        <div className='card'>
          <div className='card-header'>Изменить статус заявки</div>
          <div className='card-body'>
            <Label for='select-car'>Статус</Label>
            <Input
              type='select'
              value={request?.data.status?.id}
              onChange={(e) => editRequest({ id: +id, care_status_id: +e.target.value })}
              className='w-auto'
            >
              {statuses?.data.map(({ id, name, color }) => (
                <option key={id} value={id} style={{ color }}>
                  {name}
                </option>
              ))}
            </Input>
          </div>
        </div>

        <div className='card'>
          <div className='card-header d-flex align-items-center justify-content-between'>
            История изменений и комментариев
            <div className='d-flex'>
              <Input
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className='mx-2'
                placeholder='Комментарий'
              />
              <button
                onClick={handleAddComment}
                className='btn btn-success text-nowrap'
                disabled={isAddCommentToRequestLoading}
              >
                Добавить комментарий
              </button>
            </div>
          </div>
          <div className='card-body'>
            <ul className='overflow-auto' style={{ maxHeight: '200px' }}>
              {request?.data.comments.map((comment, i) => (
                <li className='mb-2' key={i}>
                  {comment.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
