import React, { useMemo } from "react";
import { useFormik } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import Modal from "components/Common/Modal/Modal";
import { createAdminSchema } from "common/validationSchemas";
import { adminAPI } from "services";
import Select from "react-select";
import { dataFormattingForCreateAdmin } from "../../../../utils/formatData";

const roles = [
  { id: 1, name: "admin" },
  { id: 2, name: "super-admin" },
];
interface CreateAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const CreateAdminModal = ({ isOpen, onClose }: CreateAdminModalProps) => {
  const [createAdmin, { isLoading: createAdminLoading }] =
    adminAPI.useCreateAdminMutation();
  const { data: rolesWithId, isLoading: userTypesLoading } =
    adminAPI.useGetUsersTypesQuery();

  const initialValues = {
    surname: "",
    name: "",
    patronymic: "",
    email: "",
    password: "",
    roles: [],
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createAdminSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createAdmin(dataFormattingForCreateAdmin(values));
      resetForm(initialValues);
      onClose();
    },
  });

  const selectOptionsRoles = useMemo(() => {
    return rolesWithId?.data
      .map((role) => {
        return {
          value: role.id,
          label: role.name,
        };
      })
      .filter((item) => item.label.length != 0);
  }, [rolesWithId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Создать администратора">
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
        <FormGroup className="mb-3">
          <Label for="surname">Фамилия</Label>
          <Input
            id="surname"
            name="surname"
            value={formik.values.surname}
            invalid={!!formik.errors.surname}
            onChange={formik.handleChange}
          />
          <FormText color="danger">{formik.errors.surname}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="name">Имя</Label>
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            invalid={!!formik.errors.name}
            onChange={formik.handleChange}
          />
          <FormText color="danger">{formik.errors.name}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="patronymic">Отчество</Label>
          <Input
            id="patronymic"
            name="patronymic"
            value={formik.values.patronymic}
            invalid={!!formik.errors.patronymic}
            onChange={formik.handleChange}
          />
          <FormText color="danger">{formik.errors.patronymic}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="email">Почта</Label>
          <Input
            id="email"
            name="email"
            value={formik.values.email}
            invalid={!!formik.errors.email}
            onChange={formik.handleChange}
          />
          <FormText color="danger">{formik.errors.email}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="password">Пароль</Label>
          <Input
            id="password"
            name="password"
            value={formik.values.password}
            invalid={!!formik.errors.password}
            onChange={formik.handleChange}
          />
          <FormText color="danger">{formik.errors.password}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="role">Роли</Label>
          <Select
            defaultValue={formik.values.roles}
            isMulti
            value={formik.values.roles}
            name="colors"
            options={selectOptionsRoles}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              formik.setFieldValue("roles", e);
            }}
            placeholder="Выберите роли"
            isClearable
            isSearchable
          />

          <FormText color="danger">{formik.errors.roles}</FormText>
        </FormGroup>

        <button
          type="submit"
          className="btn btn-success"
          disabled={createAdminLoading}
        >
          Создать
        </button>
      </form>
    </Modal>
  );
};

export default CreateAdminModal;
