import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { formatDate } from "utils/date";
import { infoUsersShow } from "utils/user";

const ListItem = ({ payment }) => {
  const history = useHistory();

  return (
    <tr>
      <td>{payment.rent_id}</td>
      <td>
        <Button
          disabled={!payment.user.id}
          onClick={() =>
            payment.user?.id &&
            history.push(`/alyans-rent/users/${payment.user.id}`)
          }
          className={`${payment.user.id} && 'text-onclick'`}
        >
          {infoUsersShow(payment)}
        </Button>
      </td>
      <td>
        <Button
          onClick={() =>
            payment.car.id &&
            history.push(`/alyans-rent/cars/${payment.car.id}`)
          }
        >
          {payment.car.brand} {payment.car.model}{" "}
          {payment.car.registration_number}
        </Button>
      </td>
      <td>{formatDate(payment.start_date, "HH:mm dd.MM.yyyy")}</td>
      <td>{formatDate(payment.end_date, "HH:mm dd.MM.yyyy")}</td>
      <td>{formatDate(payment.amount.data, "HH:mm dd.MM.yyyy")}</td>
      <td>{payment.amount.sum}</td>
    </tr>
  );
};

export default ListItem;
