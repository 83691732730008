import React, { useState, useEffect, useRef, useCallback } from "react";
import { chatAPI } from "../../../../services/chatService";
import PaginationTable from "components/Common/PaginationTable";
import { formatCarDataForTable } from "common/dataFormatters";
import { useHistory } from "react-router-dom";
import NewChatItem from "./New/ChatItem";
import useNewChatService from "hooks/useNewChatService";
import ActiveChat from "./New/ActiveChat";
import HeaderUserChat from "./HeaderUserChat";
import * as C from "./styles";
import { IChats } from "types/ChatTypes";
import InputChat from "./IputChat";
import audioMessage from "../../../../assets/sounds/chatMessage.mp3";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";

const WebChat = () => {
  const user = React.useMemo(() => {
    return JSON.parse(localStorage.getItem("user") as string);
  }, []);
  const history = useHistory();
  const [userImage, setUserImage] = useState("");
  const [userName, setUsername] = useState("Admin");
  const activeChatRef = React.useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const sound = new Audio(audioMessage);
  const [sendMessage] = chatAPI.useSendMessageMutation();

  const {
    activeChat,
    userInfo,
    cars,
    selectChat,
    chatsLoading,
    chats,
    chatsFetching,
  } = useNewChatService({
    scrollToBottom,
    user,
    showToast,
  });

  const commonChats = React.useMemo(() => {
    let chatsList: any = [];

    chats?.map((item) => {
      chatsList.unshift({
        ...item,
        isNew: true,
        createdAt: item.updatedAt,
      });
    });

    chatsList.sort((a, b) => {
      return new Date(b.createdAt as string) - new Date(a.createdAt as string);
    });

    console.log(
      "SORTED",
      new Date(chatsList?.[0]?.createdAt),
      new Date(chatsList?.[1]?.createdAt),
      chatsList?.[0]?.createdAt,
      chatsList?.[1]?.createdAt
    );
    return chatsList;
  }, [chats]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400);
  }, [commonChats]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      }
    }
  }, []);

  function showToast() {
    toast.info("Пришло сообщение в чат");
    sound.play();
  }

  function scrollToBottom() {
    if (activeChatRef.current) {
      activeChatRef.current.scrollTop = activeChatRef.current.scrollHeight;
    }
  }

  const handleChange = (target: any) => {
    setValue(target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (files.length > 0 || value !== "") {
      const formData = new FormData();
      formData.append("from", user.id);
      formData.append("to", activeChat?.id as string);
      formData.append("image", files[0]);
      formData.append("message", value);
      sendMessage(formData);
    }
  };

  const handleFileChange = (e: any) => {
    e.target.files.length > 0 && setFiles(e.target.files);
  };

  if (chatsLoading) return <Loader />;
  return (
    <div className="page-content">
      <C.Container>
        <C.PreviewChatsContainer>
          <C.HeaderContainer>
            <C.Avatar
              src={
                userImage
                  ? userImage
                  : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
              }
              alt="userImage"
            />
            <p>{userName}</p>
          </C.HeaderContainer>

          <C.PreviewChatsListContainer>
            <div className="chats">
              {commonChats?.map((item, index) => {
                return item?.messages?.length !== 0 ? (
                  <NewChatItem
                    selectChat={(item) => {
                      selectChat(item);
                    }}
                    item={item}
                    key={index}
                    user={user}
                  />
                ) : (
                  <></>
                );
              })}
            </div>
          </C.PreviewChatsListContainer>
        </C.PreviewChatsContainer>

        {!chatsFetching && (
          <C.ChatViewContainer>
            {activeChat && (
              <>
                <HeaderUserChat
                  activeChat={activeChat}
                  userInfo={userInfo}
                  chatsFetching={chatsLoading}
                />

                <C.MessagesContainer>
                  <div className="messages" ref={activeChatRef}>
                    <ActiveChat
                      activeChat={activeChat as IChats}
                      user={user}
                      scrollToBottom={scrollToBottom}
                    />
                  </div>
                  <InputChat
                    value={value}
                    setValue={setValue}
                    files={files}
                    setFiles={setFiles}
                    handleSubmit={handleSubmit}
                    handleFileChange={handleFileChange}
                    handleChange={handleChange}
                  />
                </C.MessagesContainer>
              </>
            )}
          </C.ChatViewContainer>
        )}
        {chatsFetching && <Loader />}
      </C.Container>

      <div className="container-fluid">
        <div>
          {
            <PaginationTable
              title="Список автомобилей пользователя"
              loading={false}
              thTitles={[
                "ID",
                "Марка",
                "Модель",
                "Модификация",
                "Пробег",
                "Номер",
                "Год",
                "STS",
                "VIN",
              ]}
              tableData={formatCarDataForTable(cars)}
              onRowClick={(carId: number) => history.push(`/cars/${carId}`)}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default WebChat;
