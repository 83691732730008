import styled from "styled-components";

export const UsersList = styled.ul`
  background: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 70;
  list-style: none;
  max-height: 250px;
  overflow: scroll;
  padding: 0;
  box-shadow: 0 0 5px #00000020;
  z-index: 1;
  border-radius: 10px;

  li {
    padding: 10px;
    color: #000;
    cursor: pointer;

    &:hover {
      background: #00000005;
    }
  }
`;

export const UserNotFound = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;

  .custom-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 500px;
  }

  button {
    margin-bottom: 1rem;
    margin-left: 10px;
  }
`;
