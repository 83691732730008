import React from 'react';
import { useFormik } from 'formik';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import Modal from 'components/Common/Modal/Modal';
import { createRequestSchema } from 'common/validationSchemas';
import { IStatus, serviceTypes } from 'types/CareServiceTypes';
import { IUserInfo } from 'types/UserTypes';
import { careAPI } from 'services';
import { formatCreateRequestDataForBackend } from 'common/dataFormatters';

interface RequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  statuses?: IStatus[];
  users?: IUserInfo[];
}
const RequestModal = ({ isOpen, onClose, statuses, users }: RequestModalProps) => {
  const [createRequest, { isLoading: createRequestLoading }] = careAPI.useCreateRequestMutation();

  const formik = useFormik({
    initialValues: {
      typeId: '',
      statusId: '',
      userId: '',
      latitude: '',
      longitude: '',
    },
    validationSchema: createRequestSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createRequest(formatCreateRequestDataForBackend(values));
      resetForm();
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Создать статус для заявки">
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
        <FormGroup className="mb-3">
          <Label for="typeId">Тип заявки</Label>
          <Input
            id="typeId"
            name="typeId"
            type="select"
            value={formik.values.typeId}
            onChange={(e) => formik.setFieldValue('typeId', e.target.value)}
          >
            <option value="">Выберите тип заявки</option>
            {serviceTypes.map((type, i) => (
              <option key={i} value={type.id}>
                {type.name}
              </option>
            ))}
          </Input>
          <FormText color="danger">{formik.errors.typeId}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="statusId">Статус заявки</Label>
          <Input
            id="statusId"
            name="statusId"
            type="select"
            value={formik.values.statusId}
            onChange={(e) => formik.setFieldValue('statusId', e.target.value)}
          >
            <option value="">Выберите статус заявки</option>
            {statuses?.map(({ id, name, color }) => (
              <option key={id} value={id} style={{ color }}>
                {name}
              </option>
            ))}
          </Input>
          <FormText color="danger">{formik.errors.statusId}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="userId">Пользователь</Label>
          <Input
            id="userId"
            name="userId"
            type="select"
            value={formik.values.userId}
            onChange={(e) => formik.setFieldValue('userId', e.target.value)}
          >
            <option value="">Выберите пользователя</option>
            {users?.map(({ id, surname, name, patronymic }) => (
              <option key={id} value={id}>
                {surname} {name} {patronymic}
              </option>
            ))}
          </Input>
          <FormText color="danger">{formik.errors.userId}</FormText>
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="latitude">Широта</Label>
          <Input
            id="latitude"
            name="latitude"
            type="text"
            value={formik.values.latitude}
            onChange={formik.handleChange}
            invalid={!!formik.errors.latitude}
          />
          <FormText color="danger">{formik.errors.latitude}</FormText>
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for="longitude">Долгота</Label>
          <Input
            id="longitude"
            name="longitude"
            type="text"
            value={formik.values.longitude}
            onChange={formik.handleChange}
            invalid={!!formik.errors.longitude}
          />
          <FormText color="danger">{formik.errors.longitude}</FormText>
        </FormGroup>

        <button disabled={createRequestLoading} type="submit" className="btn btn-success">
          Создать
        </button>
      </form>
    </Modal>
  );
};

export default RequestModal;
