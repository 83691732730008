import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);
import 'react-datepicker/dist/react-datepicker.css';

import { careAPI, userAPI } from 'services';
import PaginationTable from 'components/Common/PaginationTable';
import RequestModal from './Requests/RequestModal';
import { formatRequestDataForTable } from 'common/dataFormatters';
import DatePickerInput from 'components/Common/DatePickerInput';
import { useRequestsDateFilters } from './useRequestsDateFilters';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { debounce } from 'lodash';

const CareService = () => {
  const history = useHistory();
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [amountPage, setAmountPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data: users } = userAPI.useGetUsersQuery();
  const { data: statuses } = careAPI.useGetStatusesQuery();
  const { data: requests, isLoading: requestsLoading } = careAPI.useGetRequestsQuery({
    page: +currentPage,
    per_page: +perPage,
  });
  const [deleteRequest, { isLoading: deleteRequestLoading }] = careAPI.useDeleteRequestMutation();

  const {
    requestsLazy,
    requestsLoadingLazy,
    startFilterDate,
    endFilterDate,
    handleFilterDateChange,
    resetDateFilters,
    setDocumentId,
  } = useRequestsDateFilters(perPage, setCurrentPage, setAmountPage, currentPage);

  const updateDocumentId = useCallback(
    debounce((value) => {
      setDocumentId(value);
    }, 300),
    []
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <div className='page-title-box'>
          <h2 className='mb-3'>Служба заботы</h2>
        </div>

        <>
          <Link className='btn btn-success' to={'care-service/create'}>
            Создать заявку
          </Link>

          <Filters>
            <div className='block'>
              <span>Отфильтровать по дате</span>
              <div className='datepicker'>
                <DatePicker
                  selected={startFilterDate}
                  startDate={startFilterDate}
                  onChange={handleFilterDateChange}
                  endDate={endFilterDate}
                  selectsRange
                  customInput={<DatePickerInput className='date-picket-input' style={{ minWidth: 250 }} />}
                  locale='ru'
                  dateFormat='dd.MM.yyyy'
                  placeholderText='Выберите даты от-до'
                />
              </div>
              <div className='cross'>
                {startFilterDate && endFilterDate && <i onClick={resetDateFilters} className='ti-close mx-2 po' />}
              </div>
            </div>
            <div className='block'>
              <span>Фильтр по № документа</span>
              <Input
                onChange={({ target }) => {
                  updateDocumentId(target.value);
                }}
              />
            </div>
          </Filters>
        </>
        <PaginationTable
          title='Список заявок'
          thTitles={['№Документа', 'Дата', 'Тип', 'Статус', 'Марка/Модель', 'Адрес', 'Тип оплаты']}
          tableData={formatRequestDataForTable(requestsLazy?.data || requests?.data)}
          canDelete
          onDelete={deleteRequest}
          deletePending={deleteRequestLoading}
          loading={requestsLoadingLazy || requestsLoading}
          onRowClick={(requestId, param) =>
            history.push(`/care-service/${requestId}${param === 'Вызов эвакуатора' ? '?truck' : ''}`)
          }
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          amountPage={amountPage}
          setAmountPage={setAmountPage}
          perPage={perPage}
          setPerPage={setPerPage}
          isBackPagination={true}
          showId={false}
        />

        <RequestModal
          statuses={statuses?.data}
          users={users?.data}
          isOpen={isRequestModalOpen}
          onClose={() => setIsRequestModalOpen(false)}
        />
      </div>
    </div>
  );
};

const Filters = styled.div`
  display: flex;
  gap: 50px;

  .block {
    display: flex;
    align-items: center;
  }

  .cross {
    margin-left: 20px;
  }

  .datepicker {
    width: 250px;
  }
`;

export default CareService;
