import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutUser } from "../../store/actions";

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/login");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
  }, []);

  return <div></div>;
};

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default Logout;
