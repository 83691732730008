import React, { useEffect } from 'react';
import { YMaps, Map, Clusterer } from 'react-yandex-maps';
import { carAPI } from '../.../../../../../services';
import PlacemarkItem from './Placemark';


const Monitoring = () => {
  const mapData = {
    center: [56.151080, 47.246370],

    zoom: 12,
  };

  const [getServiceDevices, { data: serviceDevices }] = carAPI.useLazyGetServiceDevicesQuery();

  useEffect(() => {
    getServiceDevices(true)
  },[])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Мониторинг</h2>
        </div>
        <YMaps query={{ lang: 'ru_RU', load: 'package.full' }}>
          <Map defaultState={mapData} 
              modules={["geoObject.addon.balloon", "geoObject.addon.hint", 'control.ZoomControl', 'control.FullscreenControl']} width={'100%'} height={790}>
            <Clusterer
              options={{
                preset: 'islands#invertedVioletClusterIcons',
                groupByCoordinates: false,
                balloonPanelMaxMapArea: Infinity,
              }}
            >
              {
                serviceDevices && serviceDevices.data.map((item) => 
                <PlacemarkItem 
                  key={item.device_id}
                  geometry={[item.position.latitude, item.position.longityde]} 
                  carName={item.alias} speed={item?.position?.speed}/>)
              }
            </Clusterer>
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default Monitoring;
