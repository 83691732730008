import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { Button } from "reactstrap";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { formatDate } from "utils/date";
import { rentAPI } from "services";
import UpdateEndDateRentModal from "components/Rents/Modal/UpdateEndDateRentModal";

registerLocale("ru", ru);

const Rent = () => {
  const { id } = useParams();
  const { data: rent, isLoading: getRentLoading } = rentAPI.useGetRentQuery(id);
  const { data: rentPhotosBeforeIn, isLoading: rentPhotosBeforeInLoading } =
    rentAPI.useGetRentPhotosBeforeInQuery(id);
  const { data: rentPhotosBeforeOut, isLoading: rentPhotosBeforeOutLoading } =
    rentAPI.useGetRentPhotosBeforeOutQuery(id);
  const { data: rentPhotosAfterIn, isLoading: rentPhotosAfterInLoading } =
    rentAPI.useGetRentPhotosAfterInQuery(id);
  const { data: rentPhotosAfterOut, isLoading: rentPhotosAfterOutLoading } =
    rentAPI.useGetRentPhotosAfterOutQuery(id);
  // const [completeRent, { data: completedRent, isLoading: completeRentLoading }] =
  //   rentAPI.useCompleteRentMutation();

  const [
    updateEndDate,
    { data: updateEndDateData, isLoading: updateEndDateDataLoading },
  ] = rentAPI.useUpdateEndDateMutation();

  const history = useHistory();
  const [isUpdateRentModalShow, setIsUpdateRentModalShow] = useState(false);
  // const [isExtendRentModalShow, setIsExtendRentModalShow] = useState(false);

  const handleUpdateEndDate = useCallback(
    async (completeDate) => {
      await updateEndDate({
        rentId: id,
        endDate: formatDate(completeDate, "yyyy-MM-dd HH:mm:ss"),
      });
      setIsUpdateRentModalShow(false);
    },
    [updateEndDate, id]
  );
  // const handleExtendRent = useCallback(
  //   async (extendDate) => {
  //     await extendRent({
  //       rentId: id,
  //       extendDate: formatDate(extendDate, "yyyy-MM-dd HH:mm:ss"),
  //     });
  //     setIsExtendRentModalShow(false);
  //   },
  //   [extendRent, id]
  // );

  if (getRentLoading) return <p>Загрузка...</p>;
  return (
    <>
      <div className="card card-rent-page">
        <h2 className="header ">Страница аренды</h2>
        <div className="card-body">
          <div className="mb-4">
            <strong>Стоимость аренды: </strong>
            <span className="ml-3">{rent?.data?.rent.amount} ₽</span>
          </div>

          <div className="mb-4">
            <strong>Дата создания: </strong>
            <span className="ml-3">{rent?.data?.rent.created_at}</span>
          </div>

          <div className="mb-4">
            <strong>Сроки аренды: </strong>
            <span className="ml-3">
              {rent?.data?.rent?.start_date} - {rent?.data?.rent?.end_date}
            </span>
          </div>

          <div className="mb-4">
            <strong>Автомобиль: </strong>
            <Button
              onClick={() => {
                console.log("AUTO");
                history.push(`/alyans-rent/cars/${rent?.data?.rent?.car?.id}`);
              }}
              className="ml-3"
              color="primary"
            >
              {rent?.data?.rent?.car?.brand +
                " " +
                rent?.data?.rent?.car?.model}
            </Button>
          </div>
          <div className="mb-4">
            <strong>Пользователь: </strong>
            <Button
              onClick={() =>
                history.push(`/alyans-rent/users/${rent?.data?.rent?.user?.id}`)
              }
              className="ml-3"
              color="primary"
              disabled={!rent?.data?.rent?.user?.id}
            >
              {rent?.data?.rent?.user?.surname && rent?.data?.rent?.user?.name
                ? rent?.data?.rent?.user?.surname +
                  " " +
                  rent?.data?.rent?.user?.name
                : rent?.data?.rent?.user?.id
                ? "id:" + rent?.data.rent?.user.id
                : "Не указано"}
              {!rent?.data?.rent?.user?.id
                ? " (" + rent?.data?.rent?.user?.phone + ")"
                : ""}
            </Button>
          </div>
          {rent?.data?.rent?.status === "Продолжается" ? (
            <div>
              <Button
                onClick={() => setIsUpdateRentModalShow(true)}
                className="button-rent-page"
                color="primary"
              >
                Изменить даты конца аренды
              </Button>
              {/* <Button
                onClick={() => setIsExtendRentModalShow(true)}
                color="primary"
              >
                Продлить аренду
              </Button> */}
            </div>
          ) : (
            <p style={{ color: "#4466f2", textAlign: "center" }}>
              Аренда завершена
            </p>
          )}

          <p className="mt-4">
            <strong>Фото До:</strong>
          </p>
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div className="photo-box">
              {rentPhotosBeforeIn?.data.photo.length != 0 ? (
                rentPhotosBeforeIn?.data.photo.map((photo, i) => (
                  <img
                    key={i}
                    src={photo.url}
                    alt="rent"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                ))
              ) : (
                <span className="ml-3">Фотографий не существует</span>
              )}
              <p>Фото внутри</p>
            </div>

            <div className="photo-box">
              {rentPhotosBeforeOut?.data.photo.length != 0 ? (
                rentPhotosBeforeOut?.data.photo.map((photo, i) => (
                  <img
                    key={i}
                    src={photo.url}
                    alt="rent"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                ))
              ) : (
                <span className="ml-3">Фотографий не существует</span>
              )}
              <p>Фото снаружи</p>
            </div>
          </div>

          <p className="mt-4">
            <strong>Фото После:</strong>
          </p>
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div className="photo-box">
              {rentPhotosAfterIn?.data.photo.length != 0 ? (
                rentPhotosAfterIn?.data.photo.map((photo, i) => (
                  <img
                    key={photo.url}
                    src={photo.url}
                    alt="rent"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                ))
              ) : (
                <span className="ml-3">Фотографий не существует</span>
              )}
              <p>Фото внутри</p>
            </div>
            <div className="photo-box">
              {rentPhotosAfterOut?.data.photo.length != 0 ? (
                rentPhotosAfterOut?.data.photo.map((photo, i) => (
                  <img
                    key={photo.url}
                    src={photo.url}
                    alt="rent"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                ))
              ) : (
                <span className="ml-3">Фотографий не существует</span>
              )}
              <p>Фото снаружи</p>
            </div>
          </div>
        </div>
      </div>
      {/* <CompleteRentModal
        isShow={isCompleteRentModalShow}
        setIsShow={setIsCompleteRentModalShow}
        endRent={rent?.data.end}
        onSubmit={handleCompleteRent}
        isLoading={completeRentLoading}
      /> */}
      <UpdateEndDateRentModal
        isShow={isUpdateRentModalShow}
        setIsShow={setIsUpdateRentModalShow}
        onSubmit={handleUpdateEndDate}
        isLoading={updateEndDateDataLoading}
        startRent={rent?.data?.rent?.start_date}
      />
    </>
  );
};

export default Rent;
