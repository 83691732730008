import React, { useEffect, useState } from "react";

const ZoomPhotoModal = ({
  photos,
  activePhoto,
  setIsShowZoomPhotoModal,
  setActivePhoto,
}) => {
  function rotateImage() {
    const angle = 90;
    setDegree((prev) => (prev + angle) % 360);
  }

  const [degree, setDegree] = useState(0);

  const infoPhoto = () => {
    return photos?.find((photo) => photo.id === activePhoto);
  };

  const actionBack = () => {
    if (activePhoto === 1) {
      console.log("зашло");
      setActivePhoto(photos.length);
    } else {
      setActivePhoto((prev) => prev - 1);
    }
  };

  const actionForward = () => {
    console.log("photos.len", photos.length);
    console.log("active photo", activePhoto);
    if (activePhoto === photos.length) {
      setActivePhoto(1);
    } else {
      setActivePhoto((prev) => prev + 1);
    }
  };

  return (
    <div className="box-zoom-photo-modal">
      <div className="box-zoom-photo-active">
        {" "}
        <div
          className="button-zoom-modal"
          onClick={() => {
            rotateImage();
          }}
          style={{ marginRight: 10 }}
        >
          ↻
        </div>
        <div
          className="button-zoom-modal"
          onClick={() => {
            setIsShowZoomPhotoModal(false);
          }}
        >
          ☓
        </div>
      </div>
      <div className="center-box-zoom-modal">
        <button
          className="button-arrow"
          style={{ marginRight: 10 }}
          onClick={() => actionBack()}
        >
          ❮
        </button>
        {
          <div className="photo-box-zoom-modal">
            {infoPhoto().url ? (
              <img
                src={infoPhoto()?.url}
                alt=" Ссылка недействительна"
                height={650}
                style={{ rotate: `${degree}deg` }}
              />
            ) : (
              <p className="title-photo">Нет</p>
            )}

            <p className="title-photo">{String(infoPhoto()?.name)}</p>
          </div>
        }

        <button
          className="button-arrow"
          style={{ marginLeft: 10 }}
          onClick={() => actionForward()}
        >
          ❯
        </button>
      </div>
    </div>
  );
};

export default ZoomPhotoModal;
