import React, { useState } from "react";
import { useHistory } from "react-router";
import { Popover, Button, PopoverBody, Input } from "reactstrap";

const Param = ({ id, title }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const defaultUrl = "/alyans-rent/cars";

  const filterByBrand = () => {
    history.push(`${defaultUrl}?brand=${brand}`);
    setIsOpen(false);
  };

  const filterByModel = () => {
    history.push(`${defaultUrl}?model=${model}`);
    setIsOpen(false);
  };

  const filterByRegistrationNumber = () => {
    history.push(`${defaultUrl}?registration_number=${registrationNumber}`);
    setIsOpen(false);
  };

  return (
    <>
      <th scope="col" id={`Popover${id}`} onClick={() => setIsOpen(false)}>
        {title}
      </th>

      {id === 2 && (
        <Popover
          isOpen={isOpen}
          target={"Popover2"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <input
              className="form-control"
              placeholder="Найти марку"
              onChange={(e) => setBrand(e.target.value)}
              value={brand}
            />
            <div className="popover-footer" onClick={filterByBrand}>
              <Button size="100px">Применить</Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 3 && (
        <Popover
          isOpen={isOpen}
          target={"Popover3"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <input
              className="form-control"
              placeholder="Найти модель"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            />
            <div className="popover-footer" onClick={filterByModel}>
              <Button size="100px">Применить</Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 4 && (
        <Popover
          isOpen={isOpen}
          target={"Popover4"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input
              placeholder="Найти по гос.номеру"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
            />
            <div
              className="popover-footer"
              onClick={filterByRegistrationNumber}
            >
              <Button size="100px">Применить</Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 5 && (
        <Popover
          isOpen={isOpen}
          target={"Popover5"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input type="select">
              {["driver", "manager", "operator", "owner"].map((i, key) => {
                return (
                  <option key={key} value={i}>
                    {i}
                  </option>
                );
              })}
            </Input>
            <div className="popover-footer">
              <Button size="100px">Применить</Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default Param;
