import React, { useState } from "react";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./CreateCarModal.scss";

import StepBrands from "./components/StepBrands";
import StepMainInfo from "./components/StepMainInfo";
import { toast } from "react-toastify";
import { rentCarAPI } from "services";
import { accessAdminRoutes, checkRole } from "utils/accessToRoles";

const EditCarModal = ({ isShow, setIsShow, yearsArr, carData }) => {
  const [updatePostCar, { isSuccess, isError }] =
    rentCarAPI.useUpdatePostCarMutation();

  const isAdmin = checkRole(accessAdminRoutes);

  const [step, setStep] = useState(1);
  const initialCar = {
    model_id: carData.id_car_model,
    id_car_generation: carData.id_car_generation,
    id_car_serie: carData.id_car_serie,
    id_car_modification: carData.id_car_modification,
    id_car_equipment: carData.id_car_equipment || 1,
    brand_id: carData.id_car_mark,
    year: carData.year,
    photo: carData.photo,
    photos: carData.photos,
    flex: carData.flex,
    inPool: carData.inPool ? 1 : 0,
    vin: carData.vin,
    sts: carData.sts,
    registration_number: carData.registration_number,
    imei: carData.imei,
    city_id: carData.city.id,
    color: carData.color || "",
    one_three_days_cost: carData.one_three_days_cost
      ? carData.one_three_days_cost
      : 0,
    four_ten_days_cost: carData.four_ten_days_cost
      ? carData.four_ten_days_cost
      : 0,
    eleven_thirty_days_cost: carData.eleven_thirty_days_cost
      ? carData.eleven_thirty_days_cost
      : 0,
  };
  const [car, setCar] = useState(initialCar);
  const [priceList, setPriceList] = useState({
    insurance_first_year: 0,
    insurance_second_year: 0,
    insurance_third_year: 0,
    tire_work: 0,
    technical_inspection_three_years: 0,
    cost: 0,
  });

  React.useEffect(() => {
    setCar(initialCar);
  }, [carData]);
  React.useEffect(() => {
    if (isSuccess || isError) {
      isSuccess && toast.success("Автомобиль успешно изменен");
      setIsShow(false);
    }
  }, [isSuccess, isError]);

  const getCarRent = async () => {
    const formData = new FormData();
    for (var key in car) {
      if (key === "photos") {
        car[key].map((item, index) => {
          formData.append(`photos[${index}]`, item.file ? item.file : item);
        });
      } else formData.append(key, car[key]);
    }

    updatePostCar({ id: carData.id, body: formData }).then(() =>
      setCar(initialCar)
    );
  };

  const renderedComponent = () => {
    switch (step) {
      case 1:
        return <StepBrands isEdit car={car} setCar={setCar} />;

      case 2:
        return (
          <StepMainInfo
            car={car}
            setCar={setCar}
            yearsArr={yearsArr}
            priceList={priceList}
            setPriceList={setPriceList}
            isAdmin={isAdmin}
          />
        );
      default:
        return <StepBrands isEdit car={car} setCar={setCar} />;
    }
  };

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        Измените автомобиль
      </ModalHeader>
      <ModalBody>{renderedComponent()}</ModalBody>
      <ModalFooter>
        {step === 2 && (
          <Button color="danger" onClick={() => setStep(1)}>
            Назад
          </Button>
        )}
        {step === 1 && (
          <Button color="success" onClick={() => setStep(2)}>
            Далее
          </Button>
        )}
        {step === 2 && (
          <Button color="primary" onClick={() => getCarRent()}>
            Изменить
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default EditCarModal;
