import React, { useEffect, useState } from "react";
import { FormGroup, FormText, Input, Label, Button } from "reactstrap";
import downloadphoto from "assets/images/cars/downloadphoto.png";

import { rentAPI, rentCarAPI } from "services";

const StepMainInfo = ({ car, setCar, yearsArr, priceList, setPriceList, isAdmin }) => {
  const [allPhotos, setAllPhotos] = useState(car.photos ? car.photos : []);
  const [mainPhotoSrc, setMainPhotoSrc] = useState(null);
  const [addPhotos, setAddPhotos] = useState([]);

  const { data: cities } = rentCarAPI.useGetCitiesQuery();

  const [deletePhoto] = rentAPI.useRemoveCarPhotoMutation();

  const loadMainImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (ev) => {
        setCar({ ...car, photo: file });
        setMainPhotoSrc(ev.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const loadAdditionalImages = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        setAddPhotos([...addPhotos, { file, source: event.target.result }]);
        setAllPhotos([...allPhotos, { file, source: event.target.result }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteServerPhotos = (id, index) => {
    deletePhoto({ photoId: id }).then((res) => {
      if (res.data.data === "Фото было успешно удалено") {
        setAllPhotos((prev) => prev.filter((_, i) => i !== index));
      }
    });
  };

  const deleteAdditionallyPhotos = (name, index) => {
    setAllPhotos((prev) => prev.filter((_, i) => i !== index));
    setAddPhotos((prev) => prev.filter((photo) => photo.file.name !== name));
  };

  useEffect(() => {
    setCar({ ...car, photos: addPhotos });
  }, [addPhotos]);

  return (
    <div>
      <FormGroup>
        <Label htmlFor="issueYear">Год выпуска *</Label>
        <Input
          id="issueYear"
          name="issueYear"
          type="select"
          value={+car.year}
          onChange={(e) => setCar({ ...car, year: +e.target.value })}
        >
          {yearsArr.map((year) => (
            <option key={year} value={+year}>
              {year}
            </option>
          ))}
        </Input>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="vinNumber">VIN номер *</Label>
        <Input
          id="vinNumber"
          name="vinNumber"
          value={car.vin}
          onChange={(e) => setCar({ ...car, vin: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="stsNumber">Номер СТС *</Label>
        <Input
          id="stsNumber"
          name="stsNumber"
          value={car.sts}
          onChange={(e) => setCar({ ...car, sts: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="gosNumber">Гос номер *</Label>
        <Input
          id="gosNumber"
          name="gosNumber"
          value={car.registration_number}
          onChange={(e) =>
            setCar({ ...car, registration_number: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="lmei">imei устройства *</Label>
        <Input
          id="lmei"
          name="lmei"
          value={car.imei}
          onChange={(e) => setCar({ ...car, imei: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="city_id">Город *</Label>
        <Input
          id="city_id"
          name="city_id"
          type="select"
          value={car.city_id}
          onChange={(e) => {
            setCar({ ...car, city_id: e.target.value });
          }}
        >
          <option>Выберите город</option>
          {cities?.data?.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup className="d-flex align-items-center my-4" style={{ gap: 5 }}>
        <label htmlFor="inPool" className="mb-0">
          В пуле
        </label>
        <input
          id="inPool"
          name="inPool"
          onChange={() => setCar({ ...car, inPool: car.inPool === 1 ? 0 : 1 })}
          checked={!!car.inPool}
          type="checkbox"
          disabled={!isAdmin}
        />
      </FormGroup>
      <FormGroup className="d-flex align-items-center my-4" style={{ gap: 5 }}>
        <label htmlFor="inPool" className="mb-0">
          Flex
        </label>
        <input
          id="inPool"
          name="inPool"
          onChange={() => setCar({ ...car, flex: car.flex === 1 ? 0 : 1 })}
          checked={!!car.flex}
          type="checkbox"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="color">Цвет *</Label>
        <Input
          id="color"
          name="color"
          value={car.color}
          onChange={(e) => setCar({ ...car, color: e.target.value })}
        />
      </FormGroup>
      {/* <FormGroup>
        <Label htmlFor="oneThreeDayCost">Цена аренды 1-3 дня</Label>
        <Input
          id="oneThreeDayCost"
          name="oneThreeDayCost"
          type="number"
          value={car.one_three_days_cost}
          onChange={(e) =>
            setCar({ ...car, one_three_days_cost: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="fourTenDaysCost">Цена аренды 4-10 дня</Label>
        <Input
          id="fourTenDaysCost"
          name="fourTenDaysCost"
          type="number"
          value={car.four_ten_days_cost}
          onChange={(e) =>
            setCar({ ...car, four_ten_days_cost: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="elevenThirtyDaysCost">Цена аренды 11-30 дня</Label>
        <Input
          id="elevenThirtyDaysCost"
          name="elevenThirtyDaysCost"
          type="number"
          value={car.eleven_thirty_days_cost}
          onChange={(e) =>
            setCar({ ...car, eleven_thirty_days_cost: e.target.value })
          }
        /> */}
      {/* </FormGroup> */}
      {/* <FormGroup>
        <Label htmlFor="cost">Стоимость</Label>
        <Input
          id="cost"
          name="cost"
          type="number"
          value={priceList.cost}
          onChange={(e) => setPriceList({ ...priceList, cost: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="insuranceFirstYear">Цена страховки за первый год</Label>
        <Input
          id="insuranceFirstYear"
          name="insuranceFirstYear"
          type="number"
          value={priceList.insurance_first_year}
          onChange={(e) =>
            setPriceList({ ...priceList, insurance_first_year: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="insuranceSecondYear">
          Цена страховки за второй год
        </Label>
        <Input
          id="insuranceSecondYear"
          name="insuranceSecondYear"
          type="number"
          value={priceList.insurance_second_year}
          onChange={(e) =>
            setPriceList({
              ...priceList,
              insurance_second_year: e.target.value,
            })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="insuranceThirdYear">Цена страховки за третий год</Label>
        <Input
          id="insuranceThirdYear"
          name="insuranceThirdYear"
          type="number"
          value={priceList.insurance_third_year}
          onChange={(e) =>
            setPriceList({ ...priceList, insurance_third_year: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="tireWork">Цена шиномонтажа</Label>
        <Input
          id="tireWork"
          name="tireWork"
          type="number"
          value={priceList.tire_work}
          onChange={(e) =>
            setPriceList({ ...priceList, tire_work: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="technicalInspectionThreeYears">
          Цена ТО за три года
        </Label>
        <Input
          id="technicalInspectionThreeYears"
          name="technicalInspectionThreeYears"
          type="number"
          value={priceList.technical_inspection_three_years}
          onChange={(e) =>
            setPriceList({
              ...priceList,
              technical_inspection_three_years: e.target.value,
            })
          }
        />
      </FormGroup> */}
      <FormGroup>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label htmlFor="mainPhoto">Главная фотография</Label>
          <Input
            id="mainPhoto"
            name="file"
            type="file"
            onChange={loadMainImage}
          />
        </div>

        <div className={`mainPhoto-input ${!car.photo && "mainPhoto-border"}`}>
          {mainPhotoSrc || car.photo ? (
            <img src={mainPhotoSrc || car?.photo} width={122} />
          ) : (
            <img src={downloadphoto} width={75} />
          )}
        </div>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="mainPhoto">Дополнительные фотографии</Label>
        {/* {car.photos} */}

        <div className="additionally-photos">
          {allPhotos?.map((photo, index) => {
            return (
              <div className="additionally-photo" key={index}>
                <img src={photo.name || photo.source} width={122} />
                <Button
                  style={{ marginLeft: "10px" }}
                  color="danger"
                  onClick={() =>
                    photo.id
                      ? deleteServerPhotos(photo.id, index)
                      : deleteAdditionallyPhotos(photo.file.name, index)
                  }
                  className="align-self-center ml-2"
                >
                  Удалить
                </Button>
              </div>
            );
          })}
        </div>

        <Input
          id="mainPhoto"
          name="file"
          type="file"
          onChange={loadAdditionalImages}
          multiple
        />

        <FormText>Количество фото: {allPhotos?.length}</FormText>
      </FormGroup>
    </div>
  );
};

export default StepMainInfo;
