import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { carAPI, careAPI, userAPI } from "services";
import { Input, Label } from "reactstrap";

import Breadcrumb from "components/Common/Breadcrumb2";
import PaginationTable from "components/Common/PaginationTable";
import EditUserForm from "./EditUserForm";
import { IUserInfo } from "types/UserTypes";
import { ICarInfo } from "types/CarTypes";
import Tabs from "components/Common/Tabs/Tabs";
import {
  formatUserRequestsDataForTable,
  formatCarDataForTable,
} from "common/dataFormatters";
import RegisterSignaling from "components/Common/RegisterSignaling";

const User = () => {
  const history = useHistory();
  const { id: userId } = useParams<{ id: string }>();
  const { data: user, isLoading: userLoading } = userAPI.useGetUserQuery(
    +userId
  );
  const { data: cars } = carAPI.useGetCarsQuery();

  const [editUser, { isLoading: editUserLoading }] =
    userAPI.useEditUserMutation();
  const [linkCarToUser] = userAPI.useLinkCarToUserMutation();
  const [unlinkCarFromUser, { isLoading: unlinkCarFromUserLoading }] =
    userAPI.useUnlinkCarFromUserMutation();

  const [isUserEditing, setIsUserEditing] = useState(false);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb
          breadcrumbItems={[
            { title: "Пользователи", link: "/users" },
            { title: "Страница пользователя" },
          ]}
        />

        <div className="page-title-box">
          <h2 className="mb-3">Страница пользователя</h2>
        </div>

        <Tabs
          tabs={[
            {
              title: "Информация о пользователе",
              content: (
                <>
                  <div className="card my-5">
                    <div className="card-header">Информация о пользователе</div>
                    <div className="card-body">
                      <table className="table dataTable no-footer">
                        <thead>
                          <tr>
                            {[
                              "E-mail",
                              "Имя",
                              "Фамилия",
                              "Отчество",
                              "Адрес",
                              "Номер телефона",
                            ].map((th, i) => (
                              <th key={i}>{th}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{user?.data.email}</td>
                            <td>{user?.data.name}</td>
                            <td>{user?.data.surname}</td>
                            <td>{user?.data.patronymic}</td>
                            <td>{user?.data.address}</td>
                            <td>{user?.data.phone}</td>
                          </tr>
                        </tbody>
                      </table>

                      <button
                        onClick={() => setIsUserEditing(true)}
                        className="btn btn-primary mt-2"
                      >
                        Редактировать пользователя
                      </button>
                    </div>
                  </div>
                  {isUserEditing && (
                    <div className="card mb-5">
                      <div className="card-header">
                        Редактирование пользователя
                      </div>
                      <div className="card-body">
                        <EditUserForm
                          dataToEdit={user?.data}
                          setIsEdit={setIsUserEditing}
                          onSubmit={async (user) =>
                            await editUser({ ...user, id: +userId })
                          }
                          isLoading={editUserLoading}
                        />
                      </div>
                    </div>
                  )}

                  <PaginationTable
                    title="Заявки пользователя в службу заботы"
                    thTitles={["ID", "Тип", "Статус", "Адрес", "Дата"]}
                    tableData={formatUserRequestsDataForTable(
                      user?.data.care_applications
                    )}
                    loading={userLoading}
                    onRowClick={(requestId: number) =>
                      history.push(`/care-service/${requestId}`)
                    }
                  />
                </>
              ),
            },
            {
              title: "Автомобили пользователя",
              content: (
                <>
                  <div className="card my-5">
                    <div className="card-header">
                      Привязать авто к пользователю
                    </div>
                    <div className="card-body">
                      <Label for="select-car">Авто</Label>
                      <Input
                        type="select"
                        onChange={(e) =>
                          linkCarToUser({ carId: e.target.value, userId })
                        }
                        className="w-auto"
                      >
                        <option value={undefined}>Выберите авто</option>
                        {cars?.data.map((car) => (
                          <option value={car.id} key={car.id}>
                            {car.brand} {car.model} {car.modification}{" "}
                            {car.year}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>

                  <div className="card my-5">
                    <div className="card-header">Подключение сигнализации</div>
                    <div className="card-body">
                      <RegisterSignaling carsUser={user?.data?.cars} />
                    </div>
                  </div>

                  <PaginationTable
                    title="Список привязанных автомобилей"
                    thTitles={[
                      "ID",
                      "Марка",
                      "Модель",
                      "Модификация",
                      // "Цвет",
                      "Пробег",
                      "Номер",
                      "Год",
                      "STS",
                      "VIN",
                    ]}
                    tableData={formatCarDataForTable(
                      (user?.data as IUserInfo & { cars: ICarInfo[] })?.cars
                    )}
                    canDelete
                    onDelete={(carId: number) =>
                      unlinkCarFromUser({ carId, userId })
                    }
                    deletePending={unlinkCarFromUserLoading}
                    loading={userLoading}
                    onRowClick={(carId: number) =>
                      history.push(`/cars/${carId}`)
                    }
                  />
                </>
              ),
            },
            // {
            //   title: 'История'
            // }
          ]}
        />
      </div>
    </div>
  );
};

export default User;
