import PaginationTable from "components/Common/PaginationTable";
import { formatCarDataForTable } from "common/dataFormatters";
import { useHistory } from "react-router-dom";

interface InfoCarsProps {
  activeVersionChat: string;
  cars: any;
}
const InfoCars = ({ activeVersionChat, cars }: InfoCarsProps) => {
  const history = useHistory();
  return (
    <div className="container-fluid">
      <div>
        {
          <PaginationTable
            title="Список автомобилей пользователя"
            loading={false}
            thTitles={[
              "ID",
              "Марка",
              "Модель",
              "Модификация",
              "Пробег",
              "Номер",
              "Год",
              "STS",
              "VIN",
            ]}
            tableData={formatCarDataForTable(cars)}
            isShowLabel={false}
            onRowClick={(carId: number) => history.push(`/cars/${carId}`)}
          />
        }
      </div>
    </div>
  );
};
export default InfoCars;
