import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";
import { IRentFine } from "types/RentCarTypes";
import { IRentFinesFilters } from "types/RentPaymentTypes";

export const rentFineAPI = createApi({
  reducerPath: "rentFineAPI",
  tagTypes: [],
  baseQuery,
  endpoints: (build) => ({
    getFines: build.query<{ data: IRentFine[] }, IRentFinesFilters>({
      query: (params) => ({
        url: `/admin/fines`,
        method: "GET",
        params: { ...params },
      }),
    }),
    getFine: build.query<{ data: IRentFine }, number>({
      query: (id) => ({
        url: `/admin/fines/${id}`,
        method: "GET",
      }),
    }),
  }),
});
