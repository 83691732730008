import React, { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Select from "react-select";
import { carAPI, rentAPI, rentCarAPI } from "services";
import { select } from "redux-saga/effects";

const SIGNALING_DATA: { name: string; key: "key" | "name" | "tel" | "car" }[] =
  [
    {
      name: "Пинкод",
      key: "key",
    },
    {
      name: "Название устройства",
      key: "name",
    },
    {
      name: "Номер телефона",
      key: "tel",
    },
    {
      name: "Авто",
      key: "car",
    },
  ];

interface RegisterSignalingProps {
  isRent: boolean;
  carsUser: any[] | undefined;
}

const RegisterSignaling = ({
  isRent = false,
  carsUser,
}: RegisterSignalingProps) => {
  const [signaling, setSignaling] = useState<{
    key: string;
    name: string;
    type: "gps-alarm";
    tel: string;
    car_id: number | null;
    service?: null | number;
  }>({
    key: "",
    name: "",
    type: "gps-alarm",
    tel: "",
    service: 0,
    car_id: null,
  });

  const changeSignaling = (
    field: "key" | "name" | "tel" | "service" | "car_id",
    value: string | number
  ) => {
    setSignaling((prev) => ({ ...prev, [field]: value }));
  };
  const [selectCar, setSelectCar] = useState("");

  const [registerSignaling] = carAPI.useRegisterSignalingMutation();
  const [registerRentSignaling] = rentCarAPI.useRegisterSignalingMutation();

  const checkFields = () => {
    const { key, name, tel, service, car_id } = signaling;
    return (
      key === "" ||
      name === "" ||
      tel === "" ||
      (!isRent && service === null) ||
      (!isRent && car_id === null)
    );
  };

  const infoForSelect = () => {
    return carsUser
      ? carsUser.map((item) => {
          return {
            label: item.brand
              ? item.brand + " " + item.model
              : "VIN:" + item.vin,
            value: item.id,
          };
        })
      : [];
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <div className="alert alert-success" role="alert">
        Чтобы получить пин-код нужно с номера владельца отправить СМС на номер
        сим карты установленой в сигнализации и ввести команду 00581#. <br />
        После этого пин-код прийдет на номер владельца.
      </div>
      <Row>
        {SIGNALING_DATA.map(
          (item, index) =>
            !(item.key === "car" && isRent) && (
              <Col key={index}>
                <FormGroup>
                  <Label>{item.name}</Label>
                  {item.key === "car" ? (
                    <Select
                      value={selectCar}
                      onChange={(e) => {
                        if (e != null) {
                          changeSignaling("car_id", e.value);
                        }
                        setSelectCar(e);
                      }}
                      placeholder="Выберите машину"
                      options={infoForSelect()}
                      isSearchable
                    />
                  ) : (
                    <Input
                      style={{ marginLeft: 0 }}
                      value={signaling[item.key]}
                      onChange={({ target }) =>
                        changeSignaling(item.key, target.value)
                      }
                    />
                  )}
                </FormGroup>
              </Col>
            )
        )}
        {/* {!isRent && (
          <Col>
            <FormGroup>
            <Label>{s}</Label>
              <Select
                value={signaling.car}
                onChange={(e) => {
                  changeSignaling("car", e);
                }}
                placeholder="Выберите машину"
                isClearable
                isSearchable
              />
            </FormGroup>
          </Col>
        )} */}

        {/* {!isRent && (
          <Col md={2}>
            <FormGroup>
              <Label>Сервисное авто</Label>
              <FormGroup>
                <Row>
                  <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Label style={{ marginRight: 2, marginBottom: 0, marginTop: -5 }}>Нет</Label>
                    <Input
                      name="service"
                      value={"0"}
                      type="radio"
                      onChange={({ target }) => changeSignaling("service", +target.value)}
                    />
                  </Col>
                  <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Label style={{ marginRight: 2, marginBottom: 0, marginTop: -5 }}>Да</Label>
                    <Input
                      name="service"
                      value={"1"}
                      type="radio"
                      onChange={({ target }) => changeSignaling("service", +target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </FormGroup>
          </Col>
        )} */}
      </Row>
      <button
        disabled={checkFields()}
        onClick={() => {
          if (isRent) {
            delete signaling.service;
            registerRentSignaling(signaling);
          } else {
            registerSignaling(signaling);
          }
          setSignaling({
            key: "",
            name: "",
            type: "gps-alarm",
            tel: "",
            car_id: 0,
            service: 0,
          });
          setSelectCar("");
        }}
        className="btn btn-success"
        style={{ marginTop: 10 }}
      >
        Подключить
      </button>
    </Form>
  );
};

export default RegisterSignaling;
