import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getArrayFromDigit } from "utils/getArrayFromDigit";
import { useWindowSize } from "hooks/useWindowSize";

const buttonWithPoints = (page, currentPage, setCurrentPage, position, i) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }} key={i}>
      {position === "top" && (
        <p style={{ textAlign: "center", color: "blue", fontSize: "8px" }}>
          ...
        </p>
      )}
      <Button
        key={page}
        onClick={() => setCurrentPage(page)}
        // className="mx-2"
        // style={{
        //   fontSize: page === currentPage && String(page).length === 3 ? 10 : 14,
        // }}
        color={page === currentPage ? "primary" : ""}
      >
        {page}
      </Button>
      {position === "bottom" && (
        <p
          style={{
            textAlign: "center",
            color: "blue",
            fontSize: "8px",
            paddingLeft: "8px",
          }}
        >
          ...
        </p>
      )}
    </div>
  );
};

const Pagination = ({
  currentPage,
  pagesAmount,
  setCurrentPage,
  perPage = 10,
}) => {
  const [valueInPage, setValueInPage] = useState(perPage);
  const dimensions = useWindowSize();

  useEffect(() => {
    if (dimensions.width <= 610) {
      setValueInPage(4);
    }
    if (dimensions.width > 610) {
      setValueInPage(10);
    }
  }, [dimensions.width]);
  return (
    <div
      className="d-flex pagination-box"
      style={{
        marginBottom: 10,
        
      }}
    >
      <Button
        key={-1}
        disabled={currentPage === 1}
        onClick={(value) => setCurrentPage(currentPage - 1)}
        className="btn-pagination "
        color="primary"
      >
        &#8592;
      </Button>

      {getArrayFromDigit(
        pagesAmount,
        currentPage,
        valueInPage,
        "pagination"
      )?.map((page, i) => {
        if (i === 1 && page - 1 != 1) {
          return buttonWithPoints(page, currentPage, setCurrentPage, "top", i);
        } else if (i == valueInPage - 2 && page + 1 != pagesAmount) {
          return buttonWithPoints(
            page,
            currentPage,
            setCurrentPage,
            "bottom",
            i
          );
        }
        return (
          <Button
            key={i}
            onClick={() => setCurrentPage(page)}
            className="btn-pagination"
            color={page === currentPage ? "primary" : ""}
          >
            {page}
          </Button>
        );
      })}
      <Button
        key={-2}
        disabled={currentPage === pagesAmount}
        onClick={() => setCurrentPage(currentPage + 1)}
        className="btn-pagination "
        color="primary"
      >
        &#8594;
      </Button>
    </div>
  );
};

export default Pagination;
