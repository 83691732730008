import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Row, TabPane } from "reactstrap";
import RentBreadcrumb from "components/Common/RentBreadcrumb";
import RentTabs from "components/Common/Tabs/RentTabs";
import UserInformation from "components/Rents/Users/UserInformation";
import Loader from "components/Common/Loader";
import TablesRents from "components/Rents/Users/TablesRents";
import { rentUserAPI } from "services";

const RentUser = () => {
  const { id } = useParams();
  const { data: user, isLoading: userLoading } =
    rentUserAPI.useGetUserInfoQuery(id);

  const navlinks = ["Персональные данные", "Поездки"];
  const listTitleItems = [
    { id: 1, title: "ID поездки" },
    { id: 2, title: "Марка автомобиля" },
    { id: 3, title: "Модель автомобиля" },
    { id: 4, title: "Дата начала" },
    { id: 5, title: "Дата конца" },
  ];

  return (
    <div>
      {!userLoading ? (
        <div style={{ marginTop: 70, marginLeft: -10 }}>
          <RentTabs navlinks={navlinks}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <UserInformation user={user?.data} />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  {user?.data.rents.length > 0 ? (
                    <TablesRents
                      rents={user?.data.rents}
                      listTitleItems={listTitleItems}
                    />
                  ) : (
                    <p style={{ textAlign: "center" }}> Поездок нет</p>
                  )}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
          </RentTabs>
        </div>
      ) : (
        <p
          style={{
            marginTop: 100,
            textAlign: "center",
            justifyItems: "center",
          }}
        >
          Загрузка...
        </p>
      )}{" "}
    </div>
  );
};

export default RentUser;
