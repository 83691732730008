import React, { FormEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { FormGroup, FormText, Input, Label } from "reactstrap";

import { createUserSchema } from "common/validationSchemas";
import PaginationTable from "components/Common/PaginationTable";
import { PhoneInput } from "components/Common/PhoneInput";
import { userAPI } from "services";
import { formatUserDataForTable } from "common/dataFormatters";

const Users = () => {
  const history = useHistory();

  const [createUser, { isLoading: createUserLoading }] =
    userAPI.useCreateUserMutation();
  const [deleteUser, { isLoading: deleteUserLoading }] =
    userAPI.useDeleteUserMutation();

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [amountPage, setAmountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data: users, isFetching: usersLoading } = userAPI.useGetUsersQuery({
    "filters[phone]": phoneNumber,
    per_page: perPage,
    page: currentPage,
  });

  useEffect(() => {
    setAmountPage(users?.meta?.last_page);
  }, [users]);

  const [sendSms] = userAPI.useSendSmsMutation();
  const [confirmCode, { data: confirmData, isSuccess: confirmIsSuccess }] =
    userAPI.useConfirmCodeMutation();

  const [codeSended, setCodeSended] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      surname: "",
      patronymic: "",
      phone: "",
      address: "",
    },
    validationSchema: createUserSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createUser(values);
      resetForm();
    },
  });

  const phoneFormik = useFormik({
    initialValues: {
      phone: "",
      code: "",
    },
    onSubmit: (values, { resetForm }) => {},
  });

  function submitNumber(e: FormEvent) {
    e.preventDefault();
    sendSms(phoneFormik.values.phone.replace(/[-+ ]/g, ""));
    setCodeSended(true);
  }

  function submitCode(e: FormEvent) {
    e.preventDefault();
    confirmCode([
      phoneFormik.values.phone.replace(/[-+ ]/g, ""),
      phoneFormik.values.code,
    ]);
  }

  React.useEffect(() => {
    if (confirmData) {
      //@ts-ignore
      history.push("/users/" + confirmData?.data.user.id);
    }
  }, [confirmData]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Создание пользователя</h2>
        </div>

        <form onSubmit={formik.handleSubmit} className="mb-5">
          <div className="card">
            <div className="card-header">Информация о пользователе</div>
            <div className="card-body">
              <div className="filter-cars">
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="email">E-mail</Label>
                    <Input
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.email}
                    />
                    <FormText color="danger">{formik.errors.email}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="password">Пароль</Label>
                    <Input
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.password}
                    />
                    <FormText color="danger">{formik.errors.password}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="name">Имя</Label>
                    <Input
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.name}
                    />

                    <FormText color="danger">{formik.errors.name}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="surname">Фамилия</Label>
                    <Input
                      id="surname"
                      name="surname"
                      value={formik.values.surname}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.surname}
                    />
                    <FormText color="danger">{formik.errors.surname}</FormText>
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-3">
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="patronymic">Отчество</Label>
                    <Input
                      id="patronymic"
                      name="patronymic"
                      value={formik.values.patronymic}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.patronymic}
                    />
                    <FormText color="danger">
                      {formik.errors.patronymic}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="address">Адрес</Label>
                    <Input
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.address}
                    />
                    <FormText color="danger">{formik.errors.address}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="phone">Номер телефона</Label>
                    <PhoneInput
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={(value: string) =>
                        formik.setFieldValue("phone", value)
                      }
                      invalid={!!formik.errors.phone}
                    />
                  </FormGroup>
                </div>
              </div>

              <button
                type="submit"
                disabled={createUserLoading}
                className="btn btn-success"
              >
                Создать
              </button>
            </div>
          </div>
        </form>

        <form
          onSubmit={!codeSended ? submitNumber : submitCode}
          className="mb-5"
        >
          <div className="card">
            <div className="card-header">
              Создание пользователя по номеру телефона
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col">
                  <FormGroup className="mb-3">
                    <Label for="phone">Номер телефона</Label>
                    <PhoneInput
                      value={phoneFormik.values.phone}
                      onChange={(value: string) =>
                        phoneFormik.setFieldValue("phone", value)
                      }
                    />
                  </FormGroup>
                </div>
                {codeSended && (
                  <div className="col">
                    <FormGroup className="mb-3">
                      <Label for="code">Код подтверждения</Label>
                      <Input
                        id="code"
                        name="code"
                        maxLength={6}
                        value={phoneFormik.values.code}
                        onChange={phoneFormik.handleChange}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={createUserLoading}
                className="btn btn-success"
              >
                {codeSended ? "Создать" : "Отправить код"}
              </button>
            </div>
          </div>
        </form>

        <div className="box-search-number">
          <p>Поиск по номеру телефона</p>
          <Input
            placeholder="Поиск по номеру"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            maxLength={11}
            style={{ maxWidth: 150 }}
          />
        </div>

        <PaginationTable
          title="Список пользователей"
          thTitles={[
            "ID",
            "E-mail",
            "Имя",
            "Фамилия",
            "Отчество",
            "Адрес",
            // <React.Fragment>
            //   <Input
            //     placeholder="Поиск по номеру"
            //     value={phoneNumber}
            //     onChange={(e) => setPhoneNumber(e.target.value)}
            //     maxLength={11}
            //     style={{ maxWidth: 150, marginBottom: 10 }}
            //   />
            //   Номер телефона
            // </React.Fragment>,
          ]}
          tableData={formatUserDataForTable(users?.data)}
          canDelete
          onDelete={deleteUser}
          deletePending={deleteUserLoading}
          loading={usersLoading}
          onRowClick={(userId: number) => history.push(`/users/${userId}`)}
          setPerPage={setPerPage}
          perPage={perPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          amountPage={amountPage}
          setAmountPage={setAmountPage}
          isBackPagination={true}
        />
      </div>
    </div>
  );
};

export default Users;
