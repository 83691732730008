import { IMessageItem } from "types/RentUserTypes";
import moment from "moment";
interface ListRentMessagesProps {
  item: IMessageItem;
}

const ListRentMessages = ({ item }: ListRentMessagesProps) => {
  const formatInfo = (value: any) => {
    return value === undefined || value == null ? "-" : value;
  };
  return (
    <tr>
      <td>{formatInfo(item?.id)}</td>
      <td>{formatInfo(moment(item?.date).format("DD.MM.YYYY HH:mm"))}</td>
      <td>{formatInfo(item?.phone)}</td>
      <td>{formatInfo(item?.fio)}</td>
      <td>{formatInfo(item?.text)}</td>
      <td>{formatInfo(item?.status)}</td>
    </tr>
  );
};
export default ListRentMessages;
