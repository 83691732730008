const FilesLoad = (files: any) => {
  let arrayFiles: Array<File> = []

  Object.keys(files.files).forEach((key) => arrayFiles.push(files.files[key]))

  return(
    <div className='d-flex m-2'>
      {arrayFiles?.map(item => <div key={URL.createObjectURL(item)} style={{maxWidth: "100px"}} className="d-flex">
        {
          item.type.split('/')[0] === 'video' && <video style={{width: '100%'}}>
            <source src={URL.createObjectURL(item)}/>
          </video>
        }
        {
          item.type.split('/')[0] === 'image' && <img src={URL.createObjectURL(item)} alt="image" style={{width: '100%'}}/> 
        }
      </div>)}
    </div>
  )
}

export default FilesLoad;