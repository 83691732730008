export const dataFormattingForUpdateUser = (user) => {
  const formedUser = new FormData();
  formedUser.append("_method", "POST");
  formedUser.append("roles[][id]", user.roles[0].id);
  const null_array = [
    "photo_information",
    "photo_with_user",
    "photo_registration",
  ];
  for (let key in user.passport) {
    if (!null_array.includes(key)) {
      if (user.passport[key] !== "") {
        formedUser.append(`passport[${key}]`, user.passport[key]);
      }
    }
  }
  for (let key in user.user) {
    if (user.user[key] !== "") {
      formedUser.append(`user[${key}]`, user.user[key]);
    }
  }
  for (let key in user.payment_card) {
    if (user.payment_card[key] !== "") {
      formedUser.append(`payment_card[${key}]`, user.payment_card[key]);
    }
  }
  console.log(Object.fromEntries(formedUser));
  console.log("hook", formedUser);
  return formedUser;
};

export const dataFormattingForCreateUser = (user) => {
  const formedUser = new FormData();
  for (let key in user.user) {
    if (user.user[key] !== "") {
      formedUser.append(`user[${key}]`, user.user[key]);
    }
  }
  formedUser.append("roles[][id]", user.roles[0].id);

  for (let key in user.passport) {
    if (user.passport[key] && user.passport[key] !== "") {
      formedUser.append(`passport[${key}]`, user.passport[key]);
    }
  }
  for (let key in user.driver_licence) {
    if (user.driver_licence[key]) {
      formedUser.append(`driver_licence[${key}]`, user.driver_licence[key]);
    }
  }
  for (let key in user.payment_card) {
    if (user.payment_card[key] !== "") {
      formedUser.append(`payment_card[${key}]`, user.payment_card[key]);
    }
  }
  return formedUser;
};

export const dataFormattingForCreateAdmin = (admin) => {
  return { ...admin, roles: admin.roles.map((item) => item.value) };
};

export const dataFormattingForUpdateAdmin = (admin) => {
  console.log("admin", admin);
  return {
    id: admin.id,
    roles: admin.roles != null ? admin.roles.map((item) => item.value) : null,
    password: admin.password,
  };
};
