import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Select from "react-select";
import { Input } from "reactstrap";

import { formatDate } from "common/dataFormatters";
import { rentCarAPI, rentUserAPI, rentFineAPI } from "services";
import Pagination from "components/Common/Pagination";
import Loader from "components/Common/Loader";

const tableTitles = [
  "Вид штрафа",
  "Авто",
  "Адрес",
  "Дата постановления",
  "Сумма",
  "Номер постановления",
  "Дата штрафа",
  "Статус",
];

const Fines = () => {
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPost, setNumPost] = useState("");

  const { data: cars, isLoading: carsLoading } = rentCarAPI.useGetRentCarsQuery(
    {}
  );
  const {
    data: fines,
    isLoading: finesIsLoading,
    isFetching: finesIsFetching,
  } = rentFineAPI.useGetFinesQuery({
    page: currentPage,
    per_page: 10,
    "filters[car_id]": selectedCar?.value ? selectedCar.value : "",
    "filters[num_post]": numPost || "",
  });

  const selectCarOptions = useMemo(
    () =>
      cars?.data.map((car) => ({
        value: car.id,
        label: [car.brand, car.model, car.registration_number].join(" "),
      })),
    [cars]
  );
  const createLabelCar = (car, vehicle_model) => {
    if (car.id != null) {
      return car.brand + " " + car.model + " " + car.registration_number;
    } else {
      return vehicle_model;
    }
  };

  if (finesIsFetching || finesIsFetching || carsLoading) return <Loader />;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-fines-page">
            <div style={{ backgroundColor: "white" }}>
              <div className="header-fines-page">
                {/* <div className="mr-4" style={{ width: "30%", marginRight: 20 }}>
                  <Select
                    onChange={(data) => setSelectedUser(data?.value)}
                    placeholder="Выбрать пользователя"
                    isClearable
                    isSearchable
                    options={selectUserOptions}
                  />
                </div> */}
                <div className="custom-select">
                  <Select
                    onChange={(data) => setSelectedCar(data)}
                    placeholder="Выбрать авто"
                    isClearable
                    isSearchable
                    options={selectCarOptions}
                  />
                </div>
                <div className="mr-2 box-input">
                  <Input
                    id="phone"
                    name="phone"
                    style={{ height: 40 }}
                    placeholder="Номер постановления"
                    value={numPost}
                    onChange={(e) => setNumPost(e.target.value)}
                  />
                </div>
              </div>
              <div
                id="table"
                className="card-header list-title"
                style={{
                  backgroundColor: "white",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      {tableTitles.map((title, i) => (
                        <th scope="col" key={i}>
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {fines?.data?.map(
                      ({
                        id,
                        ko_ap_text,
                        car,
                        division_address,
                        date_post,
                        vehicle_model,
                        summa,
                        date_decis,
                        num_post,
                        isPaid,
                      }) => (
                        <tr
                          key={id}
                          onClick={() =>
                            history.push(`/alyans-rent/fines/${id}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <th>{ko_ap_text}</th>
                          {/* <td>
                            <span> </span>
                          </td> */}
                          <td>
                            <span>{createLabelCar(car, vehicle_model)}</span>
                          </td>
                          <td>{division_address}</td>
                          <td>{formatDate(date_post, "dd-MM-yyyy")}</td>
                          <td>{summa}</td>
                          <td>{num_post}</td>
                          <td>{formatDate(date_decis, "dd-MM-yyyy")}</td>
                          <td>{isPaid ? <span className="text-success" >Оплачен</span> : <span className="text-danger">Не оплачен</span>}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              {
                <Pagination
                  pagesAmount={fines?.meta?.last_page}
                  currentPage={currentPage}
                  setCurrentPage={(page) => setCurrentPage(page)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fines;
