import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { rentTourAPI } from "services";
import CreateTourModal from "components/Rents/Tours/Modal/CreateTourModal";
import CreateRegionModal from "components/Rents/Tours/Modal/CreateRegionModal";
import CreateCategoryModal from "components/Rents/Tours/Modal/CreateCategoryModal";

const Tours = () => {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [isCreateRegionModalShow, setIsCreateRegionModalShow] = useState(false);
  const [isCreateCategoryModalShow, setIsCreateCategoryModalShow] =
    useState(false);

  const { data: tours, isLoading: getToursLoading } =
    rentTourAPI.useGetToursQuery();
  const { data: regions, isLoading: getRegionsLoading } =
    rentTourAPI.useGetRegionsQuery();
  const { data: categories, isLoading: getCategoriesLoading } =
    rentTourAPI.useGetCategoriesQuery();
  const [createTour, { isLoading: createTourLoading }] =
    rentTourAPI.useCreateTourMutation();
  const [createRegion, { isLoading: createRegionLoading }] =
    rentTourAPI.useCreateRegionsMutation();
  const [createCategory, { isLoading: createCategoryLoading }] =
    rentTourAPI.useCreateCategoryMutation();

  console.log("tours", tours);
  const tableTitles = [
    "Id тура",
    "Название тура",
    "Расчётное расстояние",
    "Регион",
    "Категория",
    "Количество точек",
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-tours-page">
              <div className="header-tours-page ">
                <div className="list-btn mb-4 ">
                  <Button color="primary" onClick={() => setIsShow(true)}>
                    Создать тур
                  </Button>
                  <Button
                    className="mx-4"
                    color="primary"
                    onClick={() => setIsCreateRegionModalShow(true)}
                  >
                    Создать регион
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setIsCreateCategoryModalShow(true)}
                  >
                    Создать категорию
                  </Button>
                </div>
                <div
                  id="table"
                  className="card-header list-title"
                  style={{ backgroundColor: "white" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        {tableTitles.map((title, i) => (
                          <th scope="col" key={i}>
                            {title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tours?.data?.map((tour) => (
                        <tr
                          onClick={() =>
                            history.push(`/alyans-rent/tours/${tour.id}`)
                          }
                          key={tour.id}
                        >
                          <th>{tour.id}</th>
                          <td>{tour.name}</td>
                          <td>{tour.distance}</td>
                          <td>{tour.region?.name}</td>
                          <td>{tour.category?.name}</td>
                          <td>{tour.points?.length}</td>
                        </tr>
                      ))}
                      {/* {tours.map((i, index) => (
                      <ListItem key={index} />
                    ))} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <CreateTourModal
              isShow={isShow}
              setIsShow={setIsShow}
              isLoading={createTourLoading}
              regions={regions}
              categories={categories}
              onSubmit={async (tour) => {
                await createTour(tour);
              }}
            />
            <CreateRegionModal
              isShow={isCreateRegionModalShow}
              setIsShow={setIsCreateRegionModalShow}
              isLoading={createRegionLoading}
              onSumbit={async (region) => {
                await createRegion(region);
              }}
            />
            <CreateCategoryModal
              isShow={isCreateCategoryModalShow}
              setIsShow={setIsCreateCategoryModalShow}
              isLoading={createCategoryLoading}
              onSumbit={async (category) => {
                await createCategory(category);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Tours;
