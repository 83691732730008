import React, { Fragment, useState, useEffect, useMemo } from "react";
import Param from "components/Rents/Cars/Param";
import { useHistory, useLocation } from "react-router";
import { Button } from "reactstrap";
import Select from "react-select";
import { Input, Label } from "reactstrap";
import CreateCarModal from "components/Rents/Modal/Cars/CreateCarModal";
// import Loader from "components/Common/Loader";
import { isExistsPhoto } from "helpers/photo";
import { useSorting } from "hooks/useSotring";
import ReactDatePicker from "react-datepicker";
import DatePickerInput from "components/Common/DatePickerInput";
import Breadcrumb from "components/Common/RentBreadcrumb";
import Pagination from "components/Common/Pagination";
import DeleteModal from "components/Rents/Modal/deleteModal";
import useDebounce from "hooks/useDebounce";

import { formatDate } from "utils/date";
import { rentCarAPI } from "services";
import { all } from "redux-saga/effects";
import { getYear } from "date-fns";

const Cars = () => {
  const history = useHistory();
  // const params = new URLSearychParams(history.location.search);

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);

  useEffect(() => {
    if (!searchParams.get("page")) {
      searchParams.set("page", "1");
      history.replace({ search: searchParams.toString() });
    } else if (searchParams.get("page")) {
      setPage(Number(searchParams.get("page")));
    }
    if (!searchParams.get("isPool")) {
      searchParams.set("isPool", "0");
      history.replace({ search: searchParams.toString() });
    } else if (searchParams.get("isPool")) {
      setIsPool(searchParams.get("isPool") === "0" ? false : true);
    }
    if (!searchParams.get("filters[vin]")) {
      searchParams.set("filters[vin]", "");
      history.replace({ search: searchParams.toString() });
    } else if (searchParams.get("filters[vin]")) {
      setFiltersVin(String(searchParams.get("filters[vin]")));
    }
  }, []);

  const [page, setPage] = React.useState(1);
  const [isPool, setIsPool] = useState(false);
  const [filtersVin, setFiltersVin] = useState("");
  const [filtersVinValidate, setFiltersVinValidate] = useState("");
  const debouncedFiltersVin = useDebounce(filtersVinValidate, 500);
  const [date, setDate] = React.useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: null,
    to: null,
  });

  useEffect(() => {
    searchParams.set("page", String(page));
    history.replace({ search: searchParams.toString() });
  }, [page]);

  useEffect(() => {
    searchParams.set("isPool", String(isPool ? "1" : "0"));
    history.replace({ search: searchParams.toString() });
  }, [isPool]);

  useEffect(() => {
    searchParams.set("filters[vin]", String(filtersVinValidate));
    history.replace({ search: searchParams.toString() });
  }, [filtersVinValidate]);

  const [isShow, setIsShow] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [deleteСar, {}] = rentCarAPI.useDeleteCarMutation();

  const { data: cars, isLoading } = rentCarAPI.useGetRentCarsQuery({
    is_paginated: 1,
    page: page,
    "filters[available_from]": formatDate(date.from, "yyyy-MM-dd"),
    "filters[available_to]":
      date.to === null ? "" : formatDate(date.to, "yyyy-MM-dd"),
    "filters[inPool]": isPool ? "1" : "0",
    "filters[vin]": debouncedFiltersVin,
  });

  const { data: allCars, isLoading: isLoadingAllCars } =
    rentCarAPI.useGetRentCarsQuery();

  const onSubmitDeleteModal = () => {
    deleteСar(idForDelete).then(() => {
      setIdForDelete(null);
      setIsDeleteModalShow(false);
    });
  };

  // const sortedCars = useSorting(cars?.data, params, "/alyans-rent/cars");

  const listTitleItems = [
    { id: 1, title: "Фото" },
    { id: 2, title: "Марка" },
    { id: 3, title: "Модель" },
    { id: 4, title: "Гос. номер" },
    { id: 5, title: "Статус" },
    { id: 6, title: "Город" },
    { id: 7, title: "" },
  ];

  const nowYear = new Date().getFullYear();
  const yearsArr = [];

  for (let i = 0; i < 60; i++) {
    yearsArr.push(nowYear - i);
  }

  const selectCarOptions = useMemo(
    () =>
      allCars?.data.map((car: any) => ({
        value: car,
        label: [car.registration_number, car.brand, car.mark].join(" "),
      })),
    [allCars]
  );

  const getCurrentCarsArray = React.useMemo(() => {
    if (selectedCar) {
      return [selectedCar];
    }

    return cars?.data;
  }, [cars, selectedCar]);

  const validateSearch = (value: string) => {
    const reg_VIN = /^[A-Za-z0-9]+$/;
    const empty_string = /^$/;
    return (
      (reg_VIN.test(value) || empty_string.test(value)) && value.length < 18
    );
  };

  useEffect(() => {
    if (validateSearch(filtersVin)) {
      setFiltersVinValidate(filtersVin);
    }
  }, [filtersVin]);

  return (
    <Fragment>
      {isLoading ? (
        <p
          style={{
            marginTop: 100,
            textAlign: "center",
          }}
        >
          Загрузка...
        </p>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-cars-page">
                <div className=" header-card-page ">
                  <div className="row filters-cars-page">
                    <div className="custom-select col-">
                      <Select
                        onChange={(data: any) => setSelectedCar(data?.value)}
                        placeholder="Поиск по гос. номеру"
                        isClearable
                        isSearchable
                        options={selectCarOptions}
                      />
                    </div>
                    <div className="custom-select col-">
                      <Input
                        type="search"
                        onChange={(e: any) => {
                          if (e.target.value.length <= 17) {
                            setFiltersVin(e.target.value);
                          }
                        }}
                        placeholder="Поиск по VIN"
                        invalid={!validateSearch(filtersVin)}
                        value={filtersVin}
                        disabled={selectedCar != null}
                      />
                    </div>
                    <div className="filter-date">
                      <span className="mr-2" style={{ whiteSpace: "nowrap" }}>
                        Свободные авто с
                      </span>
                      <ReactDatePicker
                        locale="ru"
                        selected={date.from}
                        // onChangeRaw={(e) => e.preventDefault()}
                        onChange={(value) => {
                          let newDate: Date | null = null;
                          if (value != null) {
                            if (
                              2000 < new Date(value).getFullYear() &&
                              new Date(value).getFullYear() <
                                new Date().getFullYear() + 1
                            ) {
                              newDate = value;
                            }
                          }
                          setDate((prev) => ({
                            ...prev,
                            from: newDate,
                          }));
                        }}
                        selectsStart
                        startDate={date.from}
                        customInput={
                          <DatePickerInput className="date-picket-input" />
                        }
                        dateFormat="dd.MM.yyyy"
                      />
                      <span>по</span>
                      <ReactDatePicker
                        locale="ru"
                        selected={date.to}
                        onChange={(value) => {
                          let newDate: Date | null = null;
                          if (value != null) {
                            if (
                              new Date(value).getFullYear() <
                              new Date().getFullYear() + 1
                            ) {
                              newDate = value;
                            }
                          }
                          setDate((prev) => ({
                            ...prev,
                            to: newDate,
                          }));
                        }}
                        selectsEnd
                        startDate={date.from}
                        endDate={date.to}
                        minDate={date.from}
                        customInput={
                          <DatePickerInput className="date-picket-input" />
                        }
                        dateFormat="dd.MM.yyyy"
                      />
                      <div className="box-checkbox-only-actual">
                        <Label
                          check
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: 100,
                            alignItems: "center",
                            textAlign: "center",
                            margin: 0,
                          }}
                        >
                          <Input
                            type="checkbox"
                            checked={isPool}
                            onChange={() => {
                              setPage(1);
                              setIsPool(!isPool);
                            }}
                            style={{ marginRight: 8 }}
                          />
                          <p
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            В пуле
                          </p>
                        </Label>
                      </div>
                      <Button
                        className="btn-header-pages"
                        color="primary"
                        onClick={() => setIsShow(true)}
                        style={{ minWidth: 140 }}
                      >
                        Создать машину
                      </Button>
                    </div>
                  </div>

                  <div
                    id="table"
                    className="card-header list-title"
                    style={{ backgroundColor: "white" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          {listTitleItems.map((i) => (
                            <Param id={i.id} title={i.title} key={i.id} />
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          getCurrentCarsArray?.map((car: any) => {
                            return (
                              <tr
                                onClick={() =>
                                  history.push(`/alyans-rent/cars/${car.id}`)
                                }
                                key={car.id}
                              >
                                <th>{car.id}</th>
                                <td>
                                  {!isExistsPhoto(car.photo) ? (
                                    "Фотографии нет"
                                  ) : (
                                    <img width={70} src={car.photo} />
                                  )}
                                </td>
                                <td>{car.brand}</td>
                                <td>{car.model}</td>
                                <td>{car.registration_number}</td>
                                <td>{car.status}</td>
                                <td>{car.city.name}</td>
                                <td>
                                  <Button
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    className="waves-effect waves-light m-1"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIdForDelete(car.id);
                                      setIsDeleteModalShow(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-trash3-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-center zindex-modal">
                    {!selectedCar && !isLoading && (
                      <Pagination
                        pagesAmount={cars?.meta?.last_page}
                        setCurrentPage={(page: number) => setPage(page)}
                        currentPage={page}
                      />
                    )}
                  </div>
                </div>
              </div>
              <CreateCarModal
                isShow={isShow}
                setIsShow={setIsShow}
                yearsArr={yearsArr}
              />
              {isDeleteModalShow && (
                <DeleteModal
                  onSubmit={() => onSubmitDeleteModal()}
                  text="эту машину"
                  isShow={isDeleteModalShow}
                  setIsShow={setIsDeleteModalShow}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Cars;
