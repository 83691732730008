import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { careAPI } from 'services';
import { useThrottle } from 'common/hooks/useThrottle';
import { IStatus } from 'types/CareServiceTypes';
import StatusModal from './StatusModal';

const Statuses = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: statuses, isLoading: statusesLoading } = careAPI.useGetStatusesQuery();
  const [editStatus] = careAPI.useEditStatusMutation();
  const [deleteStatus, { isLoading: deleteStatusLoading }] = careAPI.useDeleteStatusMutation();

  const handleEditStatus = ([e, id]: [React.ChangeEvent<HTMLInputElement>, number]) => editStatus({ id, color: e.target?.value });
  const throttledEditStatus = useThrottle(handleEditStatus, 2000);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Статусы заявок</h2>
        </div>

        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            Статусы заявок
            <button onClick={() => setIsModalOpen(true)} className="btn btn-success">
              Создать статус
            </button>
          </div>

          {statusesLoading ? (
            <TailSpin color="#02a499" />
          ) : (
            <div className="card-body">
              <table className="table dataTable no-footer">
                <thead>
                  <tr>
                    {['ID', 'Название', 'Цвет', ''].map((th, i) => (
                      <th key={i}>{th}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {!statuses?.data.length && (
                      <tr>
                        <td className="text-center" colSpan={4}>
                          Статусов нет
                        </td>
                      </tr>
                    )}
                    {statuses?.data.map(({ id, name, color }) => (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>{name}</td>
                        <td>
                          <input type="color" onChange={(e) => throttledEditStatus(e, id)} value={color} />
                        </td>
                        <td style={{ width: 0 }}>
                          <button onClick={() => deleteStatus(id)} disabled={deleteStatusLoading} className="btn btn-danger">
                            Удалить
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </div>
          )}

          <StatusModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
      </div>
    </div>
  );
};

export default Statuses;
