import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const { breadcrumbItems } = props
  const itemLength = breadcrumbItems.length

  return (
    <ol className="breadcrumb px-0 mt-2 mb-0">
      {breadcrumbItems.map((item, i) => (
        <BreadcrumbItem key={i} active={i + 1 === itemLength}>
          {item.link ? <Link to={item.link}>{item.title}</Link> : item.title}
        </BreadcrumbItem>
      ))}
    </ol>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
}

export default Breadcrumb
