import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";
import { IRentCars, IRentCarsValue } from "types/RentTypes";
import { toast } from "react-toastify";
import { IRentCar, IRentFine, IRentRate, IGetRentCar } from "types/RentCarTypes";

// invalidatesTags: ['admins'],
// providesTags: () => ['admins'],
export const rentCarAPI = createApi({
  reducerPath: "rentCarAPI",
  tagTypes: ["car", "finance", "cars"],
  baseQuery,
  endpoints: (build) => ({
    getRentCars: build.query<IRentCars, IRentCarsValue | void>({
      query: (params) => {
        return {
          url: `/admin/cars`,
          method: "GET",
          params: {
            ...params,
          },
        };
      },
      providesTags: () => ["car", "finance", "cars"],
    }),
    getCar: build.query<{ data: IRentCar }, number>({
      query: (id) => ({
        url: `/admin/cars/${id}`,
        method: "GET",
      }),
      providesTags: ["car", "finance"],
    }),
    getCarRents: build.query<any, IGetRentCar>({
      query: ({ id, ...params }) => {
        return {
          url: `/admin/cars/${id}/rents`,
          method: "GET",
          params: {
            ...params,
          },
        };
      },
    }),
    getRentInfo: build.query<any, number>({
      query: (id) => ({
        url: `admin/finance/${id}`,
        method: "GET",
      }),
      providesTags: ["car", "finance"],
    }),
    createRentRate: build.mutation<any, IRentRate>({
      query: ({ carId, price, discount_before, discount_after }) => ({
        url: `admin/finance?car_id=${carId}&price=${price}&discount_before=${discount_before}&discount_after=${discount_after}`,
        method: "PUT",
      }),
    }),
    updateRentRate: build.mutation<any, IRentRate>({
      query: ({ id, carId, price, discount_before, discount_after }) => {
        console.log(
          "admin/finance/${id}?car_id=${carId}&price=${price}&discount_before=${discount_before}&discount_after=${discount_after}"
        );
        return {
          url: `admin/finance/${id}?car_id=${carId}&price=${price}&discount_before=${discount_before}&discount_after=${discount_after}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["car", "finance"],
    }),
    getFinanceInfo: build.query<any, number>({
      query: (carId) => {
        return {
          url: `admin/finance/${carId}`,
          method: "GET",
        };
      },
      providesTags: ["finance"],
    }),
    updateCarFinancies: build.mutation<any, { id: number; body: any }>({
      query: ({ id, body }) => ({
        url: `/admin/cars/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["car", "finance"],
    }),
    createCar: build.mutation<any, any>({
      query: (car) => ({
        url: `/admin/cars`,
        method: "POST",
        body: car,
      }),
      invalidatesTags: ["car", "finance"],
    }),
    deleteCar: build.mutation<any, any>({
      query: (id) => ({
        url: `/admin/cars/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["car", "finance", "cars"],
    }),
    updatePostCar: build.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/admin/cars/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["car", "finance"],
    }),
    getCities: build.query<any, any>({
      query: () => ({
        url: "/admin/cities",
        method: "GET",
      }),
    }),
    getBrands: build.query<any, void>({
      query: () => ({
        url: "/helpers/get-brands",
        method: "GET",
      }),
    }),
    getModels: build.query<any, string>({
      query: (brandId) => ({
        url: `/helpers/get-models/${brandId}`,
        method: "GET",
      }),
    }),
    getGenerations: build.query<any, string>({
      query: (modelId) => ({
        url: `/helpers/get-generations/${modelId}`,
        method: "GET",
      }),
    }),
    getSerie: build.query<any, { modelId: string; generationsId: string }>({
      query: ({ modelId, generationsId }) => ({
        url: `/helpers/get-series/${modelId}/${generationsId}`,
        method: "GET",
      }),
    }),
    getModifications: build.query<any, string>({
      query: (seriesId) => ({
        url: `/helpers/get-modifications/${seriesId}`,
        method: "GET",
      }),
    }),
    getEquipment: build.query<any, string>({
      query: (modificationId) => ({
        url: `/helpers/get-equipment/${modificationId}`,
        method: "GET",
      }),
    }),
    registerSignaling: build.mutation<
      void,
      { key: string; name: string; type: "gps-alarm"; tel: string }
    >({
      query: (body) => ({
        url: `starline/register-car`,
        method: "POST",
        body,
      }),
    }),
  }),
});
