import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "services/settings";

export const authAPI = createApi({
  reducerPath: "authAPI",
  // tagTypes: ["user"],
  baseQuery,
  endpoints: (build) => ({
    register: build.mutation({
      query: (phone) => ({
        url: "/register",
        method: "POST",
        body: phone,
      }),
    }),
    login: build.mutation({
      query: (user) => ({
        url: "/admins/login",
        method: "POST",
        body: user,
      }),
      // возвращенные тут данные попадут в результат вызова login, который получаем из сгенерированного хука
      transformResponse: (response: any, meta, arg) => {
        localStorage.setItem("accessToken", response.data.access_token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("roles", JSON.stringify(response.data.roles));
        return response;
      },
    }),
    verifyPhone: build.mutation({
      query: (code) => ({
        url: `/verify/${code}`,
        method: "POST",
      }),
    }),
  }),
});
