import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Pagination from "components/Common/Pagination";

const TablesRents = ({ rents, listTitleItems }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [amountPage, setAmountPage] = useState(1);
  useEffect(() => {
    // setAmountPage(rents?.meta?.last_page);
    console.log("rents", rents);
  }, [rents]);
  const history = useHistory();
  return (
    <div>
      <table className="table-rents">
        <thead>
          <tr className="header-table">
            {listTitleItems.map((i, index) => (
              <th key={i.title} className="item-header-table">
                {i.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rents?.map((rent, index) => {
            return (
              <tr
                key={rent.rent.id}
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() =>
                  history.push(`/alyans-rent/rent/${rent?.rent?.id}`)
                }
              >
                <td key={rent?.rent?.id}>{rent?.rent?.id}</td>
                <td key={rent?.rent?.car?.brand + rent?.rent?.id}>
                  {rent?.rent?.car?.brand}
                </td>
                <td key={rent?.rent?.car?.model + rent?.rent?.id}>
                  {rent?.rent?.car?.model}
                </td>
                <td key={rent?.rent?.start_date + rent?.rent?.id + "start"}>
                  {rent?.rent?.start_date}
                </td>
                <td key={rent?.rent?.end_date + rent?.rent?.id + "end"}>
                  {rent?.rent?.end_date}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesAmount={amountPage}
      />
    </div>
  );
};

export default TablesRents;
