import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { baseQuery } from "services/settings";
import { IAdmin, IInfo, updateAdminProps } from "types/AdminTypes";

export const adminAPI = createApi({
  reducerPath: "adminAPI",
  tagTypes: ["admins"],
  baseQuery,
  endpoints: (build) => ({
    createAdmin: build.mutation<void, IAdmin>({
      query: (admin) => ({
        url: `/admins/new-admins`,
        method: "POST",
        body: admin,
      }),
      invalidatesTags: ["admins"],
    }),
    getAdmins: build.query<{ data: IAdmin[] }, void>({
      query: () => ({
        url: `/admins/new-admins`,
        method: "GET",
      }),
      providesTags: () => ["admins"],
    }),
    deleteAdmin: build.mutation<void, number>({
      query: (adminId) => ({
        url: `/admins/new-admins/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["admins"],
    }),
    editAdmin: build.mutation<void, updateAdminProps>({
      query: (admin) => ({
        url: `/admins/new-admins/${admin.id}`,
        method: "PUT",
        body: admin,
      }),
      transformResponse: (response, meta) => {
        meta?.response?.statusText == "OK" &&
          toast.success("Администратор успешно изменен");
      },
    }),
    getUsersTypes: build.query<{ data: { id: number; name: String } }, void>({
      query: () => ({
        url: `/admins/role`,
        method: "GET",
      }),
    }),

    // изменение фото или пароля
    changeAdmin: build.mutation({
      query: (data) => ({
        url: `/admins/`,
        method: "POST",
        body: data,
      }),
    }),
    checkDataUser: build.query<{ data: IInfo[] }, void>({
      query: () => ({
        url: `/check`,
        method: "GET",
      }),
    }),
  }),
});
