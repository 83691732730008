import React from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { RouteType } from "routes/allRoutes";
import { io } from "socket.io-client";
import { accessSupportRoutes, checkRole } from "utils/accessToRoles";

import audioMessage from "../../assets/sounds/chatMessage.mp3";

interface AuthmiddlewareProps extends RouteType {
  layout: any;
  isAuthProtected: boolean;
  allowedRoles?: Array<String>;
  exact?: boolean;
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  allowedRoles,
  ...rest
}: AuthmiddlewareProps) => {
  // React.useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   const sound = new Audio(audioMessage);

  //   if (token && checkRole(accessSupportRoutes)) {
  //     const user = JSON.parse(localStorage.getItem("user") as string);
  //     const ws = new WebSocket("wss://admin.main.alyans-auto.ru/chat");

  //     const onMessage = (message: any) => {
  //       const data = JSON.parse(message.data);
  //       if (data.action === "sendMessage") {
  //         toast.info("Пришло сообщение в чат");
  //         sound.play();
  //       }
  //     };

  //     ws.onmessage = onMessage;

  //     // SOCKET

  //     const socket = io("https://admin.rent.alyans-auto.ru/", {
  //       query: {
  //         email: user.email,
  //         id: user.id,
  //         profileUrl: user.avatar,
  //         name: user.initials,
  //         isSupport: true,
  //       },
  //     });

  //     socket.on("supportRecMessage", () => {
  //       toast.info("Пришло сообщение в чат");
  //       sound.play();
  //     });

  //     return () => {
  //       ws.removeEventListener("message", onMessage);
  //       ws.close();
  //       socket.close();
  //     };
  //   }
  // }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !localStorage.getItem("accessToken")) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
        if (isAuthProtected && allowedRoles?.length !== 0) {
          if (!checkRole(allowedRoles)) {
            return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
          }
        }

        if (Component) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
        return <div></div>;
      }}
    />
  );
};
export default Authmiddleware;
