import { useFormik } from "formik";

import React, { useEffect, useMemo, useRef } from "react";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { rentUserAPI } from "services";
import { adminAPI } from "services";
import { useUserLoad } from "common/hooks/useUserLoad";
import { PhoneInput } from "components/Common/PhoneInput";
import { createUserSchema, updateUserSchema } from "utils/validationShemas";
import { formatDate } from "utils/date";
import { formatPhone } from "utils/formatPhone";
import {
  dataFormattingForUpdateUser,
  dataFormattingForCreateUser,
} from "utils/formatData";

// const rolesWithId = [
//   {
//     id: "1",
//     name: "driver",
//   },
//   {
//     id: "2",
//     name: "manager",
//   },
//   {
//     id: "3",
//     name: "admin",
//   },
//   {
//     id: "4",
//     name: "owner",
//   },
// ];

const UserModal = ({ isShow, setIsShow, type = "create", userId }) => {
  const [getInfo, { data: userInfo, isLoading: userInfoLoading }] =
    rentUserAPI.useLazyGetUserInfoQuery();
  const [updateUser, {}] = rentUserAPI.useUpdateUserMutation();
  const [createUser, {}] = rentUserAPI.useCreateUserMutation();
  // const { data: rolesWithId, isLoading: userTypesLoading } =
  //   adminAPI.useGetUsersTypesQuery();

  const rolesWithId = [{ id: 7, name: "driver" }];
  const isUserFetched = useRef(false);
  const isUpdateModal = useMemo(() => type === "update", [type]);

  const formik = useFormik({
    initialValues: {
      src: {
        passportPhotoInformation: "",
        passportPhotoWithUser: "",
        passportPhototRegistration: "",
        licencePhoto: "",
        licencePhotoOtherside: "",
      },
      user: {
        email: "",
        password: "",
        name: "",
        surname: "",
        patronymic: "",
        birthdate: "",
        phone: "",
      },
      roles: [{ id: "7" }],
      passport: {
        series_number: "",
        isset: "",
        reg_address: "",
        photo_information: "",
        photo_with_user: "",
        photo_registration: "",
      },
      driver_licence: {
        photo: "",
        photo_otherside: "",
      },
    },
    validationSchema: isUpdateModal ? updateUserSchema : createUserSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      const info = {
        user: { ...values.user, ...(isUpdateModal && { id: userId }) },
        roles: values.roles,
        passport: values.passport,
        driver_licence: values.driver_licence,
      };
      (isUpdateModal
        ? updateUser({
            id: userId,
            formedUser: dataFormattingForUpdateUser(info),
          })
        : createUser(dataFormattingForCreateUser(info))
      ).then((data) => {
        console.log("DATA", data);
      }),
        setIsShow(false);
      resetForm();
    },
  });

  const {
    passportPhotoInformationLoad,
    passportPhotoWithUserLoad,
    passportPhotoRegistrationLoad,
    licencePhotoLoad,
    licencePhotoOtherSideLoad,
  } = useUserLoad(
    (passport) => formik.setFieldValue("passport", passport),
    formik.values.passport,
    (driver_licence) => formik.setFieldValue("driver_licence", driver_licence),
    formik.values.driver_licence,
    (src) => formik.setFieldValue("src", src),
    formik.values.src
  );

  useEffect(() => {
    (async () => {
      if (isUpdateModal && !isUserFetched.current) {
        try {
          const userInfo = await getInfo(String(userId));
          isUserFetched.current = true;
          const userData = userInfo?.data.data;
          const user = {
            email: userData.email,
            password: "",
            name: userData.name,
            surname: userData.surname,
            patronymic: userData.patronymic,
            birthdate: userData.birthdate
              ? formatDate(
                  userData.birthdate.split(".")[1] +
                    "." +
                    userData.birthdate.split(".")[0] +
                    "." +
                    userData.birthdate.split(".")[2],
                  "yyyy-LL-dd"
                )
              : "",
            phone: userData.phone,
          };
          console.log("user data", user);
          const roles = [
            {
              id: rolesWithId.data.find(
                (role) => role.name === userData.roles[0]?.name
              ).id,
            },
          ];
          const passport = {
            series_number: userData?.passport?.series_number
              ? userData.passport.series_number
              : "",
            isset: userData?.passport?.isset ? userData.passport.isset : "",
            reg_address: userData?.passport?.reg_address
              ? userData.passport.reg_address
              : "",
            photo_information: userData?.passport?.photo_information
              ? userData.passport.photo_information
              : "",
            photo_with_user: userData?.passport?.photo_with_user
              ? userData.passport.photo_with_user
              : "",
            photo_registration: userData?.passport?.photo_registration
              ? userData.passport.photo_registration
              : "",
          };
          const driver_licence = {
            photo: userData?.driver_licence?.photo
              ? userData.driver_licence.photo
              : "",
            photo_otherside: userData?.driver_licence?.photo_otherside
              ? userData.driver_licence.photo_otherside
              : "",
          };
          const src = {
            passportPhotoInformation: userData?.passport?.photo_information
              ? userData.passport.photo_information
              : "",
            passportPhotoWithUser: userData?.passport?.photo_with_user
              ? userData.passport.photo_with_user
              : "",
            passportPhototRegistration: userData?.passport?.photo_registration
              ? userData.passport.photo_registration
              : "",
            licencePhoto: userData?.driver_licence?.photo
              ? userData.driver_licence.photo
              : "",
            licencePhotoOtherside: userData?.driver_licence?.photo_otherside
              ? userData.driver_licence.photo_otherside
              : "",
          };
          formik.setValues({ user, roles, passport, driver_licence, src });
        } catch (e) {
          console.error(e);
        }
      }
    })();
    return () => {};
  }, [formik, isUpdateModal, userId]);

  const renderFormItems = (formGroupName, fieldsInfo) => {
    const photo_key = [
      "photo_otherside",
      "photo_registration",
      "photo_information",
      "photo",
      "photo_with_user",
    ];
    return (
      <>
        {Object.keys(formik.values[formGroupName]).map((formItemKey, i) => {
          const isTypeFile = fieldsInfo[i].type === "file";
          if (isTypeFile && isUpdateModal) return <></>;
          return (
            <FormGroup
              key={formItemKey + i}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Label for={formGroupName + "." + formItemKey}>
                {fieldsInfo[i].title}
              </Label>
              {!photo_key.includes(formItemKey) ? (
                <Input
                  id={formGroupName + "." + formItemKey}
                  name={formGroupName + "." + formItemKey}
                  type={fieldsInfo[i].type}
                  key={formGroupName + "." + formItemKey}
                  value={formik.values[formGroupName]?.[formItemKey]}
                  invalid={!!formik.errors[formGroupName]?.[formItemKey]}
                  onChange={
                    isTypeFile ? fieldsInfo[i].fileLoader : formik.handleChange
                  }
                />
              ) : (
                <Input
                  id={formGroupName + "." + formItemKey}
                  name={formGroupName + "." + formItemKey}
                  type={fieldsInfo[i].type}
                  key={formGroupName + "." + formItemKey}
                  invalid={!!formik.errors[formGroupName]?.[formItemKey]}
                  onChange={
                    isTypeFile ? fieldsInfo[i].fileLoader : formik.handleChange
                  }
                />
              )}

              <FormText color="danger">
                {formik.errors[formGroupName]?.[formItemKey]}
              </FormText>

              {isTypeFile && fieldsInfo[i].image && (
                <img
                  src={fieldsInfo[i].image}
                  width={150}
                  className="file-img"
                  alt="Предварительный просмотр фото"
                />
              )}
            </FormGroup>
          );
        })}
      </>
    );
  };

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        {`${isUpdateModal ? "Обновить" : "Создать"}`} пользователя
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          {Object.keys(formik.values.user).map((userKey, i) => {
            const fields = [
              { title: "Почта", type: "text" },
              { title: "Пароль", type: "password" },
              { title: "Имя", type: "text" },
              { title: "Фамилия", type: "text" },
              { title: "Отчество", type: "text" },
              { title: "Дата рождения", type: "date" },
              { title: "Номер телефона", type: "tel" },
            ];
            const isTypeTel = fields[i].type === "tel";
            const ChosenInput = isTypeTel ? PhoneInput : Input;
            const chosenOnChangeHandler = isTypeTel
              ? (value) =>
                  formik.setFieldValue(`user.${userKey}`, formatPhone(value))
              : formik.handleChange;
            return (
              <FormGroup key={userKey + i}>
                <Label for={`user.${userKey}`}>{fields[i].title}</Label>
                <ChosenInput
                  id={`user.${userKey}`}
                  name={`user.${userKey}`}
                  type={fields[i].type}
                  key={userKey + i}
                  min={fields[i].type == "date" ? "1900 - 01 - 01" : undefined}
                  max={
                    fields[i].type == "date"
                      ? new Date().toISOString().split("T")[0]
                      : undefined
                  }
                  invalid={!!formik.errors.user?.[userKey]}
                  value={
                    formik?.values?.user[userKey]
                      ? formik.values.user[userKey]
                      : ""
                  }
                  onChange={chosenOnChangeHandler}
                />
                <FormText color="danger">
                  {formik.errors.user?.[userKey]}
                </FormText>
              </FormGroup>
            );
          })}
          <FormGroup>
            <Label for="roles">Роли</Label>
            <Input
              id="roles"
              name="roles"
              type="select"
              invalid={!!formik.errors.roles}
              value={formik.values.roles[0]?.id}
              onChange={(e) =>
                formik.setFieldValue("roles", [{ id: e.target.value }])
              }
            >
              {rolesWithId?.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
              {/* <option value={'operator'}>Operator</option> */}
            </Input>
            <FormText color="danger">{formik.errors.roles}</FormText>
          </FormGroup>

          {renderFormItems("passport", [
            { title: "Серия и номер паспорта", type: "text" },
            { title: "Дата выдачи паспорта", type: "date" },
            { title: "Адрес регистрации", type: "text" },
            {
              title: "Фотография паспорта",
              type: "file",
              fileLoader: passportPhotoInformationLoad,
              image: formik.values.src.passportPhotoInformation,
            },
            {
              title: "Селфи с паспортом",
              type: "file",
              fileLoader: passportPhotoWithUserLoad,
              image: formik.values.src.passportPhotoWithUser,
            },
            {
              title: "Фотография регистрации паспорта",
              type: "file",
              fileLoader: passportPhotoRegistrationLoad,
              image: formik.values.src.passportPhototRegistration,
            },
          ])}

          {renderFormItems("driver_licence", [
            {
              title: "Фотография водительских прав",
              type: "file",
              fileLoader: licencePhotoLoad,
              image: formik.values.src.licencePhoto,
            },
            {
              title: "Фотография водительских прав с другой стороны",
              type: "file",
              fileLoader: licencePhotoOtherSideLoad,
              image: formik.values.src.licencePhotoOtherside,
            },
          ])}
        </ModalBody>
        <ModalFooter>
          <button type="submit" color="primary" className="button">
            {isUpdateModal ? "Обновить" : "Создать"}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UserModal;
