import React, { Fragment } from "react";
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import carmark from "assets/images/cars/carmark.svg";
import path from "path";

const CarMonitoring = ({ carData }) => {
  const mapData = {
    center: [carData.lat, carData.long],
    zoom: 14,
  };
  console.log("CAR monitoring", [carData.lat, carData.long, carmark]);
  return (
    <Fragment>
      <YMaps query={{ lang: "ru_RU", load: "package.full" }}>
        <Map
          defaultState={mapData}
          modules={["control.ZoomControl", "control.FullscreenControl"]}
          width={"100%"}
          height={790}
        >
          <Clusterer
            options={{
              preset: "islands#invertedVioletClusterIcons",
              groupByCoordinates: false,
              balloonPanelMaxMapArea: Infinity,
            }}
          >
            <Placemark
              //   modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              geometry={[carData.lat, carData.long]}
              properties={{
                item: carData.id,
              }}
              options={{
                iconLayout: "default#imageWithContent",
                // Своё изображение иконки метки.
                iconImageHref: "/assets/carmark.svg",
                // Размеры метки.
                iconImageSize: [100, 70],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-30, 0],
                // balloonPanelMaxMapArea: Infinity,
              }}
            />
          </Clusterer>
        </Map>
      </YMaps>
    </Fragment>
  );
};

export default CarMonitoring;
