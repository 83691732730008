const reasons = [
  {
    id: 1,
    label: "Не прошли проверку",
  },
  {
    id: 2,
    label: "Не прикреплены подтверждающие документы",
  },
  {
    id: 3,
    label: "Фото не соответствуют заявленным требованиям",
  },
];

export default reasons;
