import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import React, { Fragment } from "react";
import { IMaskInput } from "react-imask";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { tourSchema } from "utils/validationShemas";

const CreateTourModal = ({
  isShow,
  setIsShow,
  regions,
  categories,
  type = "create",
  onSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      distance: "",
      category: "",
      region: "",
      points: [{ name: "", description: "", latitude: "", longitude: "" }],
    },
    validationSchema: tourSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values);
      setIsShow(false);
      resetForm();
    },
  });

  return (
    <Modal
      toggle={() => {
        setIsShow(!isShow);
        formik.resetForm();
      }}
      isOpen={isShow}
    >
      <ModalHeader
        toggle={() => {
          setIsShow(!isShow);
          formik.resetForm();
        }}
      >
        <p className="h3">Создайте тур</p>
      </ModalHeader>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name">Название тура</Label>
              <Input
                id="name"
                name="name"
                type="text"
                invalid={!!formik.errors.name}
                onChange={formik.handleChange}
              />
              <FormText color="danger">{formik.errors.name}</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="distance">Расчётное расстояние</Label>
              <Input
                id="distance"
                name="distance"
                type="number"
                invalid={!!formik.errors.distance}
                onChange={(e) =>
                  formik.setFieldValue("distance", +e.target.value)
                }
              />
              <FormText color="danger">{formik.errors.distance}</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="region">Выберите регион</Label>
              <Input
                type="select"
                id="region"
                onChange={(e) =>
                  formik.setFieldValue("region", +e.target.value)
                }
                invalid={!!formik.errors.region}
              >
                <option>Выбрать регион</option>
                {regions?.data.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </Input>
              <FormText color="danger">{formik.errors.region}</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="category">Выберите категорию</Label>
              <Input
                type="select"
                id="category"
                onChange={(e) =>
                  formik.setFieldValue("category", +e.target.value)
                }
                invalid={!!formik.errors.category}
              >
                <option>Выбрать регион</option>
                {categories?.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Input>
              <FormText color="danger">{formik.errors.category}</FormText>
            </FormGroup>

            <FieldArray
              name="points"
              render={(arrayHelpers) => (
                <>
                  {formik.values.points.map((_, index) => (
                    <Fragment key={index}>
                      <h4 className="d-flex justify-content-between mt-5 mb-3">
                        Точка №{index + 1}
                        {formik.values.points.length > 1 && (
                          <Button
                            className="btn-danger"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Убрать точку
                          </Button>
                        )}
                      </h4>

                      <Field name={`points.${index}.name`}>
                        {({ field, meta }) => (
                          <FormGroup>
                            <Label for={`points.${index}.name`}>
                              Название точки
                            </Label>
                            <Input
                              id={`points.${index}.name`}
                              invalid={!!meta.error}
                              {...field}
                            />
                            <FormText color="danger">{meta.error}</FormText>
                          </FormGroup>
                        )}
                      </Field>

                      <Field name={`points.${index}.description`}>
                        {({ field, meta }) => (
                          <FormGroup>
                            <Label for={`points.${index}.description`}>
                              Описание
                            </Label>
                            <Input
                              id={`points.${index}.description`}
                              type="textarea"
                              invalid={!!meta.error}
                              {...field}
                            />
                            <FormText color="danger">{meta.error}</FormText>
                          </FormGroup>
                        )}
                      </Field>

                      <Field name={`points.${index}.latitude`}>
                        {({ field, meta }) => (
                          <FormGroup>
                            <Label for={`points.${index}.latitude`}>
                              Широта
                            </Label>
                            <Input
                              {...field}
                              id={`points.${index}.latitude`}
                              invalid={!!meta.error}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `points.${index}.latitude`,
                                  e.target.value
                                )
                              }
                              tag={LatitudeMaskedInput}
                              placeholder="55.555555"
                            />
                            <FormText color="danger">{meta.error}</FormText>
                          </FormGroup>
                        )}
                      </Field>

                      <Field name={`points.${index}.longitude`}>
                        {({ field, meta }) => (
                          <FormGroup>
                            <Label for={`points.${index}.longitude`}>
                              Долгота
                            </Label>
                            <Input
                              {...field}
                              id={`points.${index}.longitude`}
                              invalid={!!meta.error}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `points.${index}.longitude`,
                                  e.target.value
                                )
                              }
                              tag={LatitudeMaskedInput}
                              placeholder="55.555555"
                            />
                            <FormText color="danger">{meta.error}</FormText>
                          </FormGroup>
                        )}
                      </Field>
                    </Fragment>
                  ))}

                  <Button
                    color="primary"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        description: "",
                        latitude: "",
                        longitude: "",
                      })
                    }
                  >
                    Добавить точку
                  </Button>
                </>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={formik.validateForm}
            >
              {isLoading ? "Загрузка..." : "Создать"}
            </Button>
          </ModalFooter>
        </form>
      </FormikProvider>
    </Modal>
  );
};

const LatitudeMaskedInput = (props) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00.000000"
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
};

export default CreateTourModal;
