import React from 'react';
import { IModificationCharacteristics } from 'types/CarTypes';

interface CarModificationCharacteristicsProps {
  characteristics: IModificationCharacteristics[];
}

const CarModificationCharacteristics = ({ characteristics }: CarModificationCharacteristicsProps) => {
  return (
    <>
      <h3 className="mb-2 mt-5">Характеристики модификации</h3>

      <table className="table dataTable no-footer">
        <thead>
          <tr>
            <th>Характеристика</th>
            <th>Значение</th>
          </tr>
        </thead>
        <tbody>
          {characteristics.map((characteristic, i) => (
            <tr key={characteristic.id} style={{ backgroundColor: i % 2 ? 'none' : '#fff' }}>
              <td>{characteristic.name}</td>
              <td>
                {characteristic.value} {characteristic.unit}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CarModificationCharacteristics;
