import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap";
import { IInfo } from "../../types/AdminTypes";

import { adminAPI } from "./../../services/adminService";
import { check } from "prettier";
import user1 from "../../assets/images/users/user-default.jpeg";

const UserProfile = (props: any) => {
  const [userInfo, setUserInfo] = useState<IInfo>({});
  const [roles, setRoles] = useState("");
  const [inputImage, setInputImage] = useState<any>("");
  const [showButtonSend, setShowButtonSend] = useState(false);
  const [security, setSecurity] = useState(true);
  const [password, setPassword] = useState("");

  const [userImage, setUserImage] = useState("");

  const [changeAdmin, { data: changeAdminData }] =
    adminAPI.useChangeAdminMutation();
  const [checkDataUser] = adminAPI.useLazyCheckDataUserQuery();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const obj = JSON.parse(localStorage.getItem("user") || "");
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setUserInfo(obj);
        setUserImage(obj.avatar);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setUserInfo(obj);
        setUserImage(obj.avatar);
      }
    }
    if (localStorage.getItem("roles")) {
      const obj = JSON.parse(localStorage.getItem("roles") || "");
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setRoles(obj);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setRoles(obj);
      }
    }
  }, [props.success]);

  const handleSubmitAvatar = (e: any) => {
    e.preventDefault();

    if (inputImage[0]) {
      if (!inputImage[0].type.match("image")) {
        return;
      }
      const formData = new FormData();
      formData.append("avatar", inputImage[0]);
      changeAdmin(formData).then((value: any) => {
        if (value.data.result === "success") {
          checkDataUser().then((value: any) => {
            localStorage.setItem("user", JSON.stringify(value.data.user));
          });
        }
      });

      setShowButtonSend(false);
    }
  };

  const handleSubmitPassword = (e: any) => {
    e.preventDefault();
    if (password !== "") {
      changeAdmin({ password: password });
      setPassword("");
    }
  };

  const handleChange = (e: any) => {
    setInputImage(e.target.files);
    if (e.target.files[0] !== null) {
      setShowButtonSend(true);
      setUserImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSecurity = () => {
    setSecurity(!security);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3 position-relative d-flex flex-column align-items-center">
                      {userImage ? (
                        <img
                          src={userImage}
                          alt="avatar"
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      ) : (
                        <img
                          src={user1}
                          alt="avatar"
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      )}

                      <form onSubmit={(e) => handleSubmitAvatar(e)}>
                        <Label className="position-absolute top-0 end-0">
                          <i
                            className="ti-pencil"
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            className="d-none"
                            type="file"
                            accept="image/jpeg,image/png"
                            onChange={(e) => handleChange(e)}
                          />
                        </Label>
                        <button
                          type="submit"
                          className={
                            !showButtonSend
                              ? "invisible"
                              : "btn btn-success mt-2"
                          }
                        >
                          Сохранить
                        </button>
                      </form>
                    </div>
                    <div className="align-self-center flex-1 ms-3">
                      <div className="text-muted">
                        <h5>{userInfo.initials}</h5>
                        <p className="mb-1">{userInfo.email}</p>
                        <p className="mb-0">Id: {userInfo.id}</p>
                        <p className="mb-0">Роли: {roles}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <form onSubmit={(e) => handleSubmitPassword(e)} className="mb-5">
            <div className="card">
              <div className="card-header">Изменение пароля</div>
              <div className="card-body">
                <div className="filter-cars">
                  <Label className="custom-select position-relative">
                    <i
                      className="ti-eye position-absolute"
                      style={{ cursor: "pointer", top: "30%", left: "85%" }}
                      onClick={handleSecurity}
                    />
                    <Input
                      type={security ? "password" : "text"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-100"
                      placeholder="Введите новый пароль"
                    />
                  </Label>
                  <div className="custom-select">
                    <button type="submit" className="btn btn-success">
                      Изменить пароль
                    </button>
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
