import React, { useEffect } from "react";
import { useFormik, FormikProvider, Field, FieldProps } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { carAPI } from "services";
import { IServiceDevices } from "../../../../types/CarTypes";

import { createServiceCarSchema } from "common/validationSchemas";
// // import CarModificationCharacteristics from './CarModificationCharacteristics';
import PaginationTable from "components/Common/PaginationTable";
import { formatServiceCarDataForTable } from "common/dataFormatters";

const CreateServiceCar = () => {
  const [createServiceCar, { isLoading: createServiceCarLoading }] =
    carAPI.useCreateServiceCarMutation();
  const [getServiceCar, { data: serviceCar, isLoading: serviceCarLoading }] =
    carAPI.useLazyGetServiceCarQuery();
  const [deleteServiceCar, { isLoading: deleteServiceCarLoading }] =
    carAPI.useDeleteServiceCarMutation();
  const [getServiceDevices, { data: serviceDevices }] =
    carAPI.useLazyGetServiceDevicesQuery();

  const formik = useFormik({
    initialValues: {
      name: "",
      type: 1,
      imei: "",
    },
    validationSchema: createServiceCarSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await createServiceCar({
        name: values.name,
        type: values.type,
        device_id: values.imei,
      });
      resetForm();
    },
  });

  useEffect(() => {
    getServiceCar(+formik.values.type);
  }, [formik.values.type]);

  useEffect(() => {
    getServiceDevices(+formik.values.imei);
  }, [formik.values.imei]);

  const getAlias = (device_id: string) => {
    let result = serviceDevices?.data.filter((item) => {
      return item.device_id == device_id;
    });
    return result && result[0]?.alias;
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Создание сервисного авто</h2>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="mb-5">
            <div className="card">
              <div className="card-header">Сервисное авто</div>
              <div className="card-body">
                <div className="filter-cars">
                  <div className="custom-select">
                    <FormGroup className="mb-3">
                      <Label for="imei">Imei</Label>
                      <Input
                        id="imei"
                        name="imei"
                        type="select"
                        value={formik.values.imei}
                        onChange={(e) => {
                          const imei = e.target.value;
                          formik.setFieldValue("imei", imei);
                          imei && getServiceDevices(+imei);
                          const alias = getAlias(imei) ? getAlias(imei) : "";
                          formik.setFieldValue("name", alias);
                          // formik.setFieldValue('type', 3);
                        }}
                        invalid={!!formik.errors.imei}
                      >
                        <option value="">Выберите imei</option>
                        {serviceDevices?.data.map((item, index) => (
                          <option key={index} value={item.device_id}>
                            {item.device_id}
                          </option>
                        ))}
                      </Input>
                      <FormText color="danger">{formik.errors.imei}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select">
                    <FormGroup name="type">
                      <Label for="type">Тип</Label>
                      <Input
                        id="type"
                        type="select"
                        name="type"
                        value={formik.values.type}
                        onChange={(e) => {
                          const type = e.target.value;
                          formik.setFieldValue("type", +type);
                        }}
                        invalid={!!formik.errors.type}
                      >
                        <option value="1">Эвакуатор</option>
                        <option value="2">Служба заботы</option>
                        <option value="3">Выездной шиномонтаж</option>
                        <option value="5">Комиссар</option>
                      </Input>
                      <FormText color="danger">{formik.errors.type}</FormText>
                    </FormGroup>
                  </div>
                  <div className="custom-select name-custom-select">
                    <FormGroup className="mb-3">
                      <Label for="name">Название</Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Введите название"
                        value={formik.values.name}
                        onChange={(e) => {
                          const name = e.target.value;
                          formik.setFieldValue("name", name);
                        }}
                        invalid={!!formik.errors.name}
                      ></Input>
                      <FormText color="danger">{formik.errors.name}</FormText>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="btn btn-success">
                Сохранить сервисное авто
              </button>
            </div>
          </form>
        </FormikProvider>

        <PaginationTable
          title="Список сервисных авто"
          thTitles={["ID", "Название", "Тип", "Imei"]}
          tableData={formatServiceCarDataForTable(serviceCar?.data)}
          canDelete
          onDelete={deleteServiceCar}
          deletePending={deleteServiceCarLoading}
          loading={serviceCarLoading}
        />
      </div>
    </div>
  );
};

export default CreateServiceCar;
