import { useFormik } from "formik";
import React from "react";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useParams } from "react-router";
import { rentCarAPI } from "services";
import { carFinanceSchema } from "../../../../../../utils/validationShemas";

const formFields = [
  "Цена",
  // 'Цена за три года',
  "Уменьшение цены до 2х месяцев",
  "Уменьшение цены после 2х месяцев",
  // 'Цена от 1 дня до 3',
  // 'Цена от 4 дней до 10',
  // 'Цена от 11 дней до 30',
  "Цена страховки за первый год",
  "Цена страховки за второй год",
  "Цена страховки за третий год",
  "Цена шиномонтажа",
  "Цена ТО за 3 года",
];

const EditFinanceModal = ({ isShow, setShow, finances, refetchCar }) => {
  const { price_list, price, discount_after, discount_before, rentalId } =
    finances;
  const { id: carId } = useParams();

  const [createRentRate] = rentCarAPI.useCreateRentRateMutation();
  const [updateRentRate] = rentCarAPI.useUpdateRentRateMutation();
  const [updateCarFinancies, { isLoading }] =
    rentCarAPI.useUpdateCarFinanciesMutation();

  React.useEffect(() => {
    console.log("ФИНАНСЫ ИЗМЕНЕНЫ");
  }, [finances]);

  const formik = useFormik({
    initialValues: {
      cost: price || "",
      discountBefore: discount_before || "",
      discountAfter: discount_after || "",
      insuranceFirstYear: price_list.insurance_first_year || "",
      insuranceSecondYear: price_list.insurance_second_year || "",
      insuranceThirdYear: price_list.insurance_third_year || "",
      tireWorks: price_list.tire_works || "",
      technicalInspectionThreeYears:
        price_list.technical_inspection_three_years || "",
    },
    validationSchema: carFinanceSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const copyPriceList = {};
        Object.assign(copyPriceList, price_list);

        console.log(carId);
        copyPriceList.insurance_first_year = values.insuranceFirstYear;
        copyPriceList.insurance_second_year = values.insuranceSecondYear;
        copyPriceList.insurance_third_year = values.insuranceThirdYear;
        copyPriceList.technical_inspection_three_years =
          values.technicalInspectionThreeYears;
        copyPriceList.tire_works = values.tireWorks;
        copyPriceList.cost = values.cost;
        if (!rentalId) {
          createRentRate({
            carId: +carId,
            price: values.cost,
            discount_before: values.discountBefore,
            discount_after: values.discountAfter,
          });
        } else {
          updateRentRate({
            id: rentalId,
            carId: Number(carId),
            price: values.cost,
            discount_before: values.discountBefore,
            discount_after: values.discountAfter,
          });
        }
        updateCarFinancies({
          id: +carId,
          body: {
            price_list: {
              cost: copyPriceList.cost,
              insurance_first_year: copyPriceList.insurance_first_year,
              insurance_second_year: copyPriceList.insurance_second_year,
              insurance_third_year: copyPriceList.insurance_third_year,
              technical_inspection_three_years:
                copyPriceList.technical_inspection_three_years,
              tire_works: copyPriceList.tire_works,
            },
          },
        });
        await refetchCar();
        resetForm();
        setShow(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleModalClose = () => {
    setShow(!isShow);
    formik.resetForm();
  };

  return (
    <Modal toggle={handleModalClose} isOpen={isShow}>
      <ModalHeader toggle={handleModalClose}>
        Изменить финасовые данные
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          {Object.keys(formik.values).map((key, i) => {
            return (
              <FormGroup key={i}>
                <Label for={key}>{formFields[i]}</Label>
                <Input
                  id={key}
                  name={key}
                  type="number"
                  invalid={!!formik.errors[key]}
                  value={formik.values[key]}
                  onChange={formik.handleChange}
                />
                <FormText color="danger">{formik.errors[key]}</FormText>
              </FormGroup>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading ? "Загрузка..." : "Изменить"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditFinanceModal;
