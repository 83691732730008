import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { YMaps, Map } from "react-yandex-maps";

import { rentTourAPI } from "services";

const TourPage = () => {
  const { id } = useParams();
  const { data: tour, isLoading: getTourLoading } =
    rentTourAPI.useGetTourQuery(id);
  const map = useRef(null);
  const mapState = {
    center: [55.739625, 37.5412],
    zoom: 12,
  };

  const addRoute = useCallback(
    (ymaps) => {
      const points = tour?.data.points.map((point) => [
        point.latitude,
        point.longitude,
      ]);
      const multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: points,
          params: {
            routingMode: "car",
          },
        },
        {
          boundsAutoApply: true,
        }
      );

      map.current.geoObjects.add(multiRoute);
    },
    [tour]
  );

  return (
    <>
      <div className="card">
        <h2 className="card-header h2">Страница тура "{tour?.data.name}"</h2>
        <div className="card-body">
          <div className="my-2">
            <strong>Название: </strong>
            <span>{tour?.data?.name}</span>
          </div>
          <div className="my-2">
            <strong>Расчётное расстояние: </strong>
            <span>{tour?.data?.distance}</span>
          </div>
          <div className="my-2">
            <strong>Регион: </strong>
            <span>{tour?.data?.region?.name}</span>
          </div>
          <div className="my-2">
            <strong>Категория: </strong>
            <span>{tour?.data?.category?.name}</span>
          </div>
          <div className="my-2">
            <strong>Точки: </strong>
            {tour?.data?.points.length ? (
              <table className="table mt-3">
                <thead>
                  <tr>
                    {["Номер", "Название", "Описание", "Долгота", "Широта"].map(
                      (title, i) => (
                        <th scope="col" key={i}>
                          {title}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {tour?.data.points?.map((point, i) => (
                    <tr key={point.id}>
                      <th>{i + 1}</th>
                      <td>{point.name}</td>
                      <td>{point.description}</td>
                      <td>{point.longitude}</td>
                      <td>{point.latitude}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <span>Точек нет</span>
            )}
          </div>
        </div>
      </div>

      <div className="card">
        <h2 className="card-header h2 pb-2">Маршрут тура</h2>
        <div className="card-body">
          <YMaps query={{ apikey: "717f7f8f-93c5-4bd5-8f97-4b33a4479745" }}>
            <Map
              modules={["multiRouter.MultiRoute"]}
              state={mapState}
              instanceRef={map}
              onLoad={addRoute}
              width="100%"
              height={790}
            ></Map>
          </YMaps>
        </div>
      </div>
    </>
  );
};

export default TourPage;
