import React from "react";
import "./CarFinanceGeneral.scss";

const CarFinanceCurrentInfo = ({ statistic }) => {
  return (
    <div>
      <h2 className="label">Текущие показатели</h2>
      <h5 className="text">
        Количество дней в аренде за предыдущий месяц:{" "}
        {statistic.count_days_in_previous_month}
      </h5>
      <h5 className="text">
        Количество дней в аренде за текущий месяц:{" "}
        {statistic.count_days_in_this_month}
      </h5>
      <h5 className="text">
        Сумма оплат аренд за предыдущий месяц:{" "}
        {statistic.rent_sum_in_previous_month}
      </h5>
      <h5 className="text">
        Сумма оплат аренд за текущий месяц месяц:{" "}
        {statistic.rent_sum_in_this_month}
      </h5>
      <h5 className="text">
        Разница с предыдущим месяцом:{" "}
        <span style={{ color: `${statistic.differece > 0 ? "red" : "green"}` }}>
          {statistic.differece}
        </span>
      </h5>
      <h5 className="text">
        Окупаемость за текущий месяц:{" "}
        <span style={{ color: `${statistic.payback > 0 ? "red" : "green"}` }}>
          {statistic.payback}
        </span>
      </h5>
      <h5 className="text">
        Количество дней до окупаемости: {statistic.days_before_payback}
      </h5>
    </div>
  );
};

export default CarFinanceCurrentInfo;
