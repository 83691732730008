import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";

export const rentClientAPI = createApi({
  reducerPath: "rentClientAPI",
  tagTypes: ["clients", "stages"],
  baseQuery,
  endpoints: (build) => ({
    createClient: build.mutation<{ data: any }, any>({
      query: (client) => ({
        url: `/crm/leads`,
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["clients"],
    }),
    updateClient: build.mutation<{ data: any }, any>({
      query: ({ id, client }) => ({
        url: `/crm/leads/${id}`,
        method: "PUT",
        body: client,
      }),
      invalidatesTags: ["clients"],
    }),
    getCliens: build.query<{ data: any }, void>({
      query: () => ({
        url: `/crm/leads`,
        method: "GET",
      }),
      providesTags: () => ["clients"],
    }),
    getStages: build.query<{ data: any }, void>({
      query: () => ({
        url: `/crm/leads/stage`,
        method: "GET",
      }),
      providesTags: () => ["stages"],
    }),
    createStatus: build.mutation<{ data: any }, any>({
      query: (status) => ({
        url: `/crm/leads/status`,
        method: "POST",
        body: status,
      }),
      invalidatesTags: ["stages"],
    }),
    createStage: build.mutation<{ data: any }, any>({
      query: (stage) => ({
        url: `/crm/leads/stage`,
        method: "POST",
        body: stage,
      }),
      invalidatesTags: ["stages"],
    }),
  }),
});
