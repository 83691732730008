import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { stageSchema } from "utils/validationShemas";

const CreateStageModal = ({ isShow, setIsShow, onSumbit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: stageSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await onSumbit(values);
      toast.success("Стадия успешно создана");
      setIsShow(false);
      resetForm();
    },
  });

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        Создать стадию
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="name">Название стадии</Label>
            <Input
              id="name"
              name="name"
              type="text"
              invalid={!!formik.errors.name}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.name}</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading ? "Загрузка..." : "Создать"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateStageModal;
