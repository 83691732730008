import React, { useEffect, useState } from "react";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./CreateCarModal.scss";

import StepBrands from "./components/StepBrands";
import StepMainInfo from "./components/StepMainInfo";
import { rentCarAPI } from "services";
import { toast } from "react-toastify";
import { accessAdminRoutes, checkRole } from "utils/accessToRoles";

const CreateCarModal = ({ isShow, setIsShow, yearsArr }) => {
  const [createCar, { isSuccess, isError }] = rentCarAPI.useCreateCarMutation();
  const [message, setMessage] = useState(null);
  const isAdmin = checkRole(accessAdminRoutes);
  const required = [
    "city_id",
    "year",
    "vin",
    "sts",
    "registration_number",
    "imei",
    "color",
    "id_car_modification",
  ];

  const [step, setStep] = useState(1);
  const initialCar = {
    model_id: "",
    id_car_generation: "",
    id_car_serie: "",
    id_car_modification: "",
    id_car_equipment: "",
    brand_id: "",
    year: 2022,
    photo: null,
    photos: null,
    flex: 0,
    inPool: 1,
    vin: "",
    sts: "",
    registration_number: "",
    imei: "",
    city_id: "",
    color: "",
    one_three_days_cost: 0,
    four_ten_days_cost: 0,
    eleven_thirty_days_cost: 0,
  };
  const [car, setCar] = useState(initialCar);
  const [priceList, setPriceList] = useState({
    insurance_first_year: 0,
    insurance_second_year: 0,
    insurance_third_year: 0,
    tire_work: 0,
    technical_inspection_three_years: 0,
    cost: 0,
  });

  React.useEffect(() => {
    let state = false;
    for (let key in car) {
      if (key !== "photos" && car[key] === "" && required.includes(key)) {
        state = true;
      }
    }
    setMessage(state ? "Заполнены не все обязательные поля" : null);
  }, [car]);

  React.useEffect(() => {
    if (isSuccess || isError) {
      isSuccess && toast.success("Машина создана");
      setIsShow(false);
    }
  }, [isSuccess, isError]);

  const renderedComponent = () => {
    switch (step) {
      case 1:
        return <StepBrands car={car} setCar={setCar} />;

      case 2:
        return (
          <StepMainInfo
            car={car}
            setCar={setCar}
            yearsArr={yearsArr}
            priceList={priceList}
            setPriceList={setPriceList}
            isAdmin={isAdmin}
          />
        );
      default:
        return <StepBrands car={car} setCar={setCar} step={step} />;
    }
  };

  const createFormData = (carData, priceList) => {
    const formedCar = new FormData();

    for (let key in carData) {
      if (
        carData[key] &&
        carData[key] !== "" &&
        carData[key] !== 0 &&
        key !== "photos"
      ) {
        formedCar.append(key, carData[key]);
      }
      if (key === "photos") {
        car[key].map((item, index) => {
          formedCar.append(`photos[${index}]`, item.file ? item.file : item);
        });
      }
    }
    // if (carData.photos) {
    //   carData.photos.forEach((photo) => {
    //     formedCar.append("photos[]", photo);
    //   });
    // }

    for (let key in priceList) {
      if (priceList[key] && priceList !== 0) {
        formedCar.append(`price_list[${key}]`, priceList[key]);
      }
    }

    return formedCar;
  };
  const onClickCreateCar = () => {
    const reg_vin = /^[A-Za-z0-9]+$/;
    const reg_sts = /^([0-9]|[А-Я]|[а-я]){4}[0-9]{6}$/;
    const reg_number = /^([А-Я]|[0-9])+$/;
    const reg_color = /^([А-Я]|[а-я])|[-]+$/;

    if (car.vin.length < 16 || car.vin.length > 17 || !reg_vin.test(car.vin)) {
      setMessage("Некорректный VIN");
    } else if (car.sts.length !== 10 || !reg_sts.test(car.sts)) {
      setMessage("Некорректный СТС");
    } else if (car.imei.length > 30) {
      setMessage("Превыше количество символов в поле imei");
    } else if (car.registration_number.length > 30) {
      setMessage("Превыше количество символов в поле 'Регистрационный номер'");
    } else if (!reg_color.test(car.color) || car.color.length > 30) {
      setMessage("Некорректный цвет");
    } else if (
      !reg_number.test(car.registration_number) ||
      car.registration_number.length < 8 ||
      car.registration_number.length > 9
    ) {
      setMessage("Некорректный регистрационный номер");
    } else {
      const info = createFormData(car, priceList);

      if (message == null) {
        createCar(info);
      }
    }
  };

  const closeModal = () => {
    setCar(initialCar);
  };

  return (
    <Modal
      toggle={() => setIsShow(!isShow)}
      isOpen={isShow}
      onClosed={closeModal}
    >
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        Создайте автомобиль
      </ModalHeader>
      <ModalBody>{renderedComponent()}</ModalBody>
      <div style={{ color: "red", paddingLeft: 20 }}>
        {step === 2 && message != null && message}
      </div>
      <ModalFooter>
        {step === 2 && (
          <Button color="danger" onClick={() => setStep(1)}>
            Назад
          </Button>
        )}
        {step === 1 && (
          <Button
            color="success"
            onClick={() => setStep(2)}
            disabled={
              car.model_id?.length === 0 ||
              car.id_car_generation?.length === 0 ||
              car.id_car_serie?.length === 0 ||
              car.id_car_modification?.length === 0 ||
              car.brand_id?.length === 0
            }
          >
            Далее
          </Button>
        )}

        {step === 2 && (
          <Button
            color="primary"
            onClick={onClickCreateCar}
            disabled={message !== null ? true : false}
          >
            Создать
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CreateCarModal;
