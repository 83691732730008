import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";

import rootReducer from "./reducers";
import rootSaga from "./sagas";
import {
  authAPI,
  carAPI,
  userAPI,
  careAPI,
  adminAPI,
  chatAPI,
  rentAPI,
  rentCarAPI,
  rentUserAPI,
  rentPaymentAPI,
  rentClientAPI,
  rentTourAPI,
  rentFineAPI,
  rentMessageAPI,
} from "services";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authAPI.middleware)
      .concat(carAPI.middleware)
      .concat(userAPI.middleware)
      .concat(careAPI.middleware)
      .concat(adminAPI.middleware)
      .concat(chatAPI.middleware)
      .concat(rentAPI.middleware)
      .concat(rentCarAPI.middleware)
      .concat(rentUserAPI.middleware)
      .concat(rentPaymentAPI.middleware)
      .concat(rentClientAPI.middleware)
      .concat(rentFineAPI.middleware)
      .concat(rentMessageAPI.middleware)
      .concat(rentTourAPI.middleware),
});

export default store;
