import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./settings";
import { IParamsMessage, IMessages } from "types/RentUserTypes";

export const rentMessageAPI = createApi({
  reducerPath: "rentMessageAPI",
  tagTypes: [],
  baseQuery,
  endpoints: (build) => ({
    getMessages: build.query<IMessages, IParamsMessage>({
      query: (params) => ({
        url: `/admins/registry/sms`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
  }),
});
