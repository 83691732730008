export const useUserLoad = (
  setPassport,
  passport,
  setDriverLicence,
  driver_licence,
  setSrc,
  src
) => {
  const passportPhotoInformationLoad = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPassport({ ...passport, photo_information: file });
        setSrc({ ...src, passportPhotoInformation: ev.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const passportPhotoWithUserLoad = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPassport({ ...passport, photo_with_user: file });
        setSrc({ ...src, passportPhotoWithUser: ev.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const passportPhotoRegistrationLoad = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPassport({ ...passport, photo_registration: file });
        setSrc({ ...src, passportPhototRegistration: ev.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const licencePhotoLoad = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        setDriverLicence({ ...driver_licence, photo: file });
        setSrc({ ...src, licencePhoto: ev.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const licencePhotoOtherSideLoad = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image")) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        setDriverLicence({ ...driver_licence, photo_otherside: file });
        setSrc({ ...src, licencePhotoOtherside: ev.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return {
    passportPhotoInformationLoad,
    passportPhotoWithUserLoad,
    passportPhotoRegistrationLoad,
    licencePhotoLoad,
    licencePhotoOtherSideLoad,
  };
};
