import { ICarConnectType } from "types/CarTypes";

export const CONNECT_TYPES: {
  value: ICarConnectType;
  name: string;
}[] = [
  {
    value: "starline",
    name: "Старлайн",
  },
  {
    value: "alyansconnect",
    name: "Альянс Connect",
  },
];
