import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
//menu
import { menuSection } from "utils/menuSections";
import { checkRole } from "utils/accessToRoles";

const SidebarContent = (props) => {
  const ref = useRef();
  const [activeSection, setActiveSection] = useState(null);
  const [activeSubsection, setActiveSubsection] = useState(null);

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
    };
    initMenu();
  }, []);

  useEffect(() => {
    menuSection.map((section) => {
      section.link === props.location.pathname && setActiveSection(section.id);
      section.children.map((it) => {
        if (it.link === props.location.pathname) {
          setActiveSection(section.id);
          setActiveSubsection(it.id);
        }
      });
    });
  }, [props.location.pathname]);

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className=" list-unstyled" id="side-menu">
            <li className="menu-title">Меню </li>
            {menuSection.map(
              (item) =>
                checkRole(item.allowedRoles) && (
                  <li
                    key={item.id}
                    className={`
              ${activeSection === item.id ? "mm-active" : ""} `}
                  >
                    <Link
                      to={item.link}
                      className={`${
                        item.children.length > 0 && "has-arrow"
                      }  waves-effect`}
                      onClick={() => {
                        if (item.children.length === 0) {
                          tToggle();
                        }
                      }}
                    >
                      <i className={item.icon}></i>
                      <span>{item.label}</span>
                    </Link>
                    {item.children.length > 0 && (
                      <ul
                        className={`${
                          activeSection === item.id && "mm-show"
                        }  sub-menu`}
                        aria-expanded="false"
                      >
                        {item.children.map((it) => (
                          <li
                            key={item.id + "" + it.id}
                            className={`${
                              activeSubsection === it.id && "mm-active"
                            }`}
                          >
                            <Link
                              to={it.link}
                              onClick={() => {
                                tToggle();
                              }}
                            >
                              <i className={it.icon}></i>
                              <span>{it.label}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
