import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";
import { IRentUsers, IDataRentUsers, IUpdateUser } from "types/RentUserTypes";

export const rentTourAPI = createApi({
  reducerPath: "rentTourAPI",
  tagTypes: ["tours", "tour", "regions", "categories"],
  baseQuery,
  endpoints: (build) => ({
    getTours: build.query<{ data: any }, void | any>({
      query: () => ({
        url: `/tours`,
        method: "GET",
      }),
      providesTags: () => ["tours"],
    }),
    getRegions: build.query<{ data: any }, void | any>({
      query: () => ({
        url: `/tours/region`,
        method: "GET",
      }),
      providesTags: () => ["regions"],
    }),
    getCategories: build.query<{ data: any }, void | any>({
      query: () => ({
        url: `/tours/category`,
        method: "GET",
      }),
      providesTags: () => ["categories"],
    }),
    getTour: build.query<{ data: any }, string>({
      query: (id) => ({
        url: `/tours/${id}`,
        method: "GET",
      }),
      providesTags: () => ["tour"],
    }),
    createTour: build.mutation<{ data: any }, any>({
      query: (tour) => ({
        url: `/tours/`,
        method: "POST",
        body: tour,
      }),
      invalidatesTags: ["tours"],
    }),
    createRegions: build.mutation<{ data: any }, any>({
      query: (region) => ({
        url: `/tours/region`,
        method: "POST",
        body: region,
      }),
      invalidatesTags: ["tours", "regions"],
    }),
    createCategory: build.mutation<{ data: any }, any>({
      query: (category) => ({
        url: `/tours/category`,
        method: "POST",
        body: category,
      }),
      invalidatesTags: ["tours", "categories"],
    }),
  }),
});
