import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Popover,
  Button,
  PopoverBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const Param = ({ id, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [roleName, setRoleName] = useState("driver");
  const history = useHistory();

  const changeFullnameHandler = (e) => {
    setFullName(e.target.value);
  };

  const changeRegistrationDateHandler = (e) => {
    setRegistrationDate(e.target.value);
  };

  const filterByFullName = () => {
    const [surname, name, patronymic] = fullName.split(" ");
    history.push(
      `/dashboard/users?${name && `name=${name}`}${
        surname && `&surname=${surname}`
      }`
    );
  };
  const filterByRegistrationDate = () => {
    const formatedRegistrationDate =
      moment(registrationDate).format("DD.MM.YYYY");
    history.push(`/dashboard/users?created_at=${formatedRegistrationDate}`);
  };

  const filterByRole = () => {
    history.push(
      `/dashboard/users?roles=${JSON.stringify([{ name: roleName }])}`
    );
  };

  return (
    <>
      <th scope="col" id={`Popover${id}`} onClick={() => setIsOpen(false)}>
        {title}
      </th>
      {/* {id === 1 && (
        <Popover
          isOpen={isOpen}
          target={"Popover1"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <input
              className="form-control"
              placeholder="Найти ФИО"
              value={fullName}
              onChange={changeFullnameHandler}
            />
            <div className="popover-footer">
              <Button size="100px" onClick={filterByFullName}>
                Применить
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 2 && (
        <Popover
          isOpen={isOpen}
          target={"Popover2"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input
              type="date"
              placeholder="time"
              value={registrationDate}
              onChange={changeRegistrationDateHandler}
            />
            <div className="popover-footer">
              <Button size="100px" onClick={filterByRegistrationDate}>
                Применить
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 3 && (
        <Popover
          isOpen={isOpen}
          target={"Popover3"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input
              type="date"
              placeholder="time"
              value={registrationDate}
              onChange={changeRegistrationDateHandler}
            />
            <div className="popover-footer">
              <Button size="100px" onClick={filterByRegistrationDate}>
                Применить
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      {id === 4 && (
        <Popover
          isOpen={isOpen}
          target={"Popover4"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input
              type="select"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            >
              {["driver", "manager", "operator", "owner"].map((i, key) => {
                return (
                  <option key={key} value={i}>
                    {i}
                  </option>
                );
              })}
            </Input>
            <div className="popover-footer">
              <Button size="100px" onClick={filterByRole}>
                Применить
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )} */}
      {/* {id === 5 && (
        <Popover
          isOpen={isOpen}
          target={"Popover5"}
          toggle={() => setIsOpen(true)}
          placement="left"
        >
          <PopoverBody>
            <Input
              type="select"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            >
              {["driver", "manager", "operator", "owner"].map((i, key) => {
                return (
                  <option key={key} value={i}>
                    {i}
                  </option>
                );
              })}
            </Input>
            <div className="popover-footer">
              <Button size="100px" onClick={filterByRole}>
                Применить
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )} */}
    </>
  );
};

export default Param;
