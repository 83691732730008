import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  userRoutes,
  authRoutes,
  rentRoutes,
  supportRoutes,
  careServiceRoutes,
  superAdminRoutes,
  adminRoutes,
  STORoutes,
} from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";

import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";
import VerificationToken from "components/Common/VerificationToken";
import {
  accessRentRoutes,
  accessSupportRoutes,
  accessAdminRoutes,
  accessCareServiceRoutes,
  accessSuperAdminRoutes,
  accessSTORoutes,
} from "utils/accessToRoles";

const App = (props: any) => {
  function getLayout() {
    let layoutCls: any = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  interface RouteType {
    path: string;
    component: React.FC<RouteComponentProps>;
    exact?: boolean;
  }

  const Layout = getLayout();

  const routesList = [
    {
      routes: authRoutes,
      isAuthProtected: false,
      allowedRoles: [],
      exact: false,
      layout: NonAuthLayout,
    },
    {
      routes: rentRoutes,
      isAuthProtected: true,
      allowedRoles: accessRentRoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: supportRoutes,
      isAuthProtected: true,
      allowedRoles: accessSupportRoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: careServiceRoutes,
      isAuthProtected: true,
      allowedRoles: accessCareServiceRoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: superAdminRoutes,
      isAuthProtected: true,
      allowedRoles: accessSuperAdminRoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: adminRoutes,
      isAuthProtected: true,
      allowedRoles: accessAdminRoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: STORoutes,
      isAuthProtected: true,
      allowedRoles: accessSTORoutes,
      exact: true,
      layout: Layout,
    },
    {
      routes: userRoutes,
      isAuthProtected: true,
      allowedRoles: [],
      exact: true,
      layout: Layout,
    },
  ];

  const routesComponent = (
    routes: RouteType[],
    isAuthProtected: boolean,
    allowedRoles: Array<String>,
    layout: any,
    exact: boolean
  ) => {
    return routes.map(
      (route, idx) =>
        route.component && (
          <Authmiddleware
            path={route.path}
            layout={layout}
            component={route.component}
            key={idx}
            isAuthProtected={isAuthProtected}
            allowedRoles={allowedRoles}
            exact={exact}
          />
        )
    );
  };

  return (
    <React.Fragment>
      <Router>
        <VerificationToken />
        <Switch>
          {routesList.map((routeList) =>
            routesComponent(
              routeList.routes,
              routeList.isAuthProtected,
              routeList.allowedRoles,
              routeList.layout,
              routeList.exact
            )
          )}
          <Redirect from="*" to="/login" />
        </Switch>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state: any) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
