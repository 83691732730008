import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DatePickerInput from "components/Common/DatePickerInput";
import { formatDate } from "../../../utils/date";

const UpdateEndDateRentModal = ({
  setIsShow,
  isShow,
  startRent,
  onSubmit,
  isLoading,
}) => {
  const [extendDate, setExtendDateDate] = useState(null);

  let cutFirstZero = (string) => {
    return string.startsWith("0") ? string.slice(1) : string;
  };

  const createMinDate = (value) => {
    let array_date = value.split(".");
    array_date = array_date.map((item) => {
      return cutFirstZero(item);
    });
    const date = new Date(array_date[2], +array_date[1] - 1, array_date[0]);

    return date.setDate(date.getDate() + 1);
  };

  const validateDate = (value) => {
    let newDate: Date | null = null;
    if (value != null) {
      if (
        2000 < new Date(value).getFullYear() &&
        new Date(value).getFullYear() < new Date().getFullYear() + 1
      ) {
        newDate = value;
      }
    }
    return newDate;
  };

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        Изменение даты конца
      </ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label for="extendDate" sm={4}>
            Дата конца аренды
          </Label>
          <Col sm={8}>
            <ReactDatePicker
              id="extendDate"
              // showTimeSelect
              selected={extendDate || ""}
              onChange={(date) => validateDate(date) && setExtendDateDate(date)}
              minDate={createMinDate(startRent)}
              dateFormat="dd.MM.yyyy"
              customInput={<DatePickerInput />}
              locale="ru"
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => onSubmit(extendDate)}
          disabled={isLoading || !extendDate}
        >
          Изменить
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateEndDateRentModal;
