import React, { useEffect, useState, useRef } from "react";
import * as VoxImplant from "voximplant-websdk";

import * as Styled from "./styles";

import phoneIcon from "../../../../assets/images/videocalls/Phone.svg";
import audioFile from "../../../../assets/sounds/IncomingCall.mp3";
import { VideoCallUserInfo } from "types/UserTypes";
import { careAPI, chatAPI } from "services";
import PaginationTable from "components/Common/PaginationTable";
import { formatVideoCallsHistory } from "common/dataFormatters";

const VideoHelp = () => {
  const [isShowLocalVideo, setIsShowLocalVideo] = useState(true);
  const logger = useRef(null);
  const [loggerData, setLoggerData] = useState("");

  const [currentCall, setCurrentCall] = useState<any>(null);
  const currentCallRef = React.useRef<any>(null);

  const [callIsActive, setCallIsActive] = useState(false);
  const [recallUser, setRecallUser] = useState(null);

  const [userInfo, setUserInfo] = useState<VideoCallUserInfo | null>(null);
  const [isPaused, setIsPaused] = React.useState(false);

  const { data: callsHistory, isLoading: callsHistoryLoading } =
    careAPI.useGetVideoCallsHistoryQuery();

  React.useEffect(() => {
    console.log(callsHistory);
  }, [callsHistory]);

  const sdk = VoxImplant.getInstance();
  const remoteVideoContainerRef = React.useRef(null);
  const activeUser = React.useRef(null);

  React.useEffect(() => {
    sdkInit();

    return () => {
      sdkDisconnect();
      removeListeners();
    };
  }, []);

  React.useEffect(() => {
    if (currentCall) {
      if (isPaused) {
        log("Звонок поставлен на паузу");
        setIsShowLocalVideo(false);
        showLocalVideo(false);
        sendVideo(false);
        sendAudio(false);
        console.log("ПОСТАВЛЕН НА ПАУЗУ");
      } else {
        log("Звонок возобновлён");
        setIsShowLocalVideo(true);
        showLocalVideo(true);
        sendVideo(true);
        sendAudio(true);
        console.log("УБРАЛИ С ПАУЗЫ");
      }
    }
  }, [isPaused]);

  function sdkInit() {
    sdk.on(VoxImplant.Events.SDKReady, onSdkReady);
    sdk.on(VoxImplant.Events.ConnectionEstablished, onConnectionEstablished);
    sdk.on(VoxImplant.Events.ConnectionFailed, onConnectionFailed);
    sdk.on(VoxImplant.Events.ConnectionClosed, onConnectionClosed);
    sdk.on(VoxImplant.Events.AuthResult, onAuthResult);
    sdk.on(VoxImplant.Events.IncomingCall, onIncomingCall);
    sdk.on(VoxImplant.Events.MicAccessResult, onMicAccessResult);

    try {
      const state = sdk.getClientState();
      console.log("THIS STATE", state);

      if (state !== "LOGGED_IN") {
        sdk.init({
          micRequired: true,
          videoSupport: true,
          progressTone: true,
          localVideoContainerId: "local_video_holder",
          remoteVideoContainerId: "remote_video_holder",
        });
      }
    } catch (e) {
      log("Не удалось подключиться к сети Voximplant.");
    }
  }

  function removeListeners() {
    sdk.off(VoxImplant.Events.SDKReady, onSdkReady);
    sdk.off(VoxImplant.Events.ConnectionEstablished, onConnectionEstablished);
    sdk.off(VoxImplant.Events.ConnectionFailed, onConnectionFailed);
    sdk.off(VoxImplant.Events.ConnectionClosed, onConnectionClosed);
    sdk.off(VoxImplant.Events.AuthResult, onAuthResult);
    sdk.off(VoxImplant.Events.IncomingCall, onIncomingCall);
    sdk.off(VoxImplant.Events.MicAccessResult, onMicAccessResult);
  }

  function sdkDisconnect() {
    sdk.disconnect();
  }

  function onSdkReady() {
    log("версия onSDKReady " + VoxImplant.version);
    log("WebRTC поддерживается браузером: " + sdk.isRTCsupported());
    connect();
  }

  function log(text: string) {
    setLoggerData((prev) => prev + text + "\n");
  }

  function onConnectionEstablished() {
    try {
      sdk.login("1111@service.alyansauto.voximplant.com", "/B1iSGEN");
      log("Вход под 1111@service.alyansauto.voximplant.com");
    } catch (e) {
      log("Не удалось выполнить вход");
    }
  }

  function onMicAccessResult(e: any) {
    log("Mic/Cam разрешения: " + e.result);
  }

  function onConnectionFailed() {
    log("Ошибка подключения.");
    setTimeout(function () {
      sdk.connect();
    }, 1000);
  }

  function onConnectionClosed() {
    log("Соединение закрыто");
    setTimeout(function () {
      sdk.connect();
    }, 1000);
  }

  function onIncomingCallConnected(event: any) {
    sendVideo(true);
    showRemoteVideo(true);
  }

  function onCallFailed() {
    log("Ошибка звонка...");
  }

  function onAuthResult(e: any) {
    log("AuthResult: " + e.result);
    if (e.result) {
      showLocalVideo(true);
    } else {
      log("Ошибка. Code: " + e.code);
    }
  }

  function onIncomingCall(e: any) {
    const { call } = e;
    setCurrentCall(call);
    currentCallRef.current = call;

    const audio = new Audio(audioFile);
    audio.loop = true;
    audio.play();

    function stopAudio() {
      audio.pause();
      audio.remove();
    }

    call.on(VoxImplant.CallEvents.Connected, (event: any) => {
      stopAudio();
      onIncomingCallConnected(event);
    });
    call.on(VoxImplant.CallEvents.MessageReceived, (e: any) => {
      setUserInfo(JSON.parse(e.text));
    });
    call.on(VoxImplant.CallEvents.Disconnected, (event: any) => {
      stopAudio();
      onCallDisconnected();
    });
    call.on(VoxImplant.CallEvents.Failed, onCallFailed);
    log("Входящий звонок от: " + call.number());
  }

  function createCall(id: number) {
    const outboundCall = sdk.call({
      number: String(id),
      video: { receiveVideo: true, sendVideo: true },
    });

    outboundCall.on(VoxImplant.CallEvents.Connected, () => {
      setCurrentCall(outboundCall);
      currentCallRef.current = outboundCall;
      setCallIsActive(true);
    });
    outboundCall.on(VoxImplant.CallEvents.Disconnected, onCallDisconnected);
    outboundCall.on(VoxImplant.CallEvents.Failed, onCallFailed);
  }

  function disconnectCall() {
    if (currentCall !== null) {
      log("Завершение звонка");
      currentCall.hangup();
    }
  }

  function showLocalVideo(flag: boolean) {
    sdk.showLocalVideo(flag);
  }

  function showRemoteVideo(flag: boolean) {
    currentCallRef?.current?.showRemoteVideo(flag);
  }

  function sendVideo(flag: boolean) {
    currentCallRef?.current?.sendVideo(flag);
  }

  function sendAudio(flag: boolean) {
    currentCallRef?.current?.[isPaused ? "muteMicrophone" : "unmuteMicrophone"]();
  }

  function sendMessage(message: string) {
    currentCall.sendMessage(message);
  }

  function togglePauseCall() {
    sendMessage(isPaused ? "unpause" : "pause");
    setIsPaused((prev) => !prev);
  }

  function onCallDisconnected() {
    setCurrentCall(null);
    currentCallRef.current = null;
    setCallIsActive(false);
    activeUser.current = null;
    setUserInfo(null);
    log("Звонок завершён");
  }

  async function connect() {
    try {
      log("Подключение...");
      await sdk.connect();
    } catch (e) {
      log("Ошибка подключения к voximplant...");
    }
  }

  const changeShowLocalVideo = () => {
    setIsShowLocalVideo(!isShowLocalVideo);
    isShowLocalVideo
      ? setLoggerData(loggerData + "Выключение локального видео" + "\n")
      : setLoggerData(loggerData + "Включение локального видео" + "\n");

    showLocalVideo(!isShowLocalVideo);
    sendVideo(!isShowLocalVideo);
  };

  const answerCall = () => {
    if (currentCall) {
      console.log("CURRENT CALL", currentCall);
      setCallIsActive(true);
      activeUser.current = currentCall.number();
      currentCall.answer(null, {}, { receiveVideo: true, sendVideo: true });
    }
  };

  const declineCall = () => {
    if (currentCall) {
      currentCall.decline();
      setCurrentCall(null);
    }
  };

  const endCall = React.useCallback(() => {
    if (currentCall) {
      setCallIsActive(false);
      setCurrentCall(null);
      activeUser.current = null;
      currentCall.hangup();
    }
  }, [currentCall]);

  React.useEffect(() => {
    if (!!currentCall && !callIsActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [currentCall, callIsActive]);

  return (
    <div className="page-content" style={{ position: "relative" }}>
      {!!currentCall && !callIsActive && (
        <Styled.Modal>
          <Styled.IncomingCallContainer style={{ marginTop: window.scrollY + 300 }}>
            <b>Вам звонят</b>
            <div className="buttons">
              <button onClick={() => answerCall()} className="answer">
                <img src={phoneIcon} alt="" />
              </button>
              <button onClick={() => declineCall()} className="decline">
                <img src={phoneIcon} alt="" />
              </button>
            </div>
          </Styled.IncomingCallContainer>
        </Styled.Modal>
      )}

      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Видео помощь</h2>
        </div>
        {userInfo && (
          <React.Fragment>
            <b>Информация о пользователе</b>
            <Styled.UserInfo>
              <p>
                ФИО:{" "}
                <span>
                  {userInfo?.surname} {userInfo?.name} {userInfo?.patronymic}
                </span>
              </p>
              <p>
                Телефон: <span>{userInfo?.phone}</span>
              </p>
              <p>
                Почта: <span>{userInfo?.email}</span>
              </p>
            </Styled.UserInfo>
          </React.Fragment>
        )}

        <Styled.Container>
          <Styled.VideoContainer ref={remoteVideoContainerRef}>
            <div className="card-header">Видео клиента</div>
            <Styled.Video id="remote_video_holder"></Styled.Video>
          </Styled.VideoContainer>
          <Styled.VideoContainer>
            <div className="card-header">Мое видео</div>
            <Styled.Video id="local_video_holder" style={{ background: "#e9ecef50" }}>
              {!isShowLocalVideo && (
                <Styled.Circle>
                  <i className="ti-video-camera"></i>
                </Styled.Circle>
              )}
            </Styled.Video>
          </Styled.VideoContainer>
        </Styled.Container>
        <div className="action_logger">
          <div className="card-header">Логгер</div>
          <Styled.LoggerContainer>
            <Styled.Textarea ref={logger} value={loggerData} disabled={true} />
            <Styled.ButtonsContainer>
              <Styled.DisableCameraBtn
                className={`btn ${isShowLocalVideo ? "btn-danger" : "btn-success"} btn-lg`}
                onClick={changeShowLocalVideo}>
                {isShowLocalVideo ? "Выключить видео" : "Включить видео"}
              </Styled.DisableCameraBtn>
              {currentCall && (
                <button
                  onClick={togglePauseCall}
                  style={{ marginBottom: 10 }}
                  className="btn btn-warning btn-lg">
                  {isPaused ? "Убрать с паузы" : "Поставить на паузу"}
                </button>
              )}
              {callIsActive && (
                <button className="btn btn-danger btn-lg" onClick={() => endCall()}>
                  Завершить
                </button>
              )}
            </Styled.ButtonsContainer>
          </Styled.LoggerContainer>
        </div>
        {callsHistory && (
          <PaginationTable
            title="История звонков"
            thTitles={["ID", "Рабочее время", "Имя", "Фамилия", "Отчество", "Телефон", "Почта"]}
            tableData={formatVideoCallsHistory(callsHistory?.data)}
            loading={callsHistoryLoading}
            canCall
            onCall={(id: number) => createCall(id)}
            isShowLabel={false}
          />
        )}
      </div>
      {isPaused && (
        <Styled.PauseVideo>
          <p>Видео на паузе</p>
          <button className="btn btn-success btn-lg" onClick={togglePauseCall}>
            Продолжить
          </button>
        </Styled.PauseVideo>
      )}
    </div>
  );
};

export default VideoHelp;
