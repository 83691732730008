export const accessRentRoutes = ["super-admin", "admin", "RentAdmin"]; //  доступ к разделу Альянс-Рента
export const accessSupportRoutes = ["super-admin", "admin", "Support"]; // доступ к разделу техподдержка
export const accessCareServiceRoutes = ["super-admin", "admin", "CareService"]; // доступ к разделу Сервис-Заботы
export const accessSuperAdminRoutes = ["super-admin"]; // доступ к разделу супер-админа
export const accessAdminRoutes = ["super-admin", "admin"]; //доступ к разделу админа
export const accessSTORoutes = ["super-admin", "admin", "STO"]; //доступ к разделам СТО

export const checkRole = (allowedRoles) => {
  const roles = localStorage.getItem("roles");
  const arrayRoles = JSON.parse(roles);
  const intersection = arrayRoles?.filter((role) =>
    allowedRoles.includes(role)
  );
  return intersection?.length > 0;
};
