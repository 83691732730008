import React, { useState } from "react";
import { Button } from "reactstrap";
import EditFinanceModal from "./EditFinanceModal";
import "./CarFinanceGeneral.scss";

const CarFinanceGeneralInfo = ({ finances, refetchCar }) => {
  const { price, price_list, discount_after, discount_before } = finances;
  const [isEditFinanceModalShow, setIsEditFinanceModalShow] = useState(false);
  return (
    <>
      <Button
        onClick={() => setIsEditFinanceModalShow(true)}
        className="mb-4"
        color="primary"
      >
        Изменить финасовые данные авто
      </Button>
      <h2 className="label">Общая информация</h2>
      <h5 className="text">
        Цена авто за три года:
        {price +
          price_list.insurance_first_year +
          price_list.insurance_second_year +
          price_list.insurance_third_year +
          price_list.tire_works +
          price_list.technical_inspection_three_years +
          " ₽"}
      </h5>
      <h5 className="text">Цена авто: {price + " ₽"}</h5>
      <h5 className="text">
        Уменьшение цены до 2х месяцев:{" "}
        {discount_before && discount_before + " ₽"}
      </h5>
      <h5 className="text">
        Уменьшение цены после 2х месяцев:{" "}
        {discount_after && discount_after + " ₽"}
      </h5>
      <h5 className="text">
        Цена страховки за первый год:{" "}
        {price_list.insurance_first_year &&
          price_list.insurance_first_year + " ₽"}
      </h5>
      <h5 className="text">
        Цена страховки за второй год:{" "}
        {price_list.insurance_second_year &&
          price_list.insurance_second_year + " ₽"}
      </h5>
      <h5 className="text">
        Цена страховки за третий год:{" "}
        {price_list.insurance_third_year &&
          price_list.insurance_third_year + " ₽"}
      </h5>
      <h5 className="text">
        Цена шиномонтажа:{" "}
        {price_list.tire_works && price_list.tire_works + " ₽"}
      </h5>
      <h5 className="text">
        Цена ТО за три года:{" "}
        {price_list.technical_inspection_three_years &&
          price_list.technical_inspection_three_years + " ₽"}
      </h5>
      <EditFinanceModal
        isShow={isEditFinanceModalShow}
        setShow={setIsEditFinanceModalShow}
        finances={finances}
        refetchCar={refetchCar}
      />
    </>
  );
};

export default CarFinanceGeneralInfo;
