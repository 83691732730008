import React from "react";
import { IChats } from "types/ChatTypes";
import * as C from "../styles";

interface ActiveChatProps {
  item: IChats;
  user: {
    id: string;
  };
  selectChat: (item: IChats) => void;
}

const ChatItem = ({ item, user, selectChat }: ActiveChatProps) => {
  return (
    <C.PreviewChatItem
      onClick={() => selectChat(item)}
      bg={
        item.messages[item.messages.length - 1].readBy.includes(Number(user.id)) ||
        item.messages[item.messages.length - 1].senderId === Number(user.id)
          ? "white"
          : "#f3f3f3"
      }>
      <C.Avatar
        src={
          item.creator.profilePhotoUrl === "null"
            ? "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
            : item.creator.profilePhotoUrl
        }
        alt="img"
      />
      {item.messages[item.messages.length - 1].readBy.includes(Number(user.id)) ||
      item.messages[item.messages.length - 1].senderId === Number(user.id) ? null : (
        <div style={{ position: "absolute", top: 0, right: 10, color: "#6fa4ff" }}>●</div>
      )}
      <div>
        <p className="name">{item.creator.name}</p>
        {item.messages[item.messages.length - 1]?.text !== "" && (
          <p
            style={{
              overflow: "hidden",
              maxWidth: 250,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {item.messages[item.messages.length - 1]?.text}
          </p>
        )}
        {item.messages[item.messages.length - 1]?.mediaUrl && (
          <p className="m-0 fs-6">
            <i className="ti-files mx-2"></i>Изображение
          </p>
        )}
      </div>
    </C.PreviewChatItem>
  );
};

export default ChatItem;
