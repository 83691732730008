import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

interface TabsProps {
  tabs: { title: string; content: React.ReactNode }[];
}

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <Nav tabs>
        {tabs.map(({ title }, i) => (
          <NavItem key={i}>
            <NavLink active={activeTab === i} onClick={() => setActiveTab(i)}>
              {title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.map(({ content }, i) => (
          <TabPane tabId={i} key={i}>
            {content}
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};

export default Tabs;
