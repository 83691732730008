import * as Yup from "yup";

const STRING_REQUIRED = Yup.string().required(
  "Это поле обязательно к заполнению"
);
const REQUIRED = Yup.mixed().required("Это поле обязательно к заполнению");

const VALIDATE_DATE_REQUIRED = Yup.date()
  .min(new Date("1900/01/01"), "Недопустимая дата")
  .max(new Date(), "Недопустимая дата")
  .required("Это поле обязательно к заполнению");

const VALIDATE_MILEAGE_REQUIRED = Yup.number()
  .moreThan(-1, "Значение должно быть неотрицательным")
  .required();

const VALIDATE_VIN_REQUIRED = STRING_REQUIRED.min(
  16,
  "Поле должно содержать минимум 16 символов"
)
  .max(17, "Поле должно содержать максимум 17 символов")
  .matches(
    /^[A-Za-z0-9]+$/,
    "Поле может содержать только латинские буквы и цифры"
  );

const VALIDATE_STS_REQUIRED = STRING_REQUIRED.min(
  10,
  "Поле должно содержать ровно 10 символов"
)
  .max(10, "Поле должно содержать ровно 10 символов")
  .matches(
    /^([А-Я]|[а-я]|[0-9])+$/,
    "Поле может содержать только русские буквы и цифры"
  );

export const loginSchema = Yup.object().shape({
  email: STRING_REQUIRED.email("Не корректный e-mail"),
  password: STRING_REQUIRED.min(2, "Минимум 6 символов"),
});

export const createServiceCarSchema = Yup.object().shape({
  name: REQUIRED,
  type: REQUIRED,
  imei: REQUIRED,
});

export const createEmployeeCarServiceSchema = Yup.object().shape({
  name: REQUIRED,
  telegram: REQUIRED,
  type: REQUIRED,
  serviceCar: REQUIRED,
});

export const createCarCommentSchema = Yup.object().shape({
  comments: Yup.array().of(
    Yup.object().shape({
      mileage: STRING_REQUIRED,
      author: STRING_REQUIRED,
      comment: STRING_REQUIRED,
    })
  ),
});

export const createTOCarCommentSchema = Yup.object().shape({
  comments: Yup.array().of(
    Yup.object().shape({
      mileage: STRING_REQUIRED,
      // author: STRING_REQUIRED,
      comment: STRING_REQUIRED,
      price: STRING_REQUIRED,
    })
  ),
});

export const createCarSchema = Yup.object().shape({
  mark: REQUIRED,
  model: REQUIRED,
  generation: REQUIRED,
  serie: REQUIRED,
  modification: REQUIRED,
  // equipment: REQUIRED,
  year: VALIDATE_DATE_REQUIRED,
  vin: VALIDATE_VIN_REQUIRED,
  sts: VALIDATE_STS_REQUIRED,
  registrationNumber: REQUIRED,
  mileage: VALIDATE_MILEAGE_REQUIRED,
  imei: STRING_REQUIRED.max(30, "Превышено количество символов"),
});

export const editCarSchema = Yup.object().shape({
  mark: REQUIRED,
  model: REQUIRED,
  generation: REQUIRED,
  serie: REQUIRED,
  modification: REQUIRED,
  year: VALIDATE_DATE_REQUIRED,
  vin: VALIDATE_VIN_REQUIRED,
  sts: VALIDATE_STS_REQUIRED,
  registrationNumber: REQUIRED,
  mileage: VALIDATE_MILEAGE_REQUIRED,
});

export const createUserSchema = Yup.object().shape({
  email: STRING_REQUIRED.email("Не верный формат почты"),
  password: STRING_REQUIRED.min(6, "Минимум 6 символов"),
  name: STRING_REQUIRED,
  surname: STRING_REQUIRED,
  patronymic: STRING_REQUIRED,
  phone: STRING_REQUIRED,
  address: STRING_REQUIRED,
});

export const editUserSchema = Yup.object().shape({
  email: STRING_REQUIRED.email("Не верный формат почты"),
  phone: STRING_REQUIRED,
  address: STRING_REQUIRED,
  name: STRING_REQUIRED,
  surname: STRING_REQUIRED,
  patronymic: STRING_REQUIRED,
});

export const createStatusSchema = Yup.object().shape({
  name: STRING_REQUIRED,
});

export const createRequestSchema = Yup.object().shape({
  typeId: STRING_REQUIRED,
  statusId: STRING_REQUIRED,
  userId: STRING_REQUIRED,
  latitude: STRING_REQUIRED,
  longitude: STRING_REQUIRED,
});

export const createAdminSchema = Yup.object().shape({
  surname: STRING_REQUIRED,
  name: STRING_REQUIRED,
  patronymic: STRING_REQUIRED,
  email: STRING_REQUIRED.email("Не верный формат почты"),
  password: STRING_REQUIRED.min(6, "Минимум 6 символов"),
  role: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.number(),
    })
  ),
});

export const updateAdminSchema = Yup.object().shape({
  password: STRING_REQUIRED.min(6, "Минимум 6 символов").nullable(true),
  role: Yup.array().of(
    Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.number(),
      })
      .nullable(true)
  ),
});
