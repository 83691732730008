import React, { Fragment, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import ListPayment from "components/Rents/Payments/ListPayment";
import DatePickerInput from "components/Common/DatePickerInput";
import { formatDate } from "utils/date";
import { rentUserAPI } from "services";
import { rentCarAPI } from "services";
import { rentPaymentAPI } from "services/rentPaymentService";
import { formatRentCarsServiceDataForBackend } from "../../../../common/dataFormatters";
import Pagination from "components/Common/Pagination";

const listTitleItems = [
  { id: 1, title: "ФИО" },
  { id: 2, title: "Авто" },
  { id: 3, title: "Дата начала" },
  { id: 4, title: "Дата окончания" },
  { id: 5, title: "Дата платежа" },
  { id: 5, title: "Сумма" },
];

const Payments = () => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pagesAmount, setPagesAmount] = useState(1);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const { data: cars, isLoading: carsLoading } = rentCarAPI.useGetRentCarsQuery(
    {}
  );
  const { data: allPaidRent, isLoading: rentPaidLoading } =
    rentPaymentAPI.useGetRentPaidQuery();

  const { data: users, isLoading: usersLoading } =
    rentUserAPI.useGetUsersQuery();
  const { data: paidRents, isLoading: isGetPaidRentsLoading } =
    rentPaymentAPI.useGetRentPaidQuery(
      formatRentCarsServiceDataForBackend({
        startDateFilter,
        endDateFilter,
        currentPage,
        perPage,
      })
    );

  useEffect(() => {
    setPagesAmount(paidRents?.meta.last_page);
  }, [paidRents]);

  useEffect(() => {
    if (!selectedUser && !selectedCar) {
      setFilteredPayments([]);
      return;
    }

    if (selectedUser && selectedCar) {
      const filteredByUser = allPaidRent?.data?.filter(
        (rent) => rent?.user?.id === selectedUser?.id
      );
      const filterdeByBoth = filteredByUser?.filter(
        (rent) => rent?.car?.id === selectedCar?.id
      );
      setFilteredPayments(filterdeByBoth);
    } else if (selectedUser) {
      setFilteredPayments(
        allPaidRent?.data?.filter((rent) => rent?.user?.id === selectedUser?.id)
      );
    } else if (selectedCar) {
      setFilteredPayments(
        allPaidRent?.data?.filter((rent) => rent?.car?.id === selectedCar?.id)
      );
    }
  }, [allPaidRent, selectedCar, selectedUser]);

  const selectPaymentByUserOptions = useMemo(() => {
    return users?.data
      ?.map((user) => {
        return {
          value: user,
          label:
            (user.surname ? user.surname + " " : "") +
            (user.name ? user.name + " " : "") +
            (user.patronymic ? user.patronymic : ""),
        };
      })
      .filter((item) => item?.label.trim().length != 0);
  }, [users]);

  const selectPaymentByCarOptions = useMemo(
    () =>
      cars?.data.map((car) => ({
        value: car,
        label: [car.brand, car.mark, car.registration_number].join(" "),
      })),
    [cars]
  );

  return (
    <div>
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-payments-page">
                <div style={{ backgroundColor: "white" }}>
                  <div className="header-payments-page">
                    <div className="filter-date-payments-page">
                      <span style={{ width: "100%" }}>
                        Фильтр по дате оплаты с
                      </span>
                      <ReactDatePicker
                        locale="ru"
                        selected={startDateFilter}
                        selectsStart
                        onChange={(value) => {
                          let newDate: Date | null = null;
                          if (value != null) {
                            if (
                              new Date(value).getFullYear() <
                              new Date().getFullYear() + 1
                            ) {
                              newDate = value;
                            }
                          }
                          setStartDateFilter(newDate);
                        }}
                        startDate={startDateFilter}
                        customInput={
                          <DatePickerInput className="date-picket-input" />
                        }
                        dateFormat="dd.MM.yyyy"
                      />
                      <span className="po">по</span>
                      <ReactDatePicker
                        locale="ru"
                        selected={endDateFilter}
                        onChange={setEndDateFilter}
                        selectsEnd
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        minDate={startDateFilter}
                        customInput={
                          <DatePickerInput className="date-picket-input" />
                        }
                        dateFormat="dd.MM.yyyy"
                      />
                    </div>

                    <div className="mr-2 custom-select">
                      <Select
                        onChange={(data) => setSelectedUser(data?.value)}
                        placeholder="Поиск по пользователю"
                        isClearable
                        isSearchable
                        options={selectPaymentByUserOptions}
                      />
                    </div>
                    <div className="mr-2 custom-select">
                      <Select
                        onChange={(data) => setSelectedCar(data?.value)}
                        placeholder="Поиск по авто"
                        isClearable
                        isSearchable
                        options={selectPaymentByCarOptions}
                      />
                    </div>
                    {/* <Button color="primary">Выгрузить в CSV</Button> */}
                  </div>
                  <div
                    id="table"
                    className="card-header list-title"
                    style={{ backgroundColor: "white" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">ID Аренды</th>
                          {listTitleItems.map((i) => (
                            <th scope="col">{i.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedCar || selectedUser ? (
                          filteredPayments?.length === 0 ? (
                            <tr>
                              <td colSpan={7}>
                                По указанным фильтрам нет аренд
                              </td>
                            </tr>
                          ) : (
                            filteredPayments?.map((payment) => (
                              <ListPayment payment={payment} />
                            ))
                          )
                        ) : (
                          paidRents?.data.map((payment) => (
                            <ListPayment payment={payment} />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!selectedUser && !selectedCar && (
                  <Pagination
                    pagesAmount={pagesAmount}
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Payments;
