export const validateDate = (value) => {
  let newDate: Date | null = null;
  if (value != null) {
    if (
      2000 < new Date(value).getFullYear() &&
      new Date(value).getFullYear() < new Date().getFullYear() + 1
    ) {
      newDate = value;
    }
  }
  return newDate;
};
