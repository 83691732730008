import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VideoContainer = styled.div`
  position: relative;
  max-width: 48%;
  width: 100%;
  margin: 0 0 16px 0;
`;

export const Video = styled.div`
  max-height: 400px;
  // height: 400px;
  border: 1px solid #e9ecef;
  cursor: pointer;
  video {
    object-fit: contain;
    width: 100%;
    max-height: 400px;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: calc(50% - 2.1vw);
  left: calc(50% - 2.1vw);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.2vw;
  height: 4.2vw;
  border-radius: 50%;
  background-color: white;

  i {
    font-size: 1.5vw;
  }
`;

export const LoggerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Textarea = styled.textarea`
  width: calc(100% - 210px);
  height: 200px;
  padding: 5px;
  font-size: 14px;
  color: #5b626b;
  resize: none;
  border: 1px solid #e9ecef;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
`;

export const IncomingCallContainer = styled.div`
  margin-top: 300px;
  height: 200px;
  width: 300px;
  background: #fff;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  b {
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
  }

  button {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: none;
    margin: 5px;

    &.answer {
      background: green;
    }

    &.decline {
      background: #eb4034;
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 200px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  width: 100%;
  height: 60px;
  background: ${(props) => props.color};
  border-radius: 20px;
  color: white;
  border: none;
  font-size: 18px;
  transition: opacity 0.3s;
  opacity: 0.8;
  margin-bottom: 10px;

  &:hover {
    opacity: 1;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  p {
    font-size: 17px;
    font-weight: 700;
    margin-right: 20px;

    span {
      font-weight: 400;
    }
  }
`;

export const DisableCameraBtn = styled.button`
  margin-bottom: 10px;
`;

export const PauseVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000080;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* justify-content: center; */
  flex-direction: column;

  p {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin-top: 30vh;
  }
`;
