import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { carAPI } from "services";
import PaginationTable from "components/Common/PaginationTable";
import {
  formatCarDataForBackEndFilters,
  formatCarDataForTable,
} from "common/dataFormatters";
import { CONNECT_TYPES } from "./static";
import { UsersList } from "./styles";
import { IUserInfo } from "types/UserTypes";
import SelectUser from "./SelectUser";

const Cars = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      mark: "",
      model: "",
      generation: "",
      serie: "",
      modification: "",
      equipment: "",
      user: "",
      per_page: 10,
      page: 1,
    },
    onSubmit: (values) => {
      // getCars(formatCarDataForBackEndFilters(values));
    },
  });

  const { data: marks } = carAPI.useGetMarksQuery();

  const [amountPage, setAmountPage] = useState(1);
  const [getModels, { data: models }] = carAPI.useLazyGetModelsQuery();
  const [getGenerations, { data: generations }] =
    carAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = carAPI.useLazyGetSeriesQuery();
  const [getModifications, { data: modifications }] =
    carAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] =
    carAPI.useLazyGetEquipmentsQuery();

  const [deleteCar, { isLoading: deleteCarLoading }] =
    carAPI.useDeleteCarMutation();

  const {
    data: cars,
    isLoading: carsLoading,
    isFetching: carsFetching,
  } = carAPI.useGetCarsQuery(formatCarDataForBackEndFilters(formik.values));

  useEffect(() => {
    setAmountPage(cars?.meta.last_page);
  }, [cars]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <h2 className="mb-3">Фильтры</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="mb-5">
          <div className="card">
            <div className="card-header">Информация об автомобиле</div>
            <div className="card-body">
              <div className="filter-cars">
                <div className="custom-select">
                  <FormGroup>
                    <Label for="mark">Марка</Label>
                    <Input
                      id="mark"
                      name="mark"
                      type="select"
                      value={formik.values.mark}
                      onChange={(e) => {
                        const markId = e.target.value;
                        formik.setFieldValue("mark", markId);
                        markId && getModels(+markId);
                      }}
                      invalid={!!formik.errors.mark}
                    >
                      <option value="">Выберите марку</option>
                      {marks?.data.map((mark) => (
                        <option key={mark.id} value={mark.id}>
                          {mark.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.mark}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="model">Модель</Label>
                    <Input
                      id="model"
                      name="model"
                      type="select"
                      value={formik.values.model}
                      onChange={(e) => {
                        const modelId = e.target.value;
                        formik.setFieldValue("model", modelId);
                        modelId && getGenerations(+modelId);
                      }}
                      invalid={!!formik.errors.model}
                    >
                      <option value="">Выберите модель</option>
                      {models?.data.map((model) => (
                        <option key={model.id} value={model.id}>
                          {model.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.model}</FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="generation">Поколение</Label>
                    <Input
                      id="generation"
                      name="generation"
                      type="select"
                      value={formik.values.generation}
                      onChange={(e) => {
                        const generationId = e.target.value;
                        formik.setFieldValue("generation", generationId);
                        generationId && getSeries(+generationId);
                      }}
                      invalid={!!formik.errors.generation}
                    >
                      <option value="">Выберите поколение</option>
                      {generations?.data.map((generation) => (
                        <option key={generation.id} value={generation.id}>
                          {generation.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">
                      {formik.errors.generation}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="serie">Серия</Label>
                    <Input
                      id="serie"
                      name="serie"
                      type="select"
                      value={formik.values.serie}
                      onChange={(e) => {
                        const serieId = e.target.value;
                        formik.setFieldValue("serie", serieId);
                        serieId && getModifications(+serieId);
                      }}
                      invalid={!!formik.errors.serie}
                    >
                      <option value="">Выберите серию</option>
                      {series?.data.map((serie) => (
                        <option key={serie.id} value={serie.id}>
                          {serie.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">{formik.errors.serie}</FormText>
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-3">
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="modification">Модификация</Label>
                    <Input
                      id="modification"
                      name="modification"
                      type="select"
                      value={formik.values.modification}
                      onChange={(e) => {
                        const modificationId = e.target.value;
                        formik.setFieldValue("modification", modificationId);
                        modificationId && getEquipments(+modificationId);
                      }}
                      invalid={!!formik.errors.modification}
                    >
                      <option value="">Выберите модификацию</option>
                      {modifications?.data.map((modification) => (
                        <option key={modification.id} value={modification.id}>
                          {modification.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="danger">
                      {formik.errors.modification}
                    </FormText>
                  </FormGroup>
                </div>
                <div className="custom-select">
                  <FormGroup className="mb-3">
                    <Label for="equipment">Комплектация</Label>
                    <Input
                      id="equipment"
                      name="equipment"
                      type="select"
                      value={formik.values.equipment}
                      onChange={(e) => {
                        const equipmentId = e.target.value;
                        formik.setFieldValue("equipment", equipmentId);
                      }}
                    >
                      <option value="">Выберите комплектацию</option>
                      {equipments?.data.map((equipment) => (
                        <option key={equipment.id} value={equipment.id}>
                          {equipment.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <SelectUser
                  onSelected={(item: IUserInfo) =>
                    formik.setFieldValue("user", item.id)
                  }
                />
              </div>
              <div className="box-btn-cars-page">
                <button type="submit" className="btn btn-success btn-cars-page">
                  Отфильтровать
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-cars-page"
                  onClick={() => {
                    formik.resetForm();
                  }}
                  disabled={carsLoading}
                >
                  Сбросить фильтры
                </button>
              </div>
            </div>
          </div>
        </form>

        <PaginationTable
          title="Список автомобилей"
          thTitles={[
            "ID",
            "Марка",
            "Модель",
            "Модификация",
            "Пробег",
            "Номер",
            "Год",
            "STS",
            "VIN",
          ]}
          tableData={formatCarDataForTable(cars?.data)}
          canDelete
          onDelete={deleteCar}
          deletePending={deleteCarLoading}
          loading={carsLoading || carsFetching}
          onRowClick={(carId: number) => history.push(`/cars/${carId}`)}
          OptionalBtn={CreateCarBtn}
          setPerPage={(count) => formik.setFieldValue("per_page", count)}
          perPage={formik.values.per_page}
          currentPage={formik.values.page}
          setCurrentPage={(count) => formik.setFieldValue("page", count)}
          amountPage={amountPage}
          setAmountPage={setAmountPage}
          isBackPagination={true}
        />
      </div>
    </div>
  );
};

const CreateCarBtn: React.FC = () => {
  return (
    <Link to="/create-car" className="waves-effect">
      <button type="button" className="btn btn-info mx-3">
        Создать авто
      </button>
    </Link>
  );
};

export default Cars;
