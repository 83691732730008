import {
  accessRentRoutes,
  accessSupportRoutes,
  accessAdminRoutes,
  accessCareServiceRoutes,
  accessSuperAdminRoutes,
  accessSTORoutes,
} from "utils/accessToRoles";

export const menuSection = [
  {
    id: 1,
    label: "Тех.поддержка",
    link: "/#",
    icon: "ti-comments-smiley",
    allowedRoles: accessSupportRoutes,
    children: [
      {
        id: 11,
        label: "Чат",
        link: "/chat",
        icon: "ti-comments",
      },
    ],
  },
  {
    id: 2,
    label: "Пользователи",
    link: "/users",
    icon: "ti-user",
    allowedRoles: accessAdminRoutes,
    children: [],
  },
  {
    id: 3,
    label: "Автомобили",
    link: "/cars",
    icon: "ti-car",
    allowedRoles: accessSTORoutes,
    children: [],
  },

  {
    id: 5,
    label: "Альянс-Прокат",
    link: "/#",
    icon: "ti-direction-alt",
    allowedRoles: accessRentRoutes,
    children: [
      {
        id: 51,
        label: "Аренды",
        link: "/alyans-rent/rents",
        icon: "ti-car",
      },
      {
        id: 52,
        label: "Пользователи",
        link: "/alyans-rent/users",
        icon: "ti-stamp",
      },
      {
        id: 53,
        label: "Машины",
        link: "/alyans-rent/cars",
        icon: "ti-car",
      },
      {
        id: 54,
        label: "Штрафы",
        link: "/alyans-rent/fines",
        icon: "ti-clipboard",
      },
      {
        id: 55,
        label: "Оплаты",
        link: "/alyans-rent/payments",
        icon: "ti-money",
      },
      {
        id: 56,
        label: "Клиенты",
        link: "/alyans-rent/clients",
        icon: "ti-pencil-alt",
      },
      {
        id: 57,
        label: "Мониторинг",
        link: "/alyans-rent/monitoring",
        icon: "ti-location-pin",
      },
      {
        id: 58,
        label: "Туры",
        link: "/alyans-rent/tours",
        icon: "ti-map-alt",
      },
      {
        id: 59,
        label: "Сообщения",
        link: "/alyans-rent/messages",
        icon: "ti-comment",
      },
    ],
  },
  {
    id: 4,
    label: "Служба заботы",
    link: "/#",
    icon: "ti-heart",
    allowedRoles: accessCareServiceRoutes,
    children: [
      {
        id: 41,
        label: "Заявки",
        link: "/care-service",
        icon: "ti-clipboard",
      },
      {
        id: 42,
        label: "Статусы заявок",
        link: "/request-statuses",
        icon: "ti-time",
      },
      {
        id: 43,
        label: "Мониторинг",
        link: "/monitoring",
        icon: "ti-map-alt",
      },
      {
        id: 44,
        label: "Сервисные авто",
        link: "/create-service-car",
        icon: "ti-bag",
      },
      {
        id: 45,
        label: "Сотрудники",
        link: "/employee-car-service",
        icon: "ti-headphone-alt",
      },
      {
        id: 46,
        label: "Видеопомощь",
        link: "/video-help",
        icon: "ti-video-camera",
      },
    ],
  },
  {
    id: 6,
    label: "Рекомендации",
    link: "/#",
    icon: "ti-comment",
    allowedRoles: accessSTORoutes,
    children: [
      {
        id: 47,
        label: "Обычные",
        link: "/create-car-recommendation",
        icon: "ti-layout-list-thumb-alt",
      },
      {
        id: 48,
        label: "Для ТО",
        link: "/create-to-car-recommendation",
        icon: "ti-layout-list-thumb",
      },
    ],
  },
  {
    id: 7,
    label: "Настройки",
    link: "/#",
    icon: "ti-settings",
    allowedRoles: accessSuperAdminRoutes,
    children: [
      {
        id: 71,
        label: "Администраторы",
        link: "/settings/admins",
        icon: "ti-eye",
      },
    ],
  },
];
