import React, { Fragment, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import ListRentMessages from "./ListRentMessages";
import { useHistory } from "react-router";
import Param from "components/Rents/Users/Param";
// import Loader from "../../../../common/loader";
import { Button, Input, Label } from "reactstrap";
import { rentMessageAPI } from "services";

import Pagination from "components/Common/Pagination";
import { current } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import Loader from "components/Common/Loader";
import ReactDatePicker from "react-datepicker";
import DatePickerInput from "components/Common/DatePickerInput";
import { validateDate } from "utils/validateDate";

const Messages = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [selectStatus, setSelectStatus] = useState<any>(undefined);
  const [selectDate, setSelectDate] = useState<any>(undefined);
  const [selectPhone, setSelectPhone] = useState<string | undefined>("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data: messages, isLoading: messageLoading } =
    rentMessageAPI.useGetMessagesQuery({
      "filters[status]": selectStatus ? selectStatus.value : "",
      "filters[phone]": selectPhone || "",
      "filters[date]":
        (selectDate && new Date(selectDate)?.toLocaleDateString())
          ?.split(".")
          ?.reverse()
          ?.join("-") || "",
      per_page: 10,
      page: currentPage,
    });

  const [pagesAmount, setPagesAmount] = useState(messages?.meta?.last_page);

  const infoStatusSelect = [
    {
      value: 0,
      label: "Ошибка",
    },
    {
      value: 1,
      label: "Доставлено",
    },
  ];

  const listTitleItems = [
    { id: 2, title: "Дата" },
    { id: 3, title: "Телефон" },
    { id: 4, title: "ФИО" },
    { id: 5, title: "Текст" },
    { id: 6, title: "Статус" },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [selectDate, selectPhone, selectStatus]);

  // useEffect(() => {
  //   console.log("selectDate", selectDate);
  // }, [selectDate]);

  const dateInputMask = (elm: any) => {
    elm.addEventListener("keypress", function (e: any) {
      if (e.keyCode < 47 || e.keyCode > 57) {
        e.preventDefault();
      }

      var len = elm.value.length;

      // If we're at a particular place, let the user type the slash
      // i.e., 12/12/1212
      if (len !== 1 || len !== 3) {
        if (e.keyCode == 47) {
          e.preventDefault();
        }
      }

      // If they don't add the slash, do it for them...
      if (len === 2) {
        elm.value += "/";
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        elm.value += "/";
      }
    });
  };

  if (messages === undefined)
    return <Loader isLoading={messages === undefined} />;
  return (
    <div>
      <Fragment>
        <div className="container-fluid" style={{ backgroundColor: "white" }}>
          <div className="row" style={{ backgroundColor: "white" }}>
            <div className="col-sm-12" style={{ backgroundColor: "white" }}>
              <div
                className="card-user-page"
                style={{ backgroundColor: "white" }}
              >
                {
                  <>
                    <div className="card-header" style={{ minHeight: 150 }}>
                      <div className="list-btn-message-page header-rent-page">
                        <div className="mr-2 custom-select">
                          <Select
                            value={selectStatus}
                            isSearchable={false}
                            onChange={(data) => setSelectStatus(data)}
                            placeholder="Статус сообщения"
                            isClearable
                            options={infoStatusSelect}
                          />
                        </div>

                        <div className="mr-2 custom-select">
                          <Input
                            id="phone"
                            name="phone"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base: any) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            pattern="[0-9]*"
                            maxLength={11}
                            style={{ height: 40 }}
                            placeholder="Номер телефона"
                            value={selectPhone}
                            onChange={(e) => setSelectPhone(e.target.value)}
                          />
                        </div>

                        <div className="mr-2">
                          <div className="filter-date-payments-page">
                            <span style={{ width: "100%" }}>
                              Фильтр по дате
                            </span>
                            <ReactDatePicker
                              locale="ru"
                              selected={selectDate}
                              selectsStart
                              onChange={(value) => {
                                setSelectDate(value);
                              }}
                              maxDate={new Date()}
                              customInput={
                                <DatePickerInput
                                  className="date-picket-input"
                                  value={selectDate}
                                  onKeyPress={(event: any) => {
                                    if (!/[0-9,.]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  type="text"
                                />
                              }
                              dateFormat="dd.MM.yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div id="table" className="card-header list-title">
                        <table
                          className="table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              {listTitleItems.map((i) => (
                                <Param id={i.id} title={i.title} key={i.id} />
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {
                              <>
                                {messages?.data?.map((ms: any) => (
                                  <ListRentMessages item={ms} key={ms.id} />
                                ))}
                              </>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {messages?.data.length === 0 && (
                      <div className="tr-no-message">Записей нет</div>
                    )}

                    {messages?.data.length > 0 && (
                      <Pagination
                        pagesAmount={messages.meta.last_page}
                        currentPage={currentPage}
                        setCurrentPage={(page: any) => setCurrentPage(page)}
                      />
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Messages;
