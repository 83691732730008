import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import Select from "react-select";
import "@asseinfo/react-kanban/dist/styles.css";
// import "../../../globalStyles/List.scss";
// import "../../../globalStyles/Canban.scss";

import CreateClientModal from "components/Rents/Clients/Modal/CreateClientModal";
import CreateStatusModal from "components/Rents/Clients/Modal/CreateStatusModal";
import CreateStageModal from "components/Rents/Clients/Modal/CreateStageModal";
import DatePickerInput from "components/Common/DatePickerInput";
import ReactDatePicker from "react-datepicker";
import { isSameDay, isWithinInterval } from "date-fns";
import { formatDate } from "utils/date";
import { rentClientAPI } from "services/rentClientService";

const Clients = () => {
  // использоавать вместо executeUpdateClient для изменения count, когда будет на бэке
  // const { loading: changeLeadsCountLoading, execute: executeChangeLeadsCount } = useAsync({ asyncFunction: changeLeadsCount });

  const { data: clients, isLoading: getClientsLoading } =
    rentClientAPI.useGetCliensQuery();
  const { data: stages, isLoading: getStagesLoading } =
    rentClientAPI.useGetStagesQuery();

  const [createClient, { isLoading: createClientLoading }] =
    rentClientAPI.useCreateClientMutation();
  const [updateClient, { isLoading: updateClientLoading }] =
    rentClientAPI.useUpdateClientMutation();
  const [createStatus, { isLoading: createStatusLoading }] =
    rentClientAPI.useCreateStatusMutation();
  const [createStage, { isLoading: createStageLoading }] =
    rentClientAPI.useCreateStageMutation();

  const history = useHistory();

  const [board, setBoard] = useState({ columns: [] });
  const [isShow, setIsShow] = useState(false);
  const [isCreateStatusModalShow, setIsCreateStatusModalShow] = useState(false);
  const [isCreateStageModalShow, setIsCreateStageModalShow] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const selectClientOptions = useMemo(
    () =>
      clients?.data.map((client) => ({
        value: client,
        label: [client.lead_name, client.phone].join(" "),
      })),
    [clients]
  );

  //заполняем доску сопоставляя клиентов со стадиями
  useEffect(() => {
    const board = {
      columns:
        stages?.data.map(({ id, name }) => ({
          id,
          title: name,
          cards:
            clients?.data
              .filter((client) => client.stageId === id)
              .sort((a, b) => a.count - b.count) || [],
        })) || [],
    };
    setBoard(board);
  }, [clients, stages]);

  const handleCardMove = async (
    card,
    { fromPosition, fromColumnId },
    { toPosition, toColumnId }
  ) => {
    setBoard(
      moveCard(
        board,
        { fromPosition, fromColumnId },
        { toPosition, toColumnId }
      )
    );
    let updatedLeadsCount;
    await updateClient({
      id: card.id,
      client: {
        ...card,
        stageId: toColumnId,
        count: toPosition,
        status: null,
      },
    });

    // Меняем count у остальных карточек, если изменение выше их затронуло
    if (fromColumnId !== toColumnId) {
      const leadsCountFrom = board.columns
        .find((column) => column.id === fromColumnId)
        .cards.slice(fromPosition + 1)
        .reduce(
          (cardsAcc, card) => [...cardsAcc, { ...card, count: card.count - 1 }],
          []
        );

      const leadsCountTo = board.columns
        .find((column) => column.id === toColumnId)
        .cards.slice(toPosition)
        .reduce(
          (cardsAcc, card) => [...cardsAcc, { ...card, count: card.count + 1 }],
          []
        );

      updatedLeadsCount = leadsCountFrom.concat(leadsCountTo);
    } else {
      const sourceColumn = board.columns.find(
        (column) => column.id === fromColumnId
      );
      updatedLeadsCount = sourceColumn.cards.reduce((cardsAcc, card) => {
        if (fromPosition > toPosition) {
          if (card.count >= toPosition && card.count < fromPosition) {
            return [...cardsAcc, { ...card, count: card.count + 1 }];
          }
        } else if (fromPosition < toPosition) {
          if (card.count > fromPosition && card.count <= toPosition) {
            return [...cardsAcc, { ...card, count: card.count - 1 }];
          }
        }
        return cardsAcc;
      }, []);
    }
    // await Promise.all(
    //   updatedLeadsCount.map((lead) => executeUpdateClient(lead))
    // );
    // await executeGetClients();
  };
  //   if (!clients || !stages) return <Loader isLoading />;

  const isLeadCreatedInSelectedDate = (createdDate) => {
    if (!startDateFilter || !endDateFilter) return false;
    return (
      isSameDay(new Date(createdDate), new Date(startDateFilter)) ||
      isSameDay(new Date(createdDate), new Date(endDateFilter)) ||
      isWithinInterval(new Date(createdDate), {
        start: new Date(startDateFilter),
        end: new Date(endDateFilter),
      })
    );
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div
          className="card card-clients-page"
          style={{ width: "98%", height: "90%" }}
        >
          <div className="header-clients-page">
            <Button
              color="primary"
              className="btn-cliens-pages"
              onClick={() => setIsShow(true)}
            >
              Создать задачу
            </Button>
            <Button
              className="btn-cliens-pages"
              color="primary"
              onClick={() => setIsCreateStageModalShow(true)}
            >
              Создать стадию
            </Button>
            <Button
              color="primary"
              className="btn-cliens-pages"
              onClick={() => setIsCreateStatusModalShow(true)}
            >
              Создать статус
            </Button>
            <div className="ml-4 custom-select">
              <Select
                onChange={(data) => setSelectedClient(data?.value)}
                placeholder="Поиск клиента"
                isClearable
                isSearchable
                options={selectClientOptions}
              />
            </div>
          </div>
          <div className="filter-date" style={{ marginLeft: 30 }}>
            <span className="label-filter-data">Фильтр по дате создания с</span>
            <ReactDatePicker
              locale="ru"
              selected={startDateFilter}
              onChange={setStartDateFilter}
              selectsStart
              startDate={startDateFilter}
              customInput={<DatePickerInput className="date-picket-input" />}
              dateFormat="dd.MM.yyyy"
              maxDate={endDateFilter}
            />
            <span className="po">по</span>
            <ReactDatePicker
              locale="ru"
              selected={endDateFilter}
              onChange={setEndDateFilter}
              selectsEnd
              startDate={startDateFilter}
              endDate={endDateFilter}
              minDate={startDateFilter}
              customInput={<DatePickerInput className="date-picket-input" />}
              dateFormat="dd.MM.yyyy"
              className="mr-2"
            />
          </div>
          <div className="card-body o-auto pt-0">
            <Board
              children={board}
              disableColumnDrag
              onCardDragEnd={handleCardMove}
              disableCardDrag={getClientsLoading} // || updateClientLoading
              renderCard={(
                { id, lead_name, phone, comment, created_at },
                { dragging }
              ) => (
                <div
                  onClick={() => history.push(`/alyans-rent/clients/${id}`)}
                  className="react-kanban-card"
                  dragging={dragging.toString()}
                  style={{
                    backgroundColor:
                      selectedClient?.id === id
                        ? "#4466f2"
                        : isLeadCreatedInSelectedDate(created_at)
                        ? "lightgreen"
                        : "",
                  }}
                >
                  <div className="header-card">
                    <span className="name-label">{lead_name}</span>
                    <p className="time-label">
                      {formatDate(created_at, "dd.MM.yyyy")}
                    </p>
                  </div>
                  <span className="d-block react-kanban-card__description">
                    {phone}, {comment}
                  </span>
                </div>
              )}
            />
          </div>
          <CreateClientModal
            isShow={isShow}
            setIsShow={setIsShow}
            isLoading={createClientLoading}
            onSumbit={async (client) => {
              await createClient(client);
            }}
            firstStageLength={board?.columns[0]?.cards.length}
          />
          <CreateStatusModal
            stages={stages}
            isShow={isCreateStatusModalShow}
            setIsShow={setIsCreateStatusModalShow}
            isLoading={createStatusLoading}
            onSumbit={async (status) => {
              await createStatus(status);
            }}
          />
          <CreateStageModal
            isShow={isCreateStageModalShow}
            setIsShow={setIsCreateStageModalShow}
            isLoading={createStageLoading}
            onSumbit={async (stage) => {
              await createStage(stage);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Clients;
