import ru from "date-fns/locale/ru"; // the locale you want
import {
  differenceInCalendarDays,
  differenceInHours,
  format,
  setDate,
  setDay,
  setHours,
  setMinutes,
  differenceInMinutes,
  addDays,
} from "date-fns";

export const formatDate = (date, formatTemplate) => {
  if (!date) return date;
  return format(new Date(date), formatTemplate, {
    locale: ru,
  });
};

export const copyDate = (date) => new Date(date.getTime());

export const getDatesBetween = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

//Получает массив дат для заданного диапазона, даты отличаются с шагом в 15 минут
export const getDateTimeBetween = (start, end, selectedDate) => {
  const startTime = formatDate(start, "HH:mm:ss");
  const endTime = formatDate(start, "HH:mm:ss");

  const isStartBeforeToday =
    Math.abs(
      differenceInCalendarDays(new Date(start), new Date(selectedDate))
    ) > 0;
  const isEndAfterToday =
    Math.abs(differenceInCalendarDays(new Date(end), new Date(selectedDate))) >
    0;

  // console.log(
  //   +startTime.split(':')[0],
  //   +startTime.split(':')[1]
  //   // new Date().setHours(+startTime.split(':')[0]).setMinutes(+startTime.split(':')[1])
  // );

  // console.log('isStartBeforeToday', isStartBeforeToday, 'isEndAfterToday', isEndAfterToday);

  const startMilliSeconds = (
    isStartBeforeToday
      ? setHours(setMinutes(new Date(selectedDate), 0), 0)
      : setHours(
          setMinutes(new Date(selectedDate), +startTime.split(":")[1]),
          +startTime.split(":")[0]
        )
  ).getTime();

  const endMilliSeconds = (
    isEndAfterToday
      ? addDays(setHours(setMinutes(new Date(selectedDate), 0), 0), 1)
      : setHours(
          setMinutes(new Date(selectedDate), +endTime.split(":")[1]),
          +endTime.split(":")[0]
        )
  ).getTime();

  //start и end будут одним и тем же днем, но с разным временем
  // const startTime = new Date(start).setHours();
  // const endTime = new Date(end).setHours(formatDate(end, 'HH:mm:ss'));
  // console.log(startTime, endTime, new Date(startMilliSeconds), new Date(endMilliSeconds));

  // const diffInOurs = (startMilliSeconds - endMilliSeconds) / 1000 / 60 / 60;
  const diffInMinutes = differenceInMinutes(endMilliSeconds, startMilliSeconds);
  //Так как интервал в datepicker 15 минут
  const quartersAmount = diffInMinutes / 15;
  const arrayWithDates = [];
  // console.log(diffInMinutes, quartersAmount);
  for (let i = 1; i <= quartersAmount; i++) {
    arrayWithDates.push(setMinutes(new Date(startMilliSeconds), 15 * i));
  }
  // console.log(arrayWithDates);
  return arrayWithDates;
};

//устанавливает определенный час и минуты, к указанной дате
export const setTimeToDate = (time, date) => {
  const hours = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();

  const result = copyDate(date);
  result.setHours(hours);
  result.setMinutes(minutes);
  return result;
};
