import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { statusSchema } from "utils/validationShemas";

const CreateStatusModal = ({
  stages,
  isShow,
  setIsShow,
  onSumbit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      stageId: "",
    },
    validationSchema: statusSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      await onSumbit(values);
      toast.success("Статус успешно создан");
      setIsShow(false);
      resetForm();
    },
  });

  return (
    <Modal toggle={() => setIsShow(!isShow)} isOpen={isShow}>
      <ModalHeader toggle={() => setIsShow(!isShow)}>
        Создать статус
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="name">Название статуса</Label>
            <Input
              id="name"
              name="name"
              type="text"
              invalid={!!formik.errors.name}
              onChange={formik.handleChange}
            />
            <FormText color="danger">{formik.errors.name}</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="stageId">Стадия</Label>
            <Input
              className="w-auto"
              type="select"
              id="stageId"
              name="stageId"
              invalid={!!formik.errors.stageId}
              onChange={(e) =>
                formik.setFieldValue("stageId", JSON.parse(e.target.value).id)
              }
            >
              <option value={0}>Выбрать стадию</option>
              {stages?.data.map((stage) => (
                <option key={stage.id} value={JSON.stringify(stage)}>
                  {stage.name}
                </option>
              ))}
            </Input>
            <FormText color="danger">{formik.errors.stageId}</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading ? "Загрузка..." : "Создать"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateStatusModal;
