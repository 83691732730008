import { Home, Anchor, Headphones } from "react-feather";

export const MENUITEMS = [
  {
    title: "CRM",
    icon: Headphones,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/dashboard/rents", title: "Аренды", type: "link" },
      { path: "/dashboard/clients", title: "Клиенты", type: "link" },
      { path: "/dashboard/chat", title: "Техподдержка", type: "link" },
      {
        path: "/dashboard/broadcasts",
        title: "Трансляции",
        type: "link",
      },
    ],
  },
  {
    title: "Общее",
    icon: Home,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/dashboard/monitoring", title: "Мониторинг", type: "link" },
      { path: "/dashboard/users", title: "Пользователи", type: "link" },
      { path: "/dashboard/cars", title: "Машины", type: "link" },
      { path: "/dashboard/tours", title: "Туры", type: "link" },
      { path: "/dashboard/fines", title: "Штрафы", type: "link" },
    ],
  },
  {
    title: "Бухгалтерия",
    icon: Anchor,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [{ path: "/dashboard/payments", title: "Оплаты", type: "link" }],
  },
];
