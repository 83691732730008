import { useState, useEffect, useRef, useCallback } from "react";
import { IOldChats } from "../../../../types/ChatTypes";
import { TailSpin } from "react-loader-spinner";
import Messages from "./Messages";
import FilesLoad from "./FilesLoad";

import { v4 as uuidv4 } from "uuid";
import { careAPI } from "services";

const FILE_TYPES = {
  jpeg: "jpg",
  png: "png",
  webp: "webp",
  gif: "gif",
};

const Chat = () => {
  const [userImage, setUserImage] = useState("");
  const [userName, setUsername] = useState("Admin");
  const [activeChatId, setActiveChatId] = useState(0);
  const [activeChat, setActiveChat] = useState<IOldChats | undefined>();
  const [value, setValue] = useState<string>("");
  const [allChats, setAllChats] = useState<IOldChats[] | undefined>();
  const [files, setFiles] = useState<any>([]);

  const [activeMessages, setActiveMessages] = useState([]);

  const ws = useRef(new WebSocket("wss://admin.main.alyans-auto.ru/chat")).current;
  const [wsConnected, setWsConnected] = useState(false);
  const channelId = useRef<null | number>(null);

  const { data: chats, isLoading: chatsLoading } = careAPI.useGetChatsQuery();

  const currentChats = useRef<any>(null);
  const currentUserInfo = useRef<{
    id?: number;
    contragent_code?: string | null;
    phone: string;
  } | null>();

  useEffect(() => {
    connectToWebSocket();

    return clearSocketListeners;
  }, []);

  useEffect(() => {
    currentChats.current = allChats;
  }, [allChats]);

  useEffect(() => {
    !chatsLoading && setAllChats(chats?.data);
  }, [chatsLoading]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      }
    }
  }, []);

  useEffect(() => {
    console.log(activeMessages);
  }, [activeMessages]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  const connectToWebSocket = () => {
    ws.onopen = onOpen;
    ws.onmessage = onMessage;

    ws.onerror = (error) => {
      console.log("ERROR", error);
    };

    ws.onclose = (event) => {
      console.log("CLOSE EVENT", event);
    };
  };

  const clearSocketListeners = () => {
    ws.removeEventListener("open", onOpen);

    ws.removeEventListener("message", onMessage);

    ws.removeEventListener("error", (error) => {
      console.log("ERROR", error);
    });

    ws.removeEventListener("close", (event) => {
      console.log("CLOSE EVENT", event);
    });

    ws.close();
  };

  const onOpen = () => {
    console.log("CONNECTED");
    setWsConnected(true);
  };

  const onMessage = (msg: any) => {
    const data = JSON.parse(msg.data);

    console.log(data);

    switch (data.action) {
      case "connect":
        if (channelId.current === +data.channel.id) {
          setActiveMessages(data.oldMessages);
          currentUserInfo.current = {
            id: data.user.id,
            contragent_code: data.user.contragent_code,
            phone: data.user.phone,
          };
        }
        break;
      case "sendMessage":
        if (channelId.current === Number(data.message.chat_id)) {
          setActiveMessages((prev) => prev.concat(data.message));
        }
        updateAllChats(data.message, data.user);
        break;
    }
  };

  const updateAllChats = (msg: any, user: any) => {
    let chat;
    let message;

    console.log("MESSAGE", msg);

    if (currentChats?.current.find((item: any) => item.chat_id === msg.chat_id)) {
      const clearedChats = currentChats.current.filter((item: any) => {
        if (item.chat_id === msg.chat_id) {
          chat = item;
          message = item.message;
        } else return item;
      });

      //@ts-ignore
      message = {
        //@ts-ignore
        ...message,
        id: msg.id,
        from_user: msg.from_user ? 1 : 0,
        to_user: msg.to_user ? 1 : 0,
        body: msg.body,
        file: msg.file,
      };

      //@ts-ignore
      chat = { ...chat, message };

      clearedChats.unshift(chat);

      setAllChats(clearedChats);
    } else {
      let newsChats = currentChats.current;

      const { surname, name, patronymic } = user;
      const fio = [surname, name, patronymic].filter((item) => item !== null);

      const chatMessage = {
        chat_id: msg.chat_id,
        chat_user: {
          id: user.id,
          FIO: fio.length === 0 ? "?" : fio.join(" "),
          avatar: user.avatar
            ? `https://main.alyans-auto.ru//avatar/${user.avatar}`
            : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
        },
        message: {
          id: 0,
          from_user: 0,
          to_user: 1,
          body: msg.body,
          file: msg.file,
          user: {
            FIO: `${user.surname} ${user.name} ${user.patronymic}`,
            avatar: user.avatar
              ? `https://main.alyans-auto.ru//avatar/${user.avatar}`
              : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
            id: user.id,
          },
        },
      };
      setAllChats([chatMessage, ...newsChats]);
    }
  };

  const activationChat = (item: IOldChats) => {
    ws.send(JSON.stringify({ action: "connectManagerToChat", user_id: item.chat_user.id }));
    channelId.current = item.chat_id;
    setActiveChatId(item.chat_id);
    setValue("");
    setActiveChat(item);
    if (files) setFiles([]);
  };

  const handleChange = (target: any) => {
    setValue(target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (files.length > 0 || value !== "") {
      console.log(files);

      const data = {
        action: "sendMessage",
        senderId: 30,
        text: value,
        channelId: channelId.current,
        files: [],
      };

      if (files.length > 0) {
        Promise.all(
          [...files].map(async (file: File) => {
            const fileFormData = new FormData();

            const blob = file.slice(0, file.size);
            //@ts-ignore
            const newFile = new File([blob], `${uuidv4()}.${FILE_TYPES[file.type.split("/")[1]]}`, {
              type: file.type,
            });

            fileFormData.append("file", newFile);

            const json = await (
              await fetch("https://cls01.node1.alyans-auto.ru/api/", {
                method: "POST",
                body: fileFormData,
              })
            ).json();

            return { name: json.filepath, type: file.type.split("/")[0] };
          })
        ).then((values) => {
          //@ts-ignore
          data.files = values;

          console.log("VAUES", values);

          ws.send(JSON.stringify(data));
        });
      } else {
        ws.send(JSON.stringify(data));
      }

      setValue("");
      setFiles([]);
    }
  };

  const handleFileChange = (e: any) => {
    e.target.files.length > 0 && setFiles(e.target.files);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="page-title-box">
          <div className="card text-center" style={{ cursor: "pointer" }}>
            {!wsConnected ? (
              <TailSpin color="#02a499" height={50} width={50} />
            ) : (
              <div className="row align-items-start">
                <div
                  className="col-md-5 border-secondary border-end pe-0"
                  style={{ height: "72vh" }}>
                  <div className="bg-secondary bg-gradient bg-opacity-75 d-flex align-items-center p-2">
                    <img
                      src={userImage}
                      alt="userImage"
                      className="rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <p className="m-0 ms-2 fs-5">{userName}</p>
                  </div>
                  <ul className="list-group" style={{ overflow: "scroll", height: "80vh" }}>
                    {chatsLoading ? (
                      <TailSpin color="#02a499" height={50} width={50} />
                    ) : (
                      allChats?.map((item) => (
                        <li
                          className={"list-group-item d-flex align-items-center"}
                          key={item.chat_id}
                          style={item.chat_id === activeChatId ? { background: "#dcfbff" } : {}}
                          onClick={() => activationChat(item)}>
                          <img
                            src={
                              item.chat_user.avatar
                                ? item.chat_user.avatar
                                : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                            }
                            alt="userImage"
                            className="rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div className="d-flex align-items-start flex-column">
                            <p className="m-0 ms-2 fs-5">
                              {item.chat_user.FIO ? item.chat_user.FIO : "?"}
                            </p>
                            <div className="d-flex">
                              {item.message.body !== "" && (
                                <p
                                  className="m-0 ms-2 fs-6 overflow-hidden"
                                  style={{ maxWidth: "120px", whiteSpace: "nowrap" }}>
                                  {item.message.body}
                                </p>
                              )}
                              {item.message.file.length > 0 && (
                                <p className="m-0 fs-6">
                                  <i className="ti-files mx-2"></i>файл
                                </p>
                              )}
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
                <div className="col d-flex flex-column ps-0" style={{ maxHeight: "80vh" }}>
                  {activeChat && (
                    <>
                      <div className="bg-secondary bg-gradient bg-opacity-75 d-flex align-items-center justify-content-between py-2">
                        <img
                          src={
                            activeChat?.chat_user?.avatar
                              ? activeChat?.chat_user?.avatar
                              : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                          }
                          alt="userImage"
                          className="rounded-circle ms-2"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <p className="m-0 ms-2 fs-5">
                          {activeChat.chat_user.FIO ? activeChat.chat_user.FIO : "?"}
                        </p>
                        <p className="m-0">{currentUserInfo.current?.contragent_code}</p>
                        <b className="m-0 me-2">+{currentUserInfo.current?.phone}</b>
                      </div>
                      <div
                        style={{ padding: "20px", height: "100%" }}
                        className="overflow-auto d-block">
                        {activeMessages.length === 0 ? (
                          <TailSpin color="#02a499" height={50} width={50} />
                        ) : (
                          <Messages messages={activeMessages} activeChatId={activeChatId} />
                        )}
                      </div>

                      <form
                        className="border-secondary border-top p-3"
                        style={{ width: "98%" }}
                        onSubmit={(e) => handleSubmit(e)}>
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="p-0 m-0">
                            <input
                              type="file"
                              className="d-none"
                              accept="image/jpeg, image/png, image/svg, video/mp4, video/mkv, video/x-matroska"
                              multiple
                              onChange={(e) => handleFileChange(e)}
                            />
                            <i
                              className="ti-pin-alt"
                              style={{ fontSize: "20px", cursor: "pointer" }}
                            />
                          </label>
                          <div style={{ width: "85%" }} className="py-1 px-2">
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: "1px solid #e9ecef",
                                borderRadius: "4px",
                              }}
                              value={value}
                              onChange={(e) => handleChange(e.target)}
                            />
                          </div>
                          <button className="border-0 bg-transparent" type="submit">
                            <svg
                              aria-hidden="true"
                              style={{ width: "20px" }}
                              className="svg-inline--fa fa-paper-plane fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512">
                              <path
                                fill="currentColor"
                                d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
                            </svg>
                          </button>
                        </div>
                        <div>{files?.length > 0 && <FilesLoad files={files} />}</div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
