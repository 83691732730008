import React, { useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { rentCarAPI } from "services";

const StepBrands = ({ car, setCar }) => {
  const { data: brands, isLoading, isError } = rentCarAPI.useGetBrandsQuery();
  const [getModels, { data: models }] = rentCarAPI.useLazyGetModelsQuery();
  const [getGenerations, { data: generations }] =
    rentCarAPI.useLazyGetGenerationsQuery();
  const [getSeries, { data: series }] = rentCarAPI.useLazyGetSerieQuery();
  const [getModifications, { data: modifications }] =
    rentCarAPI.useLazyGetModificationsQuery();
  const [getEquipments, { data: equipments }] =
    rentCarAPI.useLazyGetEquipmentQuery();

  React.useEffect(() => {
    console.log(car);
    !!car.brand_id && getModels(car.brand_id);
    !!car.brand_id && !!car.model_id && getGenerations(car.model_id);
    !!car.brand_id &&
      !!car.id_car_generation &&
      !!car.model_id &&
      getSeries({
        modelId: car.model_id,
        generationsId: car.id_car_generation,
      });
    !!car.brand_id &&
      !!car.id_car_generation &&
      !!car.model_id &&
      !!car.id_car_serie &&
      getModifications(car.id_car_serie);
    !!car.brand_id &&
      !!car.id_car_generation &&
      !!car.model_id &&
      !!car.id_car_serie &&
      !!car.id_car_modification &&
      getEquipments(car.id_car_modification);
  }, [car]);

  return (
    <>
      <FormGroup>
        <Label for="mark">Марка *</Label>
        <Input
          id="mark"
          name="mark"
          type="select"
          value={car.brand_id}
          onChange={(e) => {
            if (e.target.value !== "Выберите марку") {
              setCar({
                ...car,
                brand_id: e.target.value,
                model_id: "",
                id_car_generation: "",
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
              getModels(e.target.value);
            } else {
              setCar({
                ...car,
                brand_id: "",
                model_id: "",
                id_car_generation: "",
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
            }
          }}
        >
          <option defaultValue={car.brand_id}>Выберите марку</option>
          {brands?.data?.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="model">Модель * </Label>
        <Input
          id="model"
          name="model"
          type="select"
          value={car.model_id}
          onChange={(e) => {
            if (e.target.value !== "Выберите модель") {
              setCar({
                ...car,
                model_id: e.target.value,
                id_car_generation: "",
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
              getGenerations(e.target.value);
            } else {
              setCar({
                ...car,
                model_id: "",
                id_car_generation: "",
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
            }
          }}
          disabled={!models || !car.brand_id}
        >
          <option defaultValue={car.model_id}>Выберите модель</option>
          {models?.data?.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="generation">Поколение *</Label>
        <Input
          id="generation"
          name="generation"
          type="select"
          value={car.id_car_generation}
          onChange={(e) => {
            if (e.target.value !== "Выберите поколение") {
              setCar({
                ...car,
                id_car_generation: e.target.value,
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
              getSeries({
                modelId: car.model_id,
                generationsId: e.target.value,
              });
            } else {
              setCar({
                ...car,
                id_car_generation: "",
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
            }
          }}
          disabled={!generations || !car.model_id}
        >
          <option defaultValue={car.id_car_generation}>
            Выберите поколение
          </option>
          {generations?.data?.map((generation) => (
            <option key={generation.id} value={generation.id}>
              {generation.name} {generation.start_year}-{generation.end_year}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="series">Тип кузова *</Label>
        <Input
          id="series"
          name="series"
          type="select"
          value={car.id_car_serie}
          onChange={(e) => {
            if (e.target.value !== "Выберите тип кузова") {
              setCar({
                ...car,
                id_car_serie: e.target.value,
                id_car_modification: "",
                id_car_equipment: "",
              });
              getModifications(e.target.value);
            } else {
              setCar({
                ...car,
                id_car_serie: "",
                id_car_modification: "",
                id_car_equipment: "",
              });
            }
          }}
          disabled={!series || !car.id_car_generation}
        >
          <option defaultValue={car.id_car_serie}>Выберите тип кузова</option>
          {series?.data?.map((series, i) => (
            <option key={`${series.id}${Math.random() * 10}`} value={series.id}>
              {series.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="modification">Модификация *</Label>
        <Input
          id="modification"
          name="modification"
          type="select"
          value={car.id_car_modification}
          onChange={(e) => {
            if (e.target.value !== "Выберите модификацию") {
              setCar({
                ...car,
                id_car_modification: e.target.value,
                id_car_equipment: "",
              });
              getEquipments(e.target.value);
            } else {
              setCar({
                ...car,

                id_car_modification: "",
                id_car_equipment: "",
              });
            }
          }}
          disabled={!modifications || !car.id_car_serie}
        >
          <option defaultValue={car.id_car_modification}>
            Выберите модификацию
          </option>
          {modifications?.data?.map((modification) => (
            <option key={`${modification.id}`} value={modification.id}>
              {modification.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="equipment">Комплектация</Label>
        <Input
          id="equipment"
          name="equipment"
          type="select"
          value={car.id_car_equipment}
          onChange={(e) => {
            if (e.target.value !== "Выберите комплектацию") {
              setCar({ ...car, id_car_equipment: e.target.value });
            } else {
              setCar({ ...car, id_car_equipment: "" });
            }
          }}
          disabled={!equipments || !car.id_car_modification}
        >
          <option defaultValue={car.id_car_equipment}>
            Выберите комплектацию
          </option>
          {equipments &&
            equipments?.data?.map((eq) => (
              <option key={`${eq.id}`} value={eq.id}>
                {eq.name}
              </option>
            ))}
        </Input>
      </FormGroup>
    </>
  );
};

export default StepBrands;
