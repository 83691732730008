import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./rentSettings";
import { IRentCars, IRentCarsResult } from "types/RentTypes";
import { IRentCar, IRentRate } from "types/RentCarTypes";

export const rentPaymentAPI = createApi({
  reducerPath: "rentPaymentAPI",
  tagTypes: [],
  baseQuery,
  endpoints: (build) => ({
    getRentPaid: build.query<IRentCars, IRentCarsResult>({
      query: (params) => {
        return {
          url: `/admin/paidRents`,
          method: "GET",
          params: {
            ...params,
          },
        };
      },
    }),
  }),
});
