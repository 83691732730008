import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { rentUserAPI } from "services";
import DeleteModal from "./DeleteModal";
import UserModal from "./UserModal";

const ListRentUsers = ({ user, onOpen, deleteSelectedUser }) => {
  const {
    id,
    name,
    surname,
    createdAt,
    status,
    type,
    lastLease,
    roles,
    patronymic,
    permissions,
    rents,
    verify,
    verify_desc,
    verify_time,
    verify_starttime,
    phone,
    FIO,
  } = user;
  const [isShow, setIsShow] = useState(false);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [deleteUser, {}] = rentUserAPI.useDeleteUserMutation();

  const onSubmitDeleteUserModal = () => {
    deleteUser(id).then(() => {
      setIsDeleteModalShow(false);
      if (deleteSelectedUser) {
        deleteSelectedUser();
      }
    });
  };

  const isVerifyRejection = () => {
    const perm = permissions.map((per) => per.name);
    return perm.includes("verify-rejection");
  };

  const labelStatus = () => {};

  return (
    <tr
      onClick={() => onOpen(user)}
      style={{
        backgroundColor:
          (verify !== 1 && !isVerifyRejection() && "#E3DEDE") ||
          (verify !== 1 && isVerifyRejection() && "#E9BFBA") ||
          (verify === 1 && "#fff"),
      }}
    >
      <th>{id}</th>
      <td onClick={() => onOpen(user)}>
        {name && surname ? `${surname} ${name}` : FIO ? `${FIO}` : " — "}
      </td>
      <td>{new Date(createdAt).toLocaleDateString()}</td>
      <td>
        {rents.length != 0
          ? rents[0].rent.start_date + "-" + rents[0].rent.end_date
          : rents.length == 0
          ? "Aренды не было"
          : " — "}
      </td>
      <td>
        {roles?.length !== 0
          ? roles.map((item) => item.name).join(", ")
          : " — "}
      </td>
      <td>
        {status} {isVerifyRejection() && verify_desc}
        {(verify == 1 || isVerifyRejection()) &&
          verify_time != null &&
          " (" + new Date(verify_time).toLocaleDateString() + ")"}
        {verify == 0 &&
          !isVerifyRejection() &&
          verify_starttime != null &&
          " (" + new Date(verify_starttime).toLocaleDateString() + ")"}
      </td>
      <td>{phone ? phone : " — "}</td>

      <td style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="button"
          color="primary"
          size="sm"
          className="waves-effect waves-light m-1"
          onClick={(e) => {
            e.stopPropagation();
            setIsShow(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
        </Button>
        <Button
          type="button"
          color="danger"
          size="sm"
          className="waves-effect waves-light m-1"
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalShow(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill"
            viewBox="0 0 16 16"
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </Button>
      </td>

      {isShow && (
        <UserModal
          key={id}
          isShow={isShow}
          setIsShow={setIsShow}
          type="update"
          userId={id}
        />
      )}
      {isDeleteModalShow && (
        <DeleteModal
          onSubmit={() => onSubmitDeleteUserModal()}
          text="этого пользователя"
          isShow={isDeleteModalShow}
          setIsShow={setIsDeleteModalShow}
        />
      )}
    </tr>
  );
};

export default ListRentUsers;
