import React from "react";
import { toast } from "react-toastify";
import { chatAPI, userAPI } from "services";
import { io } from "socket.io-client";
import { IChats } from "types/ChatTypes";
import { IUserInfo } from "types/UserTypes";

interface NewChatServiceHookProps {
  scrollToBottom: () => void;
  user: {
    email: string;
    avatar: string;
    initials: string;
    id: string;
  };
  showToast: () => void;
}

const useNewChatService = ({
  scrollToBottom,
  user,
  showToast,
}: NewChatServiceHookProps) => {
  const socket = React.useRef<any>(null);

  const {
    data: chats,
    isLoading: chatsLoading,
    isFetching: chatsFetching,
  } = chatAPI.useGetChatsQuery();
  const [getChats] = chatAPI.useLazyGetChatsQuery();
  const [getUser] = userAPI.useLazyGetUserQuery();
  const [getUserInfo] = userAPI.useLazyGetUserQuery();
  const [joinToChat] = chatAPI.useJoinToChatMutation();
  const [readMessage] = chatAPI.useReadMessageMutation();

  const [cars, setCars] = React.useState<null | any[]>(null);
  const [userInfo, setUserInfo] = React.useState<IUserInfo | null>(null);
  const [activeChat, setActiveChat] = React.useState<IChats | undefined>();

  React.useEffect(() => {
    if (activeChat && chats) {
      const active = chats.find((item) => item.id === activeChat.id);
      setActiveChat(active);
      getInfo(active?.creatorId);
    }
  }, [chats, activeChat]);

  React.useEffect(() => {
    socket.current = io(process.env.REACT_APP_ADMIN, {
      query: {
        email: user.email,
        id: user.id,
        profileUrl: user.avatar,
        name: user.initials,
        isSupport: true,
      },
    });

    socket.current.on("connect", (e: any) => {
      console.log("CONNECTED", e);
    });

    socket.current.on("supportRecMessage", (message: any) => {
      console.log("NEW MESSAGE FROM NEW CHAT", message);
      getChats();
      scrollToBottom();
      showToast();
    });

    socket.current.on("disconnect", (e: any) => {
      console.log("DISCONNECTED", e);
    });

    return () => {
      socket.current.close();
    };
  }, []);

  const getInfo = async (item: any) => {
    const info = await getUser(item).unwrap();

    setUserInfo(info?.data);
  };

  const selectChat = async (item: IChats) => {
    const userData = await getUserInfo(item.creator.id).unwrap();
    if (userData.data.cars) {
      setCars(userData.data?.cars);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 100);
    joinToChat({
      participant: {
        name: user.initials,
        profilePhotoUrl: user.avatar === null ? "" : user.avatar,
        id: Number(user.id),
      },
      chatId: item.id,
    });
    readMessage({
      participantId: Number(user.id),
      messageId: item.messages[item.messages.length - 1].id,
    });

    setActiveChat(item);
  };

  return {
    activeChat,
    setActiveChat,
    userInfo,
    cars,
    selectChat,
    chatsLoading,
    chats,
    chatsFetching,
  };
};

export default useNewChatService;
