// import { combineReducers } from "redux"
import { combineReducers } from "@reduxjs/toolkit";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

import { adminAPI, authAPI, carAPI, userAPI } from "services";
import { careAPI } from "services/careService";
import { chatAPI } from "services/chatService";
import { rentAPI } from "services/rentService";
import { rentCarAPI } from "services/rentCarService";
import { rentUserAPI } from "services/rentUserService";
import { rentPaymentAPI } from "services/rentPaymentService";
import { rentClientAPI } from "services/rentClientService";
import { rentTourAPI } from "services/rentTourService";
import { rentFineAPI } from "services/rentFineService";
import { rentMessageAPI } from "services";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  [authAPI.reducerPath]: authAPI.reducer,
  [carAPI.reducerPath]: carAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [careAPI.reducerPath]: careAPI.reducer,
  [adminAPI.reducerPath]: adminAPI.reducer,
  [rentMessageAPI.reducerPath]: rentMessageAPI.reducer,
  [chatAPI.reducerPath]: chatAPI.reducer,
  [rentAPI.reducerPath]: rentAPI.reducer,
  [rentCarAPI.reducerPath]: rentCarAPI.reducer,
  [rentUserAPI.reducerPath]: rentUserAPI.reducer,
  [rentPaymentAPI.reducerPath]: rentPaymentAPI.reducer,
  [rentClientAPI.reducerPath]: rentClientAPI.reducer,
  [rentTourAPI.reducerPath]: rentTourAPI.reducer,
  [rentFineAPI.reducerPath]: rentFineAPI.reducer,
});

export default rootReducer;
