import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const VerificationToken = () => {
  const location = useLocation();
  const history = useHistory();

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const roles = localStorage.getItem("roles");
    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt.exp * 1000 < Date.now() || !roles) {
        history.push("/logout");
      }
    }
  }, [location]);

  return <></>;
};

export default VerificationToken;
