import React, { useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import { rentFineAPI } from "services";
import { infoUsersShow } from "utils/user";
import { Button } from "reactstrap";
import { formatDate } from "common/dataFormatters";

const FinePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data: fine, isLoading: getFineLoading } = rentFineAPI.useGetFineQuery(id);

  const createLabelCar = (car, vehicle_model) => {
    if (car?.id != null) {
      return car?.brand + " " + car?.model + " " + car?.registration_number;
    } else {
      return vehicle_model;
    }
  };

  React.useEffect(() => {
    console.log(fine);
  }, [fine]);

  return (
    <>
      <div className="card">
        <h2 className="card-header h2">Страница штрафа {fine?.data.vehicle_model}</h2>
        <div className="card-body">
          <div className="my-2">
            <strong>Вид штрафа: </strong>
            <span>{fine?.data.ko_ap_text}</span>
          </div>
          <div className="my-2">
            <strong>Пользователь: </strong>
            <Button
              onClick={() => history.push(`/alyans-rent/users/${fine?.data?.user?.id}`)}
              className="ml-3"
              color="primary"
              disabled={!fine?.data?.user}>
              {infoUsersShow(fine?.data)}
            </Button>
          </div>
          <div className="my-2">
            <strong>Аренда: </strong>
            <Button
              onClick={() => history.push(`/alyans-rent/rent/${fine?.data?.rent?.rent?.id}`)}
              className="ml-3"
              color="primary"
              disabled={!fine?.data?.rent?.rent?.id}>
              ID {fine?.data?.rent?.rent?.id}
            </Button>
          </div>
          <div className="my-2">
            <strong>Авто: </strong>
            <Button
              className="ml-3"
              color="primary"
              style={{ textDecoration: `${fine?.data?.car.id && "underline"}` }}
              disabled={fine?.data?.car.id == null}
              onClick={() => history.push(`/alyans-rent/cars/${fine?.data?.car?.id}`)}>
              {createLabelCar(fine?.data.car, fine?.data.vehicle_model)}
            </Button>
          </div>
          <div className="my-2">
            <strong>Адрес: </strong>
            <span>{fine?.data.division_address}</span>
          </div>
          <div className="my-2">
            <strong>Дата постановления: </strong>
            <span>{formatDate(fine?.data.date_post, "dd-MM-yyyy")}</span>
          </div>
          <div className="my-2">
            <strong>Номер постановления: </strong>
            <span>{fine?.data.num_post}</span>
          </div>
          <div className="my-2">
            <strong>Дата штрафа: </strong>
            <span>{formatDate(fine?.data.date_decis, "dd-MM-yyyy")}</span>
          </div>
          <div className="my-2">
            <strong>Сумма: </strong>
            <span>{fine?.data.summa} ₽</span>
          </div>
          <div>
            <strong>Статус: </strong>
            {fine?.data.isPaid ? <span className="text-success" >Оплачен</span> : <span className="text-danger">Не оплачен</span>}
          </div>
          <div className="my-2">
            {fine?.data.photos.map((item) => (
              <img src={item.url} alt="fines" key={item.name} width="400" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinePage;
