import { format } from 'date-fns';
import { IAdmin } from 'types/AdminTypes';
import { IRequest, Knowledge, serviceTypes } from 'types/CareServiceTypes';
import {
  ICarRecommendation,
  ICarFilters,
  ICarInfo,
  IServiceCar,
  IEmployeeCarService,
  ICarFilterValues,
} from 'types/CarTypes';
import { IUserInfo } from 'types/UserTypes';
import { IRentPayment, IRentsFilters } from 'types/RentPaymentTypes';
import { IVideoCallHistory } from 'types/ChatTypes';

export const formatDate = (date: string | Date | null | undefined, template: string) => {
  return date ? format(new Date(date), template) : 'Не верный формат даты';
};

// распологает поля в объекте в нужном порядке, для того, чтобы в этом порядке, они вставлялись в переиспользуемый компонент таблицы
export const formatCarDataForTable = (data?: ICarInfo[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    brand: dataItem.brand,
    model: dataItem.model,
    modification: dataItem.modification,
    mileage: dataItem.mileage,
    registration_number: dataItem.registration_number,
    year: dataItem.year,
    sts: dataItem.sts,
    vin: dataItem.vin,
    connectType: dataItem.connectType,
    imei: dataItem.imei,
    alyans_connect_code: dataItem.code,
  }));
};

export const formatCarDataForBackEndFilters = (data: ICarFilterValues): ICarFilters => {
  return {
    'filters[mark_id]': data.mark,
    'filters[model_id]': data.model,
    'filters[generation_id]': data.generation,
    'filters[serie_id]': data.serie,
    'filters[modification_id]': data.modification,
    'filters[equipment_id]': data.equipment,
    'filters[user_id]': data.user,
    per_page: data?.per_page,
    page: data?.page,
  };
};

export const formatCarCommentDataForTable = (data?: ICarRecommendation[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    model: dataItem.model,
    generation: dataItem.generation,
    serie: dataItem.serie,
    modification: dataItem.modification,
    mileage: dataItem.mileage,
    author: dataItem.author,
    comment: dataItem.comment,
  }));
};

export const formatCarTOCommentDataForTable = (data?: ICarRecommendation[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    model: dataItem.model,
    generation: dataItem.generation,
    serie: dataItem.serie,
    modification: dataItem.modification,
    mileage: dataItem.mileage,
    price: dataItem.price,
    comment: dataItem.comment,
  }));
};

export const formatCarCommentDataForBackend = ({
  mark,
  model,
  generation,
  serie,
  modification,
  equipment,
  page,
  per_page,
}: any) => {
  return {
    id_car_mark: mark,
    id_car_model: model,
    id_car_generation: generation,
    id_car_serie: serie,
    id_car_modification: modification,
    id_car_equipment: equipment,
    page: page,
    per_page: per_page,
  };
};

export const formatUserDataForTable = (data?: IUserInfo[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    email: dataItem.email,
    name: dataItem.name,
    surname: dataItem.surname,
    patronymic: dataItem.patronymic,
    address: dataItem.address,
    phone: dataItem.phone,
  }));
};

// на бэке, для Типа сервиса заботы, хранится id, а не имя, поэтому имя нужно найти по id
export const getServiceTypeNameById = (typeId?: string) => {
  return serviceTypes.find((type) => type.id.toString() === typeId)?.name;
};

export const formatRequestDataForTable = (requests?: IRequest[]) => {
  return requests?.map((request) => ({
    id: request.id,
    document: request.info?.documentid ?? request.info?.documentRealizationId ?? '-',
    date: formatDate(request.created_at, 'HH:mm dd.MM.yyyy'),
    typeName: getServiceTypeNameById(request.type),
    statusName: request?.status?.name || '',
    car: request.car ? `${request.car.brand} ${request.car.model}` : '-',
    adress: request.address,
    paymentType: request.info?.card_payment ? (request.info.card_payment ? 'Оплата картой' : 'Оплата наличными') : '-',
  }));
};

export const formatDataForKnowledgeTable = (data: Knowledge[]) => {
  return data.map(({source, brandName, modelName, engineType, year, reason, result_works, result_goods, result_solution, errorCodes}) => ({
    source: source ?? '-',
    brandName: brandName ?? '-',
    modelName: modelName ?? '-',
    engineType: engineType ?? '-',
    year: year ?? '-',
    reason: reason ?? '-',
    result_works: result_works.map(({ name }) => '∘ ' + name).join('\n'),
    result_goods: result_goods.map(({ name }) => '∘ ' + name).join('\n'),
    result_solution: result_solution.map((item) => (typeof item === 'boolean' ? item ? 'Помогло' : 'Не помогло' : item)).join('\n'),
    errorCodes: errorCodes ? (errorCodes.map(item => item.code).join('\n') ?? '-') : '-'
  }))
}

export const formatUserRequestsDataForTable = (requests?: IUserInfo['care_applications']) => {
  return requests?.map((request) => ({
    id: request.id,
    typeName: getServiceTypeNameById(request.type),
    statusName: request.status?.name || '',
    address: request.address,
    date: formatDate(request.created_at, 'HH:mm dd.MM.yyyy'),
  }));
};

export const formatCreateRequestDataForBackend = (data: any) => {
  return {
    user_id: data.userId,
    care_status_id: data.statusId,
    type: data.typeId,
    longitude: data.longitude,
    latitude: data.latitude,
  };
};

export const formatAdminDataForTable = (data?: IAdmin[]) => {
  return data?.map((admin) => ({
    id: admin.id,
    surname: admin.surname,
    name: admin.name,
    patronymic: admin.patronymic,
    email: admin.email,
    role: admin.role?.join(', '),
  }));
};

export const formatServiceCarDataForTable = (data?: IServiceCar[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    name: dataItem.name,
    type: dataItem.type,
    imei: dataItem.device_id,
  }));
};

export const formatEmployeeCarServiceDataForTable = (data?: IEmployeeCarService[]) => {
  return data?.map((dataItem) => ({
    id: dataItem.id,
    name: dataItem.name,
    telegram_id: dataItem.telegram_id,
    type: dataItem.type,
    carName: dataItem.service_car_id?.name,
  }));
};

//альянс - рент

export const formatRentCarsServiceDataForBackend = (data: IRentPayment) => {
  return {
    page: data.currentPage,
    'filters[paydate_from]': data.startDateFilter != null ? formatDate(data.startDateFilter, 'yyyy-MM-dd') : '',
    'filters[paydate_to]': data.endDateFilter != null ? formatDate(data.endDateFilter, 'yyyy-MM-dd') : '',
    per_page: data.perPage,
  };
};

export const formatRentServiceDataForBackend = (data: IRentsFilters) => {
  return {
    page: data.page,
    'filters[type]': data.type ? 1 : 0,
    'filters[rentdate_from]': data.from !== null ? data.from : undefined,
    'filters[rentdate_to]': data.to !== null ? data.to : undefined,
  };
};

export const formatVideoCallsHistory = (data: IVideoCallHistory[]) => {
  return data.map((item) => {
    const noFound = 'Нет данных';
    const {
      name = noFound,
      surname = noFound,
      patronymic = noFound,
      phone = noFound,
      email = noFound,
    } = item?.user ?? {};

    return {
      id: item?.user?.id,
      holiday: !item?.day_off ? 'Да' : 'Нет',
      name,
      surname,
      patronymic,
      phone,
      email,
    };
  });
};
