export const formatPhone = (value) => {
  return value.replace(/[\s+-]/g, "").slice(1);
};

export const formatComfortablePhone = (v) => {
  return v
    ? `+${v[0]} (${v.slice(1, 4)}) ${v.slice(4, 7)}-${v[7]}${v[8]}-${v[9]}${
        v[10]
      }`
    : "...";
};
